import React from 'react';

import styles from './miniheader.module.scss';
import teamImage from '../../assets/images/team-banner.png';
import technologyImage from '../../assets/images/technology-banner.png';
import clientsImage from '../../assets/images/clients-banner.png';
import newsImage from '../../assets/images/news-banner.png';

import PropTypes from 'prop-types';

const imagesData = {
  team: {
    src: teamImage,
    alt: 'Team banner',
  },
  technology: {
    src: technologyImage,
    alt: 'Technology banner',
  },
  clients: {
    src: clientsImage,
    alt: 'Clients banner',
  },
  news: {
    src: newsImage,
    alt: 'News banner',
  },
};

const MiniHeader = ({ title, subtitle, explanation = null, description, image }) => {
  return (
    <div>
      {/* photos div */}
      <div className={styles.banner}>
        <img
          className={styles.bannerImage}
          src={imagesData[image].src}
          alt={imagesData[image].alt}
        />
      </div>
      {/* text block div */}
      <div className={'container'}>
        <div>
          <div className={styles.block}>
            <h1 className={styles.title}>{title}</h1>
            <h3 className={styles.subtitle}>{subtitle}</h3>
            {explanation && <h5 className={styles.explanation}>{explanation}</h5>}
          </div>
          {/* quick description div */}
          <div className={styles.descriptionContainer}>
            <span className={styles.description}>{description}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

MiniHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  explanation: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
};

export default MiniHeader;
