import React, { useEffect } from 'react';
import MetaTags from 'react-meta-tags';

import PropTypes from 'prop-types';
import styles from './article.module.scss';

const Article = ({
  title,
  children,
  headerBanner,
  headerBannerAlt,
  footerBanner,
  footerBannerAlt,
  imgSrc,
}) => {
  useEffect(() => {
    document.title = title || '';
  }, [title]);

  return (
    <div className={styles.articleContainer}>
      <MetaTags>
        <meta property="og:site_name" content="Xtramile" />
        <meta property="og:type" content="article" />
        <meta
          name="title"
          property="og:title"
          content={title || "L'innovation au service du recrutement & du capital humain"}
        />
        <meta name="image" property="og:image" content={`xtramile.io${imgSrc}`} />
        <meta
          name="description"
          property="og:description"
          content="Que eût cru que la finance, la publicité et les ressources humaines aient un jour plus d'une
        chose en commun ? Au-delà d'être trois entités, a priori assez séparées, de l'économie
        nationale..."
        />
        <meta property="og:url" content="https://xtramile.io/article/bpi-deep-tech" />
      </MetaTags>
      {headerBanner && (
        <img src={headerBanner} alt={headerBannerAlt} className={styles.headerBanner} />
      )}
      <div className={styles.articleCard}>
        <h1>{title}</h1>
        <div className={styles.articleBodyContainer}>
          <div className={styles.articleBody}>{children}</div>
        </div>
        {footerBanner && (
          <img src={footerBanner} alt={footerBannerAlt} className={styles.footerBanner} />
        )}
      </div>
    </div>
  );
};

Article.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
  footerBanner: PropTypes.string,
  footerBannerAlt: PropTypes.string,
  headerBanner: PropTypes.string,
  headerBannerAlt: PropTypes.string,
  imgSrc: PropTypes.string,
};

export default Article;
