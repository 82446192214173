import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-elastic-carousel';

import quotesYellow from 'assets/icons/quotes-yellow.svg';
import styles from './customersTestifySlider.module.scss';
import customersReviewsData from 'data/customersReviewsData';

import CollapsableSlider from 'components/collapsableSlider/collapsableSlider';

export default class CustomersTestifySlider extends Component {
  constructor(props) {
    super(props);

    this.goto = this.goto.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { activeSlide } = this.props;

    if (activeSlide !== undefined && prevProps.activeSlide !== activeSlide) {
      this.goto(activeSlide);
    }
  }

  goto(index) {
    this.carousel.goTo(index);
  }

  render() {
    const { t, shouldGoCollapse } = this.props;

    return (
      <Carousel
        itemsToShow={1}
        showArrows={false}
        enableSwipe={false}
        enableMouseSwipe={false}
        preventDefaultTouchmoveEvent={true}
        ref={(ref) => (this.carousel = ref)}
      >
        {shouldGoCollapse && <CollapsableSlider t={t} slide={customersReviewsData[1]} />}
        {!shouldGoCollapse && (
          <div className={styles.post}>
            <div className={styles.postLeftPart}>
              <div className={styles.postLeftPartLogo}>
                <img src={customersReviewsData[1].logo} alt="logo" />
              </div>

              <div className={styles.postText}>
                <p>
                  <img
                    src={quotesYellow}
                    alt="quotes"
                    style={{ width: '50px', height: '42px', marginRight: '5px' }}
                  />
                  {t(`quote.${customersReviewsData[1].quote}`)}”
                </p>
              </div>

              <div className={styles.postAuthorNameContainer}>
                <div>{customersReviewsData[1].author}</div>
                <div>{t(`positions.${customersReviewsData[1].position}`)}</div>
              </div>

              <div className={styles.videoContainer}>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/I4tbWmeIOIs"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>

            <div className={styles.postRightPart}>
              <div className={styles.brief}>
                <h4 className={styles.title}>{t('titles.THE BRIEF')}</h4>
                <div>
                  <ol>
                    <li>{t('li.stedy.New platform Free - Spears on IT missions France')}</li>
                    <li>{t('li.stedy.100 types of offers different')}</li>
                    <li>{t('li.stedy.Acquisition of Talents')}</li>
                    <li>{t('li.stedy.Monitoring performance of broadcast channels')}</li>
                  </ol>
                </div>

                <div>
                  <ul>
                    <li>
                      {t(
                        'li.stedy.Difficulties in sourcing and adapting budgets according to suitable channels'
                      )}
                    </li>
                    <li>{t('li.stedy.No visibility pre-existing')}</li>
                    <li>
                      {t(
                        'li.stedy.KPIs that are difficult to follow and very heterogeneous in channel function'
                      )}
                    </li>
                    <li>{t('li.stedy.Lack of ROI measurement')}</li>
                  </ul>
                </div>

                <div className={styles.row}>
                  <div style={{ minWidth: '40%' }}>
                    <h4 className={styles.title}>{t('titles.THE GOALS')}</h4>
                    <ul>
                      <li>{t('li.stedy.Create performance in a way')}</li>
                      <li>{t('li.stedy.Recruitment of 600 talents')}</li>
                      <li>{t('li.stedy.CPA target € 50')}</li>
                    </ul>
                  </div>
                  <div className={styles.blockResult}>
                    <h4 className={styles.title}>{t('titles.THE RESULTS')}</h4>
                    <ul>
                      <li>
                        {t('li.stedy.Optimization')}
                        <span>{t('li.stedy.on any type of channel')}</span>
                      </li>
                      <li>
                        {t('li.Performance')}
                        <ul>
                          <li>
                            {t('li.stedy.+800')}
                            <span>{t('li.stedy.applicants from different sources')}</span>
                          </li>
                          <li>
                            <span>{t('li.stedy.CPA way of')}</span>
                            {t('li.stedy.€36')}
                            <span>{t('li.stedy.28% vs goal')}</span>
                          </li>
                        </ul>
                      </li>
                      <li>{t('li.stedy.Strategy for a perpetuation long-term (validated)')}</li>
                      <li>
                        {t(
                          'li.stedy.Help with setting in place of a 2021 budget based on of KPIs real'
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Carousel>
    );
  }
}

CustomersTestifySlider.propTypes = {
  t: PropTypes.func.isRequired,
  activeSlide: PropTypes.number,
  shouldGoCollapse: PropTypes.bool.isRequired,
};
