import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import styles from './dataCvCollapse.module.scss';
import PersonalInformation from './personalInformation';
import Education from './education';
import Experience from './experience';
import Skills from './skills';
import CustomButton from 'components/customButton/customButton';

const DataCvCollapse = ({ currentCv, demo, t, backToInitial, apply, goToContactForm }) => {
  if (!currentCv)
    return <div className={styles.noDataMessage}>{t('valuesOfCV.Here no data yet')}</div>;
  const { data: identitiesData } = currentCv.results.identities;
  const { data: educationData } = currentCv.results.educations;
  const { data: experienceData } = currentCv.results.experiences;
  const { data: skillsData } = currentCv.results.skills;
  const interestsData = currentCv.results?.interests?.data;

  const restructureSkillsData = (skillsData, interestsData) => {
    let hard_skill = [];
    let soft_skill = [];
    let language = [];
    let interests = [];

    skillsData.forEach((item) => {
      if (item.hard_skill.length > 0) {
        hard_skill = [...hard_skill, ...item.hard_skill];
      }

      if (item.soft_skill.length > 0) {
        soft_skill = [...soft_skill, ...item.soft_skill];
      }

      if (item.language.length > 0) {
        language = [...language, ...item.language];
      }
    });

    // we should return data in such view /{ plaintext: 'soccer' }/
    if (interestsData) {
      interests.forEach((item) => {
        if (item.interests && item.interests.type && item.interests.type.value) {
          interests = [...interests, ...item.interests.type.value];
        }
      });
    }

    return {
      hard_skill,
      soft_skill,
      language,
      interests,
    };
  };

  let restructuredSkillsData = useMemo(() => {
    return skillsData || interestsData ? restructureSkillsData(skillsData, interestsData) : null;
  }, [skillsData, interestsData]);

  return (
    <div className={styles.dataCollapseContainer}>
      <h2 className={styles.dataCollapseTitle}>{t('valuesOfCV.Your CV')}</h2>

      <PersonalInformation t={t} data={identitiesData} />

      <Education t={t} data={educationData} />

      <Experience t={t} data={experienceData} />

      <Skills t={t} data={restructuredSkillsData} />

      <div className={styles.buttonsContainer}>
        <CustomButton text={t('buttons.Try another file')} onClick={backToInitial} />
        {!demo && <CustomButton text={t('buttons.Apply')} onClick={apply} />}
        {demo && <CustomButton text={t('text.Contact us')} onClick={goToContactForm} />}
      </div>
    </div>
  );
};

DataCvCollapse.propTypes = {
  t: PropTypes.func.isRequired,
  demo: PropTypes.bool,
  currentCv: PropTypes.object.isRequired,
  backToInitial: PropTypes.func.isRequired,
  apply: PropTypes.func.isRequired,
  goToContactForm: PropTypes.func.isRequired,
};

export default DataCvCollapse;
