import React from 'react';
import PropTypes from 'prop-types';

import styles from './atsConnexion.module.scss';

import { logoMarketplaces } from 'data/blocksInfo';

const ATSConnexion = ({ t }) => {
  return (
    <section className={styles.sectionATSConnexion}>
      <div className={styles.wrapper}>
        <div className={styles.leftCol}>
          <h1 className={styles.title}>{t('titles.An application')}</h1>
          <h1 className={styles.title}>{t('titles.connected')}</h1>
          <h1 className={styles.title}>{t('titles.to your ATS')}</h1>
          <p className={styles.description}>
            {t('text.Our solution is not intended to replace your application management system')}
          </p>
          <p className={styles.description}>{t('text.A true technological connector')}</p>
        </div>
        <div className={styles.rightCol}>
          <div className={styles.card}>
            <div className={styles.titleContainer}>
              <h2 className={styles.cardTitle}>
                {t('titles.Find our services on the following marketplaces')}
              </h2>
            </div>
            <div className={styles.logos}>
              {logoMarketplaces.map((logo) => (
                <div key={logo.alt} className={styles.logoContainer}>
                  <img src={logo.src} alt={logo.alt} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ATSConnexion.propTypes = {
  t: PropTypes.func.isRequired,
};

export default ATSConnexion;
