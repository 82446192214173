import React from 'react';
import PropTypes from 'prop-types';
// import Carousel from 'react-elastic-carousel';

import styles from './howItWorks.module.scss';
import CustomersTestifySlider from 'components/x-serv/customersTestifySlider/customersTestifySlider';
// import spie from 'assets/icons/logo/spie.png';
// import quotesYellow from 'assets/icons/quotes-yellow.svg';

const HowItWorks = ({ t, shouldGoCollapse }) => {
  return (
    <section className={`howItWorks ${styles.section}`}>
      <div className={styles.wrapper}>
        <div className={`container ${styles.container}`}>
          <h3 className={styles.title}>{t('titles.How does it work ?')}</h3>

          <div className={styles.contentWrapper}>
            <div className={styles.textContainer}>
              <div
                dangerouslySetInnerHTML={{
                  __html: t('text.x-serv_Thanks to the integrated offer parsing'),
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={`container ${styles.container}`}>
        <div className={styles.ourCustomersTestify}>
          <h3 className={styles.ourCustomersTestifyTitle}>{t('titles.Our customers testify')}</h3>
          <CustomersTestifySlider shouldGoCollapse={shouldGoCollapse} t={t} />
        </div>
      </div>
    </section>
  );
};

HowItWorks.propTypes = {
  t: PropTypes.func.isRequired,
  shouldGoCollapse: PropTypes.bool.isRequired,
};

export default HowItWorks;
