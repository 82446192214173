import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import styles from './news.module.scss';
import MyCarousel from 'components/news/carousel/carousel';

const News = ({ t }) => {
  return (
    <section className={styles.sectionNews}>
      <div className={styles.wrapper}>
        <div className="container">
          <div className={styles.news}>
            <div className={styles.newsTitleContainer}>
              <h3 className={styles.title}>{t('titles.Our news')}</h3>
            </div>
            <MyCarousel t={t} />
          </div>
        </div>
      </div>
    </section>
  );
};

News.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(News);
