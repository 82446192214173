import React from 'react';
import PropTypes from 'prop-types';

import styles from './fileItem.module.scss';
import Icon from 'components/icon/Icon';

const FileItem = ({ fileObj, index, isUploading, extensions, deleteFile, retryCallback }) => {
  return (
    <div>
      <div className={styles.fileItem}>
        {!fileObj.hasFormatUnsupportedErr ? (
          <img src={extensions[fileObj.extension]} alt="type of file" className={styles.icon} />
        ) : (
          <Icon
            name="similar"
            className={styles.icon}
            width="20"
            height="20"
            color="#f29588"
            viewbox="0 0 512 512"
          />
        )}

        <div className={styles.fileNameWrapper}>
          <span className={styles.fileName}>{fileObj.file.name}</span>

          {!retryCallback && (
            <span
              className={isUploading ? `${styles.closeFile} ${styles.disabled}` : styles.closeFile}
              onClick={(event) => deleteFile(index, event)}
            >
              <Icon name="close-circle" width="20" height="20" viewbox="0 0 20 20" />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

FileItem.propTypes = {
  fileObj: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  isUploading: PropTypes.bool.isRequired,
  extensions: PropTypes.object.isRequired,
  deleteFile: PropTypes.func.isRequired,
  retryCallback: PropTypes.any,
};

export default FileItem;
