import React, { useEffect, useRef } from 'react';
import styles from './team.module.scss';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';

//own component
import { MiniHeader } from '../../components/mini-header';
import { TeamMemberCard } from '../../components/team';
//hidden while parsing isn't stable
import Uploader from 'components/uploader/uploader';

//data
import employeesCategories from '../../data/employeesCategories.js';

//assets
import Agilite from '../../assets/icons/Agilite.svg';
import Innovation from '../../assets/icons/Innovation.svg';
import Responsable from '../../assets/icons/Responsable.svg';
import Transparence from '../../assets/icons/Transparence.svg';
import pictoRetD from '../../assets/icons/picto_RetD.svg';
import pictoDev from '../../assets/icons/picto_Dev.svg';
import pictoMarketingDigital from '../../assets/icons/picto_MarketingDigital.svg';
import pictoSourcingRH from '../../assets/icons/picto_sourcingRH.svg';
import TagManager from 'react-gtm-module';

const TeamPage = ({ t, title }) => {
  useEffect(() => {
    document.title = title || '';
  }, [title]);

  TagManager.dataLayer({
    dataLayer: {
      event: 'pageview',
      pagePath: '/team',
      pageTitle: 'Team',
    },
  });

  //hidden while parsing isn't stable
  const history = useHistory();
  const dragndrop = useRef(null);

  useEffect(() => {
    if (history?.location?.pathname.includes('dragndrop')) {
      dragndrop && dragndrop.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [history, dragndrop]);

  const isSmall = useMediaQuery({
    query: '(max-width: 880px)',
  });

  const isVerySmall = useMediaQuery({
    query: '(max-width: 630px)',
  });

  return (
    <div className={styles.teamPageContainer}>
      <MiniHeader
        title={t('miniheader.Team')}
        subtitle={t('miniheader.Diversity at the service of HR innovation')}
        description={t(
          'miniheader.Xtramile is multicultural team'
        )}
        image={'team'}
      />

      <div className="container">
        <section className={styles.teamSection}>
          <div className={styles.teamPictureContainer} />
          <div className={styles.greyRectangle}>
            <div className={styles.greyRectangleTextSection}>
              {t(
                'text.Our French and international employees'
              )}
            </div>
          </div>
        </section>
      </div>
      <section className={styles.centerContent}>
        <div className={styles.ourValues}>
          <h3 className={styles.title}>{t('titles.Our Values')}</h3>
          <div className={styles.text}>
            {t('text.With a go-to-market in 2020, Xtramile was built')}
          </div>
          <div className={styles.rowForIcons}>
            <div className={styles.iconItems}>
              <img src={Transparence} alt="icon transparency" className={styles.imgItem} />
              {t('text.Transparency')}
            </div>
            <div className={styles.iconItems}>
              <img src={Agilite} alt="icon agility" className={styles.imgItem} />
              {t('text.Agility')}
            </div>
            <div className={styles.iconItems}>
              <img src={Innovation} alt="icon innovation" className={styles.imgItem} />
              {t('text.Innovation')}
            </div>
            <div className={styles.iconItems}>
              <img src={Responsable} alt="icon responsability" className={styles.imgItem} />
              {t('text.Responsibility')}
            </div>
          </div>
        </div>
      </section>

      {/* FINISH THI SECTION LATER */}
      <section className={styles.teamTreeSection}>
        {!isSmall && <div className={styles.blueRectangle}></div>}
        <div className={styles.overBlueRectangle}>
          <h3 className={styles.title} style={{ marginBottom: '80px' }}>
            {t('titles.Our team')}
          </h3>
          {employeesCategories.map((group) => (
            <div key={group.category} className={styles.rowForEmployees}>
              <div className={styles.subtitleContainer}>
               <h3 className={styles.subtitle}>{ !group.category.includes('#') && t(`titles.${group.category}`)}</h3>
               <h3 className={styles.subtitleSecondLine}>{ group.categoryDesc }</h3>
              </div>
              <div className={styles.rowOfCard}>
                {group.list.map((person) => (
                  <TeamMemberCard key={person.name} data={person} />
                ))}
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className={styles.ourExpertsSection}>
        <div className={styles.ourExperts}>
          <div>
            <h3 className={styles.title}>{t('titles.Our experts')}</h3>
          </div>
          <div className={styles.subtitleForExperts}>
            {t('text.Our employees are divided into four technological and HR areas of expertise')}
          </div>
          <div className={styles.blockByExpertise}>
            {!isVerySmall && (
              <img src={pictoRetD} alt="icon r&d" className={styles.iconByExpertise} />
            )}
            <div className={`${styles.blockOfTextByExpertise} ${styles.left}`}>
              <div style={{ display: 'flex' }}>
                {isVerySmall && (
                  <img src={pictoRetD} alt="icon r&d" className={styles.iconByExpertise} />
                )}
                <h3 className={styles.titleBlockOfExpertise}>{t('titles.R&D Lab')}</h3>
              </div>
              <span className={styles.descriptionByExpertise}>{t('text.descriptionR&DLab')}</span>
            </div>
          </div>
          <div className={`${styles.blockByExpertise} ${styles.right}`}>
            <div className={`${styles.blockOfTextByExpertise} ${styles.right}`}>
              <div style={{ display: 'flex' }}>
                <h3 className={styles.titleBlockOfExpertise}>{t('titles.Development')}</h3>
                {isVerySmall && (
                  <img src={pictoDev} alt="icon dev" className={styles.iconByExpertise} />
                )}
              </div>
              <span className={styles.descriptionByExpertise}>
                {t('text.descriptionDevelopment')}
              </span>
            </div>
            {!isVerySmall && (
              <img src={pictoDev} alt="icon dev" className={styles.iconByExpertise} />
            )}
          </div>
          <div className={styles.blockByExpertise}>
            {!isVerySmall && (
              <img
                src={pictoMarketingDigital}
                alt="icon marketing digital"
                className={styles.iconByExpertise}
              />
            )}
            <div className={`${styles.blockOfTextByExpertise} ${styles.left}`}>
              <div style={{ display: 'flex' }}>
                {isVerySmall && (
                  <img
                    src={pictoMarketingDigital}
                    alt="icon marketing digital"
                    className={styles.iconByExpertise}
                  />
                )}
                <h3 className={styles.titleBlockOfExpertise}>{t('titles.Digital Marketing')}</h3>
              </div>
              <span className={styles.descriptionByExpertise}>
                {t('text.descriptionDigitalMarketing')}
              </span>
            </div>
          </div>
          <div className={`${styles.blockByExpertise} ${styles.right}`}>
            <div className={`${styles.blockOfTextByExpertise} ${styles.right}`}>
              <div style={{ display: 'flex' }}>
                <h3 className={styles.titleBlockOfExpertise}>{t('titles.HR sourcing')}</h3>
                {isVerySmall && (
                  <img
                    src={pictoSourcingRH}
                    alt="icon sourcing RH"
                    className={styles.iconByExpertise}
                  />
                )}
              </div>
              <span className={styles.descriptionByExpertise}>
                {t('text.descriptionHRSourcing')}
              </span>
            </div>
            {!isVerySmall && (
              <img
                src={pictoSourcingRH}
                alt="icon sourcing RH"
                className={styles.iconByExpertise}
              />
            )}
          </div>
        </div>
      </section>
      {/* hidden while parsing isn't stable */}
      <div ref={dragndrop}>
        <Uploader t={t} typeDocument="cv" demo={false} />
      </div>
    </div>
  );
};

TeamPage.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default TeamPage;
