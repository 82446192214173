import React from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';

import styles from './initialUploader.module.scss';
import Icon from 'components/icon/Icon';
import CustomLoader from 'components/customLoader/CustomLoader';
import CustomCheckbox from '../customCheckbox/customCheckbox';
import FileItem from '../fileItem/fileItem';
import CustomButton from 'components/customButton/customButton';
import Button from 'components/button/button';

const InitialUploader = ({
  t,
  files,
  extensions,
  hasDataAgreement,
  readyToParse,
  hasMaxCountErr,
  hasUnsupportedFormatFiles,
  hasMaxFilesSizeErr,
  isTryingToSubmit,
  isUploading,
  isFailedParsingResult,
  deleteFile,
  toggleDataAgreement,
  onDrop,
  backToInitial,
  typeDocument,
  onParseCV,
  onParseJob,
  demo,
}) => {
  const noErrors = !(
    hasMaxCountErr ||
    hasUnsupportedFormatFiles ||
    hasMaxFilesSizeErr ||
    isFailedParsingResult
  );

  return (
    <div className={styles.initialUploader}>
      {typeDocument === 'cv' && demo === false && (
        <div className={styles.textBefore}>
          <h3 className={styles.title}>{t('titles.Join us')}</h3>
          <p className={styles.titleText}>{t('text.Would you like to send your spontaneous application')}</p>
        </div>
      )}

      <Dropzone onDrop={onDrop} multiple={false} maxFiles={1}>
        {({ getRootProps, getInputProps }) => (
          <div className={styles.dropzoneContainer}>
            {!isUploading && ((noErrors && isTryingToSubmit) || !isTryingToSubmit) && (
              <div {...getRootProps({ className: styles.dropzone })}>
                <input {...getInputProps()} />

                {files.length === 0 ? (
                  <div className={styles.uploaderLabel}>
                    <div className={styles.uploaderLabelPart1}>
                      <span className={styles.uploaderIcon}>
                        <Icon name="upload-designed" width="37" height="37" color="#000" />
                      </span>

                      <div className={styles.selectFile}>
                        <span>{t('labels.Drag & Drop or')}</span>
                        &nbsp;
                        <span className={styles.orangeLink}>{t('labels.Choose')}</span>
                        &nbsp;
                        <span>{t('labels.your file')}</span>
                      </div>
                    </div>

                    <div className={styles.allowedFormats}>
                      {t('labels.PDF, JPG or PNG file formats are allowed')}
                    </div>
                  </div>
                ) : (
                  <ul className={styles.filesList}>
                    {files.length > 0 &&
                      files.map((item, index) => {
                        return (
                          <li key={item.id} className={styles.li}>
                            <FileItem
                              fileObj={item}
                              index={index}
                              isUploading={isUploading}
                              extensions={extensions}
                              deleteFile={deleteFile}
                              retryCallback={false}
                            />
                          </li>
                        );
                      })}
                  </ul>
                )}
              </div>
            )}

            {isTryingToSubmit && (!noErrors || isUploading) && (
              <div className={styles.actionsContainer}>
                {isUploading && (
                  <div className={styles.loaderContainer}>
                    <CustomLoader />

                    <span className={styles.loaderText}>{t('titles.Parsing in progress')}</span>
                  </div>
                )}

                {!noErrors && !isUploading && (
                  <div className={styles.mainError}>
                    <div className={styles.mainErrorTitle}>
                      <Icon name="close-circle" width="40" height="40" viewbox="0 0 20 20" />
                      {hasUnsupportedFormatFiles && <span>{t('errors.Invalid format')}</span>}
                      {hasMaxFilesSizeErr && <span>{t('errors.File too large')}</span>}
                      {isFailedParsingResult && <span>{t('errors.Parsing error')}</span>}
                    </div>

                    <div className={styles.mainErrorText}>
                      {hasMaxFilesSizeErr && (
                        <span>{t('errors.Files under 30Mb are allowed')}</span>
                      )}
                      {hasUnsupportedFormatFiles && (
                        <span>{t('errors.File formats PDF, JPEG, PNG are allowed')}</span>
                      )}
                      {isFailedParsingResult && <span>{t('errors.Parsing failed')}</span>}
                    </div>
                  </div>
                )}
              </div>
            )}

            {noErrors && !isUploading && typeDocument === 'cv' && demo === false && (
              <div className={styles.dataAgreement}>
                <div className={styles.dataAgreementText}>
                  <CustomCheckbox
                    t={t}
                    hasDataAgreement={hasDataAgreement}
                    toggleDataAgreement={toggleDataAgreement}
                  />
                </div>

                {!hasDataAgreement && isTryingToSubmit ? (
                  <span className={styles.dataAgreementErr}>
                    {t(
                      'errors.Please accept that you are willing to share your personal data with our services'
                    )}
                  </span>
                ) : (
                  <div className={styles.saveSpaceBlock}> </div>
                )}
              </div>
            )}

            {noErrors && !isUploading && typeDocument === 'job' && (
              <div className={styles.wrapperButtonParse}>
                <Button
                  disabled={!readyToParse}
                  btnView={`${'button-warn-sm'} ${'small'} button-parse`}
                  text={t('buttons.PARSING')}
                  onClick={onParseJob}
                />
              </div>
            )}

            {noErrors && !isUploading && typeDocument === 'cv' && demo === true && (
              <div className={styles.wrapperButtonParse}>
                <Button
                  disabled={!readyToParse}
                  btnView={`${'button-warn-sm'} ${'small'} button-parse`}
                  text={t('buttons.PARSING')}
                  onClick={onParseCV}
                />
              </div>
            )}

            <div className={styles.buttonsWrapper}>
              {!noErrors && <CustomButton text={t('buttons.Try again')} onClick={backToInitial} />}
            </div>
          </div>
        )}
      </Dropzone>
    </div>
  );
};

InitialUploader.propTypes = {
  t: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
  extensions: PropTypes.object.isRequired,
  hasDataAgreement: PropTypes.bool.isRequired,
  readyToParse: PropTypes.bool.isRequired,
  hasMaxCountErr: PropTypes.bool.isRequired,
  hasUnsupportedFormatFiles: PropTypes.bool.isRequired,
  hasMaxFilesSizeErr: PropTypes.bool.isRequired,
  isTryingToSubmit: PropTypes.bool.isRequired,
  isUploading: PropTypes.bool.isRequired,
  isFailedParsingResult: PropTypes.bool.isRequired,
  deleteFile: PropTypes.func.isRequired,
  onParseCV: PropTypes.func.isRequired,
  toggleDataAgreement: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  backToInitial: PropTypes.func,
  typeDocument: PropTypes.string.isRequired,
  onParseJob: PropTypes.func.isRequired,
  demo: PropTypes.bool,
};

export default InitialUploader;
