import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import cx from 'classnames';

import styles from './form.module.scss';
import Input from 'components/input/input';
import Button from 'components/button/button';
import Select from 'components/select/select';

const namesOfFieldsPart1 = ['email', 'lastname', 'position', 'phonenumber'];
const namesOfFieldsPart2 = ['firstname', 'company'];

const Form = ({ dataForm, t }) => {
  const initialState = {
    firstname: { value: '', showError: false },
    lastname: { value: '', showError: false },
    email: { value: '', showError: false, errorMessage: 'Email must be formatted correctly' },
    company: { value: '', showError: false },
    country: { value: '', showError: false },
    code: { value: '' },
    position: { value: '', showError: false },
    phonenumber: { value: '', showError: false },
    click: false,
    // agreement: { value: '', showError: false }, // should use instead of "click"
  };

  const { inputLabels, countries } = dataForm;
  const [areDataSent, setAreDataSent] = useState(false);
  const [areDataResolved, setAreDataResolved] = useState(false);
  const [stateData, setStateData] = useState(initialState);
  const [disableSendButton, setDisableSendButton] = useState(true);
  const [response, setResponse] = useState('');

  const checkIsValid = (state) =>
    Object.entries(state || stateData).some((item) =>
      item[0] === 'click' ? !item[1] : !item[1].value
    );

  const fnChecked = (e) => {
    e.stopPropogation;

    const newState = {
      ...stateData,
      click: e.target.checked,
    };

    setDisableSendButton(checkIsValid(newState));

    if (e.target.checked !== undefined) setStateData(newState);
  };

  //choose country & country code from options array
  const setCountry = (country, countries) => {
    const countryObj = countries.filter((item) => item.name === country);
    return countryObj[0];
  };

  const validateEmail = (value) =>
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value);

  const inputsVerification = (e) => {
    // exception for select field
    const name = e.target.name === 'country' ? e.target.name : e.target.dataset.name;
    const { value } = e.target;

    if (name === 'country') return setStateCountry(name, value); //processing of the country selection field
    if (name === 'phonenumber') return setStatePhonnumber(name, value); //processing the phone number input field

    const newState = {
      ...stateData,
      [name]: {
        value,
        showError: name === 'email' ? !validateEmail(value) : !value,
        errorMessage: stateData[name].errorMessage || '',
      },
    };

    setDisableSendButton(checkIsValid(newState));
    setStateData(newState);
  };

  //changing the state of the country selection field
  const setStateCountry = (name, value) => {
    const country = stateData.country ? setCountry(value, countries) : '';

    if (country !== undefined) {
      const newState = {
        ...stateData,
        phonenumber: {
          value: country.code,
          showError: name === 'email' ? !validateEmail(value) : !value,
          errorMessage: stateData[name].errorMessage || '',
        },
        code: {
          value: country.code,
        },
        [name]: {
          value,
          showError: name === 'email' ? !validateEmail(value) : !value,
          errorMessage: stateData[name].errorMessage || '',
        },
      };

      setDisableSendButton(checkIsValid(newState));
      setStateData(newState);
    }
    return;
  };

  //change the state of the phone number input field
  const setStatePhonnumber = (name, value) => {
    let countryObj;
    //selection of a country object by the entered code
    countries.forEach((item) => {
      if (value.includes(item.code)) return (countryObj = item);
    });

    //change the state of the phone number input field
    if (countryObj.name !== '- Choose a value -') {
      {
        /*changing the state of the phone number input field,
      provided that a country is selected from the list of countries with the same code */
      }
      if (stateData.country.value && stateData.country.value !== '- Choose a value -') {
        const newState = {
          ...stateData,
          [name]: {
            value,
            showError: name === 'email' ? !validateEmail(value) : !value,
            errorMessage: stateData[name].errorMessage || '',
          },
        };

        setDisableSendButton(checkIsValid(newState));
        setStateData(newState);
        return;
      }
      //changing the state of the phone number input field, provided that the country code is entered
      const newState = {
        ...stateData,
        [name]: {
          value,
          showError: name === 'email' ? !validateEmail(value) : !value,
          errorMessage: stateData[name].errorMessage || '',
        },
        code: {
          value: countryObj.code,
        },
        country: {
          value: countryObj.name,
          showError: name === 'email' ? !validateEmail(value) : !value,
          errorMessage: stateData[name].errorMessage || '',
        },
      };

      setDisableSendButton(checkIsValid(newState));
      setStateData(newState);
      return;
    }
    //changing the state of the phone number input field if the code is not entered completely
    const newState = {
      ...stateData,
      [name]: {
        value,
        showError: name === 'email' ? !validateEmail(value) : !value,
        errorMessage: stateData[name].errorMessage || '',
      },
      country: {
        value: countryObj.name,
        showError: name === 'email' ? !validateEmail(value) : !value,
        errorMessage: stateData[name].errorMessage || '',
      },
    };

    setDisableSendButton(checkIsValid(newState));
    setStateData(newState);
    return;
  };

  useMemo(() => {
    const country = stateData.country ? setCountry(stateData.country, dataForm.countries) : '';
    //update state for phonenumber field
    if (country !== undefined) {
      const value = country.code;
      const newState = {
        ...stateData,
        phonenumber: { value: value, showError: !value },
      };

      setDisableSendButton(checkIsValid(newState));
      setStateData(newState);
    }
  }, [stateData.country]);

  const toSend = async (e) => {
    e.preventDefault();
    // these solution is temporary
    const data = {
      firstname: stateData.firstname.value,
      lastname: stateData.lastname.value,
      email: stateData.email.value,
      company: stateData.company.value,
      country: stateData.country.value,
      position: stateData.position.value,
      phonenumber: stateData.phonenumber.value,
    };

    const url = 'https://business-inquiries.xtramile.io/business-inquiry';

    try {
      const response = await axios.post(url, data);

      setResponse(response);
      setAreDataSent(true);
      setAreDataResolved(true);
      setStateData({
        ...initialState,
      });
      setTimeout(() => {
        setAreDataSent(false);
      }, 4000);
    } catch (e) {
      setResponse(e?.response?.data || '');
      setAreDataSent(true);
      setAreDataResolved(false);
      setTimeout(() => {
        setAreDataSent(false);
      }, 4000);
    }
  };

  return (
    <section className={styles.sectionForm} id="contact">
      <div className={styles.imgLayer} />
      <div className={styles.whiteLayer} />
      <div className="container">
        <div className={styles.wrapper}>
          <h2 className={styles.title}>
            {t('titles.A question, a project, a quote? contact us!')}
          </h2>
          <div className={styles.underTitle}>
            <span>{t('text.CALL US AT 06 58 18 24 59')}</span>
          </div>
          <p className={styles.quote}>{t('text.or write to us using the form below')}</p>

          <form className={styles.form}>
            <div className={`${styles.fieldset} ${styles.fieldsetleft}`}>
              {namesOfFieldsPart1.map((item) => (
                <label key={item} className={styles.labelContainer}>
                  <Input
                    label={inputLabels[item]}
                    value={stateData[item].value}
                    name={item}
                    type={item === 'email' ? 'email' : 'text'}
                    error={stateData[item].showError}
                    errorMessage={
                      stateData[item].errorMessage && t(`errors.${stateData[item].errorMessage}`)
                    }
                    onChange={(e) => inputsVerification(e)}
                    t={t}
                  />
                </label>
              ))}
            </div>

            <div className={`${styles.fieldset} ${styles.fieldsetRigth}`}>
              {namesOfFieldsPart2.map((item) => (
                <label key={item} className={styles.labelContainer}>
                  <Input
                    label={inputLabels[item]}
                    value={stateData[item].value}
                    name={item}
                    error={stateData[item].showError}
                    errorMessage={stateData[item].errorMessage}
                    onChange={(e) => inputsVerification(e)}
                    t={t}
                  />
                </label>
              ))}

              <label className={styles.labelContainer}>
                <p className={styles.label}>{t(`labels.${inputLabels.country}`)}</p>
                <Select
                  nameSelect="country"
                  value={stateData.country.value}
                  name="country"
                  options={countries}
                  onChange={(e) => inputsVerification(e)}
                  t={t}
                />
              </label>
              <label className={styles.labelContainer}>
                <Input
                  // label={inputLabels.company}
                  type="checkbox"
                  fnClick={(e) => fnChecked(e)}
                  checked={stateData.click}
                  t={t}
                  // onChange={(e) => inputsVerification(e)} name="agreement"
                />
              </label>

              <div className={styles.buttonContainer}>
                <span>{t('text.*Required fields')}</span>
                <Button
                  disabled={disableSendButton}
                  btnView="button-blue-md"
                  text={t('buttons.SEND THE MESSAGE')}
                  onClick={(e) => toSend(e)}
                />
              </div>
            </div>
          </form>

          <div
            className={cx(
              styles.sendNotification,
              styles[areDataSent ? 'active' : 'notActive'],
              styles[areDataResolved ? 'resolved' : 'rejected']
            )}
          >
            {areDataResolved
              ? t('text.Data sent successfully!')
              : `Correct next data: ${response ? response : ''}`}
          </div>
        </div>
      </div>
    </section>
  );
};

Form.propTypes = {
  dataForm: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default Form;
