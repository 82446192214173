//for technologies block
import lock from 'assets/icons/lock_icon.svg';
import lamp from 'assets/icons/lamp_icon.svg';
import control from 'assets/icons/control_icon.svg';
import doc from 'assets/icons/document_icon.svg';
import setting from 'assets/icons/setting_icon.svg';
import exchange from 'assets/icons/exchange.svg';
//for logos
import microsoft from 'assets/icons/logo/microsoft.png';
import google from 'assets/icons/logo/google.png';
import bpi from 'assets/icons/logo/bpi_france.png';
import inria from 'assets/icons/logo/inria.png';
import universite from 'assets/icons/logo/universite_paris_saclay.png';
import les_deeptech from 'assets/icons/logo/les_deeptech.png';
import orangeBuisness from 'assets/icons/logo/orange_business_services.png';
import lafrench from 'assets/icons/logo/la_french_tech.png';
import adecco from 'assets/icons/logo/adecco_group.png';
import leLabRH from 'assets/icons/logo/le_lab_RH.png';
import indeed from 'assets/icons/logo/indeed.png';
import finovam from 'assets/icons/logo/finovam.png';
import ds from 'assets/icons/logo/d&s_log_bleu.png';
import densu from 'assets/icons/logo/densu_log_bleu.png';
import epoka from 'assets/icons/logo/epoka_log_bleu.png';
// import ideuzo from 'assets/icons/logo/ideuzo_log_bleu.png'; //replaced by oko
import oko from 'assets/icons/logo/logo_oko.png';
import insign from 'assets/icons/logo/insign_log_bleu.png';
import wat from 'assets/icons/logo/wat_log_bleu.png';
import zcomme from 'assets/icons/logo/zcomme_log_bleu.png';
import Beetween from 'assets/icons/logo/Beetween_log_bleu.png';
import SAP from 'assets/icons/logo/SAP_log_bleu.png';
import SmartRecruiters from 'assets/icons/logo/SmartRecruiters_log_bleu.png';
import TalentSoft from 'assets/icons/logo/TalentSoft_log_bleu.png';
import Taleo from 'assets/icons/logo/Taleo_log_bleu.png';
// import Job_ from 'assets/icons/logo/job_.png'; //replaced by viaweb
// import Viaweb from 'assets/icons/logo/logo_viaweb.png';
import ViawebHonrizontal from 'assets/icons/logo/logo_viaweb_horizontal.png';

import deeptech from 'assets/icons/LesDeeptech.png';
import aglae from 'assets/icons/logo/logo_aglae.png';
import idem from 'assets/icons/logo/logo_idem.png';

//for block The Team
import lab from 'assets/icons/lab_icon.svg';
import development from 'assets/icons/development_icon.svg';
import marketing from 'assets/icons/marketing_icon.svg';
import sourcing from 'assets/icons/sourcing_icon.svg';
//for x-cv-ranking
import cv from '../assets/icons/x-cv-ranking/cv.svg';
import tool from '../assets/icons/x-cv-ranking/tool.svg';
//for Trust section (colorized logo)
import accorColor from '../assets/images/trustLogos/accor.png';
import adhapColor from '../assets/images/trustLogos/adhap.png';
import admrColor from '../assets/images/trustLogos/admr.png';
import adsearchColor from '../assets/images/trustLogos/adsearch.png';
import aphpColor from '../assets/images/trustLogos/aphp.png';
import axaColor from '../assets/images/trustLogos/axa.png';
import bouyguesColor from '../assets/images/trustLogos/bouygues.png';
import clubmedColor from '../assets/images/trustLogos/clubmed.png';
import creditagricoleColor from '../assets/images/trustLogos/creditagricole.png';
import dispojobColor from '../assets/images/trustLogos/dispojob.png';
import drhaaColor from '../assets/images/trustLogos/drhaa.png';
import drhatColor from '../assets/images/trustLogos/drhat.png';
import engieColor from '../assets/images/trustLogos/engie.png';
import eurogroupColor from '../assets/images/trustLogos/eurogroup.png';
import jobamaxColor from '../assets/images/trustLogos/jobamax.png';
import m6Color from '../assets/images/trustLogos/m6.png';
import manutanColor from '../assets/images/trustLogos/manutan.png';
import marinenationaleColor from '../assets/images/trustLogos/marinenationale.png';
import mcdonaldsColor from '../assets/images/trustLogos/mcdonalds.png';
import ministeredutourismeColor from '../assets/images/trustLogos/ministeredutourisme.png';
import mozaikrhColor from '../assets/images/trustLogos/mozaikrh.png';
import openclassroomsColor from '../assets/images/trustLogos/openclassrooms.png';
import regionbretagneColor from '../assets/images/trustLogos/regionbretagne.png';
import rexelColor from '../assets/images/trustLogos/rexel.png';
import saintgobainColor from '../assets/images/trustLogos/saintgobain.png';

export const technologies = [
  {
    src: lock,
    alt: 'lock',
    title: 'GDPR',
    text: 'Our technology complies with the GDPR',
  },
  {
    src: lamp,
    alt: 'lamp',
    title: 'Transparency',
    text: 'Our data policy is strict and guarantees transparency',
  },
  {
    src: control,
    alt: 'control',
    title: 'Control',
    text: 'You retain control of your data',
  },
  {
    src: doc,
    alt: 'doc',
    title: 'Data protection',
    text: 'You have at your disposal the explanatory reports',
  },
  {
    src: setting,
    alt: 'setting',
    title: 'Open system',
    text: 'Our technology is based on the most modern infrastructure',
  },
  {
    src: exchange,
    alt: 'exchange',
    title: 'Connection to your TTY',
    text: 'Our platform connects easily to your ATS',
  },
];

export const logos = [
  { src: microsoft, alt: 'microsoft' },
  { src: bpi, alt: 'bpi' },
  { src: inria, alt: 'inria' },
  { src: universite, alt: 'universite' },
  { src: les_deeptech, alt: 'les_deeptech' },
  { src: orangeBuisness, alt: 'orange_buisness_service' },
  { src: lafrench, alt: 'lafrench' },
  { src: adecco, alt: 'adecco_group' },
  { src: leLabRH, alt: 'le_lab_RH' },
  { src: indeed, alt: 'indeed' },
  { src: finovam, alt: 'finovam' },
  { src: microsoft, alt: 'microsoft' },
  { src: bpi, alt: 'bpi' },
  { src: inria, alt: 'inria' },
  { src: universite, alt: 'universite' },
  { src: les_deeptech, alt: 'les_deeptech' },
  { src: orangeBuisness, alt: 'orange' },
  { src: lafrench, alt: 'lafrench' },
  { src: adecco, alt: 'adecco_group' },
  { src: leLabRH, alt: 'le_lab_RH' },
  { src: indeed, alt: 'indeed' },
  { src: finovam, alt: 'finovam' },
];

export const trustLogos = [
  { src: accorColor, alt: 'accor' },
  { src: adhapColor, alt: 'adhap' },
  { src: admrColor, alt: 'admr' },
  { src: adsearchColor, alt: 'adsearch' },
  { src: aphpColor, alt: 'aphp' },
  { src: axaColor, alt: 'axa' },
  { src: bouyguesColor, alt: 'bouygues' },
  { src: clubmedColor, alt: 'clubmed' },
  { src: creditagricoleColor, alt: 'creditagricole' },
  { src: dispojobColor, alt: 'dispojob' },
  { src: drhaaColor, alt: 'drhaa' },
  { src: drhatColor, alt: 'drhat' },
  { src: engieColor, alt: 'engie' },
  { src: eurogroupColor, alt: 'eurogroup' },
  { src: jobamaxColor, alt: 'jobamax' },
  { src: m6Color, alt: 'm6' },
  { src: manutanColor, alt: 'manutan' },
  { src: marinenationaleColor, alt: 'marinenationale' },
  { src: mcdonaldsColor, alt: 'mcdonalds' },
  { src: ministeredutourismeColor, alt: 'ministeredutourisme' },
  { src: mozaikrhColor, alt: 'mozaikrh' },
  { src: openclassroomsColor, alt: 'openclassrooms' },
  { src: regionbretagneColor, alt: 'regionbretagne' },
  { src: rexelColor, alt: 'rexel' },
  { src: saintgobainColor, alt: 'saintgobain' },
];

export const logoTechnologicalPartners = [
  { src: google, alt: 'google' },
  { src: bpi, alt: 'bpi' },
  { src: inria, alt: 'inria' },
  { src: universite, alt: 'iniversite' },
  { src: deeptech, alt: 'deeptech' },
  // { src: ecole, alt: 'ecole' },
  { src: microsoft, alt: 'microsoft' },
  { src: lafrench, alt: 'lafrench' },
  { src: adecco, alt: 'adecco_group' },
  { src: leLabRH, alt: 'le_lab_RH' },
  { src: indeed, alt: 'indeed' },
  { src: finovam, alt: 'finovam' },
];

export const logoRHPartners = [
  { src: epoka, alt: 'epoka' },
  { src: zcomme, alt: 'zcomme' },
  { src: idem, alt: 'idem' },
  { src: wat, alt: 'wat' },
  // { src: ideuzo, alt: 'ideuzo' },
  { src: oko, alt: 'oko' },
  { src: insign, alt: 'insign' },
  { src: densu, alt: 'densu' },
  { src: ds, alt: 'ds' },
  { src: aglae, alt: 'aglae' },
];

export const logoMarketplaces = [
  { src: SmartRecruiters, alt: 'SmartRecruiters' },
  { src: TalentSoft, alt: 'TalentSoft' },
  { src: ViawebHonrizontal, alt: 'Viaweb' },
  { src: SAP, alt: 'SAP' },
  { src: Taleo, alt: 'Taleo' },
  { src: Beetween, alt: 'Beetween' },
];

export const expertises = [
  {
    src: lab,
    alt: 'lab',
    title: 'R&D Lab',
    text: 'At the very heart of technology, the Research',
  },
  {
    src: development,
    alt: 'development',
    title: 'Development',
    text: 'At Xtramile, the centers of expertise of our developers are as varied',
  },
  {
    src: marketing,
    alt: 'marketing',
    title: 'Digital Marketing',
    text: 'Our traders and campaign managers are familiar',
  },
  {
    src: sourcing,
    alt: 'sourcing',
    title: 'HR sourcing',
    text: 'Our teams use HR innovation to facilitate sourcing and propose qualified candidates',
  },
];

export const instruments = [
  {
    src: cv,
    alt: 'cv',
    title: 'A job and CV parsing tool',
    text:
      'capable of reliably and comprehensively extracting, analyzing and classifying the data available on candidates’ CVs',
  },
  {
    src: tool,
    alt: 'tool',
    title: 'A matching tool',
    text: 'integrating parsing techniques',
  },
];

export const quickNews = [
  {
    titleFr: 'Programme européen EIC et accélérateur Google ',
    titleEn: 'European EIC program and Google accelerator',
    bodyFr:
      "Dans le cadre du programme d’accélération de l’innovation mis en œuvre par le Conseil européen de l’Innovation (EIC), notre solution s’adresse à un marché de masse et pas uniquement aux grandes entreprises. En ce sens, Xtramile coopère avec Google pour plus de visibilité et proposer son produit directement sur internet en dehors du processus de vente classique : cold calls (ou appels à froid), réunions, informations, démos, etc. Conçue pour une prise en main directe par les clients eux-mêmes, la solution devrait être disponible d'ici la fin du premier trimestre de 2021.",
    bodyEn:
      'As part of the innovation acceleration program implemented by the European Innovation Council (EIC), our solution is aimed at a mass market and not just large companies. In this sense, Xtramile cooperates with Google for more visibility and to offer its product directly on the internet outside the traditional sales process: cold calls, meetings, information, demos, etc. Designed for direct ownership by customers themselves, the solution is expected to be available by the end of 2021.',
    hyperlink:
      'https://startup.google.com/accelerator/#:~:text=Google%20for%20Startups%20Accelerator%20is,USA%2C%20access%20COVID%20relief%20dollars',
  },
  {
    titleFr: 'Finance, Marketing & RH : de la programmatique RH à la micro-économie',
    titleEn: 'Finance, Marketing & HR: HR programmatic to the micro-economics',
    bodyFr:
      "La \"révolution programmatique\" transforme profondément l'univers RH traditionnel, en y associant les apports de l'intelligence artificielle textuelle, de la finance et du marketing digital. Ce faisant, comme l'explique notre CEO Xavier Ragage dans un article de synhtèse publié par BPI Deep Tech, elle fait également entrer le monde du recrutement dans l'univers de la micro-économie comme la finance dans les années 80 & 90 de la fin du XXe siècle et le monde média au début des années 2000. Pour mesurer vraiment l'impact de cette approche résolument moderne, rendez-vous dans 20 ans ?",
    bodyEn:
      'The "programmatic revolution" is profoundly transforming the traditional HR universe, by combining it with the contributions of textual artificial intelligence, finance and digital marketing. In doing so, as our CEO Xavier Ragage explains in a summary article published by BPI Deep Tech, it also brings the world of recruitment into the world of micro-economics and finance in the 80s & 90s of the end of the twentieth century and the media world in the early 2000s. To really measure the impact of this resolutely modern approach, lets touch base in 20 years?',
    hyperlink: '/article/bpi-deep-tech',
  },
];

export default {
  technologies,
  logos,
  logoTechnologicalPartners,
  logoRHPartners,
  logoMarketplaces,
  quickNews,
  expertises,
  instruments,
};
