import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import styles from './home.module.scss';
import News from 'components/news/news';
import Team from 'components/team/team';
import Technology from 'components/technology/technology';
import Partners from 'components/partners/partners';
import { technologies, logos, expertises } from 'data/blocksInfo';
import customersReviewsData from 'data/customersReviewsData';
import TagManager from 'react-gtm-module';

const HomePage = ({ t, title }) => {
  useEffect(() => {
    document.title = title || '';
  }, [title]);

  TagManager.dataLayer({
    dataLayer: {
      event: 'pageview',
      pagePath: '/home',
      pageTitle: 'Home',
    },
  });

  return (
    <div className={styles.HomePage}>
      <Technology t={t} infoIcons={technologies} />
      <Partners t={t} infoIcons={logos} />
      <News />
      <Team
        t={t}
        infoIcons={expertises}
        infoCarousel={customersReviewsData.filter((testify) => testify.ava && testify.author)}
      />
    </div>
  );
};

HomePage.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default HomePage;
