import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-elastic-carousel';

import quotesYellow from 'assets/icons/quotes-yellow.svg';
import styles from './customersTestifySlider.module.scss';
import customersReviewsData from 'data/customersReviewsData';

import CollapsableSlider from 'components/collapsableSlider/collapsableSlider';

export default class CustomersTestifySlider extends Component {
  constructor(props) {
    super(props);

    this.goto = this.goto.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { activeSlide } = this.props;

    if (activeSlide !== undefined && prevProps.activeSlide !== activeSlide) {
      this.goto(activeSlide);
    }
  }

  goto(index) {
    this.carousel.goTo(index);
  }

  render() {
    const { t, shouldGoCollapse } = this.props;

    return (
      <Carousel
        itemsToShow={1}
        showArrows={false}
        enableSwipe={false}
        enableMouseSwipe={false}
        preventDefaultTouchmoveEvent={true}
        ref={(ref) => (this.carousel = ref)}
      >
        {shouldGoCollapse && <CollapsableSlider t={t} slide={customersReviewsData[3]} />}
        {!shouldGoCollapse && (
          <div className={styles.post}>
            <div className={styles.postLeftPart}>
              <div className={styles.postLeftPartLogo}>
                <img src={customersReviewsData[3].logo} alt="logo" />
              </div>

              <div className={styles.postText}>
                <p>
                  <img
                    src={quotesYellow}
                    alt="quotes"
                    style={{ width: '50px', height: '42px', marginRight: '5px' }}
                  />
                  {t(`quote.${customersReviewsData[3].quote}`)}”
                </p>
              </div>

              <div className={styles.postAuthorNameContainer}>
                <div>{customersReviewsData[3].author}</div>
                <div>{t(`positions.${customersReviewsData[3].position}`)}</div>
              </div>

            </div>

            <div className={styles.postRightPart}>
              <div className={styles.brief}>
                <h4 className={styles.title}>{t('titles.THE BRIEF')}</h4>
                <div>
                  <ol>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.armeeAir.acquisition of engineers, technicians'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.armeeAir.duplicate and distribute thousands of job offers'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.armeeAir.recruit candidates throughout France'),
                      }}
                    ></li>
                  </ol>
                </div>

                <div className={styles.row}>
                  <div style={{ minWidth: '40%' }}>
                    <h4 className={styles.title}>{t('titles.THE GOALS')}</h4>
                    <ul>
                      <li
                        dangerouslySetInnerHTML={{
                          __html: t('li.armeeAir.improve the recruitment process'),
                        }}
                      ></li>
                      <li
                        dangerouslySetInnerHTML={{
                          __html: t('li.armeeAir.automate tedious tasks'),
                        }}
                      ></li>
                    </ul>
                  </div>
                  <div className={styles.blockResult}>
                    <h4 className={styles.title}>{t('titles.THE RESULTS')}</h4>
                    <ul>
                      <li
                        dangerouslySetInnerHTML={{
                          __html: t('li.armeeAir.Connection to all channels used'),
                        }}
                      ></li>
                      <li
                        dangerouslySetInnerHTML={{
                          __html: t('li.armeeAir.A time saving of several tens'),
                        }}
                      ></li>
                      <li
                        dangerouslySetInnerHTML={{
                          __html: t('li.armeeAir.Clear and precise economic intelligence'),
                        }}
                      ></li>
                      <li
                        dangerouslySetInnerHTML={{
                          __html: t('li.armeeAir.Nearly 40k job offers'),
                        }}
                      ></li>
                      <li
                        dangerouslySetInnerHTML={{
                          __html: t('li.armeeAir.6k applications'),
                        }}
                      ></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Carousel>
    );
  }
}

CustomersTestifySlider.propTypes = {
  t: PropTypes.func.isRequired,
  activeSlide: PropTypes.number,
  shouldGoCollapse: PropTypes.bool.isRequired,
};
