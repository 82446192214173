import React from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-elastic-carousel';

import quotesYellow from 'assets/icons/quotes-yellow.svg';
import styles from './customersTestifySlider.module.scss';
import customersReviewsData from 'data/customersReviewsData';
import CollapsableSlider from 'components/collapsableSlider/collapsableSlider';

const CustomersTestifySlider = ({ t, shouldGoCollapse }) => {
  return (
    <Carousel
      itemsToShow={1}
      showArrows={false}
      enableSwipe={false}
      enableMouseSwipe={false}
      preventDefaultTouchmoveEvent={true}
    >
      {shouldGoCollapse && <CollapsableSlider t={t} slide={customersReviewsData[0]} />}
      {!shouldGoCollapse && (
        <div className={styles.post}>
          <div className={styles.postLeftPart}>
            <div className={styles.postLeftPartLogo}>
              <img src={customersReviewsData[0].logo} alt="logo" />
            </div>

            <div className={styles.postText}>
              <p>
                <img
                  src={quotesYellow}
                  alt="logo"
                  style={{ width: '50px', height: '42px', marginRight: '5px' }}
                />
                {t(`quote.${customersReviewsData[0].quote}`)}”
              </p>
            </div>

            <div className={styles.postAuthorNameContainer}>
              <div>{customersReviewsData[0].author}</div>
              <div>{t(`positions.${customersReviewsData[0].position}`)}</div>
            </div>

            <div className={styles.videoContainer}>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/c3l0pRtLN5g"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>

          <div className={styles.postRightPart}>
            <div className={styles.brief}>
              <h4 className={styles.title}>{t('titles.THE BRIEF')}</h4>
              <div>
                <ol>
                  <li>{t('li.fiboise.National actor specialized in forest sectors-wood')}</li>
                  <li>{t('li.fiboise.Initial treatment by words-keys')}</li>
                  <li>
                    {t(
                      'li.fiboise.Resume recovery in all formats and adaptation to needs of recruiter'
                    )}
                  </li>
                </ol>
              </div>

              <div>
                <ul>
                  <li>
                    {t(
                      'li.fiboise.Difficulties in sourcing and finding relevant skills using an existing platform'
                    )}
                  </li>
                  <li>
                    {t(
                      'li.fiboise.Present relevant offers to a candidate directly when arriving on the platform and presenting his candidacy'
                    )}
                  </li>
                  <li>{t('li.fiboise.Modernize the image of Fibois on this service')}</li>
                </ul>
              </div>

              <div className={styles.row}>
                <div>
                  <h4 className={styles.title}>{t('titles.THE GOALS')}</h4>
                  <ul>
                    <li>{t('li.fiboise.Gain in precision and time')}</li>
                    <li>
                      {t('li.fiboise.Interfacing directly with the candidate (not the recruiter)')}
                    </li>
                    <li>{t('li.fiboise.Dynamic matching')}</li>
                    <li>
                      {t(
                        'li.fiboise.Use of AI to gain an understanding of context and not just keywords'
                      )}
                    </li>
                  </ul>
                </div>

                <div className={styles.blockResult}>
                  <h4 className={styles.title}>{t('titles.THE RESULTS')}</h4>
                  <ul>
                    <li>
                      {t('li.fiboise.Matching obtained')}
                      <span>{t('li.fiboise.on any type of format')}</span>
                    </li>
                    <li>
                      {t('li.Performance')}
                      <ul>
                        <li>
                          {t('li.fiboise.3s')}
                          <span>{t('li.fiboise.average time of 4 sec')}</span>
                        </li>
                        <li>
                          {t('li.fiboise.Visualization immediate')}
                          <span>
                            {t(
                              'li.fiboise.elements according to Identity, Experience, Education, Soft Skills;'
                            )}
                          </span>
                        </li>
                        <li className={styles.bold}>
                          {t('li.fiboise.Improvement of the candidate acquisition tunnel')}{' '}
                          <span>
                            {t('li.fiboise.by the application adapted to offers and precise')}
                          </span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Carousel>
  );
};

CustomersTestifySlider.propTypes = {
  t: PropTypes.func.isRequired,
  shouldGoCollapse: PropTypes.bool.isRequired,
};

export default CustomersTestifySlider;
