import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// import { LANGS } from './constants';
import translationEN from './locales/en/translation';
import translationFR from './locales/fr/translation';

// const fallbackLng = LANGS.split(' ');
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: { translation: translationEN },
  fr: { translation: translationFR },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // debug: true,
    resources,
    lng: 'fr',
    // maybe should use request for detain(like in recruiter-app)
    fallbackLng: 'en',
  });

export default i18n;
