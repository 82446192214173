import React from 'react';
import Carousel from 'react-elastic-carousel';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useMediaQuery } from 'react-responsive';

import styles from './carousel.module.scss';

import Icon from 'components/ui/icon';

const data = [
  {
    id: '0',
    text: 'STEPHANIE A LA STATION F',
    subtitle: 'Station F I Programme AI Factory Microsoft I 2019',
    link: 'https://www.youtube.com/embed/Q86FvSr-sZI',
    hyperlink: 'https://www.youtube.com/watch?v=Q86FvSr-sZI&ab_channel=Xtramile',
  },
  {
    id: '1',
    text: 'XAVIER A LA BFM ACADEMIE SAISON 14',
    subtitle: 'BFM Business I 2019',
    link: 'https://www.youtube.com/embed/aE-3A3_1ncg',
    hyperlink: 'https://www.youtube.com/watch?v=aE-3A3_1ncg&t=16s&ab_channel=Xtramile',
  },
  {
    id: '2',
    text: 'Interview BPI Deeptech',
    subtitle: 'BPI France I 2021',
    link: 'https://www.youtube.com/embed/HBqNbGWTGFc',
    hyperlink: 'https://www.youtube.com/watch?v=HBqNbGWTGFc&ab_channel=Xtramile',
  },
  {
    id: '3',
    text: 'Black Founders Fund',
    subtitle: 'BBC World I 2021',
    link: 'https://www.youtube.com/embed/32HCgvrNPXE',
    hyperlink: 'https://www.youtube.com/watch?v=32HCgvrNPXE&ab_channel=Xtramile',
  },
  {
    id: '4',
    text: '#LODR Special Recrutement Programmatique',
    subtitle: 'Ecole du recrutement I 2021',
    link: 'https://www.youtube.com/embed/G_rSv9BtLuA',
    hyperlink: 'https://www.youtube.com/watch?v=G_rSv9BtLuA',
  },
];

const MyCarousel = ({ t }) => {
  const isWide = useMediaQuery({
    query: '(min-width: 1180px)',
  });

  const isSmall = useMediaQuery({
    query: '(min-width: 480px)',
  });

  const itemsToShow = isWide ? 2 : 1;
  const breakPoints = [
    { width: 470, itemsToShow: 1 },
    { width: 550, itemsToShow: 1 },
    { width: 850, itemsToShow: 1 },
    { width: 1150, itemsToShow: 2 },
    { width: 1450, itemsToShow: 2 },
    { width: 1750, itemsToShow: 2 },
  ];

  return (
    <div className={cx('topButtons', styles.carouselWrapper)}>
      <Carousel
        showArrows={(data && data.length > 2) || itemsToShow < data.length}
        enableSwipe={true}
        enableMouseSwipe={true}
        itemsToShow={itemsToShow}
        outerSpacing={isWide ? -30 : -50}
        breakPoints={breakPoints}
      >
        {data &&
          data.map((item) => (
            <div key={item.id} className={styles.post}>
              <div className={styles.videoContainer}>
                <iframe
                  width="100%"
                  height="100%"
                  src={item.link}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                />
              </div>

              <div className={styles.postsText}>
                <h3
                  className={styles.videoName}
                  dangerouslySetInnerHTML={{ __html: t(`text.${item.text}`) }}
                ></h3>
                <h3
                  className={styles.subtitleName}
                  dangerouslySetInnerHTML={{ __html: t(`text.${item.subtitle}`) }}
                ></h3>
                {isSmall && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={item.hyperlink}
                    className={styles.postsLink}
                  >
                    <Icon
                      name="arrow-right"
                      width="27"
                      height="22"
                      color="#e0bc37"
                      viewbox="0 0 38 30"
                    />
                  </a>
                )}
              </div>
            </div>
          ))}
      </Carousel>
    </div>
  );
};

MyCarousel.propTypes = {
  t: PropTypes.func.isRequired,
};

export default MyCarousel;
