import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ btnView, text, disabled, onClick, icon }) => {
  return (
    <button disabled={disabled} className={`button ${btnView}`} onClick={onClick}>
      {icon && icon}
      {text}
    </button>
  );
};

Button.propTypes = {
  btnView: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.elementType,
};

export default Button;
