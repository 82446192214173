import React from 'react';
import PropTypes from 'prop-types';

import styles from './approachToProgrammatic.module.scss';
import schema from '../../../assets/images/Schema_Programmatics-adapted.png';
import mediaData from '../../../assets/images/MediaData.svg';
import audianceRation from '../../../assets/images/AudianceRation.svg';

const ApproachToProgrammatic = ({ t }) => {
  return (
    <section className={`slider2 ${styles.section}`}>
      <div className={`container ${styles.container}`}>
        <h3 className={styles.title}>{t('titles.x-serv_Our specific approach to HR programmatic')}</h3>

        <p className={styles.text}>{t(`text.x-serv_Our HR Trading solution`)}</p>

        <div className={styles.mediaDataWrapper}>
          <div className={styles.mediaData}>
            <img src={mediaData} alt="media data" />
            <div>
              <div>{t('titles.x-serv_Media & Offers')}</div>
              <div>{t('titles.x-serv_which make up the')}</div>
              <div>{t('titles.x-serv_Campaign creation part')}</div>
            </div>
          </div>

          <div className={styles.audianceRation}>
            <img src={audianceRation} alt="audiance Ration" />
            <div>
              <div>{t('titles.x-serv_Audiences & Ratios')}</div>
              <div>{t('titles.x-serv_in order to optimize monitoring')}</div>
            </div>
          </div>
        </div>

        <div className={styles.schemaContainer}>
          <img src={schema} alt="schema of Our specific approach to HR programmatic" />
        </div>
      </div>
    </section>
  );
};

ApproachToProgrammatic.propTypes = {
  t: PropTypes.func.isRequired,
};

export default ApproachToProgrammatic;
