import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import styles from './videoCarousel.module.scss';
import MyCarousel from 'components/videosForNewsPage/carousel/carousel';
import Icon from 'components/icon';

const News = ({ t }) => {
  return (
    <section className={styles.sectionVideo}>
      <div className={styles.wrapper}>
        <div className="container">
          <div className={styles.news}>
            <div className={styles.newsTitleContainer}>
              <a href="https://www.youtube.com/channel/UCbtApKDmZRLmuFpyBKEhPvQ">
                <h3 className={styles.title}>{t('titles.Our videos')}</h3>
              </a>
              <div style={{ paddingBottom: 20, marginLeft: 20 }}>
                <Icon name="link" width="40" height="40" color="#fff" viewbox="0 0 25 25"></Icon>
              </div>
            </div>
            <MyCarousel t={t} />
          </div>
        </div>
      </div>
    </section>
  );
};

News.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(News);
