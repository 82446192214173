import React from 'react';
import PropTypes from 'prop-types';

import styles from './infoBlocks.module.scss';

const InfoBlocks = ({ t, info }) => {
  return (
    <>
      {info.map((block, index) => {
        return (
          <div className={styles.block} key={index}>
            <img src={block.src} alt={block.alt} className={styles.blockIcon} />
            <p data-title className={styles.blockTitle}>
              {t(`titles.${block.title}`)}
            </p>
            <p
              data-text
              className={styles.blockText}
              dangerouslySetInnerHTML={{ __html: t(`text.${block.text}`) }}
            ></p>
          </div>
        );
      })}
    </>
  );
};

InfoBlocks.propTypes = {
  t: PropTypes.func.isRequired,
  info: PropTypes.array.isRequired,
  extraClass: PropTypes.string,
};

export default InfoBlocks;
