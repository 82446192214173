import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Slider1 from '../../components/impact-rh-bi/slider1/slider1';
import Slider2 from '../../components/impact-rh-bi/slider2/slider2';
import ApproachToBI from '../../components/impact-rh-bi/approachToBI/approachToBI';
import HowItWorks from '../../components/impact-rh-bi/howItWorks/howItWorks';
import TagManager from 'react-gtm-module';

const ImpactRHBI = ({ t, title }) => {
  useEffect(() => {
    document.title = title || '';
  }, [title]);

  TagManager.dataLayer({
    dataLayer: {
      event: 'pageview',
      pagePath: '/impact-rh',
      pageTitle: 'Impact RH BI',
    },
  });

  return (
    <>
      <Slider1 t={t} />
      <Slider2 t={t} />
      <ApproachToBI t={t}/>
      <HowItWorks t={t} />
    </>
  );
};

ImpactRHBI.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default ImpactRHBI;
