import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { MiniHeader } from '../../components/mini-header';

import IntroductionForTechnology from 'components/introductionForTechnology/introductionForTechnology';
import IntroductionForTechnologyWithXavier from 'components/introductionForTechnologyWithXavier/introductionForTechnologyWithXavier';
import TechnologicalApproach from 'components/technologicalApproach/technologicalApproach';
import SecurisedSolutions from 'components/securisedSolutions/securisedSolutions';
import TechnologicalPartners from 'components/technologicalPartners/technologicalPartners';
import ATSConnexion from 'components/atsConnexion/atsConnexion';
import RHPartners from 'components/rhPartners/rhPartners';
import TrustingClients from 'components/trustingClients/trustingClients';
import OurNews from 'components/ourNews/ourNews';
import TagManager from 'react-gtm-module';
import { technologies } from 'data/blocksInfo';
import customersReviewsData from 'data/customersReviewsData';

const TechnologyPage = ({ t, title }) => {
  useEffect(() => {
    document.title = title || '';
  }, [title]);

  TagManager.dataLayer({
    dataLayer: {
      event: 'pageview',
      pagePath: '/technology',
      pageTitle: 'Technology',
    },
  });

  return (
    <div>
      <MiniHeader
        title={t('miniheader.Technology')}
        subtitle={t('miniheader.An expert and open technological ecosystem')}
        description={t(
          'miniheader.With its technology resulting from the most modern infrastructure approaches (API & modular tools) and several years of research carried out, among others, in partnership with INRIA and the University of Paris-Saclay, Xtramile is recognized as a key player of textual & visual artificial intelligence in France & Europe'
        )}
        image={'technology'}
      />
      <IntroductionForTechnologyWithXavier t={t} />
      <IntroductionForTechnology t={t} />
      <TechnologicalApproach t={t} />
      <SecurisedSolutions t={t} infoIcons={technologies} />
      <TechnologicalPartners t={t} />
      <ATSConnexion t={t} />
      <RHPartners t={t} />
      <TrustingClients
        t={t}
        infoCarousel={customersReviewsData.filter((testify) => testify.ava && testify.author)}
      />
      <OurNews t={t} />
    </div>
  );
};

TechnologyPage.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default TechnologyPage;
