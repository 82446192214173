import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

import Header from 'components/header/header';
import HeaderForGeneral from 'components/headerForGeneral/headerForGeneral';
import Footer from 'components/footer/footer';
import Form from 'components/form/form';
import NotFound from 'components/notFound/notFound';

import TeamPage from 'pages/team/team';
import PrivacyStatementPage from 'pages/privacyStatement/PrivacyStatementPage';
import DisclaimerPage from 'pages/disclaimer/DisclaimerPage';
import TermsAndConditionsPage from 'pages/termsAndConditions/TermsAndConditionsPage';
import HomePage from 'pages/home/home';
import XJobProgrammatics from 'pages/x-job-programmatics/x-job-programmatics';
import XCvRanking from 'pages/x-cv-ranking/x-cv-ranking';
import XtramileServices from 'pages/xtramile-services/xtramile-services';
import ImpactRHBI from 'pages/impact-rh-bi/impact-rh-bi';
import TechnologyPage from 'pages/technology/technology';
import CaseStudiesPage from 'pages/case-studies/caseStudies';
import NewsPage from 'pages/news/news';
import Article from 'pages/article/article';

import inputLabels from 'data/dataForm';
import countries from 'data/coutries';
import productNames from 'data/productNames';

//articlesData
import BpiDeepTech from 'data/articlesData/bpiDeepTech.js';
import BpiDeepTechLogo from 'assets/images/BpiDeepTechLogo.png';
import BpiDeepTechBanner from 'assets/images/BpiDeepTechBanner.png';

const { XPar, XPaUrl, XRa, XRaUrl, XServices, XServicesUrl, ImpactRH, ImpactRHUrl } = productNames;
const data = {
  inputLabels,
  countries,
};

const pagesWithGeneralHeader = ['/home', `/${XPaUrl}`, `/${XRaUrl}`, `/${XServicesUrl}`, `/${ImpactRHUrl}`];
const pagesWithGeneralFooter = [
  '/home',
  `/${XPaUrl}`,
  `/${XRaUrl}`,
  `/${XServicesUrl}`,
  `/${ImpactRHUrl}`,
  '/technology',
  '/news',
  '/clients',
];

function AuthenticatedApp({ t }) {
  const history = useHistory();
  const [contactPage, setContactPage] = useState(
    history.location.pathname === '/contact' ? true : false
  );

  useEffect(() => {
    if (contactPage && history.location.pathname === '/home') {
      setTimeout(() => {
        document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
      }, 500);
      setContactPage(false);
    }
  }, [contactPage]);

  ReactGA.initialize('UA-194226951-1');

  history.listen((location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });

  return (
    <>
      <Header t={t} />

      {pagesWithGeneralHeader.includes(history.location.pathname) && <HeaderForGeneral />}

      <Switch>
        <Redirect exact from="/" to="/home" />
        <Redirect exact from="/contact" to="/home" />

        <Route path="/home" render={() => <HomePage t={t} title={t('pageTitles.Xtramile')} />} />
        <Route path={`/${XPaUrl}`} render={() => <XJobProgrammatics t={t} title={XPar} />} />
        <Route path={`/${XRaUrl}`} render={() => <XCvRanking t={t} title={XRa} />} />
        <Route path={`/${XServicesUrl}`} render={() => <XtramileServices t={t} title={XServices} />} />
        <Route path={`/${ImpactRHUrl}`} render={() => <ImpactRHBI t={t} title={ImpactRH} />} />

        <Route path="/team" render={() => <TeamPage t={t} title={t('pageTitles.The team')} />} />
        <Route
          path="/technology"
          render={() => <TechnologyPage t={t} title={t('pageTitles.Technology')} />}
        />
        <Route
          path="/clients"
          render={() => <CaseStudiesPage t={t} title={t('pageTitles.Our clients testify')} />}
        />
        <Route path="/news" render={() => <NewsPage t={t} title={t('pageTitles.News')} />} />

        <Route
          path="/terms-and-conditions"
          render={() => (
            <TermsAndConditionsPage t={t} title={t('pageTitles.Terms and conditions')} />
          )}
        />

        <Route
          path="/privacy-statement"
          render={() => <PrivacyStatementPage t={t} title={t('pageTitles.Privacy statement')} />}
        />
        <Route
          path="/disclaimer"
          render={() => <DisclaimerPage t={t} title={t('pageTitles.Disclaimer')} />}
        />
        <Route
          path="/article/bpi-deep-tech"
          render={() => (
            <Article
              title={'Finance, Marketing & RH : de la programmatique RH à la micro - économie'}
              headerBanner={BpiDeepTechLogo}
              headerBannerAlt="bpi deep tech logo"
              footerBanner={BpiDeepTechBanner}
              footerBannerAlt="bpi deep tech bannière"
              imgSrc="/static/media/BpiDeepTechBanner.81022d1e.png"
            >
              <BpiDeepTech />
            </Article>
          )}
        />
        <Route path="/" render={() => <NotFound t={t} />} />
      </Switch>

      {pagesWithGeneralFooter.includes(history.location.pathname) && <Form dataForm={data} t={t} />}

      <Footer t={t} />
    </>
  );
}

AuthenticatedApp.propTypes = {
  t: PropTypes.func.isRequired,
  btnView: PropTypes.string,
};

export default withRouter(withTranslation()(AuthenticatedApp));
