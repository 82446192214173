import React from 'react';
import PropTypes from 'prop-types';

import styles from './smallHeaderDropdown.module.scss';
import { Link } from 'react-router-dom';
import productNames from 'data/productNames';
import Langs from '../header/langs';

import facebook from 'assets/icons/facebook.svg';
import twitter from 'assets/icons/twitter.svg';
import linkedin from 'assets/icons/linkedin.svg';
import instagram from 'assets/icons/instagram.svg';
import customerAccess from 'assets/icons/customer_access_white_background.svg';

const { XSoftJob, XPaUrl, XSoftCV, XRaUrl, XServices, XServicesUrl, ImpactRH, ImpactRHUrl } = productNames;

const dropdownLink = [
  { id: 1, path: '/', text: 'Home' },
  { id: 2, path: `/${XPaUrl}`, text: XSoftJob },
  { id: 3, path: `/${XRaUrl}`, text: XSoftCV },
  { id: 3, path: `/${XServicesUrl}`, text: XServices },
  { id: 5, path: `/${ImpactRHUrl}`, text: ImpactRH },
  { id: 6, path: '/technology', text: 'Technology' },
  { id: 7, path: '/news', text: 'News' },
  { id: 8, path: '/clients', text: 'Our clients testify' },
  { id: 9, path: '/team', text: 'The team' },
];

const SmallHeaderDropdown = ({ t, goToForm, closeDropdown }) => {
  return (
    <div className={styles.headerDropdown} id="headerDropdown">
      <span className={styles.headerBaseline}>
        {t('titles.Innovation at the service of recruitment & human capital')}
      </span>
      <ul className={styles.headerDropdownList}>
        {dropdownLink.map((item) => (
          <li className={styles.headerDropdownElem} key={item.id}>
            <Link to={item.path} className={styles.headerDropdownLink} onClick={closeDropdown}>
              {t(`pageTitles.${item.text}`)}
            </Link>
          </li>
        ))}
        <li className={styles.headerDropdownElem}>
          <a className={styles.headerDropdownLink} onClick={(e) => goToForm(e, 'contact')}>
            {t('text.Contact')}
          </a>
        </li>
      </ul>

      <div className={styles.customerAccess}>
        <img src={customerAccess} alt="tw" />
        <Link
          to={{ pathname: 'https://app.xtramile.io' }}
          target="_blank"
          className="button button-blue-md"
          rel="noreferrer"
          style={{ textAlign: 'center', textTransform: 'uppercase' }}
        >
          <span>{t('titles.customer access')}</span>
        </Link>
      </div>
      <div className={styles.bottomRow}>
        <ul className={styles.networkPanel}>
          <li>
            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/myxtramile">
              <span className={styles.netIcons}>
                <img src={facebook} alt="fb" />
              </span>
            </a>
          </li>
          <li>
            <a target="_blank" rel="noreferrer" href="https://twitter.com/myxtramile">
              <span className={styles.netIcons}>
                <img src={twitter} alt="tw" />
              </span>
            </a>
          </li>
          <li>
            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/myxtramile">
              <span className={styles.netIcons}>
                <img src={linkedin} alt="ln" />
              </span>
            </a>
          </li>
          <li>
            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/myxtramile">
              <span className={styles.netIcons}>
                <img src={instagram} alt="sh" />
              </span>
            </a>
          </li>
        </ul>
        <Langs mobileVersion={true} />
      </div>
    </div>
  );
};

SmallHeaderDropdown.propTypes = {
  t: PropTypes.func.isRequired,
  goToForm: PropTypes.func.isRequired,
  closeDropdown: PropTypes.func.isRequired,
};

export default SmallHeaderDropdown;
