import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Slider1 from '../../components/x-pr/slider1/slider1';
import Slider2 from '../../components/x-pr/slider2/slider2';
import ApproachToMultidiff from '../../components/x-pr/approachToMultidiff/approachToMultidiff';
import HowItWorks from '../../components/x-pr/howItWorks/howItWorks';
import TagManager from 'react-gtm-module';
import { useMediaQuery } from 'react-responsive';

const XJobProgrammatics = ({ t, title }) => {
  useEffect(() => {
    document.title = title || '';
  }, [title]);

  const shouldGoCollapse = useMediaQuery({
    query: '(max-width: 900px)',
  });

  TagManager.dataLayer({
    dataLayer: {
      event: 'pageview',
      pagePath: '/x-job-programmatics',
      pageTitle: 'Xtramile Software (Job!)',
    },
  });

  return (
    <>
      <Slider1 t={t} />
      <Slider2 t={t} />
      <ApproachToMultidiff t={t} />
      <HowItWorks shouldGoCollapse={shouldGoCollapse} t={t} />
    </>
  );
};

XJobProgrammatics.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default XJobProgrammatics;
