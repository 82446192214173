import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-elastic-carousel';

import clock from '../../../assets/icons/x-cv-ranking/clock.svg';
import styles from './slider1.module.scss';
import Button from 'components/button/button';
import DialogParse from 'components/dialogParse/dialogParse';
import Icon from 'components/ui/icon';

const Slider1 = ({ t }) => {
  const data = [
    {
      id: '0',
      textPart1: 'A saving of candidate sourcing time reduced from',
      textNumber: '40 to 85%',
    },
    {
      id: '1',
      textPart1: 'Application parsing more than',
      textNumber: '90 %',
      textPart2: 'correct',
    },
    {
      id: '2',
      textPart1: 'A candidate acquisition in one click',
      textNumber: '2 seconds'
    },
  ];

  const itemsToShow = 1;

  const carousel = useRef(null);
  const delay = 2000;

  const setCarouselToStart = (currentItem) => {
    if (currentItem.index === data.length - 1) {
      setTimeout(() => {
        if (!carousel) return;

        carousel?.current?.goTo(0);
      }, delay);
    }
  };

  const [showDialog, setShowDialog] = useState(false);
  const targetDialog = (show) => {
    setShowDialog(show);
  };

  return (
    <section className={`slider1 ${styles.section}`}>
      <div className={styles.sectionContent}>
        <div className={styles.imgWrapper}>
          <img src={clock} alt="clock" />
        </div>

        <div className={styles.text}>
          <span>{t('text.Recruiters spend an average of 35 seconds reading a Resume')}</span>
          <div>{t('text.With the right tools, you can')}</div>
        </div>

        <div className={styles.slider}>
          <Carousel
            ref={carousel}
            itemsToShow={itemsToShow}
            showArrows={itemsToShow < data.length}
            enableSwipe={false}
            enableMouseSwipe={false}
            preventDefaultTouchmoveEvent={true}
            enableAutoPlay
            autoPlaySpeed={delay}
            transitionMs={delay}
            onChange={(currentItem) => {
              setCarouselToStart(currentItem);
            }}
          >
            <div key={data[0].id} className={styles.slide}>
              <div className={styles.slidersHeader}>
                <span>{t(`text.${data[0].textPart1}`)}</span>
                <div className={styles.textNumber}>{t(`text.${data[0].textNumber}`)}</div>
              </div>
            </div>

            <div key={data[1].id} className={styles.slide}>
              <div className={styles.slidersHeader}>
                <span>{t(`text.${data[1].textPart1}`)}</span>
                <div className={styles.textNumber}>{data[1].textNumber}</div>
                <span>{t(`text.${data[1].textPart2}`)}</span>
              </div>
            </div>

            <div key={data[2].id} className={styles.slide}>
              <div className={styles.slidersHeader}>
                <span>{t(`text.${data[2].textPart1}`)}</span>
                <div className={styles.textNumber}>{t(`text.${data[2].textNumber}`)}</div>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
      <div className={styles.sectionWrapperButtonParse}>
        <Button
          btnView={`${'button-warn-sm'} ${'small'} button-try-parse`}
          text={t('buttons.TRY YOUR CV')}
          icon={<Icon name="goal" width="25" height="25" color="#fff" viewbox="0 0 122 119" />}
          onClick={() => targetDialog(true)}
        />
      </div>
      {showDialog && (
        <DialogParse type="cv" demo={true} closeDialog={() => targetDialog(false)} t={t} />
      )}
    </section>
  );
};

Slider1.propTypes = {
  t: PropTypes.func.isRequired,
};

export default Slider1;
