import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';

import HeaderForHome from './headerForHome/headerForHome';
import HeaderForXJob from './headerForXJob/headerForXJob';
import HeaderForXCV from './headerForXCV/headerForXCV';
import HeaderForXServices from './headerForXServices/headerForXServices';
import HeaderForImpactRH from './headerForImpactRH/headerForImpactRH';
import styles from './headerForGeneral.module.scss';
import productNames from 'data/productNames';

const { XPaUrl, XRaUrl, XServicesUrl, ImpactRHUrl } = productNames;

const routes = ['home', XPaUrl, XRaUrl, XServicesUrl, ImpactRHUrl];
const states = ['active', 'notActive', 'hidden'];

const HeaderForGeneral = () => {
  const [activeRoute, setActiveRoute] = useState(routes[0]);
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname.includes(XPaUrl) && activeRoute !== XPaUrl)
      return setActiveRoute(XPaUrl);

    if (history.location.pathname.includes(XRaUrl) && activeRoute !== XRaUrl)
      return setActiveRoute(XRaUrl);

    if (history.location.pathname.includes(XServicesUrl) && activeRoute !== XServicesUrl)
      return setActiveRoute(XServicesUrl);

    if (history.location.pathname.includes(ImpactRHUrl) && activeRoute !== ImpactRHUrl)
      return setActiveRoute(ImpactRHUrl);

    if (history.location.pathname.includes('home') && activeRoute !== 'home')
      return setActiveRoute('home');
  }, [history.location.pathname]);

  const callback = (value) => {
    setActiveRoute(value);
    history.push(`/${value}`);
  };

  return (
    <section className={cx(styles.container)}>
      <div className={styles.header}>
        <HeaderForHome
          state={activeRoute === routes[0] ? states[0] : states[2]}
          routeName={routes[0]}
          callback={callback}
        />
        <HeaderForXJob
          state={
            activeRoute === routes[1]
              ? states[0]
              : activeRoute === routes[0]
              ? states[1]
              : states[2]
          }
          states={states}
          routeName={routes[1]}
          callback={callback}
        />

        <HeaderForXCV
          state={
            activeRoute === routes[2]
              ? states[0]
              : activeRoute === routes[0]
              ? states[1]
              : states[2]
          }
          states={states}
          routeName={routes[2]}
          activeRoute={activeRoute}
          callback={callback}
        />

        <HeaderForXServices
          state={
            activeRoute === routes[3]
              ? states[0]
              : activeRoute === routes[0]
                ? states[1]
                : states[2]
          }
          states={states}
          routeName={routes[3]}
          activeRoute={activeRoute}
          callback={callback}
        />

        <HeaderForImpactRH
          state={
            activeRoute === routes[4]
              ? states[0]
              : activeRoute === routes[0]
                ? states[1]
                : states[2]
          }
          states={states}
          routeName={routes[4]}
          callback={callback}
        />

      </div>
    </section>
  );
};

export default HeaderForGeneral;
