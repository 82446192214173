import React from 'react';
import PropTypes from 'prop-types';

import styles from './securisedSolutions.module.scss';
import InfoBlocks from 'components/infoBlocks/infoBlocks';

const SecurisedSolutions = ({ t, infoIcons }) => {
  return (
    <section id={1} className={styles.sectionTechnology}>
      <div className="container">
        <div className={styles.wrapper}>
          <h1 className={styles.title}>{t('titles.titleForSecurisedSolution')}</h1>
          <div className={styles.infoTechnology}>
            <InfoBlocks t={t} info={infoIcons} />
          </div>
        </div>
      </div>
    </section>
  );
};

SecurisedSolutions.propTypes = {
  t: PropTypes.func.isRequired,
  infoIcons: PropTypes.array.isRequired,
};

export default SecurisedSolutions;
