import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { MiniHeader } from '../../components/mini-header';

import NewsForNewsPage from 'components/newsForNewsPage/news';
import VideosForNewsPage from 'components/videosForNewsPage/videoCarousel';
import TagManager from 'react-gtm-module';

const NewsPage = ({ t, title }) => {
  useEffect(() => {
    document.title = title || '';
  }, [title]);

  TagManager.dataLayer({
    dataLayer: {
      event: 'pageview',
      pagePath: '/news',
      pageTitle: 'News',
    },
  });

  return (
    <div>
      <MiniHeader
        title={t('miniheader.News')}
        subtitle={t("miniheader.So you don't miss any news from Xtramile")}
        description={t(
          'miniheader.Because technology never stops evolving, find all our news on this page and follow our development'
        )}
        image={'news'}
      />
      <NewsForNewsPage />
      <VideosForNewsPage />
    </div>
  );
};

NewsPage.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default NewsPage;
