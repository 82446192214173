import React from 'react';
import PropTypes from 'prop-types';
// import Carousel from 'react-elastic-carousel';

import styles from './howItWorks.module.scss';
import CustomersTestifySlider from 'components/x-ra/customersTestifySlider/customersTestifySlider';
// import howItWorks from 'assets/images/HowItWorks.png';
// import spie from 'assets/icons/logo/spie.png';
// import quotesYellow from 'assets/icons/quotes-yellow.svg';

const HowItWorks = ({ t, shouldGoCollapse }) => {
  return (
    <section className={`howItWorks ${styles.section}`}>
      <div className={`container ${styles.container}`}>
        <div className={styles.ourCustomersTestify}>
          <h3 className={styles.ourCustomersTestifyTitle}>{t('titles.Our customers testify')}</h3>
          <CustomersTestifySlider shouldGoCollapse={shouldGoCollapse} t={t} />
        </div>
      </div>
    </section>
  );
};

HowItWorks.propTypes = {
  t: PropTypes.func.isRequired,
  shouldGoCollapse: PropTypes.bool.isRequired,
};

export default HowItWorks;
