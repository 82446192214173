import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './dataCvCollapse.module.scss';
import Icon from '../../icon';

const Skills = ({ t, data }) => {
  const dataKeys = Object.keys(data);
  let isEmptySection = [...data.hard_skill, ...data.soft_skill, ...data.language].length === 0;

  if (isEmptySection && data.interestsData && data.interestsData.length > 0) isEmptySection = false;

  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => setIsOpen(!isOpen);

  return (
    !isEmptySection && (
      <div className={styles.dataSection}>
        <div className={styles.dataBlock}>
          <div className={styles.dataTitleContainer} onClick={toggle}>
            <span className={styles.itemTitle}>{t('valuesOfCV.skills')}</span>
            <span className={isOpen ? styles.activeArrow : styles.notActiveArrow}>
              <Icon name="arrow" width="10" height="5" />
            </span>
          </div>

          <div className={isOpen ? styles.activeBlock : styles.notActiveBlock}>
            {dataKeys.map(
              (item, dataIndex) =>
                data[item].length > 0 && (
                  <div key={dataIndex} className={styles.partOfData}>
                    <div className={styles.dataItem}>
                      <span className={styles.itemKey}>{t(`valuesOfCV.${item}`)}</span>
                      <div className={styles.itemValue}>
                        {data[item].map(
                          (skill, skillIndex) =>
                            skill.plaintext && <div key={skillIndex}>{skill.plaintext}</div>
                        )}
                      </div>
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    )
  );
};

Skills.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default Skills;
