import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import styles from './footer.module.scss';
// import logo from 'assets/images/Xtramile_logo_footer.png'; static logo
import logoAnimated from 'assets/images/logo-animation2.gif';
import facebook from 'assets/icons/facebook.svg';
import twitter from 'assets/icons/twitter.svg';
import linkedin from 'assets/icons/linkedin.svg';
import instagram from 'assets/icons/instagram.svg';
import youtube from 'assets/icons/youtube.svg';
// import StationF from 'assets/images/Station-F.png';
import productNames from 'data/productNames';

const { XSoftJob, XPaUrl, XSoftCV, XRaUrl, XServices, XServicesUrl, ImpactRH, ImpactRHUrl } = productNames;

// import { productNames } from 'data/productNames';

const footerLinks = [
  { id: 0, title: 'HOME', elem: 'header' },
  { id: 1, title: XSoftJob, elem: XPaUrl },
  { id: 2, title: XSoftCV, elem: XRaUrl },
  { id: 3, title: XServices, elem: XServicesUrl },
  { id: 4, title: ImpactRH, elem: ImpactRHUrl },
  { id: 3, title: 'Technology', elem: 'technology' },
  { id: 4, title: 'News', elem: 'news' },
  { id: 5, title: 'Our clients testify', elem: 'clients' },
  { id: 6, title: 'The team', elem: 'team' },
  { id: 7, title: 'Contact', elem: 'contact' },
];

const footerAdresses = [
  { id: 0, town: 'Paris', elem: 'Station F ; 5 Parvis Alan Turing ; 75013 Paris' },
  { id: 1, town: 'Metz', elem: '11 Rempart Saint-Thiébault ; 57000 Metz' },
  { id: 2, town: 'Lyon', elem: 'KEPAX ; 229 Route Vienne ; 69000 Lyon'},
];

const Footer = ({ t }) => {
  const [location, setLocation] = useState(''); //the state of the location
  const [elem, setElem] = useState(''); //the element to scroll to
  const history = useHistory(); // current location

  useEffect(() => {
    if (elem) {
      const _elem = [
        XPaUrl,
        XRaUrl,
        XServicesUrl,
        ImpactRHUrl,
        'technology',
        'news',
        'clients',
        'team',
        'privacy-statement',
        'disclaimer',
      ].includes(elem)
        ? 'header'
        : elem;
      //If there was a redirect from a page where there is no form
      if (history.location.pathname !== location) {
        //state to initialState
        setLocation('');
        setElem('');
        return () => document.getElementById(_elem).scrollIntoView();
      }
      //state to initialState
      setLocation('');
      setElem('');
      //scrolling to the form from the current page where the form is
      return () =>
        document.getElementById(_elem).scrollIntoView({
          behavior: 'smooth',
        });
    }
  });

  const goByLink = (e, elem) => {
    e.preventDefault();
    const loc = history.location.pathname;

    if (['partners', 'header'].includes(elem)) {
      if (loc === '/home') {
        setLocation(loc);
        setElem(elem);
        return;
      }

      setLocation(loc);
      setElem(elem);
      history.push('/');
      return;
    }

    if (
      [
        XPaUrl,
        XRaUrl,
        XServicesUrl,
        ImpactRHUrl,
        'technology',
        'news',
        'clients',
        'team',
        'privacy-statement',
        'disclaimer',
      ].includes(elem)
    ) {
      if (loc === `/${elem}`) {
        setLocation(loc);
        setElem(elem);
        return;
      }

      setLocation(loc);
      setElem(elem);
      history.push(`/${elem}`);
      return;
    }

    if (['/home', `/${XPaUrl}`, `/${XRaUrl}`, `/${XServicesUrl}`, `/${ImpactRHUrl}`].includes(loc)) {
      setLocation(loc);
      setElem(elem);
      return;
    }

    setLocation(loc);
    setElem(elem);
    history.push('/');
  };

  const isWideEnough = useMediaQuery({
    query: '(min-width: 841px)',
  });

  return (
    <>
      <footer className={styles.footer}>
        <div className="container">
          <div className={styles.wrapper}>
            <div className={styles.leftPanel}>
              <div className={styles.topSide}>
                <a href="/">
                  <span className={styles.logo}>
                    {/* <img src={logo} alt="logo" /> static logo */}
                    <img src={logoAnimated} alt="logo" />
                  </span>
                </a>
                <div
                  className={styles.innovations}
                  dangerouslySetInnerHTML={{
                    __html: t('text.Innovation at the service of recruitment & human capital'),
                  }}
                />
                <ul className={styles.networkPanel}>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://www.facebook.com/myxtramile">
                      <span className={styles.netIcons}>
                        <img src={facebook} alt="fb" />
                      </span>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://twitter.com/myxtramile">
                      <span className={styles.netIcons}>
                        <img src={twitter} alt="tw" />
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.linkedin.com/company/myxtramile"
                    >
                      <span className={styles.netIcons}>
                        <img src={linkedin} alt="ln" />
                      </span>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://www.instagram.com/myxtramile">
                      <span className={styles.netIcons}>
                        <img src={instagram} alt="sh" />
                      </span>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UCbtApKDmZRLmuFpyBKEhPvQ">
                      <span className={styles.netIcons}>
                        <img src={youtube} alt="yt" />
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
              {isWideEnough && (
                <div className={styles.footerBottom}>
                  <span>{t('text.Copyright 2023')}</span>
                  <span>{t('text.All rights reserved by Xtramile SAS')}</span>
                </div>
              )}
            </div>
            <div className={styles.rightPanel}>
              <div className={styles.rightTopBlock}>
                <ul className={styles.blocks}>
                  <li className={styles.block}>
                    {footerLinks.map((item) => {
                      return (
                        <a key={item.id} onClick={(e) => goByLink(e, item.elem)}>
                          <span>{t(`pageTitles.${item.title}`)}</span>
                        </a>
                      );
                    })}
                  </li>
                </ul>
                <div>
                  <ul className={styles.blocks}>
                    <li className={styles.adressBlock}>
                      <span className={styles.adressItem}>{t('titles.addresses')}</span>
                      {footerAdresses.map((item) => {
                        return (
                          <div key={item.id} className={styles.adressItemContainer}>
                            <span className={styles.adressItem}>
                              {item.town}
                              {item.elem && ' :'}
                            </span>
                            <span className={styles.adress}>{item.elem}</span>
                          </div>
                        );
                      })}
                    </li>
                  </ul>
                  {/* <img src={S/>ationF} alt="station-f" className={styles.stationF} /> */}
                </div>
              </div>
              {!isWideEnough && (
                <div className={styles.footerBottomAlt}>
                  <span>{t('text.Copyright 2023')}</span>
                  <span>{t('text.All rights reserved by Xtramile SAS')}</span>
                </div>
              )}
              <div className={styles.footerBottom}>
                {/* // TODO: should be deleted as old version of realisation. */}
                {/* <a href="https://xtramile.io/en/privacy-statement" target="_blank" rel="noreferrer">
                  <span>{t('titles.Privacy Policy')}</span>
                </a>
                <a href="https://xtramile.io/en/disclaimer" target="_blank" rel="noreferrer">
                  <span>{t('titles.Disclaimer')}</span>
                </a> */}
                <Link
                  to="/terms-and-conditions"
                  onClick={() => {
                    document
                      .querySelector('.app-wrapper')
                      .scrollIntoView({ block: 'start', behavior: 'smooth' });
                  }}
                >
                  <span>{t('titles.Terms and conditions')}</span>
                </Link>

                <Link
                  to="/privacy-statement"
                  onClick={() => {
                    document
                      .querySelector('.app-wrapper')
                      .scrollIntoView({ block: 'start', behavior: 'smooth' });
                  }}
                >
                  <span>{t('titles.Privacy Policy')}</span>
                </Link>
                <Link
                  to="/disclaimer"
                  onClick={() => {
                    document
                      .querySelector('.app-wrapper')
                      .scrollIntoView({ block: 'start', behavior: 'smooth' });
                  }}
                >
                  <span>{t('titles.Disclaimer')}</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

Footer.propTypes = {
  t: PropTypes.func.isRequired,
};

export default Footer;
