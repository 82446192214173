import React, { useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import styles from './teamMemberCard.module.scss';
import LinkedInLogo from '../../assets/icons/LinkedInLinkIcon.svg';
import yellowArrow from '../../assets/icons/YellowArrow.svg';
import closeButton from '../../assets/icons/Close.svg';

import Xavier from '../../assets/images/team/members/xavier.png';
import Stephanie from '../../assets/images/team/members/stephanie.png';

import Laurentiu from '../../assets/images/team/members/laurentiu.png';
import Mariem from '../../assets/images/team/members/mariem.png';
import Halima from '../../assets/images/team/members/halima.png';
import Aurelie from '../../assets/images/team/members/aurelie.png';
import Laetitia from '../../assets/images/team/members/laetitia.png';

import Iulian from '../../assets/images/team/members/iulian.png';
import Ionut from '../../assets/images/team/members/ionut.png';
import Faycal from '../../assets/images/team/members/faycal.png';
import Elodie from '../../assets/images/team/members/elodie.png';
import Henitsoa from '../../assets/images/team/members/henitsoa.png';
import Amira from '../../assets/images/team/members/amira.png';

import Vassanth from '../../assets/images/team/members/vassanth.png';
import Valentin from '../../assets/images/team/members/valentin.png';
import Sabrina from '../../assets/images/team/members/sabrina.png';
import Alexandre from '../../assets/images/team/members/alexandre.png';
import Elmano from '../../assets/images/team/members/elmano.png';

import Dominique from '../../assets/images/team/members/dominique.png';
import Olivier from '../../assets/images/team/members/olivier.png';
import Valeriu from '../../assets/images/team/members/valeriu.png';
import Gregory from '../../assets/images/team/members/gregory.png';

const TeamMemberCard = ({ data }) => {
  const [cardIsExpanded, toggleCardExpanded] = useState(false);
  const { name, titleFr, titleEn, linkedinLink, descriptionFr, descriptionEn, picture } = data;

  const openLinkedInPage = (link) => {
    var win = window.open(link, '_blank');
    win.focus();
  };

  const picturesBank = {
    Xavier,
    Stephanie,

    Laurentiu,
    Mariem,
    Halima,
    Aurelie,
    Laetitia,

    Iulian,
    Ionut,
    Faycal,
    Elodie,
    Henitsoa,
    Amira,

    Vassanth,
    Valentin,
    Sabrina,
    Alexandre,
    Elmano,

    Dominique,
    Olivier,
    Valeriu,
    Gregory,
  };

  return (
    <div className={`${styles.card} ${cardIsExpanded && styles.expanded}`}>
      <div className={`${styles.photo} ${cardIsExpanded && styles.expanded}`}>
        <img src={picturesBank[picture]} alt="employee" />
      </div>
      <h5 className={`${styles.name} ${cardIsExpanded && styles.expanded}`}>{name}</h5>
      <h5 className={`${styles.title} ${cardIsExpanded && styles.expanded}`}>
        {i18n.language === 'fr' ? titleFr : titleEn}
      </h5>
      <pre className={`${styles.description} ${cardIsExpanded && styles.expanded}`}>
        {i18n.language === 'fr' ? descriptionFr : descriptionEn}
      </pre>
      <img
        src={LinkedInLogo}
        alt="linkedin shortcut"
        className={`${styles.linkedin} ${cardIsExpanded && styles.expanded}`}
        onClick={() => openLinkedInPage(linkedinLink)}
      />
      <img
        src={yellowArrow}
        onClick={() => toggleCardExpanded(true)}
        alt="expand card shortcut"
        className={`${styles.yellowArrow} ${cardIsExpanded && styles.expanded}`}
      />
      <img
        src={closeButton}
        onClick={() => toggleCardExpanded(false)}
        alt="expand card shortcut"
        className={`${styles.closeButton} ${cardIsExpanded && styles.expanded}`}
      />
    </div>
  );
};

TeamMemberCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TeamMemberCard;
