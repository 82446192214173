import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from './technology.module.scss';
import Button from 'components/button/button';
import InfoBlocks from 'components/infoBlocks/infoBlocks';

const Technology = ({ t, infoIcons }) => {
  useEffect(() => {
    return () => document.getElementById('header').scrollIntoView();
  });

  return (
    <section id={1} className={styles.sectionTechnology}>
      <div className="container">
        <div className={styles.wrapper}>
          <h1 className={styles.title}>{t('titles.Technology')}</h1>
          <p className={styles.description}>
            {t('text.Our solutions come both from our internal R&D Lab')}
          </p>
          <div className={styles.infoTechnology}>
            <InfoBlocks t={t} info={infoIcons} />
          </div>

          <Link to="/technology">
            <Button btnView="button-warn-sm" text={t('buttons.LEARN MORE')} />
          </Link>
        </div>
      </div>
    </section>
  );
};

Technology.propTypes = {
  t: PropTypes.func.isRequired,
  infoIcons: PropTypes.array.isRequired,
};

export default Technology;
