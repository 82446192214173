import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ width, height, viewbox, name, color, className }) => (
  <svg
    width={width}
    height={height}
    color={color}
    className={className}
    viewBox={viewbox || `0 0 ${width} ${height}`}
  >
    <use xlinkHref={`#${name}`} fill={color || '#ff88b0'} />
  </svg>
);

Icon.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  viewbox: PropTypes.string,
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
};

export default Icon;
