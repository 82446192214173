import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-elastic-carousel';
import quotesYellow from 'assets/icons/quotes-yellow.svg';
import styles from './customersTestifySlider.module.scss';
import customersReviewsData from 'data/customersReviewsData';
import CollapsableSlider from 'components/collapsableSlider/collapsableSlider';
export default class CustomersTestifySlider extends Component {
  constructor(props) {
    super(props);

    this.goto = this.goto.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { activeSlide } = this.props;

    if (activeSlide !== undefined && prevProps.activeSlide !== activeSlide) {
      this.goto(activeSlide);
    }
  }

  goto(index) {
    this.carousel.goTo(index);
  }

  render() {
    const { t, shouldGoCollapse } = this.props;
    return (
      <Carousel
        itemsToShow={1}
        showArrows={true}
        enableSwipe={false}
        enableMouseSwipe={false}
        preventDefaultTouchmoveEvent={true}
        ref={(ref) => (this.carousel = ref)}
      >
        {shouldGoCollapse &&
          customersReviewsData.map((slide, i) => <CollapsableSlider key={i} t={t} slide={slide} />)}
        {/*first slide*/}
        {!shouldGoCollapse && (
          <div className={styles.post}>
            <div className={styles.postLeftPart}>
              <div className={styles.postLeftPartLogo}>
                <img src={customersReviewsData[0].logo} alt="logo" />
              </div>

              <div className={styles.postText}>
                <p>
                  <img
                    src={quotesYellow}
                    alt="logo"
                    style={{ width: '50px', height: '42px', marginRight: '5px' }}
                  />
                  {t(`quote.${customersReviewsData[0].quote}`)}”
                </p>
              </div>

              <div className={styles.postAuthorNameContainer}>
                <div>{customersReviewsData[0].author}</div>
                <div>{t(`positions.${customersReviewsData[0].position}`)}</div>
              </div>

              <div className={styles.videoContainer}>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/c3l0pRtLN5g"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>

            <div className={styles.postRightPart}>
              <div className={styles.brief}>
                <h4 className={styles.title}>{t('titles.THE BRIEF')}</h4>
                <div>
                  <ol>
                    <li>{t('li.fiboise.National actor specialized in forest sectors-wood')}</li>
                    <li>{t('li.fiboise.Initial treatment by words-keys')}</li>
                    <li>
                      {t(
                        'li.fiboise.Resume recovery in all formats and adaptation to needs of recruiter'
                      )}
                    </li>
                  </ol>
                </div>

                <div>
                  <ul>
                    <li>
                      {t(
                        'li.fiboise.Difficulties in sourcing and finding relevant skills using an existing platform'
                      )}
                    </li>
                    <li>
                      {t(
                        'li.fiboise.Present relevant offers to a candidate directly when arriving on the platform and presenting his candidacy'
                      )}
                    </li>
                    <li>{t('li.fiboise.Modernize the image of Fibois on this service')}</li>
                  </ul>
                </div>

                <div className={styles.row}>
                  <div>
                    <h4 className={styles.title}>{t('titles.THE GOALS')}</h4>
                    <ul>
                      <li>{t('li.fiboise.Gain in precision and time')}</li>
                      <li>
                        {t(
                          'li.fiboise.Interfacing directly with the candidate (not the recruiter)'
                        )}
                      </li>
                      <li>{t('li.fiboise.Dynamic matching')}</li>
                      <li>
                        {t(
                          'li.fiboise.Use of AI to gain an understanding of context and not just keywords'
                        )}
                      </li>
                    </ul>
                  </div>

                  <div className={styles.blockResult}>
                    <h4 className={styles.title}>{t('titles.THE RESULTS')}</h4>
                    <ul>
                      <li>
                        {t('li.fiboise.Matching obtained')}
                        <span>{t('li.fiboise.on any type of format')}</span>
                      </li>
                      <li>
                        {t('li.Performance')}
                        <ul>
                          <li>
                            {t('li.fiboise.3s')}
                            <span>{t('li.fiboise.average time of 4 sec')}</span>
                          </li>
                          <li>
                            {t('li.fiboise.Visualization immediate')}
                            <span>
                              {t(
                                'li.fiboise.elements according to Identity, Experience, Education, Soft Skills;'
                              )}
                            </span>
                          </li>
                          <li className={styles.bold}>
                            {t('li.fiboise.Improvement of the candidate acquisition tunnel')}
                            <span>
                              {t('li.fiboise.by the application adapted to offers and precise')}
                            </span>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/*second slide*/}
        {!shouldGoCollapse && (
          <div className={styles.post}>
            <div className={styles.postLeftPart}>
              <div className={styles.postLeftPartLogo}>
                <img src={customersReviewsData[1].logo} alt="logo" />
              </div>

              <div className={styles.postText}>
                <p>
                  <img
                    src={quotesYellow}
                    alt="quotes"
                    style={{ width: '50px', height: '42px', marginRight: '5px' }}
                  />
                  {t(`quote.${customersReviewsData[1].quote}`)}”
                </p>
              </div>

              <div className={styles.postAuthorNameContainer}>
                <div>{customersReviewsData[1].author}</div>
                <div>{t(`positions.${customersReviewsData[1].position}`)}</div>
              </div>

              <div className={styles.videoContainer}>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/I4tbWmeIOIs"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>

            <div className={styles.postRightPart}>
              <div className={styles.brief}>
                <h4 className={styles.title}>{t('titles.THE BRIEF')}</h4>
                <div>
                  <ol>
                    <li>{t('li.stedy.New platform Free - Spears on IT missions France')}</li>
                    <li>{t('li.stedy.100 types of offers different')}</li>
                    <li>{t('li.stedy.Acquisition of Talents')}</li>
                    <li>{t('li.stedy.Monitoring performance of broadcast channels')}</li>
                  </ol>
                </div>

                <div>
                  <ul>
                    <li>
                      {t(
                        'li.stedy.Difficulties in sourcing and adapting budgets according to suitable channels'
                      )}
                    </li>
                    <li>{t('li.stedy.No visibility pre-existing')}</li>
                    <li>
                      {t(
                        'li.stedy.KPIs that are difficult to follow and very heterogeneous in channel function'
                      )}
                    </li>
                    <li>{t('li.stedy.Lack of ROI measurement')}</li>
                  </ul>
                </div>

                <div className={styles.row}>
                  <div style={{ minWidth: '40%' }}>
                    <h4 className={styles.title}>{t('titles.THE GOALS')}</h4>
                    <ul>
                      <li>{t('li.stedy.Create performance in a way')}</li>
                      <li>{t('li.stedy.Recruitment of 600 talents')}</li>
                      <li>{t('li.stedy.CPA target € 50')}</li>
                    </ul>
                  </div>
                  <div className={styles.blockResult}>
                    <h4 className={styles.title}>{t('titles.THE RESULTS')}</h4>
                    <ul>
                      <li>
                        {t('li.stedy.Optimization')}
                        <span>{t('li.stedy.on any type of channel')}</span>
                      </li>
                      <li>
                        {t('li.Performance')}
                        <ul>
                          <li>
                            {t('li.stedy.+800')}
                            <span>{t('li.stedy.applicants from different sources')}</span>
                          </li>
                          <li>
                            <span>{t('li.stedy.CPA way of')}</span>
                            {t('li.stedy.€36')}
                            <span>{t('li.stedy.28% vs goal')}</span>
                          </li>
                        </ul>
                      </li>
                      <li>{t('li.stedy.Strategy for a perpetuation long-term (validated)')}</li>
                      <li>
                        {t(
                          'li.stedy.Help with setting in place of a 2021 budget based on of KPIs real'
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* third slide */}
        {!shouldGoCollapse && (
          <div className={styles.post}>
            <div className={styles.postLeftPart}>
              <div className={styles.postLeftPartLogo}>
                <img src={customersReviewsData[2].logo} alt="logo" />
              </div>

              <div className={styles.postText}>
                <p>
                  <img
                    src={quotesYellow}
                    alt="quotes"
                    style={{ width: '50px', height: '42px', marginRight: '5px' }}
                  />
                  {t(`quote.${customersReviewsData[2].quote}`)}”
                </p>
              </div>

              <div className={styles.postAuthorNameContainer}>
                <div>{customersReviewsData[2].author}</div>
                <div>{t(`positions.${customersReviewsData[2].position}`)}</div>
              </div>

              <div className={styles.videoContainer}>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/1TPaIq6AdXg"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>

            <div className={styles.postRightPart}>
              <div className={styles.brief}>
                <h4 className={styles.title}>{t('titles.THE BRIEF')}</h4>
                <div>
                  <ol>
                    <li>{t('li.afpa.national agency')}</li>
                    <li>{t('li.afpa.development yutz')}</li>
                  </ol>
                </div>

                <div>
                  <ul>
                    <li>
                      {t('li.afpa.Difficulty finding a target that is difficult to identify')}
                    </li>
                    <li>{t('li.afpa.No pre-existing visibility')}</li>
                    <li>
                      {t('li.afpa.Very manual and time-consuming operations in the initial state')}
                    </li>
                  </ul>
                </div>

                <div className={styles.row}>
                  <div style={{ minWidth: '40%' }}>
                    <h4 className={styles.title}>{t('titles.THE GOALS')}</h4>
                    <ul>
                      <li>{t('li.afpa.Promote the Job Academie device')}</li>
                      <li>{t("li.afpa.Better understand AFPA's own target")}</li>
                      <li>{t('li.afpa.Promote organized events and meetings')}</li>
                      <li>{t('li.afpa.Optimize distribution costs')}</li>
                    </ul>
                  </div>
                  <div className={styles.blockResult} style={{ marginLeft: '20px' }}>
                    <h4 className={styles.title}>{t('titles.THE RESULTS')}</h4>
                    <ul>
                      <li>
                        {t('li.afpa.More than 8 promoted events, 1 generic campaign')}
                        <ul>
                          <li>
                            <span>{t('li.afpa.Large increase of Job Academy visibility')}</span>
                          </li>
                          <li>
                            <span>
                              {t('li.afpa.+400 people registered for an average CPA of 65 €')}
                            </span>
                          </li>
                          <li>
                            <span>{t('li.afpa.+ 30% of those present at events')}</span>
                          </li>
                          <li>
                            <span>{t('li.afpa.+ 25% of those enrolled in the device')}</span>
                          </li>
                          <li>
                            <span>
                              {t(
                                'li.afpa.Capability of predictive budget and notion of ROI in training campaigns'
                              )}
                            </span>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* fourth slide */}
        {!shouldGoCollapse && (
          <div className={styles.post}>
            <div className={styles.postMainPart}>
              <img src={customersReviewsData[3].logo} alt="logo" />
              <div className={styles.brief}>
                <h4 className={styles.title}>{t('titles.THE BRIEF')}</h4>
                <div>
                  <ol>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.armeeAir.acquisition of engineers, technicians'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.armeeAir.duplicate and distribute thousands of job offers'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.armeeAir.recruit candidates throughout France'),
                      }}
                    ></li>
                  </ol>
                </div>

                <div style={{ minWidth: '40%' }}>
                  <h4 className={styles.title}>{t('titles.THE GOALS')}</h4>
                  <ul>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.armeeAir.improve the recruitment process'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.armeeAir.automate tedious tasks'),
                      }}
                    ></li>
                  </ul>
                </div>
                <div>
                  <h4 className={styles.title}>{t('titles.THE RESULTS')}</h4>
                  <ul>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.armeeAir.Connection to all channels used'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.armeeAir.A time saving of several tens'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.armeeAir.Clear and precise economic intelligence'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.armeeAir.Nearly 40k job offers'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.armeeAir.6k applications'),
                      }}
                    ></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* fifth slide */}
        {!shouldGoCollapse && (
          <div className={styles.post}>
            <div className={styles.postMainPart}>
              <img src={customersReviewsData[4].logo} alt="logo" />
              <div className={styles.brief}>
                <h4 className={styles.title}>{t('titles.THE BRIEF')}</h4>
                <div>
                  <ol>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.armeeTerre.diffusion de l’ensemble des profils'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.armeeTerre.dupliquer et diffuser des milliers'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.armeeTerre.permettre et faciliter le recrutement'),
                      }}
                    ></li>
                  </ol>
                </div>

                <div style={{ minWidth: '40%' }}>
                  <h4 className={styles.title}>{t('titles.THE GOALS')}</h4>
                  <ul>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.armeeTerre.améliorer le processus de recrutement'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.armeeTerre.automatiser les tâches fastidieuses'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.armeeTerre.fournir des KPIs'),
                      }}
                    ></li>
                  </ul>
                </div>
                <div>
                  <h4 className={styles.title}>{t('titles.THE RESULTS')}</h4>
                  <ul>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.armeeTerre.Connexion à tous les canaux utilisés'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.armeeTerre.Un gain de temps de plusieurs dizaines'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.armeeTerre.Une intelligence économique claire et précise'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.armeeTerre.Près de 100k offres d’emplois'),
                      }}
                    ></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* sixth slide */}
        {!shouldGoCollapse && (
          <div className={styles.post}>
            <div className={styles.postMainPart}>
              <img src={customersReviewsData[5].logo} alt="logo" />
              <div className={styles.brief}>
                <h4 className={styles.title}>{t('titles.THE BRIEF')}</h4>
                <div>
                  <ol>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t(
                          'li.bouygues.Acquisition d’une cible principale de conseillers clientele'
                        ),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.bouygues.Cibler aussi d’autres types de profils'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.bouygues.Déployer une campagne générique'),
                      }}
                    ></li>
                  </ol>
                </div>

                <div style={{ minWidth: '40%' }}>
                  <h4 className={styles.title}>{t('titles.THE GOALS')}</h4>
                  <ul>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.bouygues.Recruter des profils'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.bouygues.Un double objectif'),
                      }}
                    ></li>
                  </ul>
                </div>
                <div>
                  <h4 className={styles.title}>{t('titles.THE RESULTS')}</h4>
                  <ul>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t('li.bouygues.Performances'),
                      }}
                    ></span>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.bouygues.+800 candidatures'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.bouygues.+3,8 M d’impressions'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.bouygues.+60k clics'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.bouygues.Un taux de conversion'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.bouygues.Optimisation sur tous type de canal'),
                      }}
                    ></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* seventh slide */}
        {!shouldGoCollapse && (
          <div className={styles.post}>
            <div className={styles.postMainPart}>
              <img src={customersReviewsData[6].logo} alt="logo" />
              <div className={styles.brief}>
                <h4 className={styles.title}>{t('titles.THE BRIEF')}</h4>
                <div>
                  <ol>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.engie.Recrutement de profils pénuriques'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.engie.Un atout visibilité pour atteindre des profils rares'),
                      }}
                    ></li>
                  </ol>
                </div>

                <div style={{ minWidth: '40%' }}>
                  <h4 className={styles.title}>{t('titles.THE GOALS')}</h4>
                  <ul>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.engie.Créer des performances cohérentes'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.engie.Créer un vivier de talents'),
                      }}
                    ></li>
                  </ul>
                </div>
                <div>
                  <h4 className={styles.title}>{t('titles.THE RESULTS')}</h4>
                  <ul>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t('li.engie.Performance'),
                      }}
                    ></span>
                    :
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.engie.+1 700 candidatures'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.engie.CPA moyen dans l’intervalle'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.engie.+3,2M d’impressions'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.engie.+48k clics'),
                      }}
                    ></li>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t('li.engie.Display'),
                      }}
                    ></span>
                    :
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.engie.Un atout visibilité'),
                      }}
                    ></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* eighth slide */}
        {!shouldGoCollapse && (
          <div className={styles.post}>
            <div className={styles.postMainPart}>
              <img src={customersReviewsData[7].logo} alt="logo" />
              <div className={styles.brief}>
                <h4 className={styles.title}>{t('titles.THE BRIEF')}</h4>
                <div>
                  <ol>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.admr.Mettre en place un dispositif'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.admr.Campagne de double'),
                      }}
                    ></li>
                  </ol>
                </div>

                <div style={{ minWidth: '40%' }}>
                  <h4 className={styles.title}>{t('titles.THE GOALS')}</h4>
                  <ul>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.admr.Recruter des profils'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.admr.Promouvoir l’ADMR'),
                      }}
                    ></li>
                  </ul>
                </div>
                <div>
                  <h4 className={styles.title}>{t('titles.THE RESULTS')}</h4>
                  <ul>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t('li.admr.Performances vague 1 et 2'),
                      }}
                    ></span>
                    :
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.admr.CPA de 3€'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.admr.Total de +18K'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.admr.+400K clics'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.admr.Effet notoriété accru'),
                      }}
                    ></li>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t('li.admr.Résultats'),
                      }}
                    ></span>
                    :
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.admr.Des canaux de visibilités'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.admr.Une répartition des candidatures'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.admr.Des remontées de variances'),
                      }}
                    ></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* ninth slide */}
        {!shouldGoCollapse && (
          <div className={styles.post}>
            <div className={styles.postMainPart}>
              <img src={customersReviewsData[8].logo} alt="logo" />
              <div className={styles.brief}>
                <h4 className={styles.title}>{t('titles.THE BRIEF')}</h4>
                <div>
                  <ol>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.mozaic.Inciter les candidats'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.mozaic.Préparer une campagne'),
                      }}
                    ></li>
                  </ol>
                </div>

                <div style={{ minWidth: '40%' }}>
                  <h4 className={styles.title}>{t('titles.THE GOALS')}</h4>
                  <ul>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.mozaic.Objectifs de Coût'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.mozaic.Promotion de la visibilité'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.mozaic.Acquisition de talents'),
                      }}
                    ></li>
                  </ul>
                </div>
                <div>
                  <h4 className={styles.title}>{t('titles.THE RESULTS')}</h4>
                  <ul>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.mozaic.Optimisation sur tout type de canal'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.mozaic.60K clics'),
                      }}
                    ></li>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t('li.mozaic.Performance'),
                      }}
                    ></span>
                    :
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.mozaic.+1 500 inscriptions'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.mozaic.+3M impressions'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.mozaic.Mise en place d’une campagne'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.mozaic.Coût de conversion'),
                      }}
                    ></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* tenth slide */}
        {!shouldGoCollapse && (
          <div className={styles.post}>
            <div className={styles.postMainPart}>
              <img src={customersReviewsData[9].logo} alt="logo" />
              <div className={styles.brief}>
                <h4 className={styles.title}>{t('titles.THE BRIEF')}</h4>
                <div>
                  <ol>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.spie.Trouver facilement'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.spie.Ecarter les profils'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.spie.Transparence'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.spie.Capacité à traiter'),
                      }}
                    ></li>
                  </ol>
                </div>

                <div style={{ minWidth: '40%' }}>
                  <h4 className={styles.title}>{t('titles.THE GOALS')}</h4>
                  <ul>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.spie.Augmenter et améliorer la productivité'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.spie.Gains de temps et de précision'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.spie.Explicativité des résultats'),
                      }}
                    ></li>
                  </ul>
                </div>
                <div>
                  <h4 className={styles.title}>{t('titles.THE RESULTS')}</h4>
                  <ul>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.spie.130k CVs'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.spie.10k offres indexées'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.spie.75% de qualité'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.spie.Adéquation entre les offres'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.spie.Extension de l’offre'),
                      }}
                    ></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* eleventh slide */}
        {!shouldGoCollapse && (
          <div className={styles.post}>
            <div className={styles.postMainPart}>
              <img src={customersReviewsData[10].logo} alt="logo" />
              <div className={styles.brief}>
                <h4 className={styles.title}>{t('titles.THE BRIEF')}</h4>
                <div>
                  <ol>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.jobplus.Acteur national'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.jobplus.Traitement initial'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.jobplus.Récupération de CVs'),
                      }}
                    ></li>
                  </ol>
                </div>

                <div>
                  <ul>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.jobplus.Difficultés à parser'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.jobplus.Processus chronophage'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.jobplus.Peu de KPIs précis'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.jobplus.Pas de visiblité'),
                      }}
                    ></li>
                  </ul>
                </div>

                <div style={{ minWidth: '40%' }}>
                  <h4 className={styles.title}>{t('titles.THE GOALS')}</h4>
                  <ul>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.jobplus.Gain de précision et de temps'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.jobplus.Facilité d’usage'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.jobplus.Usage de l’IA'),
                      }}
                    ></li>
                  </ul>
                </div>
                <div>
                  <h4 className={styles.title}>{t('titles.THE RESULTS')}</h4>
                  <ul>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.jobplus.Parsing obtenu'),
                      }}
                    ></li>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t('li.mozaic.Performance'),
                      }}
                    ></span>
                    :
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.jobplus.3s-8s'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.jobplus.Visualisation immédiate'),
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('li.jobplus.Aide au choix'),
                      }}
                    ></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
      </Carousel>
    );
  }
}

CustomersTestifySlider.propTypes = {
  t: PropTypes.func.isRequired,
  activeSlide: PropTypes.number,
  shouldGoCollapse: PropTypes.bool.isRequired,
};
