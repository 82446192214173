import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import ProductCards from 'components/caseStudies/productCardsSection';
import MyCarousel from 'components/caseStudies/theyTrustUs';
import CustomerReviews from 'components/caseStudies/customerReviewsSection';
import { trustLogos } from 'data/blocksInfo';
import { MiniHeader } from '../../components/mini-header';

import TagManager from 'react-gtm-module';

const ClientCases = ({ t, title }) => {
  useEffect(() => {
    document.title = title || '';
  }, [title]);

  const shouldGoCollapse = useMediaQuery({
    query: '(max-width: 900px)',
  });

  TagManager.dataLayer({
    dataLayer: {
      event: 'pageview',
      pagePath: '/clients',
      pageTitle: 'Case Studies',
    },
  });

  return (
    <div>
      <MiniHeader
        title={t('miniheader.Case studies')}
        subtitle={t('miniheader.A data approach for an optimal ROI')}
        explanation={t('miniheader.Return on investment')}
        description={t(
          'miniheader.The rigor of our technological approach based on data and artificial intelligence promotes optimal transparency'
        )}
        image={'clients'}
      />
      <ProductCards t={t} />
      <MyCarousel t={t} infoIcons={trustLogos} />
      <CustomerReviews t={t} shouldGoCollapse={shouldGoCollapse} />
    </div>
  );
};

ClientCases.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default ClientCases;
