import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import cx from 'classnames';

import styles from './headerForHome.module.scss';

const HeaderForHome = ({ t, state, routeName, callback }) => {
  return (
    <div className={cx(styles[state], styles.main)} onClick={() => callback(routeName)}>
      <div className={styles.content}>
        <h1 className={styles.title}>
          {t('titles.Bring your recruitment')}
        </h1>
        <p
          className={styles.text}
          dangerouslySetInnerHTML={{
            __html: t('text.The third algorithmic revolution is underway'),
          }}
        ></p>
        £
      </div>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();

          document.getElementById('1').scrollIntoView({ block: 'start', behavior: 'smooth' });
        }}
        className={styles.scrollButton}
      >
      </a>
    </div>
  );
};

HeaderForHome.propTypes = {
  t: PropTypes.func.isRequired,
  state: PropTypes.oneOf(['active', 'notActive', 'hidden']).isRequired,
  routeName: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
};

export default withTranslation()(HeaderForHome);
