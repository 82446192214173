import productNames from 'data/productNames';

const { XPa, XPaUrl, XRa, XRaUrl } = productNames;

const titlesArr = [
  { id: 0, title: 'Technology', path: '/technology' },
  { id: 1, title: 'News', path: '/news' },
  { id: 2, title: 'Our clients testify', path: '/clients' },
  { id: 3, title: 'Team', path: '/team' },
  { id: 4, title: XPa, path: `/${XPaUrl}` },
  { id: 5, title: XRa, path: `/${XRaUrl}` },
  { id: 6, title: 'Xtramile', path: '/' },
  { id: 7, title: 'Xtramile', path: '/general' },
  { id: 8, title: 'Xtramile', path: '/home' },
  { id: 9, title: 'Privacy Statement', path: '/privacy-statement' },
  { id: 10, title: 'Disclaimer', path: '/disclaimer' },
  { id: 11, title: 'Terms and conditions', path: '/terms-and-conditions' },
];

const titleHead = document.getElementsByTagName('title')[0];

export default function (history) {
  const pathArr = history.location.pathname.split('/');
  const pathname = pathArr[pathArr.length - 1];

  const newTitleArr = titlesArr.filter((item) => pathname === item.path.slice(1));

  titleHead.innerHTML = newTitleArr[0]?.title;
}
