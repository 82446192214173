import React from 'react';
import PropTypes from 'prop-types';

import styles from './rhPartners.module.scss';
import { logoRHPartners } from 'data/blocksInfo';

const RHPartners = ({ t }) => {
  return (
    <section className={styles.sectionRHPartners}>
      <div className="container">
        <div className={styles.wrapper}>
          <h1 className={styles.title}>{t('titles.Our HR ecosystem')}</h1>
          <p className={styles.description}>
            {t(
              'text.Xtramile works closely with players in the HR sector and in particular with the main French HR communication agencies'
            )}
          </p>
          <p className={styles.description}>
            {t(
              'text.Their mastery of HR issues and their knowledge of the recruitment professions contribute to the continuous improvement of our solutions'
            )}
          </p>
          <div className={styles.logos}>
            {logoRHPartners.map((logo) => (
              <div key={logo.alt} className={styles.logoContainer}>
                <img src={logo.src} alt={logo.alt} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

RHPartners.propTypes = {
  t: PropTypes.func.isRequired,
};

export default RHPartners;
