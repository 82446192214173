import React from 'react';
import PropTypes from 'prop-types';

import styles from './approachToMultidiff.module.scss';
import schema from '../../../assets/images/xtramile_software_job/background_approach.png';
import mediaData from '../../../assets/images/MediaData.svg';
import audianceRation from '../../../assets/images/AudianceRation.svg';

const ApproachToMultidiff = ({ t }) => {
  return (
    <section className={`slider2 ${styles.section}`}>
      <div className={`container ${styles.container}`}>
        <h3 className={styles.title}>{t('titles.x-soft-job_Our specific approach')}</h3>

        <p className={styles.text}>{t(`text.x-soft-job_Our tool`)}</p>

        <div className={styles.mediaDataWrapper}>
          <div className={styles.mediaData}>
            <img src={mediaData} alt="media data" />
            <div>
              <div>{t('titles.x-soft-job_Media & Channels')}</div>
              <div>{t('titles.x-soft-job_which constitute')}</div>
              <div>{t('titles.x-soft-job_the part of reception')}</div>
            </div>
          </div>

          <div className={styles.audianceRation}>
            <img src={audianceRation} alt="audiance Ration" />
            <div>
              <div>{t('titles.x-soft-job_Posting & Analytics')}</div>
              <div>{t('titles.x-soft-job_in order to optimize the dissemination')}</div>
            </div>
          </div>
        </div>

        <div className={styles.schemaContainer}>
          <img src={schema} alt="schema of Our specific approach to HR programmatic" />
        </div>
      </div>
    </section>
  );
};

ApproachToMultidiff.propTypes = {
  t: PropTypes.func.isRequired,
};

export default ApproachToMultidiff;
