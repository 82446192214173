import React from 'react';
import Carousel from 'react-elastic-carousel';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useMediaQuery } from 'react-responsive';

import styles from './carousel.module.scss';
import slide1 from 'assets/images/news/slide-1.png';
import slide2 from 'assets/images/news/slide-2.png';
import slide3 from 'assets/images/news/slide-3.png';
import slide4 from 'assets/images/news/slide-4.png';
import slide5 from 'assets/images/news/slide-5.png';
import slide6 from 'assets/images/news/slide-6.jpeg';
import slide7 from 'assets/images/news/slide-7.jpeg';

import Icon from 'components/ui/icon';

const data = [
  {
    id: '6',
    ava: slide7,
    text: 'Immersion dans l’univers du recrutement et de ses tendances 2022',
    link: 'https://leblogrh.net/2022/02/immersion-dans-lunivers-du-recrutement-et-de-ses-tendances-2022/',
  },
  {
    id: '5',
    ava: slide6,
    text: 'Recrutement  armée: Une nouvelle marque de confiance',
    link: 'https://www.linkedin.com/feed/update/urn:li:activity:6901814003866095616/',
  },
  {
    id: '4',
    ava: slide5,
    text: 'Xtramile and partner Oko receives Gold price',
    link: 'https://www.linkedin.com/feed/update/urn:li:activity:6892424600404197376/?commentUrn=urn%3Ali%3Acomment%3A(activity%3A6892424600404197376%2C6892450392769126400)Xtramile',
  },
  {
    id: '3',
    ava: slide4,
    text: 'Xtramile collaborates with the Google company (June 2021)',
    link: 'https://techcrunch.com/2021/06/03/google-shares-its-2m-black-founders-fund-among-30-european-startups/?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAACjIlN0gQXQwePd-DPX1vipRtTZM_PXg7aucjZZa_hDqHgklgl1ahpmqcyofD0JlDEoj0lH2awT7-sbBLLC4Osz11Akwfr2imUAY8YUXailq7f-Frv8xjIOS_l3nXzNB0AswGBB2yEwrRp2zkA7iQA8X1vNPwXcTdGJnVu7qBQ41',
  },
  {
    id: '2',
    ava: slide3,
    text: 'Xtramile rejoint le programme BPI Deep Tech (BPI) (Novembre 2020)',
    link: 'https://ec.europa.eu/easme/en/section/sme-instrument/eic-accelerator-funding-opportunities',
  },
  {
    id: '1',
    ava: slide2,
    text: 'Xtramile joins Microsoft',
    link: 'https://www.bpifrance.fr/A-la-une/Actualites/Qu-est-ce-que-la-Deep-Tech-37852',
  },
  {
    id: '0',
    ava: slide1,
    text: 'Xtramile joins Microsoft AI Factory (January 2019) and Co-Sell program (January 2020)',
    link: 'https://experiences.microsoft.fr/business/segment/start-up/ai-factory/',
  },
];

const MyCarousel = ({ t }) => {
  const isWide = useMediaQuery({
    query: '(min-width: 1180px)',
  });

  const isSmall = useMediaQuery({
    query: '(max-width: 480px)',
  });

  const itemsToShow = isWide ? 3 : 1;
  // const itemsToShow = 3;
  const breakPoints = [
    { width: 470, itemsToShow: 1 },
    { width: 550, itemsToShow: 1 },
    { width: 850, itemsToShow: 2 },
    { width: 1150, itemsToShow: 3 },
    { width: 1450, itemsToShow: 3 },
    { width: 1750, itemsToShow: 3 },
  ];

  return (
    <div className={cx('topButtons', styles.carouselWrapper)}>
      <Carousel
        itemsToShow={itemsToShow}
        showArrows={!isSmall && itemsToShow < data.length}
        enableSwipe={true}
        enableMouseSwipe={true}
        breakPoints={breakPoints}
      >
        {data &&
          data.map((item) => (
            <div key={item.id} className={styles.post}>
              <img src={item.ava} alt="news Img" />

              <div className={styles.postsText}>
                <p
                  className={styles.newsText}
                  dangerouslySetInnerHTML={{ __html: t(`text.${item.text}`) }}
                ></p>
                <a href={item.link} className={styles.postsLink} target="_blank" rel="noreferrer">
                  <Icon
                    name="arrow-right"
                    width="27"
                    height="22"
                    color="#e0bc37"
                    viewbox="0 0 38 30"
                  />
                </a>
              </div>
            </div>
          ))}
      </Carousel>
    </div>
  );
};

MyCarousel.propTypes = {
  t: PropTypes.func.isRequired,
};

export default MyCarousel;
