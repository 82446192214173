import React, { useRef } from 'react';

import PropTypes from 'prop-types';
import Carousel from 'react-elastic-carousel';
import { useMediaQuery } from 'react-responsive';
import styles from './slider2.module.scss';

const Slider2 = ({ t, className }) => {
  const data = [
    {
      id: '0',
      title: 'Reduce your candidate sourcing time',
      text: 'Xtramile makes your research less time-consuming',
    },
    {
      id: '1',
      title: `Don't waste any more talent`,
      text: 'The combined models of automatic natural language processing',
    },
    {
      id: '2',
      title: 'Explicit matching',
      text:
        'The enrichment of the extracted data - anonymization, standardization, detailed description contextualization, etc',
    },
    {
      id: '3',
      title: 'Recruitment simply and serenely',
      text: 'You can link a specific Resume to other semantically',
    },
  ];
  const itemsToShow = 1;

  const carousel = useRef(null);
  const delay = 3000;

  const shouldGoHorizontal = useMediaQuery({
    query: '(max-width: 1180px)',
  });

  const setCarouselToStart = (currentItem) => {
    if (currentItem.index === data.length - 1) {
      setTimeout(() => {
        if (!carousel) return;

        carousel?.current?.goTo(0);
      }, delay);
    }
  };

  return (
    <section className={`slider2 ${styles.section} ${className || ''}`}>
      <div className={`${styles.slidersHeader} text`}>
        {t('text.Thanks to parsing and sorting techniques')}
      </div>

      <div className={styles.slider}>
        <Carousel
          verticalMode={!shouldGoHorizontal}
          showArrows={false}
          ref={carousel}
          itemsToShow={itemsToShow}
          enableSwipe={false}
          enableMouseSwipe={false}
          preventDefaultTouchmoveEvent={true}
          enableAutoPlay
          autoPlaySpeed={delay}
          transitionMs={delay}
          onChange={(currentItem) => {
            setCarouselToStart(currentItem);
          }}
        >
          {data &&
            data.map((item, index) => (
              <div key={item.id} className={`${styles.post} ${styles[`post${index}`]}`}>
                <div className={styles.postsText}>
                  <span className={`${styles.title} header`}>{t(`titles.${item.title}`)}</span>
                  <p className={styles.text}>{t(`text.${item.text}`)}</p>
                </div>
              </div>
            ))}
        </Carousel>
      </div>
    </section>
  );
};

Slider2.propTypes = {
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default Slider2;
