import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './news.module.scss';
import MyCarousel from 'components/news/carousel/carousel';
import Button from 'components/button/button';

const News = ({ t }) => {
  useEffect(() => {
    return () => document.getElementById('header').scrollIntoView();
  });

  return (
    <section className={styles.wrapper}>
      <div className="container">
        <div className={styles.news}>
          <h3 className={styles.title}>{t('titles.News')}</h3>

          <MyCarousel t={t} />

          <span className={styles.seeEverythingButton}>
            <Link to="/news">
              <Button btnView="button-warn-sm" text={t('buttons.SEE EVERYTHING')} />
            </Link>
          </span>
        </div>
      </div>
    </section>
  );
};

News.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(News);
