import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './dataCvCollapse.module.scss';
import Icon from 'components/icon';

const Experience = ({ t, data }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => setIsOpen(!isOpen);

  const findEmptySections = (data) =>
    data.map((item) => {
      return !(
        item.organisation_name.plaintext ||
        item.city.plaintext ||
        item.country.plaintext ||
        item.postal_code
      );
    });

  const emptySections = useMemo(() => findEmptySections(data), [data]);

  const areAllSectionsEmpty = emptySections.every((item) => !!item);

  return (
    !areAllSectionsEmpty && (
      <div className={styles.dataSection}>
        <div className={styles.dataBlock}>
          <div className={styles.dataTitleContainer} onClick={toggle}>
            <span className={styles.itemTitle}>{t('valuesOfCV.professional experience')}</span>
            <span className={isOpen ? styles.activeArrow : styles.notActiveArrow}>
              <Icon name="arrow" width="10" height="5" />
            </span>
          </div>

          <div className={isOpen ? styles.activeBlock : styles.notActiveBlock}>
            {data.map(
              (item, dataIndex) =>
                !emptySections[dataIndex] && (
                  <div key={dataIndex} className={styles.partOfData}>
                    {item.organisation_name.plaintext && (
                      <div className={styles.dataItem}>
                        <span className={styles.itemKey}>{t('valuesOfCV.company')}</span>
                        <span className={styles.itemValue}>{item.organisation_name.plaintext}</span>
                      </div>
                    )}

                    {item.city.plaintext && (
                      <div className={styles.dataItem}>
                        <span className={styles.itemKey}>{t(`valuesOfCV.city`)}</span>
                        <span className={styles.itemValue}>{item.city.plaintext}</span>
                      </div>
                    )}

                    {item.country.plaintext && (
                      <div className={styles.dataItem}>
                        <span className={styles.itemKey}>{t(`valuesOfCV.country`)}</span>
                        <span className={styles.itemValue}>{item.country.plaintext}</span>
                      </div>
                    )}

                    {item.postal_code && (
                      <div className={styles.dataItem}>
                        <span className={styles.itemKey}>{t(`valuesOfCV.postal_code`)}</span>
                        <span className={styles.itemValue}>{item.postal_code}</span>
                      </div>
                    )}
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    )
  );
};

Experience.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
};

export default Experience;
