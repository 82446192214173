const data = [
  {
    category: 'The founders',
    list: [
      {
        name: 'Xavier RAGAGE',
        titleFr: 'CEO / Co-fondateur',
        titleEn: 'CEO / Co-founder',
        picture: 'Xavier',
        descriptionEn:
          "An ex-private banker at JPMorgan, Xavier has spent almost 10 years within JPM Private Bank in Paris and London offices. He spent 5 years, providing personalized financial and banking services to high net worth individuals covering the French Market. He then moved to the alternatives team in London to promote the alternatives investments such as hedge funds, private equity to the bank's clients across EMEA.He holds two Masters: One in Economics from New York University(NYU) and One in Philosophy from Sorbonne University.He is a French citizen.\n\nXavier is also an author interested in entrepreneurial themes and has for instance written an essay combining his philosophical and economic experiences: The Ethics of Aristotle Applied to Entrepreneurs.\n\nIt is available at the following link: http://www.editions-harmattan.fr/index.asp?navig=catalogue&obj=livre&no=61930",
        descriptionFr:
          "Ancien conseiller en banque privée chez JPMorgan, Xavier a passé près de 10 ans au sein de JPM Private Bank à Paris et à Londres. Pendant 5 ans, il a fourni des services bancaires personnalisés au sein de la banque privée, ainsi que des services financiers et bancaires à une clientèle fortunée sur le marché français. Il a ensuite rejoint l'équipe des investissements alternatifs à Londres pour promouvoir les actifs tels que les hedge funds, les sociétés de capital ­investissement, etc.pour les clients de la banque privée sur la zone EMEA.Cette experience en Private Equity l'a poussé à devenir entrepreneur en fondant Xtramile en France et en Angleterre, appliquant des stratégies de trading dans le domaine du recrutement. Il est titulaire de deux Masters en économie et en philosophie respectivement de l'Université de New York(NYU) et de l'Université de la Sorbonne. Il est français.\n\nXavier est aussi un auteur intéressé par les thématiques entrepreneuriales et a par exemple écrit un essai combinant ses expériences philosophiques et économiques: L'Ethique d'Aristote appliquée aux Entrepreneurs.\n\nL'ouvrage est disponible au lien suivant: http://www.editions-harmattan.fr/index.asp?navig=catalogue&obj=livre&no=61930",
        linkedinLink: 'https://www.linkedin.com/in/xavierragage/',
      },
      {
        name: 'Stéphanie NENTA',
        titleFr: 'CMO & COO / Co-fondateur',
        titleEn: 'CMO & COO / Co-founder',
        picture: 'Stephanie',
        descriptionEn:
          'Prior to co-founding myXtramile, she worked at JPMorgan Private Bank in London as a senior VP in charge of the lending book helping clients structure their debt financing with solutions ranging from complex to plain vanilla. She was also in charge of promoting investments ideas using leverage across the EMEA platform. Before joining JPM in 2009, she worked at Lehman Brothers within the Equity Capital Markets division covering financial institutions clients. She worked on some high profiles transactions including the rights issue of Credit Agricole in 2008. She has a Master in Finance from HEC Grande école. She is originally from Cameroon and holds a British passport.',
        descriptionFr:
          "Avant de co­fonder Xtramile, elle a travaillé chez JPM Private Bank à Londres en tant que vice-présidente senior dans l'équipe crédit aidant les clients à mettre en place leur financement par emprunt, qu'il s'agisse de structures vanilla aux structures plus complexes.Elle a également été en charge de la promotion d'investissements avec effet de levier sur la plate-forme EMEA. Avant de rejoindre JPM en 2009, elle a travaillé chez Lehman Brothers au sein du département Equity Capital Markets dans l'equipe FIG en charge des institutions financières.Elle a travaillé sur des transactions comme l'augmentation de capital du Crédit Agricole en 2008. Elle est diplômée de l'Ecole des Hautes Éludes Commerciales(HEC).Elle est originaire du Cameroun et titulaire d'un passeport britannique.",
        linkedinLink: 'https://www.linkedin.com/in/stephanienenta/',
      },
    ],
  },
  {
    category: 'The managers',
    list: [
      {
        name: 'Laurențiu IONAȘCU',
        titleFr: '',
        titleEn: '',
        picture: 'Laurentiu',
        linkedinLink: 'https://www.linkedin.com'
      },
      {
        name: 'Mariem BOUHAHA',
        titleFr: '',
        titleEn: '',
        picture: 'Mariem',
        linkedinLink: 'https://www.linkedin.com'
      },
      {
        name: 'Aurélie BARBAT',
        titleFr: '',
        titleEn: '',
        picture: 'Aurelie',
        linkedinLink: 'https://www.linkedin.com'
      },
      {
        name: 'Halima RAMDANI',
        titleFr: '',
        titleEn: '',
        picture: 'Halima',
        linkedinLink: 'https://www.linkedin.com'
      },
      {
        name: 'Laetitia LEFEBVRE',
        titleFr: '',
        titleEn: '',
        picture: 'Laetitia',
        linkedinLink: 'https://www.linkedin.com'
      },
    ]
  },
  {
    category: 'The developers',
    categoryDesc: '(tech & business)',
    list: [
      {
        name: 'Iulian PLEȘOIANU',
        titleFr: '',
        titleEn: '',
        picture: 'Iulian',
        linkedinLink: 'https://www.linkedin.com'
      },
      {
        name: 'Ionuț PRUNACHE',
        titleFr: '',
        titleEn: '',
        picture: 'Ionut',
        linkedinLink: 'https://www.linkedin.com'
      },
      {
        name: 'Faycal BRAHAM',
        titleFr: '',
        titleEn: '',
        picture: 'Faycal',
        linkedinLink: 'https://www.linkedin.com'
      },
      {
        name: 'Elodie VERNETTE',
        titleFr: '',
        titleEn: '',
        picture: 'Elodie',
        linkedinLink: 'https://www.linkedin.com'
      },
      {
        name: 'Henitsoa RAKOTOMALALA',
        titleFr: '',
        titleEn: '',
        picture: 'Henitsoa',
        linkedinLink: 'https://www.linkedin.com'
      },
      {
        name: 'Amira AM',
        titleFr: '',
        titleEn: '',
        picture: 'Amira',
        linkedinLink: 'https://www.linkedin.com'
      },
    ]
  },
  {
    category: 'The developers #2',
    list: [
      {
        name: 'Vassanth SIVACOUMARANE',
        titleFr: '',
        titleEn: '',
        picture: 'Vassanth',
        linkedinLink: 'https://www.linkedin.com'
      },
      {
        name: 'Valentin LAGACHE',
        titleFr: '',
        titleEn: '',
        picture: 'Valentin',
        linkedinLink: 'https://www.linkedin.com'
      },
      {
        name: 'Sabrina BETKA',
        titleFr: '',
        titleEn: '',
        picture: 'Sabrina',
        linkedinLink: 'https://www.linkedin.com'
      },
      {
        name: 'Alexandre DENIS',
        titleFr: '',
        titleEn: '',
        picture: 'Alexandre',
        linkedinLink: 'https://www.linkedin.com'
      },
      {
        name: 'Elmano NOEL',
        titleFr: '',
        titleEn: '',
        picture: 'Elmano',
        linkedinLink: 'https://www.linkedin.com'
      },
    ]
  },
  {
    category: 'The mentors',
    list: [
      {
        name: 'Dominique VALENTINY',
        titleFr: '',
        titleEn: '',
        picture: 'Dominique',
        descriptionEn:
          "Dominique is an active investor and a successful entrepreneur. In early 2012, Dominique joined the start-up Skysites Americas as lead investor and full-time CEO. Under Dominique's leadership, Skysites Americas has been able to secure a portfolio of more than 30, 000 urban sites for the extension of the mobile operator network in Brazil.Previously, Dominique worked for the Aéroports de Paris Group(ADP) as CEO of Hub Télécom.Dominique's previous experiences in the industry include Booz Allen and Hamilton as well as 12 years with the Orange Group. Dominique is a graduate of École Polytechnique, Télécom ParisTech, and has an MBA from MIT Sloan School.",
        descriptionFr:
          "Dominique est une investisseuse active et une entrepreneuse à succès. Début 2012, Dominique rejoint la start-up Skysites Americas en tant qu'investisseur principal et PDG à plein temps.Sous la direction de Dominique, Skysites Americas a été en mesure d'obtenir un portefeuille de plus de 30.000 sites urbains pour l'extension du réseau des opérateurs mobiles au Brésil.Auparavant, Dominique a travaillé pour le Groupe Aéroports de Paris(ADP) en tant que PDG de Hub Télécom.Les précédentes expériences de Dominique dans l'industrie incluent Booz Allen et Hamilton ainsi que 12 années au sein du Groupe Orange. Dominique est diplômée de l'École Polytechnique, de Télécom ParisTech, et possède un MBA de MIT Sloan School.",
        linkedinLink: 'https://www.linkedin.com/in/dominiquevalentiny/',
      },
      {
        name: 'Olivier NJAMFA',
        titleFr: '',
        titleEn: '',
        picture: 'Olivier',
        linkedinLink: '',
      },
      {
        name: 'Valeriu Lăcătușu',
        titleFr: '',
        titleEn: '',
        picture: 'Valeriu',
        linkedinLink: '',
      },
      {
        name: 'Gregory GUILLOU',
        titleFr: '',
        titleEn: '',
        picture: 'Gregory',
        descriptionEn:
          'Grégory is a Senior Lead Architect, specializing in data, cloud and most of the components required for complete technology infrastructures (from database deployments (all types) to application services and applications). operators (RabbitMQ, Spark etc.) Its objectives are to build reliable and scalable operable systems like those built by Xtramile. Grégory has worked for both large groups such as Oracle (for which he continues to make regular presentations) and the startup world he enjoys. He joins Xtramile as a technical mentor. He originally graduated in Signal and Image Processing Major from Centrale-Supélec School.',
        descriptionFr:
          'Grégory est un Lead Architecte Senior de haut-rang, spécialiste de la donnée, du cloud et de la plupart des composants nécessaires à des infrastructures technologiques complètes (allant des déploiements de bases de données (tous types) à ceux des services applicatifs et d’opérateurs (RabbitMQ ; Spark etc.). Ses objectifs sont de construire des systèmes opérables fiables et scalables comme ceux que bâtit Xtramile. Grégory a travaillé à la fois pour de grands groupes tels Oracle (pour lequel il continue de faire des présentations régulières) et l’univers startup qu’il apprécie. Il rejoint Xtramile en tant que mentor technique. Il est originellement diplômé en Traitement du signal et des images de l’école Centrale-Supélec.',
        linkedinLink: 'https://www.linkedin.com/in/gregoryguillou/',
      },
    ],
  },
];

export default data;
