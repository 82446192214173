import React, { useState } from 'react';
import PropTypes from 'prop-types';
import quotesYellow from 'assets/icons/quotes-yellow.svg';

import styles from './collapsable.module.scss';

const CollapsableSlider = ({ slide, t }) => {
  const [collapses, setCollapses] = useState([false, false, false]);

  const videoSource = {
    fibois: 'https://www.youtube.com/embed/c3l0pRtLN5g',
    stedy: 'https://www.youtube.com/embed/I4tbWmeIOIs',
    afpa: 'https://www.youtube.com/embed/1TPaIq6AdXg',
  };

  const changeState = (index) => {
    setCollapses(
      collapses.map((collapse, i) => {
        return i === index ? !collapse : collapse;
      })
    );
  };
  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <img src={slide.logo} alt="logo" />
        {slide.author && (
          <div className={styles.postText}>
            <p>
              <img
                src={quotesYellow}
                alt="logo"
                style={{ width: '50px', height: '42px', marginRight: '5px' }}
              />
              {t(`quote.${slide.quote}`)}”
            </p>
          </div>
        )}
        {slide.author && (
          <div className={styles.postAuthorNameContainer}>
            <div>{slide.author}</div>
            <div>{t(`positions.${slide.position}`)}</div>
          </div>
        )}
      </div>
      {videoSource[slide.name] && (
        <div className={styles.videoContainer}>
          <iframe
            src={videoSource[slide.name]}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      )}

      <div className={styles.accordionHeader} onClick={() => changeState(0)}>
        <span>{t('titles.THE BRIEF')}</span>
        {!collapses[0] && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="2 3 15 15"
            width="35"
            height="35"
            fill="#e0bc37"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        )}
        {collapses[0] && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="2 3 15 15"
            width="35"
            height="35"
            fill="#e0bc37"
          >
            <path
              fillRule="evenodd"
              d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
              clipRule="evenodd"
            />
          </svg>
        )}
      </div>
      <div className={`${styles.accordionContent} ${collapses[0] && styles.displayed}`}>
        {slide.name === 'fibois' && (
          <>
            <div>
              <ol>
                <li>{t('li.fiboise.National actor specialized in forest sectors-wood')}</li>
                <li>{t('li.fiboise.Initial treatment by words-keys')}</li>
                <li>
                  {t(
                    'li.fiboise.Resume recovery in all formats and adaptation to needs of recruiter'
                  )}
                </li>
              </ol>
            </div>

            <div>
              <ul>
                <li>
                  {t(
                    'li.fiboise.Difficulties in sourcing and finding relevant skills using an existing platform'
                  )}
                </li>
                <li>
                  {t(
                    'li.fiboise.Present relevant offers to a candidate directly when arriving on the platform and presenting his candidacy'
                  )}
                </li>
                <li>{t('li.fiboise.Modernize the image of Fibois on this service')}</li>
              </ul>
            </div>
          </>
        )}

        {slide.name === 'stedy' && (
          <>
            <div>
              <ol>
                <li>{t('li.stedy.New platform Free - Spears on IT missions France')}</li>
                <li>{t('li.stedy.100 types of offers different')}</li>
                <li>{t('li.stedy.Acquisition of Talents')}</li>
                <li>{t('li.stedy.Monitoring performance of broadcast channels')}</li>
              </ol>
            </div>

            <div>
              <ul>
                <li>
                  {t(
                    'li.stedy.Difficulties in sourcing and adapting budgets according to suitable channels'
                  )}
                </li>
                <li>{t('li.stedy.No visibility pre-existing')}</li>
                <li>
                  {t(
                    'li.stedy.KPIs that are difficult to follow and very heterogeneous in channel function'
                  )}
                </li>
                <li>{t('li.stedy.Lack of ROI measurement')}</li>
              </ul>
            </div>
          </>
        )}

        {slide.name === 'afpa' && (
          <>
            <div>
              <ol>
                <li>{t('li.afpa.national agency')}</li>
                <li>{t('li.afpa.development yutz')}</li>
              </ol>
            </div>

            <div>
              <ul>
                <li>{t('li.afpa.Difficulty finding a target that is difficult to identify')}</li>
                <li>{t('li.afpa.No pre-existing visibility')}</li>
                <li>
                  {t('li.afpa.Very manual and time-consuming operations in the initial state')}
                </li>
              </ul>
            </div>
          </>
        )}
      </div>
      <div className={styles.accordionHeader} onClick={() => changeState(1)}>
        <span>{t('titles.THE GOALS')}</span>
        {!collapses[1] && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="2 3 15 15"
            width="35"
            height="35"
            fill="#e0bc37"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        )}
        {collapses[1] && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="2 3 15 15"
            width="35"
            height="35"
            fill="#e0bc37"
          >
            <path
              fillRule="evenodd"
              d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
              clipRule="evenodd"
            />
          </svg>
        )}
      </div>
      <div className={`${styles.accordionContent} ${collapses[1] && styles.displayed}`}>
        {slide.name === 'fibois' && (
          <ul>
            <li>{t('li.fiboise.Gain in precision and time')}</li>
            <li>{t('li.fiboise.Interfacing directly with the candidate (not the recruiter)')}</li>
            <li>{t('li.fiboise.Dynamic matching')}</li>
            <li>
              {t('li.fiboise.Use of AI to gain an understanding of context and not just keywords')}
            </li>
          </ul>
        )}
        {slide.name === 'stedy' && (
          <ul>
            <li>{t('li.stedy.Create performance in a way')}</li>
            <li>{t('li.stedy.Recruitment of 600 talents')}</li>
            <li>{t('li.stedy.CPA target € 50')}</li>
          </ul>
        )}
        {slide.name === 'afpa' && (
          <ul>
            <li>{t('li.afpa.Promote the Job Academie device')}</li>
            <li>{t("li.afpa.Better understand AFPA's own target")}</li>
            <li>{t('li.afpa.Promote organized events and meetings')}</li>
            <li>{t('li.afpa.Optimize distribution costs')}</li>
          </ul>
        )}
      </div>
      <div className={styles.accordionHeader} onClick={() => changeState(2)}>
        <span>{t('titles.THE RESULTS')}</span>
        {!collapses[0] && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="2 3 15 15"
            width="35"
            height="35"
            fill="#e0bc37"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        )}
        {collapses[0] && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="2 3 15 15"
            width="35"
            height="35"
            fill="#e0bc37"
          >
            <path
              fillRule="evenodd"
              d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
              clipRule="evenodd"
            />
          </svg>
        )}
      </div>
      <div
        className={`${styles.accordionContent} ${styles.blockResult} ${
          collapses[2] && styles.displayed
        }`}
      >
        {slide.name === 'fibois' && (
          <ul>
            <li>
              {t('li.fiboise.Matching obtained')}
              <span>{t('li.fiboise.on any type of format')}</span>
            </li>
            <li>
              {t('li.Performance')}
              <ul>
                <li>
                  {t('li.fiboise.3s')}
                  <span>{t('li.fiboise.average time of 4 sec')}</span>
                </li>
                <li>
                  {t('li.fiboise.Visualization immediate')}
                  <span>
                    {t(
                      'li.fiboise.elements according to Identity, Experience, Education, Soft Skills;'
                    )}
                  </span>
                </li>
                <li className={styles.bold}>
                  {t('li.fiboise.Improvement of the candidate acquisition tunnel')}{' '}
                  <span>{t('li.fiboise.by the application adapted to offers and precise')}</span>
                </li>
              </ul>
            </li>
          </ul>
        )}
        {slide.name === 'stedy' && (
          <ul>
            <li>
              {t('li.stedy.Optimization')}
              <span>{t('li.stedy.on any type of channel')}</span>
            </li>
            <li>
              {t('li.Performance')}
              <ul>
                <li>
                  {t('li.stedy.+800')}
                  <span>{t('li.stedy.applicants from different sources')}</span>
                </li>
                <li>
                  <span>{t('li.stedy.CPA way of')}</span>
                  {t('li.stedy.€36')}
                  <span>{t('li.stedy.28% vs goal')}</span>
                </li>
              </ul>
            </li>
            <li>{t('li.stedy.Strategy for a perpetuation long-term (validated)')}</li>
            <li>
              {t('li.stedy.Help with setting in place of a 2021 budget based on of KPIs real')}
            </li>
          </ul>
        )}

        {slide.name === 'afpa' && (
          <ul>
            <li>
              {t('li.afpa.More than 8 promoted events, 1 generic campaign')}
              <ul>
                <li>
                  <span>{t('li.afpa.Large increase of Job Academy visibility')}</span>
                </li>
                <li>
                  <span>{t('li.afpa.+400 people registered for an average CPA of 65 €')}</span>
                </li>
                <li>
                  <span>{t('li.afpa.+ 30% of those present at events')}</span>
                </li>
                <li>
                  <span>{t('li.afpa.+ 25% of those enrolled in the device')}</span>
                </li>
                <li>
                  <span>
                    {t(
                      'li.afpa.Capability of predictive budget and notion of ROI in training campaigns'
                    )}
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

CollapsableSlider.propTypes = {
  slide: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default CollapsableSlider;
