import React from 'react';
import PropTypes from 'prop-types';

import styles from './technologicalPartners.module.scss';
import { logoTechnologicalPartners } from 'data/blocksInfo';

const TechnologicalPartners = ({ t }) => {
  return (
    <section className={styles.sectionTechnologicalPartners}>
      <div className="container">
        <div className={styles.wrapper}>
          <h1 className={styles.title}>{t('titles.Our technology partners')}</h1>
          <p className={styles.description}>
            {t(
              'text.Our technological foundation is based on an ecosystem of partners made up of the main market players and the state of the art in artificial intelligence knowledge'
            )}
          </p>
          <div className={styles.logos}>
            {logoTechnologicalPartners.map((logo) => (
              <div key={logo.alt} className={styles.logoContainer}>
                <img src={logo.src} alt={logo.alt} className={styles.logo} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

TechnologicalPartners.propTypes = {
  t: PropTypes.func.isRequired,
};

export default TechnologicalPartners;
