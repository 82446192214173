import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { useMediaQuery } from 'react-responsive';

import Button from 'components/button/button';

import styles from './ourNews.module.scss';
import { quickNews } from 'data/blocksInfo';
import yellowArrow from 'assets/icons/YellowArrow.svg';

const OurNews = ({ t }) => {
  const isSmall = useMediaQuery({
    query: '(max-width: 480px)',
  });

  return (
    <div className={styles.sectionOurNews}>
      <div className={styles.blueBlock}>{/* first section with blue background  */}</div>
      <div className={`container ${styles.marginNegative}`}>
        <div className={styles.col}>
          <div className={` ${styles.header} ${styles.row} ${styles.spaceBetween}`}>
            <h1 className={styles.title}>{t('titles.Our news')}</h1>
            {!isSmall && (
              <a href="/news" className={styles.seeMoreNewsButton}>
                <Button btnView={`${'button-warn-sm'} ${'small'}`} text={t('titles.SEE ALL')} />
              </a>
            )}
          </div>
          <div className={styles.card}>
            {quickNews.map((news, i) => (
              <div key={`news-${i}`} className={styles.blockNews}>
                <a href={news.hyperlink}>
                  <img src={yellowArrow} alt="fleche" className={styles.accessArrow} />
                </a>
                <p className={styles.cardTitle}>
                  {i18n.language === 'fr' ? news.titleFr : news.titleEn}
                </p>
                <p className={styles.body}>{i18n.language === 'fr' ? news.bodyFr : news.bodyEn}</p>
              </div>
            ))}
          </div>
          {isSmall && (
            <a href="/news" className={styles.seeMoreNewsButton}>
              <Button btnView={`${'button-warn-sm'} ${'small'}`} text={t('titles.SEE ALL')} />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

OurNews.propTypes = {
  t: PropTypes.func.isRequired,
};

export default OurNews;
