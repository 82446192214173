import React from 'react';
import PropTypes from 'prop-types';

import styles from './technologicalApproach.module.scss';

import multidiffIcon from 'assets/icons/picto_Trading_bleu_tech.svg';
import parsingIcon from 'assets/icons/picto_Parsing_jaune_tech.svg';
import servicesIcon from 'assets/icons/picto_sourcingRH.svg';
import Yarrow from 'assets/icons/YellowArrow.svg';
import Warrow from 'assets/icons/WhiteArrow.svg';
import productNames from 'data/productNames';
import { Link } from 'react-router-dom';

const { XSoftJob, XPaUrl, XSoftCV, XRaUrl, XServices, XServicesUrl, ImpactRH, ImpactRHUrl } = productNames;

const TechnologicalApproach = ({ t }) => {
  return (
    <section className={styles.sectionTechnologicalApproach}>
      <div className="container">
        <div className={styles.wrapper}>
          <div className={styles.title}>{t('titles.Our technological approach')}</div>
          <div className={styles.description}>
            {t(
              "text.Xtramile's algorithms are based on a detailed analysis of statistics, automatic natural language processing (NLP) and Machine Learning models"
            )}
          </div>

          <div className={styles.cardsContainer}>
            <div className={`${styles.card} ${styles.yellow}`}>
              <img className={styles.icon} src={multidiffIcon} alt={XSoftJob} />
              <p className={styles.baseline}>{t('titles.Automate your job offers using')}</p>
              <div className={`${styles.product} ${styles.bigProductTitle}`}>
                <p className={`${styles.big} ${styles.white}`}>{XSoftJob}</p>
              </div>
              <Link
                to={XPaUrl}
                onClick={() => {
                  document
                    .querySelector('.app-wrapper')
                    .scrollIntoView({ block: 'start', behavior: 'smooth' });
                }}
              >
                <img src={Warrow} alt="arrow" className={styles.arrow} />
              </Link>
            </div>
            <div className={`${styles.card} ${styles.white}`}>
              <img className={styles.icon} src={parsingIcon} alt={XSoftCV} />
              <p className={styles.baseline}>
                {t('titles.Automate your CV readings with')}
              </p>
              <div className={`${styles.product} ${styles.bigProductTitle}`}>
                <p className={`${styles.big} ${styles.yellow}`}>{XSoftCV}</p>
              </div>
              <Link
                to={XRaUrl}
                onClick={() => {
                  document
                    .querySelector('.app-wrapper')
                    .scrollIntoView({ block: 'start', behavior: 'smooth' });
                }}
              >
                <img src={Yarrow} alt="arrow" className={styles.arrow} />
              </Link>
            </div>
          </div>

          <div className={styles.cardsContainer}>
            <div className={`${styles.card} ${styles.yellow}`}>
              <img className={styles.icon} src={servicesIcon} alt={XServices} />
              <p className={styles.baseline}>{t('titles.Optimise your recruitment campaigns')}</p>
              <div className={`${styles.product}`} style={{ padding: '25px 0' }}>
                <p className={`${styles.big} ${styles.white}`}>{XServices}</p>
              </div>
              <Link
                to={XServicesUrl}
                onClick={() => {
                  document
                    .querySelector('.app-wrapper')
                    .scrollIntoView({ block: 'start', behavior: 'smooth' });
                }}
              >
                <img src={Warrow} alt="arrow" className={styles.arrow} />
              </Link>
            </div>
            <div className={`${styles.card} ${styles.white}`}>
              <img className={styles.icon} src={parsingIcon} alt={ImpactRH} />
              <p className={styles.baseline}>
                {t('titles.Pioneer in HR Data')}
              </p>
              <div className={`${styles.product} ${styles.bigProductTitle}`} style={{ padding: '35px 0' }}>
                <p className={`${styles.big} ${styles.yellow}`}>{ImpactRH}</p>
              </div>
              <Link
                to={ImpactRHUrl}
                onClick={() => {
                  document
                    .querySelector('.app-wrapper')
                    .scrollIntoView({ block: 'start', behavior: 'smooth' });
                }}
              >
                <img src={Yarrow} alt="arrow" className={styles.arrow} />
              </Link>
            </div>
          </div>

          <div className={styles.title}>{t('titles.Our semantic approach')}</div>
          <div className={styles.semanticalApproach}>
            <div className={`${styles.block} ${styles.left}`}>
              <p style={{ marginTop: '10px' }}>
                {t(
                  'text.The basic semantic repositories on which the Xtramile solutions are based aim to cover the widest possible spectrum of content'
                )}
              </p>
              <p style={{ marginTop: '10px' }}>
                {t(
                  'text.This is why they come from a combination of Rome repositories (French repository / Pôle Emploi), ESCO repositories (European repository) and proprietary Xtramile data'
                )}
              </p>
              <p style={{ marginTop: '10px' }}>
                {t(
                  'text.Together they help create Professional Audiences segments that allow us a perfect analysis of the audiences targeted by the job offers entrusted to us'
                )}
              </p>
            </div>
            <div className={`${styles.block} ${styles.right}`}>
              <p className={styles.firstSentence}>
                {t('text.Xtramile has precise data on more than')}
              </p>
              <p className={styles.dataSentence}>20 000 audiences</p>
              <p className={styles.localisationSentence}>{t('text.in France')}</p>
            </div>
          </div>
          <div className={styles.bottomText}>
            <p>{t('text.Established on the basis of these repositories')}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

TechnologicalApproach.propTypes = {
  t: PropTypes.func.isRequired,
};

export default TechnologicalApproach;
