import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import styles from './dataCvCollapse.module.scss';
import Icon from 'components/icon';

const Education = ({ t, data }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => setIsOpen(!isOpen);

  const findEmptySections = (data) =>
    data.map((item) => {
      return !(
        item.school_name ||
        item.level.plaintext ||
        item.specialisation.plaintext ||
        item.city.plaintext ||
        item.country.plaintext ||
        item.date_start.plaintext ||
        item.date_end.plaintext
      );
    });

  const emptySections = useMemo(() => findEmptySections(data), [data]);

  const areAllSectionsEmpty = emptySections.every((item) => !!item);

  return (
    !areAllSectionsEmpty && (
      <div className={styles.dataSection}>
        <div className={styles.dataBlock}>
          <div className={styles.dataTitleContainer} onClick={toggle}>
            <span className={styles.itemTitle}>{t('valuesOfCV.education')}</span>
            <span className={isOpen ? styles.activeArrow : styles.notActiveArrow}>
              <Icon name="arrow" width="10" height="5" />
            </span>
          </div>

          <div className={isOpen ? styles.activeBlock : styles.notActiveBlock}>
            {data.map(
              (item, dataIndex) =>
                !emptySections[dataIndex] && (
                  <div key={dataIndex} className={styles.partOfData}>
                    {item.school_name && (
                      <div className={styles.dataItem}>
                        <span className={styles.itemKey}>{t('valuesOfCV.school_name')}</span>
                        <span className={styles.itemValue}>{item.school_name}</span>
                      </div>
                    )}

                    {item.level.plaintext && (
                      <div className={styles.dataItem}>
                        <span className={styles.itemKey}>{t(`valuesOfCV.level`)}</span>
                        <span className={styles.itemValue}>{item.level.plaintext}</span>
                      </div>
                    )}

                    {item.specialisation.plaintext && (
                      <div className={styles.dataItem}>
                        <span className={styles.itemKey}>{t(`valuesOfCV.specialisation`)}</span>
                        <span className={styles.itemValue}>{item.specialisation.plaintext}</span>
                      </div>
                    )}

                    {item.city.plaintext && (
                      <div className={styles.dataItem}>
                        <span className={styles.itemKey}>{t(`valuesOfCV.city`)}</span>
                        <span className={styles.itemValue}>{item.city.plaintext}</span>
                      </div>
                    )}

                    {item.country.plaintext && (
                      <div className={styles.dataItem}>
                        <span className={styles.itemKey}>{t(`valuesOfCV.country`)}</span>
                        <span className={styles.itemValue}>{item.country.plaintext}</span>
                      </div>
                    )}

                    {item.date_start.plaintext && (
                      <div className={styles.dataItem}>
                        <span className={styles.itemKey}>{t(`valuesOfCV.date_start`)}</span>
                        <span className={styles.itemValue}>{item.date_start.plaintext}</span>
                      </div>
                    )}

                    {item.date_end.plaintext && (
                      <div className={styles.dataItem}>
                        <span className={styles.itemKey}>{t(`valuesOfCV.date_end`)}</span>
                        <span className={styles.itemValue}>{item.date_end.plaintext}</span>
                      </div>
                    )}
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    )
  );
};

Education.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
};

export default Education;
