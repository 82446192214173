import React from 'react';
import PropTypes from 'prop-types';

import styles from './specificApproachToParsing.module.scss';

const SpecificApproach = ({ t, infoIcons }) => (
  <section className={styles.sectionSpecificApproach}>
    <div className="container">
      <div className={styles.wrapper}>
        <h2 className={styles.title}>{t('titles.Our specific approach to parsing')}</h2>
        <p className={styles.underTitle}>
          {t('text.Our X-CV Ranking solution is based on two essential building blocks')}
        </p>
        <div className={styles.infoBlocks}>
          {infoIcons &&
            infoIcons.map((item, index) => {
              return (
                <div key={index} className={styles.infoBlock}>
                  <img src={item.src} alt={item.alt} className={styles.imageBlock} />
                  <p className={styles.titleBlock}>{t(`titles.${item.title}`)}</p>
                  <p className={styles.textBlock}>{t(`text.${item.text}`)}</p>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  </section>
);

SpecificApproach.propTypes = {
  t: PropTypes.func.isRequired,
  infoIcons: PropTypes.array.isRequired,
};

export default SpecificApproach;
