import React from 'react';
import PropTypes from 'prop-types';

import styles from './approachToBI.module.scss';
import icon1 from "../../../assets/icons/x-cv-ranking/cv.svg";
import icon2 from "../../../assets/icons/x-cv-ranking/tool.svg";

const ApproachToBI = ({ t }) => (
  <section className={styles.sectionSpecificApproach}>
    <div className="container">
      <div className={styles.wrapper}>
        <h2 className={styles.title}>{t('titles.impact-rh_Our innovative approach')}</h2>
        <p className={styles.underTitle}>
          {t('text.impact-rh_Our Data and Impact BI solution')}
        </p>

        <div className={styles.infoBlocks}>
          <div className={styles.infoBlock}>
            <img src={icon1} className={styles.imageBlock} alt="icon1" />
            <p className={styles.titleBlock}>{t(`titles.impact-rh_Impact BI & HR Micro-Economics`)}</p>
            <p className={styles.textBlock}>{t(`text.impact-rh_that provide data specific to your business`)}</p>
          </div>

          <div className={styles.infoBlock}>
            <img src={icon2} className={styles.imageBlock} alt="icon2" />
            <p className={styles.titleBlock}>{t(`titles.impact-rh_Impact BI & HR Macro-Economics`)}</p>
            <p className={styles.textBlock}>{t(`text.impact-rh_which provide data on a given geographical area`)}</p>
          </div>
        </div>

      </div>
    </div>
  </section>
);

ApproachToBI.propTypes = {
  t: PropTypes.func.isRequired,
  infoIcons: PropTypes.array.isRequired,
};

export default ApproachToBI;
