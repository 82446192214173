import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './input.module.scss';

const Input = ({
  value,
  error,
  errorMessage,
  fnClick,
  checked = false,
  label,
  type = 'text',
  name,
  onChange,
  t,
}) => {
  if (type && type === 'checkbox') {
    return (
      <div className={styles.checkboxContainer}>
        <div
          className={styles[checked ? 'checkboxInputChecked' : 'checkboxInput']}
          onClick={(e) => fnClick(e)}
        >
          <input
            type={type}
            id="confirmForm"
            name="confirmForm"
            onChange={() => {}}
            hidden
            checked={checked}
          />
        </div>
        <div className={styles.checkboxLabel}>
          <p className={styles.checkboxLabelFirst}>{t('text.By checking this box')}</p>
          <p className={styles.checkboxLabelSecond}>
            {t('text.To find out more about our policy regarding')}
          </p>
        </div>
      </div>
    );
  } else {
    const errorMsg = errorMessage || t('errors.Please complete this required field');

    return (
      <>
        <p className={styles.inputLabel}>{t(`labels.${label}`)}</p>
        <input
          type={type}
          value={value}
          className={cx(styles.inputField, error ? styles.error : '')}
          data-name={name}
          onChange={onChange}
          required
        />
        <span className={`${styles.errorMessage} ${error ? styles.active : ''}`}>{errorMsg}</span>
      </>
    );
  }
};

Input.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.bool,
  fnClick: PropTypes.func,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.string,
  errorMessage: PropTypes.string,
  t: PropTypes.func.isRequired,
};

export default Input;
