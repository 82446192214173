import React from 'react';
import PropTypes from 'prop-types';

import styles from './customButton.module.scss';

const CustomButton = ({ text, disabled, onClick }) => {
  return (
    <button disabled={disabled} className={styles.customButton} onClick={onClick}>
      {text}
    </button>
  );
};

CustomButton.propTypes = {
  btnView: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default CustomButton;
