import React from 'react';
import PropTypes from 'prop-types';

import styles from './headerDropdown.module.scss';
import { Link } from 'react-router-dom';
import productNames from 'data/productNames';

const { XSoftJob, XPaUrl, XSoftCV, XRaUrl, XServices, XServicesUrl, ImpactRH, ImpactRHUrl } = productNames;

const dropdownLink = [
  { id: 1, path: '/', text: 'Home' },
  { id: 2, path: `/${XPaUrl}`, text: XSoftJob },
  { id: 3, path: `/${XRaUrl}`, text: XSoftCV },
  { id: 4, path: `/${XServicesUrl}`, text: XServices },
  { id: 5, path: `/${ImpactRHUrl}`, text: ImpactRH },
  { id: 6, path: '/technology', text: 'Technology' },
  { id: 7, path: '/news', text: 'News' },
  { id: 8, path: '/clients', text: 'Our clients testify' },
  { id: 9, path: '/team', text: 'The team' },
];

const HeaderDropdown = ({ t, goToForm, closeDropdown }) => {
  return (
    <div className={styles.headerDropdown} id="headerDropdown">
      <ul className={styles.headerDropdownList}>
        {dropdownLink.map((item) => (
          <li className={styles.headerDropdownElem} key={item.id}>
            <Link to={item.path} className={styles.headerDropdownLink} onClick={closeDropdown}>
              {t(`pageTitles.${item.text}`)}
            </Link>
          </li>
        ))}
        <li className={styles.headerDropdownElem}>
          <a className={styles.headerDropdownLink} onClick={(e) => goToForm(e, 'contact')}>
            {t('text.Contact')}
          </a>
        </li>
      </ul>
    </div>
  );
};

HeaderDropdown.propTypes = {
  t: PropTypes.func.isRequired,
  goToForm: PropTypes.func.isRequired,
  closeDropdown: PropTypes.func.isRequired,
};

export default HeaderDropdown;
