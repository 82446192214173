import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Comment from 'components/x-ra/comment/comment';
import Slider1 from 'components/x-ra/slider1/slider1';
import Slider2 from 'components/x-ra/slider2/slider2';
import { instruments } from 'data/blocksInfo';
import SpecificApproach from 'components/x-ra/specificApproachToParsing/specificApproachToParsing';
import HowItWorks from 'components/x-ra/howItWorks/howItWorks';
import TagManager from 'react-gtm-module';
import { useMediaQuery } from 'react-responsive';

const XCvRanking = ({ t, title }) => {
  useEffect(() => {
    document.title = title || '';
  }, [title]);

  const shouldGoCollapse = useMediaQuery({
    query: '(max-width: 900px)',
  });

  TagManager.dataLayer({
    dataLayer: {
      event: 'pageview',
      pagePath: '/x-cv-ranking',
      pageTitle: 'Xtramile Software (CV!)',
    },
  });

  return (
    <>
      <Slider1 t={t} />
      <Slider2 t={t} className="x-ranking-slider" />
      <SpecificApproach t={t} infoIcons={instruments} />
      <Comment t={t} />
      <HowItWorks shouldGoCollapse={shouldGoCollapse} t={t} />
    </>
  );
};

XCvRanking.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default XCvRanking;
