import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Slider1 from '../../components/x-serv/slider1/slider1';
import Slider2 from '../../components/x-serv/slider2/slider2';
import ApproachToProgrammatic from '../../components/x-serv/approachToProgrammatic/approachToProgrammatic';
import HowItWorks from '../../components/x-serv/howItWorks/howItWorks';
import TagManager from 'react-gtm-module';
import { useMediaQuery } from 'react-responsive';

const XtramileServices = ({ t, title }) => {
  useEffect(() => {
    document.title = title || '';
  }, [title]);

  const shouldGoCollapse = useMediaQuery({
    query: '(max-width: 900px)',
  });

  TagManager.dataLayer({
    dataLayer: {
      event: 'pageview',
      pagePath: '/x-services',
      pageTitle: 'Xtramile Services',
    },
  });

  return (
    <>
      <Slider1 t={t} />
      <Slider2 t={t} />
      <ApproachToProgrammatic t={t} />
      <HowItWorks shouldGoCollapse={shouldGoCollapse} t={t} />
    </>
  );
};

XtramileServices.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default XtramileServices;
