import React from 'react';
import styles from '../../pages/article/article.module.scss';

const BpiDeepTechContent = () => {
  return (
    <div>
      <p>
      Que eût cru que la finance, la publicité et les ressources humaines aient un jour plus d’une chose en commun ? Au-delà d’être trois entités, a priori assez séparées, de l’économie nationale...
      </p>
      <p>
      En l’occurrence, qu’elles aient en effet en partage en plus les techniques le plus récentes et innovantes de fonctionnement. Toutes faisant appel à divers degrés aux avancées dernier cri des statistiques, de l’intelligence artificielle et du traitement des langues (« NLP », Natural Language Processing pour les initiés) ; voire à quelques autres domaines « deeptech » où la France s’est illustrée depuis le tournant de l’IA cette dernière décennie 2010.
      </p>
      <p>
      Finance, Publicité & RH ; bien devin qui eût été capable de faire un tel tableau de ces disciplines il y a quelques années encore, lorsque la finance et les coups de bourse s’effectuaient par téléphone entre « brokers », fût-ce sur des fuseaux horaires différents. Lorsque la publicité s’effectuait à coups de balais-brosses derrière les grandes vitrines d’abribus pour une affiche de cinéma ou de voyage au Maroc. Lorsqu’enfin dans le domaine des « RH », on passait des offres d’emploi sous le format de petites annonces ou de salon « RH » de rencontres s’échangeant CVs et cartes de visite.
      </p>
      <p>
      On voit d’ailleurs l’ensemble de ces pratiques affleurer dans les films « vintage » qu’on regarde avec nostalgie, tissant une trame disons des années Mitterrand aux années Macron, où le dernier bastion est tombé.
      </p>
      <p>
      Car les trois domaines ont effectué chacun une révolution assez étonnante depuis une trentaine d’années ; chacun selon leur ADN propre mais avec un même sillon finalement : celui de l’optimisation, de la désintermédiation et, disons-le, de la transparence. Le tout sous un même couvre-chef technologique que rien ne permettait d’anticiper au départ.
      </p>
      <p className={styles.separator}>* * *</p>
      <p>
      Reprenons, et primauté historique oblige, commençons par la finance. Chacun se souviendra sûrement des chemises à bretelles de Gordon Gekko, le trader star du film Wall Street d’Oliver Stone en 1987, moins peut-être qu’il passait tous ses ordres d’achat ou de ventes de titres d’actions dernier cri par le biais d’un téléphone gros comme le poing. Depuis, une révolution algorithmique est passée par là, transformant les « trading floors » en larges espaces remplis d’écran et reflétant à la nanoseconde prés les dernières évolutions boursières ; agissant aussi sur elles en quelques clics. Période 1980-2000.
      </p>
      <p>
      Le chose deviendra plus stimulante encore lorsqu’on se rendra compte que la même évolution a bien été constatée dans le milieu publicitaire la génération suivante, 2000-2020. Avec le passage progressif là aussi des balais-brosses, colles et pinceaux aux ordinateurs et autres algorithmes optimisés, remplaçant le placardage d’affiches par de l’« achat d’espace » automatique, de l’abri bus aux espaces blancs de Marie-Claire ou d’Hôtels de France. Le tout coordonné par des outils dits de « DMP » (Demand Side Platform) en jargon, presqu’aussi impressionnants que les formules de « Black&Scholes » en finance ou les outils « Bloomberg ». Non du délit mais du trading d’initié, version publicitaire cette fois. En France, un acteur comme Criteo est aujourd’hui un fleuron de ce versant publicitaire, né en 2004.
      </p>
      <p>
      Restait alors un dernier avatar encore épargné par ce mécanisme technologique d’optimisation, à savoir : celui des ressources humaines. Roc pourtant inaccessible disait-on. Et ce pour des raisons diverses, allant du texte immaîtrisable comme le CV (peut-être le document administratif le plus difficile à lire car totalement hétérogène (en effet la « rue Jeanne d’Arc » par exemple est bien un élément à comprendre comme étant une rue et une adresse, mais non un nom et prénom de candidat et encore moins de sainte nationale ; facile à dire pour un lecteur humain moins pour une machine…)), allant du CV donc ou de l’offre d’emploi au côté inaccessible des choix humains. Blocs sémantique dans les deux cas, quasi-impossible à traduire en nombre, passage obligé par où doit passer toute velléité d’optimisation.
      </p>
      <p>
      Croyait-on. Car là aussi, et de façon récente, le vent de l’innovation semble n’avoir épargné aucun élément. Et pas plus aujourd’hui le texte rédigé de la demande d’emploi ou le CV que naguère la description de l’action cotée ou les lunettes dernière mode.
      </p>
      <p>
      (Remarque en passant, nous noterons ceci : qu’on y voit là un progrès ou un problème de société globale est un autre problème qui nous dépasse et que nous n’aborderons pas ici, se contentant de l’analyse factuelle des faits et des évolutions techniques en cours elles, bien constatables.)
      </p>
      <p>
      Dès lors, apparu depuis quelques années, manifeste depuis la fin des années 2010, et plus encore après le « Grand Tournant Digital » qu’aura été la grande pandémie de 2020, (là aussi positive ou négative, c’est un autre débat) s’est installée dernièrement dans le paysage des RH de façon nette la notion de « recrutement à la performance » aussi innovante dans ce domaine que le passage de l’achat d’espace des groupes médias dans un magazine féminin, naguère au téléphone d’une voix ronde, à présent directement par ordinateur et par « API calls ».
      </p>
      <p>
      A présent, pour le RH, il s’agit en l’occurrence, en quelques clics à peine, de permettre :
      </p>
      <ul>
        <li>
          <p>
          l’absorption automatique d’offres d’emploi depuis un site carrière vers un nouvel outil appelé « plateforme programmatique ou de trading RH », (quelques secondes)
          </p>
        </li>
        <li>
          <p>
          d’organiser l’ensemble de ces offres en groupes bien ordonnés (nommés souvent « Clusters ») et de permettre l’envoi de l’ensemble de ces offres sous tous formats, dont publicitaires. Prêts à partir sur les canaux digitaux où ils rencontreront des internautes, candidats actifs ou passifs le plus souvent (sur Facebook, Tiktok, Le Bon Coin, Cadres Emploi ; il y en a des dizaines pour ne pas dire plus), (quelques minutes)
          </p>
        </li>
        <li>
          <p>
          de « trader » effectivement, c’est-à-dire de publier ces offres sur les canaux les plus pertinents, au bon moment et permettant les meilleurs « chemins de conversion » vers les pages de candidatures ; publiant aussi au meilleur prix (on parle de « bidding » et d’achat d’espace un peu comme les écrans de Marie-Claire), (quelques heures)
            heures)
          </p>
        </li>
        <li>
          <p>
          de laisser les internautes, candidats à la recherche active d’emploi ou non, cliquer à loisir et s’ils le souhaitent, atterrir sur une plage plus complète présentant l’ensemble des offres détaillées et enfin libres au final de candidater, d’envoyer peut-être ces mêmes offres à un tiers ou finalement de ne rien du tout de ce clic d’intérêt à une annonce. (quelques jours)
          </p>
        </li>
      </ul>
      <p>
      L’ensemble faisant donc un cycle bien maîtrisé, tenant, pour la durée, mettons en quelques cadrans horaires. Mais où, on l’aura remarqué, le recruteur aura été débarrassé des tâches pénibles comme naguère les lavandières les tâches de leurs lavoirs pour ne s’appesantir que sur la part restante utile et à valeur ajoutée réelle : la couture de l’embauche.
      </p>
      <p className={styles.separator}>* * *</p>
      <p>
      Cherry on the cake, dit-on en anglais et addition qui n’est anodine - on parle quand même de sciences & données économiques - on sait à l’avance ce que cela va coûter ! Un peu comme un taxi Uber qui vous indique depuis le smartphone et avant la course le prix qui sera bel et bien débité. Le prix de l’ensemble ? Prédicatif justement ; saviez-vous ainsi que la probabilité de pouvoir recruter en 2 mois un consultant IT pour des missions free-lance en France, région parisienne, est de 95% pour un coût d’acquisition de candidature de 38€ ? Déclinable à l’ensemble des professions et catégories professionnelles en France. Nous contacter en MP pour plus d’informations.
      </p>
      <p>
        Voilà en quelques mots ce que permet la « Programmatique RH », véritable incise
        technologique et micro-économique dans l’univers jusque-là un peu labyrinthique des
        ressources humaines.
      </p>
      <p>
      Et pour notre trio, finance, marketing et RH, cousins économiques après des tests d’ADN d’un nouveau genre, c’est-à-dire tracés par l’I.A et les domaines deep Tech, adieu Gordon Gekko, adieu Marie-Claire, et à présent les petites annonces : tous remplacés par un même tatouage technologique sur le dos et bardé de données ; comme une intendance à notre service.
      </p>
      <p>
      Tatouage permettant, pour en rester à notre domaine RH, de répondre assez vite et assez justement, à des questions impérieuses comme :
      </p>
      <ul>
        <li>
          <p>
          à quel coût puis-je embaucher telle ou telle compétence précise à Strasbourg ou à Bordeaux (voire à Hambourg ou Séville, la géographie est extensive) ?
          </p>
        </li>
        <li>
          <p>
          combien de candidats en moyenne répondant par exemple à une annonce d’électricien du bâtiment sont-ils vraiment qualifiés ?
          </p>
        </li>
      </ul>
      <p>Et bien d’autres encore.</p>
      <p>
      Questions & réponses plus qu’utiles pour quiconque a touché au domaine large du marché de l’emploi, et réponses dont, jusqu’à une date récente, le manque se faisait cruellement sentir.
      </p>
      <p className={styles.separator}>* * *</p>
      <p>
      Sans crier gare, au-delà de la technologie c’est ici une sorte d’entrée fracassante de la micro-économie dans l’univers des RH ou du Sourcing qui a eu lieu. Jusqu’ici difficilement analysable ou alors uniquement de façon « macro » par des statistiques de l’INSEE et autres données agrégées.
      </p>
      <p>
      A présent, de façon granulaire et détaillées, les systèmes d’offres d’emploi peuvent aider non seulement directement les entreprises à améliorer leur recrutement - c’est bien le driver initial - mais aussi, par effet boule, les politiques publiques. Permettant par exemple de trouver les pans lisses du marché ou, à l’inverse, les creux, les points où cela bloque et permettant d’aller investiguer plus avant pourquoi. Ce n’est pas rien…
      </p>
      <p>
      D’ailleurs certains académiciens ne s’y sont pas trompés, récompensant récemment, en Décembre 2020, deux économistes de l’Université Stanford par le Prix Nobel d’Economie, Paul Milgrom et Robert Wilson, dont les travaux sur les mécanismes d’enchères s’adaptent peu ou prou aux secteurs indiqués ci-dessus parmi d’autres.
      </p>
      <p>
      Sans tourner PhD Candidate se réjouissant que ces avancées donnent lieu à des palmes académiques et restant simplement factuels : lorsqu’on sait qu’il y a 3 millions d’offres d’emploi publiées chaque année en France, 300,000 emplois non pourvus, un taux de chômage résilient à plus de 7% de la population active et que le CV reste le document le plus imprimé et lu au monde (!), on mesure le chemin parcouru.
      </p>
      <p>
      On voit ici le résultat de l’heureux concours croisé des sciences entre elles (techniques & économiques), des capitaines d’industrie agiles (d’ailleurs le même Paul Milgrom cité ci-dessus, Prix Nobel, 72 ans, a fondé lui-même sa start-up bien nommée « Auctionomics…) ; du concours enfin des programmes comme ceux de la BPI Deep Tech qui ont permis en France, sur une tradition scientifique déjà bien éprouvée, d’ajouter une tradition entrepreneuriale qui ne demandait qu’à renaître.
      </p>
      <p>
      A sa place plus modeste et ciblée qu’Actionnomics mais méritante, la jeune pousse française Xtramile, spécialiste donc de « Programmatique RH », peut en témoigner. Puisse l’avenir lui donner raison et se retournant d’ici quelques temps sur l’évolution des ressources humaines lui permettre de dire : voici comment tout a commencé… Rendez-vous dans 20 ans.
      </p>

      <p className={styles.author}>Xavier Ragage, CEO Xtramile</p>
      <p className={styles.date}>Mai 2021</p>
    </div>
  );
};

export default BpiDeepTechContent;
