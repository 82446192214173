import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-elastic-carousel';
import cx from 'classnames';

import styles from './partners.module.scss';

const Partners = ({ t, infoIcons }) => {
  const carousel = useRef(null);
  const delay = 2000;

  const setCarouselToStart = (currentItem) => {
    if (currentItem.index === 17) {
      setTimeout(() => {
        if (!carousel) return;

        carousel?.current?.goTo(0);
      }, delay);
    }
  };

  const breakPoints = [
    { width: 470, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 850, itemsToShow: 3 },
    { width: 1150, itemsToShow: 5 },
    { width: 1450, itemsToShow: 5 },
    { width: 1750, itemsToShow: 5 },
  ];

  return (
    <section className={styles.sectionPartners} id="partners">
      <div className="container">
        <div className={styles.wrapper}>
          <h2 className={styles.title}>{t('titles.Partners')}</h2>
          <div className={cx(styles.logos, 'sideButtons')}>
            <Carousel
              enableSwipe={false}
              enableMouseSwipe={false}
              preventDefaultTouchmoveEvent={true}
              ref={carousel}
              itemsToShow={5}
              showArrows={true}
              enableAutoPlay
              autoPlaySpeed={delay}
              transitionMs={delay}
              breakPoints={breakPoints}
              onChange={(currentItem) => {
                setCarouselToStart(currentItem);
              }}
            >
              {infoIcons.map((logo, index) => {
                return (
                  <li key={`carousel-${index}`} className={styles.logo}>
                    <a href="#">
                      <img src={logo.src} alt={logo.alt} />
                    </a>
                  </li>
                );
              })}
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
};

Partners.propTypes = {
  t: PropTypes.func.isRequired,
  infoIcons: PropTypes.array.isRequired,
};

export default Partners;
