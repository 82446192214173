import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from './customCheckbox.module.scss';

const CustomCheckbox = ({ t, hasDataAgreement, toggleDataAgreement }) => {
  return (
    <label className={styles.container}>
      <input type="checkbox" checked={hasDataAgreement} onChange={toggleDataAgreement} />
      <span className={styles.checkmark} />
      {t('text.By posting my CV, I accept that Xtramile collects')}
      &nbsp;
      <Link
        to="/privacy-statement"
        className={styles.orangeLink}
        onClick={() => {
          document
            .querySelector('.app-wrapper')
            .scrollIntoView({ block: 'start', behavior: 'smooth' });
        }}
      >
        {t('text.my personal data')}
      </Link>
      &nbsp;
      {t('text.and I agree to be contacted for job ads')}
    </label>
  );
};

CustomCheckbox.propTypes = {
  t: PropTypes.func.isRequired,
  hasDataAgreement: PropTypes.bool.isRequired,
  toggleDataAgreement: PropTypes.func.isRequired,
};

export default CustomCheckbox;
