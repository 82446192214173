import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Carousel from 'react-elastic-carousel';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import styles from './team.module.scss';
import InfoBlocks from 'components/infoBlocks/infoBlocks';
import Button from 'components/button/button';
import quotesYellow from 'assets/icons/quotes-yellow.svg';

const Team = ({ t, infoIcons, infoCarousel }) => {
  const itemsToShow = 1;

  useEffect(() => {
    return () => document.getElementById('header').scrollIntoView();
  });

  return (
    <>
      <section className={styles.wrapper}>
        <div className="container">
          <div className={styles.clientsTestify}>
            <div className={styles.clientsTestifyTitleWrapper}>
              <h3 className={styles.clientsTestifyTitle}>{t('titles.Our clients testify')}</h3>
              <div className={styles.clientsTestifyButton}>
                <Link to="/clients">
                  <Button btnView="button-warn-sm" text={t('buttons.DISCOVER CASE STUDIES')} />
                </Link>
              </div>
            </div>

            <div className={cx('downButtons', styles.carouselWrapper)}>
              <Carousel
                itemsToShow={itemsToShow}
                showArrows={itemsToShow < infoCarousel.length}
                enableSwipe={false}
                enableMouseSwipe={false}
                preventDefaultTouchmoveEvent={true}
              >
                {infoCarousel &&
                  infoCarousel.map((item) => (
                    <div key={item.id} className={styles.post}>
                      <div>
                        <div className={styles.postLeftPartAva}>
                          <img src={item.ava} alt="news Img" />
                        </div>
                        <div className={styles.postLeftPartLogo}>
                          <img src={item.logo} alt="news Img" />
                        </div>
                      </div>

                      <div className={styles.postRightPart}>
                        <div className={styles.postsText}>
                          <p>
                            <img
                              src={quotesYellow}
                              alt="quotes"
                              style={{ width: '50px', height: '42px', marginRight: '5px' }}
                            />
                            {t(`quote.${item.quote}`)}”
                          </p>
                        </div>

                        <div className={styles.postAuthorNameContainer}>
                          <div>{item.author}</div>
                          <div>{t(`positions.${item.position}`)}</div>
                        </div>
                      </div>
                    </div>
                  ))}
              </Carousel>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.sectionTeam}>
        <div className="container">
          <div className={styles.wrapper}>
            <div className={styles.topSide}>
              <h2 className={styles.title}>{t('titles.The team')}</h2>
              <p className={styles.description}>
                {t('miniheader.Xtramile is multicultural team')}
              </p>
              <div className={styles.teamImage} />
            </div>
            <div className={styles.bottomSide}>
              <h2 className={styles.directExpertise}>{t('titles.4 AREAS OF EXPERTISE')}</h2>
              <div className={styles.directions}>
                <InfoBlocks t={t} info={infoIcons} />
              </div>
              <Link to="/team">
                <Button btnView="button-blue-md" text={t('buttons.DISCOVER OUR TEAMS')} />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

Team.propTypes = {
  t: PropTypes.func.isRequired,
  infoIcons: PropTypes.array.isRequired,
  infoCarousel: PropTypes.array.isRequired,
};

export default withTranslation()(Team);
