import React from 'react';
import PropTypes from 'prop-types';

import styles from './comment.module.scss';
// import { Link } from 'react-router-dom';

const Comment = ({ t }) => (
  <section className={styles.sectionComment}>
    <div className="container">
      <div className={styles.wrapper}>
        <h2 className={styles.title}>{t('titles.How it works')}</h2>
        <p className={`${styles.paragraph} ${styles.firstParagraph}`}>
          {t(
            'text.Our semantic approach is based on a linguistic and technological combination that promotes'
          )}
        </p>
        <p className={styles.paragraph}>
          {t(
            'text.Specially designed for the world of human resources in collaboration with INRIA'
          )}
        </p>
        <p className={styles.paragraph}>
          {t('text.As a result, the recruiter almost immediately has a reliable result')}
        </p>
        <div className={styles.blockImage}></div>
        {/* <div className={styles.buttonContainer}>
          //hidden while parsing isn't stable
          <Link to="/team/dragndrop" className="button button-blue-md small">
            {t('buttons.Try on your own CV now')}
          </Link>
        </div> */}
      </div>
    </div>
  </section>
);

Comment.propTypes = {
  t: PropTypes.func.isRequired,
};

export default Comment;
