import React from 'react';
import PropTypes from 'prop-types';

import Carousel from 'react-elastic-carousel';
import cx from 'classnames';
import quotesYellow from 'assets/icons/quotes-yellow.svg';

import styles from './trustingClients.module.scss';

const TrustingClients = ({ t, infoCarousel }) => {
  const itemsToShow = 1;
  return (
    <section className={styles.wrapper}>
      <div className={styles.clientsTestify}>
        <div className={styles.clientsTestifyTitleWrapper}>
          <h3 className={styles.clientsTestifyTitle}>{t('titles.They trust us')}</h3>
        </div>
      </div>

      <div className={cx('downButtonsTechnology', styles.carouselWrapper)}>
        <Carousel
          itemsToShow={itemsToShow}
          showArrows={itemsToShow < infoCarousel.length}
          enableSwipe={false}
          enableMouseSwipe={false}
          preventDefaultTouchmoveEvent={true}
        >
          {infoCarousel &&
            infoCarousel.map((item) => (
              <div key={item.id} className={styles.post}>
                <div>
                  <div className={styles.postLeftPartAva}>
                    <img src={item.ava} alt="news Img" />
                  </div>
                  <div className={styles.postLeftPartLogo}>
                    <img src={item.logo} alt="news Img" />
                  </div>
                </div>

                <div className={styles.postRightPart}>
                  <div className={styles.postsText}>
                    <p>
                      <img
                        src={quotesYellow}
                        alt="quotes"
                        style={{ width: '50px', height: '42px', marginRight: '5px' }}
                      />
                      {t(`quote.${item.quote}`)}”
                    </p>
                  </div>

                  <div className={styles.postAuthorNameContainer}>
                    <div>{item.author}</div>
                    <div>{t(`positions.${item.position}`)}</div>
                  </div>
                </div>
              </div>
            ))}
        </Carousel>
      </div>
    </section>
  );
};

TrustingClients.propTypes = {
  t: PropTypes.func.isRequired,
  infoCarousel: PropTypes.array.isRequired,
};

export default TrustingClients;
