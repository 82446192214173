import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TagManager from 'react-gtm-module';
import styles from './termsAndConditionsPage.module.scss';

const TermsAndConditionsPage = ({ t, title }) => {
  useEffect(() => {
    document.title = title || '';
  }, [title]);

  TagManager.dataLayer({
    dataLayer: {
      event: 'pageview',
      pagePath: '/terms-and-conditions',
      pageTitle: 'Terms and Conditions',
    },
  });

  const lang = t('lang');

  return (
    <div className={styles.termsAndConditionPage}>
      <div className="container">
        <div className={styles.wrapper}>
          <h4 className={styles.title}>
            {t('titles.Terms and conditions of the Xtramile Platform Client')}
          </h4>
          <h5 className={styles.subtitle}>XTRAMILE</h5>
          <h5 className={styles.subtitle}>
            {lang === 'fr'
              ? 'RCS de Metz sous le n° 808 982 037'
              : 'RCS of Metz under no. 808 982 037'}
          </h5>
          <h5 className={styles.subtitle}>11 Rempart Saint Thiébault, 57000 Metz (France)</h5>
          <h5 className={`${styles.subtitle} ${styles.subtitleBlock}`}>contact@myxtramile.com</h5>

          {lang === 'fr' ? (
            <div className={styles.text}>
              <p className={styles.titleBlock}>
                <strong>1. Objet</strong>
              </p>
              <div>
                La société Xtramile (ci-après « XTRAMILE ») propose une plateforme de recrutement à
                l&apos;adresse www.xtramile.io (ci-après la « Plateforme ») permettant :
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>
                        aux employeurs (ci-après le/les « Client(s) »), d&apos;automatiser des
                        processus recrutement via les solutions suivantes détaillées ci-après :
                        X-Parsing, X-Ranking, X-Programmatics, X-Metrics, Publication des offres
                        d&apos;emploi.
                      </span>
                    </p>
                  </li>
                </ul>
                <p>
                  Les présentes conditions générales d&apos;utilisation s&apos;appliquent sans
                  restriction ni réserve à toute commande de services (ci-après les « Services »)
                  par le Client auprès de XTRAMILE, et ont pour objet de définir leurs modalités et
                  conditions de réalisation.
                </p>
                <p>
                  Les présentes conditions générales d&apos;utilisation et son annexe constituent,
                  avec la Proposition Commerciale, un ensemble contractuel indissociable (ci-après
                  le « Contrat »). En cas de contradiction, les dispositions de la (des)
                  Proposition(s) Commerciale(s) prévalent sur les présentes conditions générales
                  d&apos;utilisation. En cas de contradiction entre différentes Propositions
                  Commerciales, le document le plus récent prévaut sur les plus anciens.
                </p>
                <p>
                  Le Contrat prévaut sur toutes autres conditions générales ou particulières non
                  expressément agréées par XTRAMILE.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>2. Plateforme et Services réservées aux professionnels</strong>
                </p>
                <p>
                  La Plateforme et les Services sont conçus pour et destinés à un usage
                  professionnel et s&apos;adressent ainsi exclusivement aux professionnels dans le
                  cadre de leur activité.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>
                    3. Souscription aux Services et acceptation des conditions générales
                    d&apos;utilisation
                  </strong>
                </p>
                <p>
                  <strong>3.1 Souscription aux services</strong>
                </p>
                <p>
                  Les Services sont fournis sous la forme d&apos;abonnement (ci-après «
                  <strong>l&apos;Abonnement</strong> »), selon l&apos;une des modalités suivantes :
                </p>
                <p className={styles.subtitleBlock}>3.1.1 Formule site internet</p>
                <p>Le Client souscrit un Abonnement sur le site internet www.xtramile.io.</p>
                <p className={styles.subtitleBlock}>3.1.2 Formule API</p>
                <p>
                  XTRAMILE peut mettre à la disposition du Client une API (ci-après « l&apos;API »)
                  que le Client devra intégrer sur son système informatique. A cette fin, XTRAMILE
                  communique au Client, par tout moyen écrit utile et notamment par email, un lien
                  d&apos;accès à l&apos;API.
                </p>
                <p>
                  Une documentation d&apos;intégration de l&apos;API est fournie par XTRAMILE.
                  XTRAMILE peut également apporter au Client un support technique, dans les
                  conditions prévues à l&apos;article « Support technique ».
                </p>
                <p>
                  En cas de souscription à la formule API, le Client reconnaît et accepte que
                  l&apos;utilisation de la Plateforme nécessite des connaissances techniques
                  approfondies et des compétences en développement et en intégration logicielle afin
                  de faire fonctionner l&apos;API avec les systèmes du Client.
                </p>
                <p>
                  <strong>3.2 Proposition commerciale</strong>
                </p>
                <p>3.2.1</p>
                <p>
                  Préalablement à toute réalisation de Services, il appartient au Client de fournir
                  à XTRAMILE les informations et documents utiles afin de lui permettre
                  d&apos;identifier ses besoins et attentes. XTRAMILE établira sur cette base une ou
                  plusieurs proposition commerciale (ci-après une «
                  <strong>Proposition Commerciale</strong> »). La Proposition Commerciale indiquera
                  notamment le contenu des Services, leur prix, ainsi que sa durée de validité.
                </p>
                <p>3.2.2</p>
                <p>
                  Le Client qui souhaite commander les Services doit valider la Proposition
                  Commerciale correspondante dans le délai prévu, par tout moyen écrit utile et
                  notamment par email. A défaut, la Proposition Commerciale sera caduque.
                </p>
                <p>3.2.3</p>
                <p>
                  Toute validation d&apos;une Proposition Commerciale, expresse ou implicite,
                  emporte acceptation pleine et entière des présentes conditions générales
                  d&apos;utilisation, dans leur version en vigueur à la date de la Proposition
                  Commerciale concernée. Toute acceptation sous réserve est considérée comme nulle
                  et non avenue. Le Client qui n&apos;accepte pas d&apos;être lié par les présentes
                  conditions générales d&apos;utilisation ne doit pas commander de Services auprès
                  de XTRAMILE.
                </p>
                <p>3.2.4</p>
                <p>
                  En cas de modification des Services initiaux ou de nouvelle commande, une nouvelle
                  Proposition Commerciale est établie. Les dispositions de cette nouvelle
                  Proposition Commerciale prévalent sur celles de l&apos;ancienne. A défaut
                  d&apos;indication dans la nouvelle Proposition Commerciale, les périodes
                  d&apos;engagement restent identiques à la première Proposition Commerciale.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>4. Conditions d&apos;accès et d&apos;utilisation de la Plateforme</strong>
                </p>
                <p>
                  <strong>4.1 Capacité juridique</strong>
                </p>
                <p>La Plateforme et les Services sont accessibles :</p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>
                        à toute personne physique disposant de la pleine capacité juridique pour
                        s&apos;engager au titre des présentes conditions générales. La personne
                        physique qui ne dispose pas de la pleine capacité juridique ne peut accéder
                        à la Plateforme et aux Services qu&apos;avec l&apos;accord de son
                        représentant légal ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        à toute personne morale agissant par l&apos;intermédiaire d&apos;une
                        personne physique disposant de la capacité juridique pour contracter au nom
                        et pour le compte de la personne morale.
                      </span>
                    </p>
                  </li>
                </ul>
                <p>
                  <strong>4.2 Moyens d&apos;accès à la Plateforme</strong>
                </p>
                <p>
                  L&apos;accès à la Plateforme nécessite la possession de matériel informatique, de
                  logiciels et d&apos;une connexion à Internet adaptés, performants et sécurisés.
                </p>
                <p>
                  L&apos;accès à la Plateforme et son utilisation optimale requièrent la
                  configuration technique minimale suivante :
                </p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>
                        la Plateforme est optimisée pour une résolution d&apos;écran de 1024 x 768
                        pixels,
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        pour les navigateurs Internet Explorer 9 et supérieur, Chrome 32 et
                        supérieur, Firefox 26 et supérieur et Safari 5 et supérieur,
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        un moyen de connexion à Internet (permettant l&apos;accès à la Plateforme).
                      </span>
                    </p>
                  </li>
                </ul>
                <p>
                  En conséquence, les Clients sont personnellement responsables de leur choix
                  d&apos;équipement et de connexion Internet et XTRAMILE ne pourra en aucun cas être
                  tenue responsable des conséquences résultant de l&apos;installation et de
                  l&apos;utilisation du matériel informatique ou du navigateur Internet du Client.
                </p>
                <p>
                  <strong>4.3 Création du compte</strong>
                </p>
                <p>
                  Lors de la souscription à un Abonnement, le Client ouvre un compte sur la
                  Plateforme (ci-après le « Compte ») en remplissant le formulaire prévu à cet effet
                  sur la Plateforme. A cette fin, le Client doit fournir l&apos;ensemble des
                  informations marquées comme obligatoires. Toute inscription incomplète ne pourra
                  donner lieu à l&apos;ouverture du Compte.
                </p>
                <p>
                  le Client recevra une confirmation de son inscription par XTRAMILE. Toute
                  inscription incomplète ne sera pas validée. Une fois l&apos;inscription validée
                  par XTRAMILE, le Client aura accès à son Compte. Il pourra ainsi accéder à tout
                  moment à son Compte après s&apos;être identifié à l&apos;aide de son identifiant
                  de connexion ainsi que de son mot de passe.
                </p>
                <p>
                  L&apos;ouverture du Compte permet au Client de gérer son utilisation des Services,
                  sous une forme et selon les moyens techniques que XTRAMILE juge les plus
                  appropriés.
                </p>
                <p>
                  Le Client garantit que toutes les toutes les informations qu&apos;il donne dans le
                  formulaire sont exactes, à jour et sincères et ne sont entachées d&apos;aucun
                  caractère trompeur. Il s&apos;engage à mettre à jour ces informations en cas de
                  modifications, afin qu&apos;elles correspondent toujours aux critères susvisés.
                </p>
                <p>
                  Le Client s&apos;engage à ne créer qu&apos;un seul Compte pour un profil et à
                  utiliser personnellement les Services, et à ne permettre à aucun tiers de les
                  utiliser à sa place ou pour son compte.
                </p>
                <p>
                  Le Client est informé et accepte que les informations saisies aux fins de création
                  ou de mise à jour de son Compte valent preuve de son identité. Les informations
                  saisies par le Client l&apos;engagent dès leur validation.
                </p>
                <p>
                  Le Compte est géré par un ou plusieurs administrateur(s) (ci-après le/les «
                  <strong>Administrateur(s)</strong> »). L&apos;Administrateur peut créer un ou
                  plusieurs accès au Compte à d&apos;autres utilisateurs, qui bénéficieront de
                  fonctionnalités limitées. Le nombre d&apos;accès qui peuvent être créer par le
                  Client sont précisés dans la Proposition Commerciale.
                </p>
                <p>
                  L&apos;Administrateur est seul responsable de la création de ces accès et des
                  personnes auxquelles il les attribue. Il s&apos;interdit d&apos;utiliser la
                  faculté de création d&apos;un accès afin de donner accès à la Plateforme,
                  directement ou indirectement, à un tiers concurrent de XTRAMILE.
                </p>
                <p>
                  Le Client ne doit pas, sans que cette liste soit exhaustive, communiquer,
                  diffuser, partager, rendre accessible de quelque façon que ce soit son mot de
                  passe et/ou son identifiant à des tiers. Il est responsable du maintien de la
                  confidentialité et de la sécurité de son identifiant et de son mot de passe, tout
                  accès à la Plateforme à l&apos;aide de ces derniers étant réputée effectuée par le
                  Client. Celui-ci doit immédiatement contacter XTRAMILE aux coordonnées mentionnées
                  à l&apos;article « Coordonnées » des présentes s&apos;il remarque que son Compte
                  Client a été utilisé à son insu. Il reconnaît à XTRAMILE le droit de prendre
                  toutes mesures appropriées en pareil cas.
                </p>
                <p>
                  À tout moment, le Client peut demander la suppression de son Compte. Le cas
                  échéant, le Client n&apos;aura plus la possibilité d&apos;utiliser les Services.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>5. Licence sur la Plateforme</strong>
                </p>

                <p>
                  <strong>5.1 Étendue de la Licence</strong>
                </p>

                <p>
                  XTRAMILE concède au Client, moyennant le paiement du prix défini à l&apos;article
                  « Conditions financières », une Licence non exclusive, personnelle et non
                  transmissible d&apos;utilisation de la Plateforme, dans sa version existante à la
                  date des présentes et dans toutes éventuelles versions à venir, aux seules fins de
                  fourniture des Services.
                </p>
                <p>
                  Le Client peut donner accès à la Plateforme au nombre d&apos;utilisateurs indiqué
                  dans la Proposition Commerciale (ci-après les «<strong> Utilisateurs</strong> »).
                </p>
                <p>
                  La Licence est concédée au Client pour ses besoins propres, entendus comme les
                  besoins afférents à son activité et pour les seuls Utilisateurs. Il est interdit
                  au Client d&apos;en céder ou d&apos;en transférer le bénéfice à un tiers, quel
                  qu&apos;il soit.
                </p>
                <p>Le Client s&apos;interdit formellement de :</p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>
                        reproduire, arranger, adapter, de façon permanente ou provisoire l&apos;un
                        des éléments de la Plateforme en tout ou en partie, par tous moyens et sous
                        toutes formes ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        procéder à toute forme d&apos;exploitation commerciale de la Plateforme
                        auprès de tiers ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        vendre, céder, fournir, prêter, louer, transférer ou distribuer la
                        Plateforme, en concéder des sous-licences ou autres droits d&apos;usage, ou
                        de manière plus générale, de communiquer à un tiers ou à une société
                        affiliée tout ou partie de la Plateforme ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        modifier la Plateforme et/ou fusionner, intégrer tout ou partie de la
                        Plateforme dans d&apos;autres programmes informatiques ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        compiler la Plateforme, le décompiler, désassembler, traduire, analyser,
                        procéder au reverse engineering ou tenter d&apos;y procéder, sauf dans les
                        limites autorisées par la loi et notamment à l&apos;article L. 122-6-1 du
                        Code de la propriété intellectuelle ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>utiliser la Plateforme pour développer un produit concurrent ;</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        et plus généralement d&apos;effectuer tout acte d&apos;utilisation ou
                        d&apos;exploitation de la Plateforme non compris dans la Licence.
                      </span>
                    </p>
                  </li>
                </ul>
                <p>
                  La Licence est concédée pour la durée définie à l&apos;article « Durée du Contrat,
                  résiliation » et sous réserve du paiement effectif intégral du prix convenu à
                  l&apos;article « Conditions financières ».
                </p>
                <p>
                  <strong>1.1 Assistance à la mise en place de la Licence </strong>
                </p>
                <p>
                  XTRAMILE assiste le Client dans la mise en place de la Plateforme tel que
                  spécifiée dans la Proposition Commerciale (ci-après le « <strong>Setup</strong>
                   »).
                </p>
                <p>
                  Toute demande supplémentaire du Client relative au Setup et/ou d&apos;ajout de
                  nouveaux Services et/ou de nouvelles fonctionnalités fera l&apos;objet d&apos;une
                  commande spécifique. En cas d&apos;accord sur les conditions, notamment
                  financières, d&apos;adaptation de la Licence, celles-ci feront l&apos;objet
                  d&apos;une Proposition Commerciale complémentaire.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>6. Description des Services</strong>
                </p>
                <p>
                  En fonction de son Abonnement, le Client a accès à un certain nombre de Services
                  définis sur la Plateforme, sous une forme et selon les fonctionnalités et moyens
                  techniques que XTRAMILE juge les plus appropriés, et notamment :
                </p>
                <p>
                  <strong>6.2 Base de données</strong>
                </p>
                <p>
                  XTRAMILE fournit aux Clients un accès à une base de données de Candidats,
                  comprenant les profils des Candidats.
                </p>
                <p>
                  <strong>6.3 Publication des offres d&apos;emploi</strong>
                </p>
                <p>
                  XTRAMILE publie les offres d&apos;emploi des Clients sur Facebook, Instagram, sur
                  la Plateforme et/ou sur toutes autres plateformes et réseaux sociaux souhaités par
                  les Clients.
                </p>
                <p>
                  <strong>6.4 Outil X-Metrics</strong>
                </p>
                <p>
                  X-Metrics est un tableau de bord qui aide les ressources humaines à mesurer les
                  performances et donc les budgets des différents leviers de sourcing digitaux mis
                  en place au sein des entreprises.
                </p>
                <p>
                  Le Client peut ainsi avoir une lecture plus fine du ROI des canaux de diffusion
                  utilisés grâce à des analyses croisées comme le coût du recrutement par profil,
                  par région et par niveau d&apos;expérience.
                </p>
                <p>
                  <strong>6.5 Outil X-Programmatics</strong>
                </p>
                <p>
                  XTRAMILE publie les offres d&apos;emploi des Clients sur Facebook, Instagram, sur
                  la Plateforme et/ou sur toutes autres plateformes et réseaux sociaux souhaités par
                  les Clients.
                </p>
                <p>
                  <strong>6.6 Outil X-Ranking</strong>
                </p>
                <p>
                  X-Ranking permet de trier automatiquement des candidatures et des offres
                  d&apos;emplois grâce à une technologie s&apos;appuyant sur la sémantique.
                </p>
                <p>
                  <strong>6.7 Outil X-Parsing</strong>
                </p>
                <p>
                  X-parsing analyse les offres d&apos;emploi et CV pour en extraire les informations
                  clés et les organiser en entités nommées qui sont facilement identifiables.
                </p>
                <p>
                  <strong>6.8 Autres Services</strong>
                </p>
                <p>
                  XTRAMILE se réserve la possibilité de proposer tout autre Service qu&apos;elle
                  jugera utile, sous une forme et selon les fonctionnalités et moyens techniques
                  qu&apos;elle estimera les plus appropriés pour rendre lesdits Services.
                </p>
                <p className={styles.titleBlock}>
                  <strong>7. Développements spécifiques</strong>
                </p>
                <p>
                  XTRAMILE pourra également être amené à proposer au Client des développements
                  spécifiques (ci-après les « Développements Spécifiques »). Les Développements
                  Spécifiques réalisés, ainsi que le planning indicatif et le processus de recette
                  sont le cas échéant décrits dans une Proposition Commerciale.
                </p>
                <p>
                  <strong>7.1 Cahier des charges</strong>
                </p>
                <p>
                  Les parties s&apos;engagent à rédiger ensemble et d&apos;un commun accord un
                  cahier des charges définissant les éléments opérationnels et techniques des
                  Développements Spécifiques (ci-après le « <strong>Cahier des Charges</strong> »).
                </p>
                <p>
                  Le Cahier des Charges déterminera notamment les besoins du Client, les
                  spécifications attendues, les différentes phases du développement, les livrables
                  attendus et les délais de réalisation, ceux-ci étant mentionnés à titre indicatif
                  (à ce titre, la responsabilité de XTRAMILE ne pouvant en aucun cas être engagée en
                  cas de retard de livraison par rapport au délai annoncé)
                </p>
                <p>
                  Les Développements Spécifiques ne pourront débuter qu&apos;une fois que les
                  parties auront définitivement validé le Cahier des Charges. Sa version définitive
                  sera établie en 2 (deux) exemplaires, datés et signés par les parties. Chaque
                  partie en conservera 1 (un) exemplaire.
                </p>
                <p>
                  <strong>
                    7.2 Propriété intellectuelle relative aux Développements Spécifiques
                  </strong>
                </p>
                <p>
                  Le Client ne dispose que d&apos;une Licence d&apos;utilisation sur les
                  Développements Spécifiques, selon des modalités identiques à celles définies
                  ci-dessus pour la Solution.
                </p>
                <p>
                  La Licence ne confère au Client aucun droit de propriété intellectuelle sur les
                  Développements Spécifiques, qui demeurent la propriété entière et exclusive de
                  XTRAMILE.
                </p>
                <p>
                  Le droit d&apos;utilisation est consenti au Client sous réserve du paiement du
                  prix des Développements Spécifiques tel que défini dans les modalités convenues à
                  l&apos;article « Conditions financières ».
                </p>
                <p>
                  En outre, le Client s&apos;engage expressément à ne pas utiliser, copier, ou
                  reproduire, directement ou indirectement, tout ou partie des droits de propriété
                  intellectuelle dont XTRAMILE est titulaire, notamment au titre du droit des
                  marques.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>8. Maintenance et support technique de la Plateforme</strong>
                </p>
                <p>
                  <strong>8.1 Maintenant corrective</strong>
                </p>
                <p>
                  XTRAMILE s&apos;engage à corriger, pendant la durée du Contrat, toute anomalie
                  identifiée sur la Plateforme. Le Client devra signaler cette anomalie à XTRAMILE à
                  l&apos;adresse suivante support@myxtramile.com, en fournissant le plus
                  d&apos;informations possible. Si l&apos;anomalie ne semble pas provenir de la
                  Plateforme, il en informera immédiatement le Client. Dans le cas contraire, il
                  prendra tous les moyens nécessaires pour corriger l&apos;anomalie dans un délai
                  qu&apos;il estimera en fonction des corrections à apporter.
                </p>
                <p>
                  XTRAMILE ne saurait être tenue d&apos;assurer une quelconque maintenance dans les
                  cas suivants :
                </p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>
                        refus du Client de collaborer avec XTRAMILE dans la résolution des anomalies
                        et notamment de répondre aux questions et demandes de renseignement ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        utilisation de la Plateforme de manière non conforme à sa destination ou à
                        sa documentation ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        modification non autorisée de la Plateforme par le Client ou par un tiers ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>manquement du Client à ses obligations au titre du Contrat ;</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        implantation de tous progiciels, logiciels ou système d&apos;exploitation
                        non compatibles avec la Plateforme ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>utiliser la Plateforme pour développer un produit concurrent ;</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>défaillance des réseaux de communication électronique ;</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>acte volontaire de dégradation, malveillance, sabotage ;</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        détérioration due à un cas de force majeure telle que définie aux présentes
                        ou à une mauvaise utilisation de la Plateforme.
                      </span>
                    </p>
                  </li>
                </ul>
                <p>
                  Toute intervention dans les cas énumérés ci-dessus fera l&apos;objet d&apos;une
                  proposition commerciale supplémentaire.
                </p>
                <p>
                  <strong>8.2 Maintenance évolutive</strong>
                </p>
                <p>
                  Les évolutions ou mises à jour de la Plateforme et des Services ne sont pas
                  compris dans le prix défini à l&apos;article « Conditions financières ». Si le
                  Client souhaite en bénéficier, cela devra faire l&apos;objet d&apos;une nouvelle
                  Proposition Commerciale.
                </p>
                <p>
                  Néanmoins, XTRAMILE se réserve le droit, à sa libre discrétion, de mettre
                  gratuitement à disposition du Client certaines nouvelles fonctionnalités ou mises
                  à jour.
                </p>
                <p>
                  Elle se réserve le droit de limiter ou de suspendre le fonctionnement de la
                  Plateforme pendant les opérations de maintenance. Elle informera le Client au
                  préalable par tout moyen utile de la réalisation de ces opérations.
                </p>
                <p>
                  <strong>8.3 Support technique</strong>
                </p>
                <p>
                  En dehors des dysfonctionnements et pour toute question liée à l&apos;intégration
                  de l&apos;API, la formation à l&apos;utilisation des Services et plus généralement
                  à l&apos;utilisation des Services, XTRAMILE offre au Client un support technique.
                </p>
                <p>
                  XTRAMILE répondra dans les meilleurs délais en fonction de la nature de la
                  demande, étant entendu que le support technique est ouvert du lundi au vendredi de
                  9 heures à 12 heures et de 13 heures à 18 heures, heure de Paris, France, hors
                  jours fériés en France (ci-après « <strong>Heures Ouvrées</strong> »).
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>9. Hébergement</strong>
                </p>
                <p>
                  XTRAMILE s&apos;engage à assurer, dans les termes d&apos;une obligation de moyens,
                  l&apos;hébergement de la Plateforme, et des données saisies par les Clients dans
                  le cadre de leur utilisation des Services, et échangées avec eux via la
                  Plateforme, conformément aux usages de la profession et à l&apos;état de
                  l&apos;art, sur ses serveurs situés dans un territoire de l&apos;Union européenne.
                </p>
                <p>
                  Dans le cadre de ce service d&apos;hébergement, XTRAMILE s&apos;engage à fournir
                  au Client des capacités de stockage et de traitement suffisantes pour
                  l&apos;exploitation de la Plateforme.
                </p>
                <p>
                  XTRAMILE s&apos;engage à mettre en œuvre l&apos;ensemble des moyens techniques,
                  conformes à l&apos;état de l&apos;art, nécessaires pour assurer la sécurité de
                  l&apos;accès à la Plateforme et aux Services, portant sur la protection et la
                  surveillance des infrastructures, le contrôle de l&apos;accès physique et/ou
                  immatériel auxdites infrastructures, ainsi que sur la mise en œuvre des mesures de
                  détection, de prévention et de récupération pour protéger les serveurs
                  d&apos;actes malveillants.
                </p>
                <p>
                  XTRAMILE s&apos;engage également à prendre toutes précautions utiles, au regard de
                  la nature des données et des risques présentés par les traitements automatisés de
                  données mis en œuvre pour les besoins des Services, pour préserver la sécurité des
                  données, et notamment empêcher qu&apos;elles soient déformées, endommagées ou que
                  des tiers non autorisés y aient accès.
                </p>
              </div>
              <div></div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>10. Garantie de niveau de service</strong>
                </p>
                <p>
                  XTRAMILE s&apos;engage à assurer la permanence, la continuité et la qualité de
                  l&apos;accès à la Plateforme et aux Services, ainsi que de l&apos;exploitation de
                  la Plateforme.
                </p>
                <p>
                  A ce titre, XTRAMILE fera ses meilleurs efforts pour maintenir un accès à ses
                  outils 24h/24h et 7j/7j et garantir la disponibilité de la Plateforme à 99%, sauf
                  cas de force majeure, telle que définie aux présentes.
                </p>
                <p>
                  Eu égard à la complexité d&apos;Internet, l&apos;inégalité des capacités des
                  différents sous-réseaux, l&apos;afflux à certaines heures des Utilisateurs, aux
                  différents goulots d&apos;étranglement sur lesquels XTRAMILE n&apos;a aucune
                  maîtrise, la responsabilité de XTRAMILE sera limitée au fonctionnement de ses
                  serveurs, dont les limites extérieures sont constituées par les points de
                  raccordement.
                </p>
                <p>
                  XTRAMILE ne saurait être tenue pour responsable (i) des vitesses d&apos;accès à
                  ses serveurs, (ii) des ralentissements externes à ses serveurs, et (iii) des
                  mauvaises transmissions dues à une défaillance ou à un dysfonctionnement de ces
                  réseaux.
                </p>
                <p>
                  En cas de nécessité, XTRAMILE se réserve la possibilité de limiter ou de suspendre
                  l&apos;accès à la Plateforme et aux Services pour procéder à toute opération de
                  maintenance programmée de la Plateforme. Dans cette hypothèse, XTRAMILE
                  s&apos;engage à informer le Client au minimum 24 (vingt-quatre) heures à
                  l&apos;avance, par tous moyens utiles et notamment par email envoyé aux
                  Administrateurs, de ces opérations de maintenance. XTRAMILE fera ses meilleurs
                  efforts afin que lesdites opérations de maintenance n&apos;excèdent pas 8 (huit)
                  heures et s&apos;effectuent en dehors des Heures Ouvrées.
                </p>
                <p>
                  En cas de faille de sécurité nécessitant l&apos;interruption immédiate de la
                  Plateforme, XTRAMILE se réserve la possibilité de suspendre immédiatement
                  l&apos;accès à la Plateforme. Il en informera le Client dans les plus brefs
                  délais.
                </p>
                <p>
                  Le Client reconnaît et accepte que la présente garantie de niveau de service ne
                  couvre pas toute panne ou interruption de la Plateforme intervenant du fait des
                  opérateurs télécoms ou fournisseurs d&apos;accès à Internet.
                </p>
                <p>
                  Le Client reconnait et accepte expressément que le taux de disponibilité ci-dessus
                  ne s&apos;appliquera pas dans les cas énumérés à l&apos;article « Maintenance
                  corrective ».
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>10. Durée de contrat, résiliation</strong>
                </p>
                <p>
                  auf disposition contraire prévue dans la Proposition Commerciale, les Services
                  sont souscrits sous forme d&apos;abonnement (ci-après l&apos;«
                  <strong>Abonnement</strong> »), qui débute à compter de la date de signature du
                  Contrat pour une durée initiale indiquée dans la Proposition Commerciale (ci-après
                  la « <strong>Période Initiale</strong> »).
                </p>
                <p>
                  L&apos;Abonnement se renouvelle ensuite tacitement pour des périodes de durée
                  identique, qui sont indiquées dans la Proposition Commerciale (ci-après la/les «
                  <strong>Période(s) Ultérieure(s)</strong> »), sauf dénonciation effectuée par
                  XTRAMILE ou par le Client au plus tard :
                </p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>2 (deux) semaines avant la fin de la Période Initiale ;</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>2 (deux) mois avant la fin d&apos;une Période Ultérieure.</span>
                    </p>
                  </li>
                </ul>
                <p>
                  La Période initiale et les Périodes Ultérieures sont ci-après ensemble désignées
                  la « Période ».
                </p>
                <p>
                  La dénonciation de l&apos;Abonnement par l&apos;une des parties s&apos;effectue en
                  adressant une demande à cet effet à l&apos;autre partie par email (à
                  l&apos;adresse support@myxtramile.com) en cas de résiliation par le Client et à
                  l&apos;adresse email utilisée lors de la création du Compte en cas de résiliation
                  par XTRAMILE), mentionnant la volonté de dénoncer l&apos;Abonnement.
                </p>
                <p>
                  La dénonciation de l&apos;Abonnement prend effet à la fin de la Période pendant
                  laquelle la demande est envoyée. Elle entraîne la fermeture de l&apos;accès à la
                  Plateforme. Le Client recevra une confirmation de suppression de Compte par
                  courrier électronique.
                </p>
                <p>
                  Le Client reconnait et accepte qu&apos;en cas de résiliation, l&apos;ensemble des
                  paramétrages effectués par le Client sur la Plateforme seront perdus.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>11. Conditions financières</strong>
                </p>
                <p>
                  <strong>11.1 Prix</strong>
                </p>
                <p>
                  Le prix est indiqué dans la Proposition Commerciale et est, sauf mention
                  contraire, exprimé en Euros et hors taxes.
                </p>
                <p>
                  Toute Période entamée est due dans son intégralité et tout acompte versé est
                  non-remboursable.
                </p>
                <p>
                  <strong>11.2 Révision des prix</strong>
                </p>
                <p>
                  Les prix peuvent faire l’objet d’une révision par XTRAMILE à tout moment, à sa
                  libre discrétion. Le Client sera informé de ces modifications par XTRAMILE par
                  tout moyen écrit utile (et notamment par email) 3 (trois) mois au moins avant
                  l’entrée en vigueur du nouveau prix.
                </p>
                <p>
                  Une fois entrés en vigueur, les nouveaux prix s’appliqueront à compter de la date
                  de renouvellement de l’Abonnement.
                </p>
                <p>
                  Si le Client n’accepte pas les nouveaux prix, il pourra résilier son Abonnement
                  selon les modalités prévues à l’article « Durée du Contrat, résiliation ».
                </p>
                <p>
                  <strong>11.3 Facturations et modalités de paiement</strong>
                </p>
                <p>
                  Les modalités de facturation de l’Abonnement sont indiquées dans la Proposition
                  Commerciale.
                </p>
                <p>
                  Sauf mention contraire dans la Proposition Commerciale, chaque facture est
                  réglable par virement bancaire dans les 30 (trente) jours calendaires à compter de
                  son émission.
                </p>
                <p>
                  <strong>11.4 Retards et incidents de paiement</strong>
                </p>
                <p>
                  Le Client est informé et accepte expressément que tout retard de paiement de tout
                  ou partie d’une somme due à son échéance entraînera automatiquement, et sans
                  préjudice des dispositions des articles « Résolution pour manquement » et
                  « Sanctions des manquements » et dès le jour suivant la date de règlement figurant
                  sur la facture :
                </p>
                <p>
                  (i) la déchéance du terme de l’ensemble des sommes dues par le Client et leur
                  exigibilité immédiate ;
                </p>
                <p>
                  (ii) la suspension immédiate des Services en cours jusqu’au complet paiement de
                  l’intégralité des sommes dues par le Client ;
                </p>
                <p>
                  (iii) la facturation au profit de XTRAMILE d’un intérêt de retard au taux de 3
                  (trois) fois le taux de l’intérêt légal, assis sur le montant de l’intégralité des
                  sommes dues par le Client et d’une indemnité forfaitaire de 40 (quarante) euros au
                  titre des frais de recouvrement, sans préjudice d’une indemnisation complémentaire
                  si les frais de recouvrement effectivement exposés sont supérieurs à ce montant
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>12. Convention de preuve</strong>
                </p>
                <p>Le Client reconnaît et accepte expressément :</p>
                <p>
                  (i) que les données recueillies sur la Plateforme et les équipements informatiques
                  de XTRAMILE font foi de la réalité des opérations intervenues dans le cadre des
                  présentes ;
                </p>
                <p>
                  (ii) que ces données constituent le principal mode de preuve admis entre les
                  parties.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>13. Obligations et responsabilité du Client</strong>
                </p>
                <p>
                  Sans préjudice des autres obligations prévues aux présentes, le Client s’engage à
                  respecter les obligations qui suivent.
                </p>
                <p>
                  <strong>13.1</strong>
                </p>
                <p>
                  Le Client s’engage, dans son usage de la Plateforme et des Services, à respecter
                  les lois et règlements en vigueur et à ne pas porter atteinte aux droits de tiers
                  ou à l’ordre public.
                </p>
                <p>
                  <strong>13.2</strong>
                </p>
                <p>
                  Le Client déclare avoir reçu de XTRAMILE, préalablement à la signature des
                  présentes, tous conseils, instructions et précisions qui lui sont nécessaires pour
                  souscrire aux présentes en toute connaissance de cause, qu’il dispose ainsi d’une
                  connaissance suffisante des caractéristiques et des fonctionnalités de la
                  Plateforme et des Services, et qu’il a, préalablement aux présentes, suffisamment
                  échangé avec XTRAMILE pour s’assurer que la Plateforme et les Services
                  correspondent à ses attentes, besoins et contraintes.
                </p>
                <p>
                  Le Client est seul responsable de son utilisation de la Plateforme et des
                  Services.
                </p>
                <p>
                  <strong>13.3</strong>
                </p>
                <p>
                  Le Client s’engage à faire un usage strictement personnel des Services. Il
                  s’interdit en conséquence de céder, concéder ou transférer tout ou partie de ses
                  droits ou obligations au titre des présentes à un tiers, de quelque manière que ce
                  soit, et notamment le Client s’interdit de commercialiser les Services fournis par
                  XTRAMILE.
                </p>
                <p>
                  Plus généralement le Client s’engage à coopérer activement avec XTRAMILE en vue de
                  la bonne exécution des présentes.
                </p>
                <p>
                  Le Client reconnait et accepte que tout document transmis par XTRAMILE dans le
                  cadre des Services (et notamment des documents techniques d’aide à l’intégration
                  des outils sélectionnés par le Client).
                </p>
                <p>
                  <strong>13.4</strong>
                </p>
                <p>
                  Le Client s’engage à ce que les contenus, CVs, offres d’emploi, profils,
                  photographies, offres d&apos;emploi ou toute autre information ajoutés/indiqués
                  sur la Plateforme (ci-après les « Contenus ») soient licites, ne portent pas
                  atteinte à l’ordre public, aux bonnes mœurs ou aux droits de tiers, n’enfreignent
                  aucune disposition législative ou règlementaire et plus généralement, ne soient
                  aucunement susceptibles de mettre en jeu la responsabilité civile ou pénale de
                  XTRAMILE.
                </p>
                <p>
                  Le Client est seul responsable de l’exactitude et de l’exhaustivité des Contenus,
                  XTRAMILE ne pouvant en aucun cas être tenue responsable des éventuelles erreurs,
                  coquilles, omissions ou indications de nature à induire en erreur.
                </p>
                <p>
                  Le Client s’interdit ainsi de diffuser, notamment et sans que cette liste soit
                  exhaustive :
                </p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>
                        des Contenus pornographiques, obscènes, indécents, choquants ou inadaptés à
                        un public familial, diffamatoires, injurieux, violents, racistes, xénophobes
                        ou révisionnistes ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>des Contenus contrefaisants ;</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>des Contenus attentatoires à l’image d’un tiers ;</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        des Contenus mensongers, trompeurs ou proposant ou promouvant des activités
                        illicites, frauduleuses ou trompeuses ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        des Contenus ayant pour effet de réduire, désorganiser ou empêcher
                        l&apos;utilisation normale des Services ou d&apos;interrompre ou de ralentir
                        la circulation normale des communications entre les Candidats et les
                        Clients, tels que des contenus nuisibles aux systèmes informatiques de tiers
                        (tels que virus, vers, chevaux de Troie, etc.) ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        et plus généralement des Contenus susceptibles de porter atteinte aux droits
                        de tiers ou d’être préjudiciables à des tiers, de quelque manière et sous
                        quelque forme que ce soit.
                      </span>
                    </p>
                  </li>
                </ul>
                <p>Le cas échéant, XTRAMILE se réserve le droit de supprimer ces Contenus.</p>
                <p>
                  <strong>13.5</strong>
                </p>
                <p>
                  Le Client est informé et accepte que l’utilisation de la Plateforme et de Services
                  nécessitent qu’il soit connecté à Internet et que la qualité des Services dépend
                  directement de cette connexion, dont il est seul responsable.
                </p>
                <p>
                  <strong>13.6</strong>
                </p>
                <p>
                  Le Client s’interdit de monnayer, céder, concéder ou transférer tout ou partie de
                  ses droits ou obligations au titre des présentes à un quelconque tiers, y compris
                  si ce tiers a un lien direct ou indirect avec Client, de quelque manière que ce
                  soit.
                </p>
                <p>
                  <strong>13.7</strong>
                </p>
                <p>
                  Le Client est seul responsable à l&apos;égard de XTRAMILE et, le cas échéant, de
                  tout tiers, pour tous les dommages, directs ou indirects, de quelque nature que ce
                  soit, causés par un Contenu publié, transmis ou diffusé lors de l&apos;utilisation
                  des Services.
                </p>
                <p>
                  Plus généralement, le Client garantit ainsi XTRAMILE contre toutes plaintes,
                  réclamations, actions et/ou revendications quelconques qu’elle pourrait subir du
                  fait de la violation, par le Client de l’une quelconque de ses obligations aux
                  termes des présentes. Il s’engage à payer à XTRAMILE tous les frais, charges et/ou
                  condamnations qu’elle pourrait avoir à supporter de ce fait.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>14. Comportements prohibés</strong>
                </p>
                <p>Il est strictement interdit d’utiliser les Services aux fins suivantes :</p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>
                        l’exercice d’activités illégales, frauduleuses ou portant atteinte aux
                        droits ou à la sécurité des tiers ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        l’atteinte à l’ordre public ou la violation des lois et règlements en
                        vigueur ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        l’intrusion dans le système informatique d’un tiers ou toute activité de
                        nature à nuire, contrôler, interférer, ou intercepter tout ou partie du
                        système informatique d’un tiers, en violer l’intégrité ou la sécurité ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        l’envoi d’emails non sollicités et/ou de prospection ou sollicitation
                        commerciale ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        les manipulations destinées à améliorer le référencement d’un site tiers ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        l’aide ou l’incitation, sous quelque forme et de quelque manière que ce
                        soit, à un ou plusieurs des actes et activités décrits ci-dessus ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        et plus généralement toute pratique détournant les Services à des fins
                        autres que celles pour lesquelles ils ont été conçus.
                      </span>
                    </p>
                  </li>
                </ul>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>15. Obligations et responsabilité de XTRAMILE</strong>
                </p>
                <p>
                  Sans préjudice des autres obligations prévues aux présentes, XTRAMILE s’engage à
                  respecter les obligations qui suivent.
                </p>
                <p>
                  <strong>15.1</strong>
                </p>
                <p>
                  XTRAMILE s’engage à fournir la Plateforme et les Services avec diligence et selon
                  les règles de l’art, étant précisé qu’il pèse sur elle une obligation de moyens,
                  ce que le Client reconnaît et accepte expressément.
                </p>
                <p>
                  <strong>15.2</strong>
                </p>
                <p>
                  XTRAMILE s’engage à faire ses meilleurs efforts pour assurer la sécurité de la
                  Plateforme.
                </p>
                <p>
                  XTRAMILE ne saurait être tenue responsable de tout manque de vigilance de la part
                  des Clients dans le maintien de la confidentialité de leur identifiant et de leur
                  mot de passe pour accéder à la Plateforme.
                </p>
                <p>
                  <strong>15.3</strong>
                </p>
                <p>
                  XTRAMILE s’engage à n’utiliser les éléments qui pourront lui être transmis dans le
                  cadre des présentes qu’aux fins d’exécution des Services et à ne pas les diffuser
                  ou partager avec quelque tiers que ce soit, sauf demande ou accord exprès du
                  Client.
                </p>
                <p>
                  XTRAMILE s’interdit d’utiliser ces éléments à des fins de prospection ou de
                  sollicitation commerciale. Plus généralement, il s’interdit par conséquent d’en
                  réutiliser tout ou partie sous quelque forme que ce soit et dus détourner de leur
                  finalité à savoir l’exécution des Services.
                </p>
                <p>
                  <strong>15.4</strong>
                </p>
                <p>
                  XTRAMILE n’a pas connaissance des Contenus mis en ligne par les Clients dans le
                  cadre des Services, sur lesquels elle n’effectue aucune modération, sélection,
                  vérification ou contrôle d’aucune sorte et à l’égard desquels elle n’intervient
                  qu’en tant que prestataire d’hébergement.
                </p>
                <p>
                  XTRAMILE intervient en qualité de courtier en ce qu’elle met à la disposition des
                  Employés des outils et moyens techniques leur permettant d’entrer en relation aux
                  fins de recrutement. Sa responsabilité se limite à la fourniture de ces moyens,
                  tels que décrits aux présentes et à la mise en relation entre Candidats et
                  Clients.
                </p>
                <p>Par conséquent :</p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>
                        XTRAMILE ne peut être tenue pour responsable des Contenus, dont les auteurs
                        sont des tiers.
                      </span>
                    </p>
                    <p>
                      <span>
                        Par conséquent, le Client est seul responsable de l’exactitude, de
                        l’exhaustivité et de la mise à jour des Contenus.
                      </span>
                    </p>
                    <p>
                      <span>
                        Les contenus préjudiciables à un tiers peuvent faire l’objet d’une
                        notification à XTRAMILE selon les modalités prévues par l’article 6 I 5 de
                        la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l&apos;économie
                        numérique, XTRAMILE se réservant de prendre les mesures décrites à l’article
                        « Sanctions des manquements ».
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        XTRAMILE agit en son nom personnel et ne passe aucun acte juridique au nom
                        et pour le compte des Clients, qui contractent directement avec les
                        Candidats.
                      </span>
                    </p>
                    <p>
                      <span>
                        XTRAMILE n’étant donc pas partie aux contrats conclus entre les Candidats et
                        les Clients, elle ne saurait en aucun cas voire sa responsabilité engagée au
                        titre des difficultés pouvant intervenir lors de la conclusion ou de
                        l’exécution de ces contrats, ni être partie à quelques litiges éventuels que
                        ce soit. Les Clients s’obligent ainsi à mettre XTRAMILE hors de cause dans
                        tous différends ou litiges entre lesdites personnes et à faire leur affaire
                        personnelle de leur résolution.
                      </span>
                    </p>
                  </li>
                </ul>
                <p>
                  Toutefois, dans un souci d’amélioration constante de la qualité des Services,
                  XTRAMILE invite les Clients à lui faire part de tous les commentaires et
                  informations qu’ils pourraient souhaiter porter à sa connaissance concernant la
                  qualité des transactions réalisées par l’intermédiaire des Services.
                </p>
                <p>
                  <strong>15.5</strong>
                </p>
                <p>
                  XTRAMILE ne garantit pas au Client que ce dernier trouvera le Candidat souhaité
                  pour un poste.
                </p>

                <p>
                  <strong>15.6</strong>
                </p>
                <p>
                  XTRAMILE s’engage à procéder régulièrement à des contrôles afin de vérifier le
                  fonctionnement et l’accessibilité de la Plateforme.
                </p>
                <p>
                  Néanmoins, XTRAMILE ne garantit pas au Client que la Plateforme et les Services,
                  soumis à une recherche constante pour en améliorer notamment la performance et le
                  progrès, seront totalement exempts d’erreurs, de vices ou défauts. A ce titre,
                  XTRAMILE se réserve la faculté d’interrompre momentanément l’accès à la Plateforme
                  pour des raisons de maintenance. De même, XTRAMILE ne saurait être tenue
                  responsable des difficultés ou impossibilités momentanées d’accès à la Plateforme
                  qui auraient pour origine des circonstances qui lui sont extérieures, telles que
                  la force majeure, ou encore qui seraient dues à des perturbations des réseaux de
                  télécommunication.
                </p>

                <p>
                  <strong>15.7</strong>
                </p>
                <p>
                  11.7. XTRAMILE se réserve le droit, à tout moment et sans préavis, de modifier les
                  informations contenues sur la Plateforme dans le cadre de sa mise à jour ou de
                  correction d&apos;erreurs ou d&apos;inexactitudes.
                </p>

                <p>
                  <strong>15.8</strong>
                </p>
                <p>
                  Les Services fournis par les outils X-Metrics, X-Programmatics, X-Ranking et
                  X-Parsing ne peuvent être rendus que pour :
                </p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>des Contenus édités par ordinateur (i.e. non manuscrits) ;</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        des données rédigées dans l’alphabet latin et/ou contenant une numérotation
                        décimale ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        toute autre précision spécifiée par XTRAMILE dans la Proposition
                        Commerciale.
                      </span>
                    </p>
                  </li>
                </ul>
                <p>
                  XTRAMILE ne peut pas garantir l’extraction exacte des données et informations
                  issues des Contenus transmis sous d’autres systèmes et/ou formats que ceux évoqués
                  ci-dessus.
                </p>
                <p>
                  <strong>15.9</strong>
                </p>
                <p>
                  XTRAMILE ne garantit pas l’exactitude du marquage ou de la saisie des données et
                  informations figurant dans les Contenus. Le Client reconnait et accepte que les
                  Contenus contenant des données illisibles peuvent ne pas être traités
                  correctement.
                </p>

                <p>
                  <strong>15.10</strong>
                </p>
                <p>
                  XTRAMILE ne garantit pas que les notes ou autres informations manuscrites figurant
                  sur les Contenus seront correctement traités par XTRAMILE.
                </p>

                <p>
                  <strong>15.11</strong>
                </p>
                <p>
                  TRAMILE ne pourra être tenue responsable vis-à-vis de tout Client, que des
                  dommages directs subis par ledit Client. En tout état de cause la responsabilité
                  de XTRAMILE sera limitée au montant total payé par Client dans les 6 (six)
                  derniers mois précédant le fait générateur de responsabilité.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>16. Propriété intellectuelle</strong>
                </p>
                <p>
                  <strong>16.1 Propriété intellectuelle de XTRAMILE</strong>
                </p>
                <p>
                  Le Client reconnaît de convention expresse que le Contrat ne lui confère aucun
                  droit de propriété intellectuelle de quelque nature que ce soit sur la Plateforme,
                  l’API et plus généralement, sur les systèmes, logiciels, structures,
                  infrastructures, bases de données et contenus de toute nature (documentation
                  technique remise au Client, templates, textes, images, visuels, logos, marques,
                  base de données, etc.) exploités par XTRAMILE dans le cadre de la fourniture des
                  Services.
                </p>
                <p>
                  Le Client ne dispose que d’une simple licence d’utilisation sur la Plateforme et
                  les contenus dans les conditions définies aux présentes.
                </p>
                <p>
                  En conséquence, tous désassemblages, décompilations, décryptages, extractions,
                  réutilisations, copies et plus généralement tous actes de reproduction,
                  représentation, diffusion et utilisation de l’un quelconque des éléments composant
                  la Plateforme, en tout ou en partie, sans l’autorisation de XTRAMILE, sont
                  strictement interdits et pourront faire l’objet de poursuites judiciaires.
                </p>
                <p>
                  <strong>12.2 Propriété intellectuelle du Client</strong>
                </p>
                <p>
                  Aux fins de mise en œuvre des Services, le Client accorde à XTRAMILE une licence
                  mondiale, gratuite, non-exclusive d’utilisation des Contenus. Ainsi, le Client :
                </p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>
                        consent à ce que ses Contenus soient diffusés à titre gracieux par XTRAMILE
                        par tout moyen et sur tout support sur la Plateforme et sur tous autres
                        sites internet français ou étrangers, édités par toutes sociétés avec
                        lesquelles XTRAMILE a des accords ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        accepte que ses Contenus rédactionnels soient traduits en toutes langues ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        reconnaît et accepte que les Contenus pourront faire l’objet de
                        modifications, notamment quant à leur cadrage, leur format et leurs
                        couleurs, ainsi que d’altérations ou de dégradations dans leur qualité, en
                        fonction des contraintes techniques de la Plateforme ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        renonce à demander à XTRAMILE une quelconque rémunération, redevance,
                        indemnité ou compensation financière à ce titre.
                      </span>
                    </p>
                  </li>
                </ul>
                <p>
                  En outre, le Client comprend et reconnait qu&apos;il peut être difficile de
                  retirer les Contenus des canaux de médias sociaux et des sites internet de partage
                  de XTRAMILE (tels que Facebook, Twitter, Instagram, Pinterest, Tumblr, YouTube,
                  etc.), et accepte que ces éléments puissent rester sur les canaux de médias
                  sociaux et les sites internet de partage tels qu&apos;ils ont été publiés à
                  l&apos;origine, à condition qu&apos;à l’issue du Contrat, XTRAMILE ne publie pas à
                  nouveau ou n&apos;autorise pas d&apos;autres personnes à publier à nouveau ces
                  éléments.
                </p>
              </div>
              <div>
                <p>
                  <strong>17. Liens hypertextes</strong>
                </p>
                <ul>
                  <li>
                    <p>Vers une plateforme tierce</p>
                  </li>
                </ul>
                <p>
                  La Plateforme peut contenir des liens hypertextes vers d&apos;autres sites
                  présents sur Internet, et notamment le site internet du Client sur lequel peuvent
                  être intégrés les Services.
                </p>
                <p>
                  XTRAMILE ne pourra en aucun cas être tenue pour responsable de la disponibilité
                  technique de sites internet ou d’applications mobiles exploités par des tiers (y
                  compris ses éventuels partenaires).
                </p>
                <p>
                  XTRAMILE n&apos;endosse aucune responsabilité au titre des contenus, publicités,
                  produits et/ou services disponibles sur de tels sites et applications mobiles
                  tiers dont il est rappelé qu’ils sont régis par leurs propres conditions
                  d’utilisation.
                </p>
                <p>
                  XTRAMILE n&apos;est pas non plus responsable des transactions intervenues entre le
                  Client et un quelconque annonceur, professionnel ou commerçant (y compris ses
                  éventuels partenaires) vers lequel le Client serait orienté par
                  l&apos;intermédiaire de la Plateforme et ne saurait en aucun cas être partie à
                  quelques litiges éventuels que ce soit avec ces tiers concernant notamment la
                  livraison de produits et/ou services, les garanties, déclarations et autres
                  obligations quelconques auxquelles ces tiers sont tenus.
                </p>
                <ul>
                  <li>
                    <p>Vers la Plateforme</p>
                  </li>
                </ul>
                <p>
                  Le Client est libre d’inclure sur son site internet un lien vers la Plateforme. La
                  Plateforme devra le cas échéant être affichée dans une nouvelle fenêtre de
                  navigateur. XTRAMILE se réserve néanmoins le droit de demander la suppression
                  d&apos;un lien s’il l’estime incompatible avec ses Services ou son image.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>18. Protection des données à caractère personnel</strong>
                </p>
                <p>
                  <strong>18.1 XTRAMILE en qualité de responsable de traitement</strong>
                </p>
                <p>
                  Les caractéristiques détaillées de ce traitement sont explicitées dans la
                  <a href="/privacy-statement">charte relative à la protection des données</a> à
                  caractère personnel accessible sur la Plateforme.
                </p>
                <p>
                  <strong>18.2 Formule API : XTRAMILE en qualité de sous-traitant</strong>
                </p>
                <p>
                  Dans le cadre de l’utilisation de la Plateforme par le Client par le biais d’une
                  API, XTRAMILE est amené à traiter des données à caractère personnel en qualité de
                  sous-traitant, dont les modalités sont indiquées en Annexe.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>19. Références commerciales</strong>
                </p>
                <p>
                  Sauf mention expresse dans la Proposition Commerciale, les parties s’autorisent
                  expressément et mutuellement à faire usage à titre gratuit de leurs noms, marques
                  et logos respectifs ainsi que des références de leurs sites internet et réseaux
                  sociaux et plus généralement pour toute communication extérieure, à titre de
                  références commerciales, sur tout support et sous quelque forme que ce soit,
                  pendant la durée du Contrat et au-delà, pendant une durée de 5 (cinq) ans.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>20. Confidentialité</strong>
                </p>
                <p>
                  Chaque partie s’engage à garder strictement confidentiels les documents, éléments,
                  données et informations de l’autre partie dont elle serait destinataire.
                  S’agissant de XTRAMILE, les parties conviennent d’ores et déjà expressément que
                  cette obligation de confidentialité couvre les données à caractère personnel que
                  XTRAMILE sera amené à traiter pour le Client dans le cadre des Services.
                  L’ensemble de ces informations est désigné ci-après les «
                  <strong>Informations Confidentielles</strong> ».
                </p>
                <p>
                  La partie qui reçoit des Informations Confidentielles s’engage à ne pas les
                  divulguer sans accord préalable de l’autre partie, pendant une durée de 5 (cinq)
                  ans à compter de la fin de l’exécution des Services concernés. Elle ne pourra les
                  transmettre à des employés, collaborateurs, stagiaires ou conseils que s’ils sont
                  tenus à la même obligation de confidentialité que celle prévue aux présentes.
                  Cette obligation ne s’étend pas aux documents, éléments, données et informations :
                </p>
                <p>(i) dont la partie qui les reçoit avait déjà connaissance ;</p>
                <p>
                  (ii) déjà publics lors de leur communication ou qui le deviendraient sans
                  violation du Contrat ;
                </p>
                <p>(iii) qui auraient été reçus d’un tiers de manière licite ;</p>
                <p>
                  (iv) dont la communication serait exigée par les autorités judiciaires, en
                  application des lois et règlements ou en vue d’établir les droits d’une partie au
                  titre du Contrat.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>Cession du contrat</strong>
                </p>
                <p>
                  l est d’ores et déjà entendu que chaque partie pourra déléguer ou céder tout ou
                  partie des droits et obligations du Contrat :
                </p>
                <ul>
                  <li>
                    <strong>à toute société affiliée</strong>
                  </li>
                </ul>
                <p>
                  Par société affiliée, on entend toute personne, présente ou à venir, qui,
                  directement ou indirectement, (i) contrôle ou est contrôlée par, ou est sous le
                  contrôle commun, de l’une des parties, ou (ii) sur lesquelles l’une des parties
                  détient une participation ; les notions de contrôle et de participation étant
                  celles entendues au sens des articles L. 233-1 et suivants du Code de commerce, à
                  savoir :
                </p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>
                        pour la participation, une détention entre 10% et 50% du capital ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        pour le contrôle, une détention de plus de 50% des actions ou des droits de
                        vote, ou le droit d&apos;élire la majorité des membres du conseil
                        d&apos;administration ou de tout autre organe directeur similaire.
                      </span>
                    </p>
                  </li>
                </ul>
                <ul>
                  <li>
                    <strong>
                      en cas de changement de contrôle, de fusion, d&apos;absorption, de cession, de
                      transfert de l’une des parties, de son fonds de commerce ou de ses activités à
                      une autre personne morale, sous réserve qu’il ne s’agisse pas d’un concurrent
                      direct de l’autre partie.
                    </strong>
                  </li>
                </ul>
                <p>
                  S’il s’agit d’un concurrent direct, la partie concernée pourra refuser la cession
                  et devra résilier le Contrat selon les modalités prévues aux présentes.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>22. Sous-traitance</strong>
                </p>
                <p>
                  XTRAMILE se réserve la faculté de recourir à des sous-traitants afin de procéder
                  aux Services. Dans cette hypothèse, XTRAMILE en avisera le Client et s’engage à
                  faire respecter par ses sous-traitants les mêmes obligations contractuelles que
                  celles auxquelles il se soumet dans le cadre du Contrat. XTRAMILE restera en tout
                  état de cause seule responsable de la bonne exécution des Services à l’égard du
                  Client.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>23. Réglementation sociale</strong>
                </p>
                <p>
                  XTRAMILE déclare se conformer à la législation fiscale et sociale en vigueur, être
                  à jour du paiement des cotisations sociales et être en mesure de fournir la preuve
                  du respect des différentes obligations applicables en la matière, à la demande du
                  Client. XTRAMILE devra obligatoirement fournir au Client, sur demande de celui-ci
                  et aussi souvent que nécessaire, jusqu’à la fin de l’exécution du Contrat, les
                  documents suivants :
                </p>
                <p>
                  (i) une carte d’identification justifiant de l’immatriculation au registre des
                  métiers ou un extrait de l’inscription au registre du Commerce et des Sociétés
                  daté de moins de 3 (trois) mois (extrait K ou KBIS), ou équivalent pour une
                  société étrangère ;
                </p>
                <p>
                  (ii) une attestation de fourniture de déclarations sociales émanant de l’organisme
                  de protection sociale chargé du recouvrement des cotisations et des contributions
                  sociales incombant à XTRAMILE.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>23. Force majeure</strong>
                </p>
                <p>
                  Aucune des parties ne saurait être tenue responsable des difficultés ou
                  impossibilités momentanées d’exécution des présentes qui auraient pour origine la
                  force majeure. Les parties conviennent d’entendre par force majeure les événements
                  habituellement reconnus comme tels à l’article 1218 du Code civil et les tribunaux
                  français, en ce compris notamment les grèves, activités terroristes, émeutes,
                  insurrections, guerres, actions gouvernementales, épidémies, catastrophes
                  naturelles ou défaut imputable à un prestataire tiers de télécommunication.
                </p>
                <p>
                  La survenance d’un cas de force majeure suspend l’exécution des conditions
                  générales dès réception par l’une des parties de l’information transmise par
                  lettre recommandée avec demande d’avis de réception par l’autre partie. Les
                  parties feront donc leur meilleurs efforts pour notifier l’autre partie de la
                  survenance d’un cas de force majeure.
                </p>
                <p>
                  Dans l’hypothèse d’un cas de force majeure faisant obstacle à l’exécution de ses
                  obligations par une partie et se poursuivant au-delà d’une durée d’un mois, les
                  présentes conditions générales pourront être résiliées par l’une ou l’autre des
                  parties, par lettre recommandée avec accusé de réception, sans qu’aucune des
                  parties n’ait à verser à l’autre une quelconque indemnité.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>24. Résiliation par manquement</strong>
                </p>
                <p>
                  En cas de manquement par l’une des parties à ses obligations au titre du Contrat,
                  celui-ci sera résilié de plein droit 30 (trente) jours après réception par la
                  partie défaillante d’une mise en demeure, restée sans effet, par lettre
                  recommandée avec accusé de réception, mentionnant l’intention de faire application
                  de la présente clause, sans préjudice de tous dommages et intérêts qui pourront
                  être réclamés à la partie défaillante.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>25. Sanctions des manquements</strong>
                </p>
                <p>
                  En cas de manquement à l’une quelconque des dispositions du Contrat, ou plus
                  généralement, d’infraction aux lois et règlements par le Client, XTRAMILE se
                  réserve le droit de :
                </p>
                <p>
                  (i) suspendre, supprimer ou empêcher l’accès à la Plateforme au Client, auteur du
                  manquement ou de l’infraction, ou y ayant participé ;
                </p>
                <p>
                  (ii) supprimer tout Service en lien avec le manquement ou l’infraction
                  considéré(e), en totalité ou en partie ;
                </p>
                <p>(iii) prendre toutes mesures appropriées et engager toute action en justice ;</p>
                <p>
                  (iv) avertir le cas échéant les autorités compétentes, coopérer avec elles et leur
                  fournir toutes les informations utiles à la recherche et à la répression
                  d’activités illégales ou illicites.
                </p>
              </div>
              <div>
                <p>
                  <strong>26. Effet de la fin du Contrat et réversibilité</strong>
                </p>
                <p>
                  La fin du Contrat, quelle qu’en soit la cause, hormis lorsqu’elle est due à un cas
                  de force majeure dans les conditions définies à l’article « Force majeure »,
                  entrainera la fin des Services et le Client devra cesser sans délai toute
                  utilisation de la Plateforme et remettre à XTRAMILE tous programmes et documents
                  relatifs à celle-ci. Elle sera sans incidence sur les dispositions des présentes
                  ayant vocation à perdurer au-delà, et notamment les articles « Propriété
                  intellectuelle », « Effets de la fin du Contrat et réversibilité » et
                  « Confidentialité ».
                </p>
                <p>
                  Sur demande, XTRAMILE pourra procéder à la réversibilité de ces données afin de
                  permettre au Client, ou à tout prestataire de son choix de reprendre les Services
                  objet des présentes dans des conditions d’exploitation normales assurant la
                  continuité desdits Services, sur le système que le Client aura sélectionné.
                </p>
                <p>
                  L’ensemble des services liés à la réversibilité fera l’objet d’un devis par
                  XTRAMILE.
                </p>
                <p>
                  XTRAMILE s’engage également à procéder à la destruction des données et
                  informations du Client et à ne pas conserver de copie. Le Client s’engage à
                  collaborer activement avec XTRAMILE afin de faciliter la récupération des données
                  et informations.
                </p>
                <p>
                  Il est entendu de convention expresse entre les parties que XTRAMILE sera
                  déchargée de son obligation de procéder aux Services de réversibilité visés
                  ci-dessus tant que le Client n’aura pas réglé l’intégralité des factures émises
                  par XTRAMILE au titre de l’exécution des présentes.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>27. Modifications</strong>
                </p>
                <p>
                  XTRAMILE se réserve la faculté de modifier à tout moment les présentes conditions
                  générales.
                </p>
                <p>
                  Le Client sera informé de ces modifications par tout moyen écrit utile, y compris
                  par email 2 (deux) mois au moins avant leur entrée en vigueur.
                </p>
                <p>
                  Une fois entrées en vigueur, les conditions générales modifiées s’appliqueront à
                  compter de la date de renouvellement de l’Abonnement.
                </p>
                <p>
                  Si le Client n’accepte pas les nouvelles conditions générales, il doit résilier le
                  Contrat selon les modalités prévues à l’article « Durée du Contrat, résiliation ».
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>28. Signature éléctronique</strong>
                </p>
                <p>
                  Il est entendu entre les parties que le Contrat pourra être signé par tout moyen
                  électronique, les parties reconnaissant la fiabilité du procédé lui conférant
                  ainsi la même valeur juridique qu&apos;une signature manuscrite au sens de la loi.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>23. Loi applicable et juridiction</strong>
                </p>
                <p>
                  Le Contrat est soumis au droit français et sera régi et interprété selon ce droit.
                </p>
                <p>
                  Tout litige pouvant naître à l&apos;occasion de sa validité, de son interprétation
                  ou de son exécution sera soumis à la compétence exclusive du Tribunal de Commerce
                  de Paris (France), sauf règle de procédure impérative contraire.
                </p>
              </div>
              <div style={{ marginTop: '80px' }}>
                <h5 className={styles.subtitle}>Annexe</h5>
                <h5 className={styles.subtitle}>DPA</h5>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>1. Objet</strong>
                </p>
                <p>
                  Les présentes clauses ont pour objet de définir les conditions dans lesquelles
                  XTRAMILE s’engage à effectuer pour le compte du responsable de traitement les
                  opérations de traitement de données à caractère personnel définies ci-après. Dans
                  le cadre de leurs relations contractuelles, les parties s’engagent à respecter la
                  réglementation en vigueur applicable au traitement de données à caractère
                  personnel et, en particulier, le règlement (UE) 2016/679 du Parlement européen et
                  du Conseil du 27 avril 2016 applicable à compter du 25 mai 2018 (ci-après le «
                  règlement européen sur la protection des données »).
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>
                    2. Description du traitement faisant l&apos;objet de la sous-traitance
                  </strong>
                </p>
                <p>
                  XTRAMILE (ci-après le « <strong>Sous-traitant</strong> ») est autorisé à traiter
                  pour le compte du Client (ci-après le « <strong>Responsable de traitement</strong>
                   ») les données à caractère personnel nécessaires pour fournir ses services.
                </p>
                <p>
                  La nature des opérations réalisées sur les données est : la collecte et
                  conservation des données personnelles.
                </p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>
                        Collecte : le Responsable de traitement, est le seul à décider de la nature,
                        du but et de l’utilisation des informations personnelles saisies que ce soit
                        des informations générales (nom, prénom, date de naissance), de contact
                        (adresse, téléphone), ou bien plus spécifiques (champs personnalisés de
                        clients) ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Conservation : le stockage des données saisies est réalisé par le
                        sous-traitant sur des serveurs sécurisés localisés en Europe (cf. ci-après).
                      </span>
                    </p>
                  </li>
                </ul>
                <p>La ou les finalités du traitement sont :</p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>
                        la gestion du service de recrutement pour les Candidats qui souhaitent
                        postuler à des annonces d&apos;emploi postées par le Responsable de
                        traitement ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        le pilotage d’évènements RH relatifs aux salariés du Responsable de
                        traitement.
                      </span>
                    </p>
                  </li>
                </ul>
                <p>
                  Les données à caractère personnel traitées sont : nom, prénom, email, téléphone,
                  expérience professionnelle, formation, compétences, adresse IP.
                </p>
                <p>
                  Les catégories de personnes concernées sont les Candidats qui souhaitent postuler
                  à une offre d’emploi publiée par le Responsable de traitement et les salariés du
                  Responsable de traitement.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>3. Durée du traitement</strong>
                </p>
                <p>
                  Le traitement est conclu pour une durée nécessaire à la réalisation des Services
                  par le Sous-traitant. Le traitement prendra également fin lorsque le Responsable
                  du traitement décide de fermer son Compte. Une demande de fermeture de Compte (y
                  compris la suppression de l’information) peut être envoyée par mail à tout moment
                  à <span style={{ textDecoration: 'underline' }}>rgpd@myxtramile.com</span>.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>
                    4. Obligations du Sous-traitant vis-à-vis du Responsable de traitement
                  </strong>
                </p>
                <p>Le Sous-traitant s&apos;engage à : </p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>
                        Traiter les données uniquement pour les seules finalités qui font l’objet de
                        la sous-traitance ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Traiter les données conformément aux instructions documentées du Responsable
                        de traitement. Il est précisé sur ce point que tout paramétrage, par le
                        Responsable de traitement, de la Plateforme, sera réputé valoir instruction
                        du Responsable de traitement, les données recueillies sur la Plateforme et
                        les équipements informatiques du Sous-traitant constituant une preuve entre
                        les parties à cet égard.
                      </span>
                    </p>
                    <p>
                      <span>
                        Traiter les données conformément aux instructions documentées du Responsable
                        de traitement. Il est précisé sur ce point que tout paramétrage, par le
                        Responsable de traitement, de la Plateforme, sera réputé valoir instruction
                        du Responsable de traitement, les données recueillies sur la Plateforme et
                        les équipements informatiques du Sous-traitant constituant une preuve entre
                        les parties à cet égard.
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Garantir la confidentialité des données à caractère personnel traitées dans
                        le cadre du Contrat ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Veiller à ce que les personnes autorisées à traiter les données à caractère
                        personnel en vertu du Contrat :
                        <ul className={styles.listEL}>
                          <li>
                            <p>
                              s’engagent à respecter la confidentialité ou soient soumises à une
                              obligation légale appropriée de confidentialité ;
                            </p>
                          </li>
                          <li>
                            <p>
                              reçoivent la formation nécessaire en matière de protection des données
                              à caractère personnel ;
                            </p>
                          </li>
                        </ul>
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Prendre en compte, s’agissant de ses outils, produits, applications ou
                        services, les principes de protection des données dès la conception et de
                        protection des données par défaut.
                      </span>
                    </p>
                  </li>
                </ul>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>5. Sous-traitance</strong>
                </p>
                <p>
                  Le Sous-traitant peut faire appel à un autre sous-traitant (ci-après le «
                  <strong>Sous-traitant ultérieur</strong> ») pour mener des activités de traitement
                  spécifiques tel que notamment la gestion des accès.
                </p>
                <p>
                  Dans ce cas, il informe préalablement et par écrit le Responsable de traitement de
                  tout changement envisagé concernant l’ajout ou le remplacement d’autres
                  sous-traitants.
                </p>
                <p>
                  Cette information doit indiquer clairement les activités de traitement
                  sous-traitées, l’identité et les coordonnées du sous-traitant et les dates du
                  contrat de sous-traitance.
                </p>
                <p>
                  Le Responsable de traitement dispose d’un délai minium de 1 (un) mois à compter de
                  la date de réception de cette information pour présenter ses objections. Cette
                  sous-traitance ne peut être effectuée que si le Responsable de traitement n&pos;a
                  pas émis d&apos;objection pendant le délai convenu. Le Sous-traitant ultérieur est
                  tenu de respecter les obligations des présentes pour le compte et selon les
                  instructions du Responsable de traitement. Il appartient au Sous-traitant initial
                  de s’assurer que le Sous-traitant ultérieur présente les mêmes garanties
                  suffisantes quant à la mise en œuvre de mesures techniques et organisationnelles
                  appropriées de manière à ce que le traitement réponde aux exigences du règlement
                  européen sur la protection des données. Si le Sous-traitant ultérieur ne remplit
                  pas ses obligations en matière de protection des données, le Sous-traitant initial
                  demeure pleinement responsable devant le Responsable de traitement de l’exécution
                  par l’autre sous-traitant de ses obligations.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>6. Droit d&apos;information des personnes concernées</strong>
                </p>
                <p>
                  Il appartient au Responsable de traitement de fournir l’information aux personnes
                  concernées par les opérations de traitement au moment de la collecte.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>7. Exercice des droits des personnes</strong>
                </p>
                <p>
                  Dans la mesure du possible, le Sous-traitant doit aider le Responsable de
                  traitement à s’acquitter de son obligation de donner suite aux demandes d’exercice
                  des droits des personnes concernées : droit d’accès, de rectification,
                  d’effacement et d’opposition, droit à la limitation du traitement, droit à la
                  portabilité des données, droit de ne pas faire l’objet d’une décision individuelle
                  automatisée (y compris le profilage). Lorsque les personnes concernées exercent
                  auprès du Sous-traitant des demandes d’exercice de leurs droits, le sous-traitant
                  doit adresser ces demandes dès réception par courrier électronique au Responsable
                  de traitement par tout moyen et notamment par mail.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>8. Notification des violations de données à caractère personnel</strong>
                </p>
                <p>
                  Le Sous-traitant notifie au Responsable de traitement toute violation de données à
                  caractère personnel dans un délai maximum de 48 (quarante-huit) heures après en
                  avoir pris connaissance et par email.
                </p>
                <p>
                  Cette notification est accompagnée de toute documentation utile afin de permettre
                  au Responsable de traitement, si nécessaire, de notifier cette violation à
                  l’autorité de contrôle compétente. Après accord du Responsable de traitement, le
                  Sous-traitant notifie à l’autorité de contrôle compétente (la CNIL), au nom et
                  pour le compte du Responsable de traitement, les violations de données à caractère
                  personnel dans les meilleurs délais et, si possible, 72 (soixante-douze) heures au
                  plus tard après en avoir pris connaissance, à moins que la violation en question
                  ne soit pas susceptible d’engendrer un risque pour les droits et libertés des
                  personnes physiques.
                </p>
                <p>La notification contient au moins : </p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>
                        la description de la nature de la violation de données à caractère personnel
                        y compris, si possible, les catégories et le nombre approximatif de
                        personnes concernées par la violation et les catégories et le nombre
                        approximatif d&apos;enregistrements de données à caractère personnel
                        concernés ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        le nom et les coordonnées du délégué à la protection des données ou
                        d&apos;un autre point de contact auprès duquel des informations
                        supplémentaires peuvent être obtenues ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        la description des conséquences probables de la violation de données à
                        caractère personnel ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        la description des mesures prises ou que le responsable du traitement
                        propose de prendre pour remédier à la violation de données à caractère
                        personnel, y compris, le cas échéant, les mesures pour en atténuer les
                        éventuelles conséquences négatives.
                      </span>
                    </p>
                  </li>
                </ul>
                <p>
                  Si, et dans la mesure où il n’est pas possible de fournir toutes ces informations
                  en même temps, les informations peuvent être communiquées de manière échelonnée
                  sans retard indu.
                </p>
                <p>
                  Après accord du Responsable de traitement, le Sous-traitant communique, au nom et
                  pour le compte du Responsable de traitement, la violation de données à caractère
                  personnel à la personne concernée dans les meilleurs délais, lorsque cette
                  violation est susceptible d&apos;engendrer un risque élevé pour les droits et
                  libertés d&apos;une personne physique.
                </p>
                <p>
                  La communication à la personne concernée décrit, en des termes clairs et simples,
                  la nature de la violation de données à caractère personnel et contient au moins :
                </p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>
                        la description de la nature de la violation de données à caractère personnel
                        y compris, si possible, les catégories et le nombre approximatif de
                        personnes concernées par la violation et les catégories et le nombre
                        approximatif d&apos;enregistrements de données à caractère personnel
                        concernés ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        le nom et les coordonnées du délégué à la protection des données ou
                        d&apos;un autre point de contact auprès duquel des informations
                        supplémentaires peuvent être obtenues ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        la description des conséquences probables de la violation de données à
                        caractère personnel ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        la description des mesures prises ou que le responsable du traitement
                        propose de prendre pour remédier à la violation de données à caractère
                        personnel, y compris, le cas échéant, les mesures pour en atténuer les
                        éventuelles conséquences négatives.
                      </span>
                    </p>
                  </li>
                </ul>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>
                    9. Aide du Sous-traitant dans le cadre du respect par le Responsable de
                    traitement de ses obligations
                  </strong>
                </p>
                <p>
                  Le Sous-traitant aide le Responsable de traitement pour la réalisation d’analyses
                  d’impact relative à la protection des données.
                </p>
                <p>
                  Le Sous-traitant aide le Responsable de traitement pour la réalisation de la
                  consultation préalable de l’autorité de contrôle.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>10. Mesures de sécurité</strong>
                </p>
                <p>
                  Le Sous-traitant s’engage à mettre en œuvre les mesures de sécurité suivantes :
                </p>
                <ul>
                  <li>
                    <strong>Accès sécurisé et confidentialité </strong>
                  </li>
                </ul>
                <p>
                  La navigation sur la plateforme du Sous-traitant est privée et protégée grâce aux
                  technologies SSL (https). Aucune autre personne à part le Responsable de
                  traitement (ou toute autre personne désignée par le Responsable de traitement)
                  n’aura accès aux informations saisies sur la Plateforme.
                </p>
                <p>
                  Mot de passe : seul le Responsable du traitement a accès à ses données. La gestion
                  des accès est déléguée à un partenaire de confiance. L’utilisation d’un mot de
                  passe personnel, confidentiel et sécurisé est obligatoire (au moins huit
                  caractères, lettres et chiffres).
                </p>
                <p>
                  Le Sous-traitant ne connaît pas le mot de passe et donc n’est pas en mesure de le
                  communiquer. En cas d’oubli, le Responsable du traitement peut choisir de recevoir
                  un email lui permettant de choisir un nouveau mot de passe.
                </p>
                <p>
                  Il est interdit de partager les informations de connexion à des tiers. Le
                  Sous-traitant met à disposition des accès sécurisés pour des tiers autorisés (i.e
                  comptable, équipe technique et support).
                </p>
                <p>
                  Les moyens permettant de garantir la sécurité, confidentialité, l&apos;intégrité,
                  la disponibilité et la résilience constantes des systèmes et des services de
                  traitement pour lesquels le Sous-traitant s’appuie sur les services gérés par
                  Microsoft Azure.
                </p>
                <ul>
                  <li>
                    <strong>Conservation des données</strong>
                  </li>
                </ul>
                <p>
                  Toutes les données saisies sont stockées dans des serveurs hautement sécurisés et
                  des copies de sécurité sont réalisées sur des serveurs pour diminuer au maximum le
                  risque de perte des données. Cf
                  <a href="https://privacy.microsoft.com/fr-fr/privacystatement">
                    politique de confidentialité de Microsoft
                  </a>
                  .
                </p>
                <ul>
                  <li>
                    <strong>Protection du logiciel</strong>
                  </li>
                </ul>
                <p>
                  En outre, le Sous-traitant réalise de tests de sécurité, des correctifs et des
                  mises à jour régulières afin de garantir le bon fonctionnement du service. En cas
                  de panne du système, le Sous-traitant s’engage à rétablir le système sous 48
                  heures.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>11. Sort des données</strong>
                </p>
                <p>
                  Au terme du Contrat, le Sous-traitant s’engage à détruire toutes les données à
                  caractère personnel.
                </p>
                <p>
                  Le renvoi doit s’accompagner de la destruction de toutes les copies existantes
                  dans les systèmes d’information du Sous-traitant. Une fois les copies détruites,
                  le Sous-traitant doit justifier par écrit de la destruction.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>12. Délégue à la protection des données</strong>
                </p>
                <p>
                  Le Sous-traitant communique au Responsable de traitement le nom et les coordonnées
                  de son délégué à la protection des données, s’il en a désigné un conformément à
                  l’article 37 du règlement européen sur la protection des données.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>13. Registre des catégories d’activités de traitement</strong>
                </p>
                <p>
                  Le Sous-traitant déclare tenir par écrit un registre de toutes les catégories
                  d’activités de traitement effectuées pour le compte du Responsable de traitement.
                </p>
                <p>
                  Le Responsable de traitement peut demander le registre des activités de traitement
                  pour consultation à l’adresse
                  <span style={{ textDecoration: 'underline' }}>rgpd@myxtramile.com</span>.
                </p>
                <p>Ce registre doit contenir :</p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>
                        le nom et les coordonnées du Responsable de traitement pour le compte duquel
                        il agit, des éventuels sous-traitants et, le cas échéant, du délégué à la
                        protection des données ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        les catégories de traitements effectués pour le compte du Responsable du
                        traitement;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        le cas échéant, les transferts de données à caractère personnel vers un pays
                        tiers ou à une organisation internationale, y compris l&apos;identification
                        de ce pays tiers ou de cette organisation internationale et, dans le cas des
                        transferts visés à l&apos;article 49, paragraphe 1, deuxième alinéa du
                        règlement européen sur la protection des données, les documents attestant de
                        l&apos;existence de garanties appropriées ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        dans la mesure du possible, une description générale des mesures de sécurité
                        techniques et organisationnelles, y compris entre autres, selon les besoins
                        : le chiffrement des données à caractère personnel, des moyens permettant de
                        garantir la confidentialité, l&apos;intégrité, la disponibilité et la
                        résilience constantes des systèmes et des services de traitement; des moyens
                        permettant de rétablir la disponibilité des données à caractère personnel et
                        l&apos;accès à celles-ci dans des délais appropriés en cas d&apos;incident
                        physique ou technique; une procédure visant à tester, à analyser et à
                        évaluer régulièrement l&apos;efficacité des mesures techniques et
                        organisationnelles pour assurer la sécurité du traitement.
                      </span>
                    </p>
                  </li>
                </ul>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>14. Documentation</strong>
                </p>
                <p>
                  Le Sous-traitant met à la disposition du responsable de traitement la
                  documentation nécessaire pour démontrer le respect de toutes ses obligations et
                  pour permettre la réalisation d&apos;audits, y compris des inspections, par le
                  Responsable de traitement ou un autre auditeur qu&apos;il a mandaté contribuer à
                  ces audits.
                </p>
                <p>
                  Dans le cadre de tels audits, le Responsable de traitement ou l’auditeur mandaté
                  par lui ne seront pas autorisés à accéder aux secrets d’affaires du Sous-traitant,
                  aux informations stratégiques de celle-ci ou aux informations que le Sous-traitant
                  s’est engagé à garder confidentielles. Le Sous-traitant pourra s’opposer à toute
                  mesure de contrôle du Responsable de traitement ou de l’auditeur mandaté par lui
                  qui serait susceptible de leur donner accès à de telles données ou informations,
                  sans que le Responsable de traitement puisse effectuer la moindre réclamation à
                  cet égard. Le Responsable de traitement veillera par ailleurs en tout état de
                  cause à ce que l’auditeur et, plus généralement, le personnel procédant audit
                  contrôle soient soumis à des obligations de confidentialité appropriées.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>
                    15. Obligations du responsable de traitement vis à-vis du sous-traitant
                  </strong>
                </p>
                <p>Le Responsable de traitement s’engage à : </p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>
                        Fournir au Sous-traitant les données visées au II des présentes clauses ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Veiller, au préalable et pendant toute la durée du traitement, au respect
                        des obligations prévues par le règlement européen sur la protection des
                        données de la part du Sous-traitant ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Superviser le traitement, y compris réaliser les audits et les inspections
                        auprès du Sous-traitant.
                      </span>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            <div className={styles.text}>
              <p className={styles.titleBlock}>
                <strong>1. Object</strong>
              </p>
              <div>
                The company Xtramile (hereinafter &quot;XTRAMILE&quot;) offers a recruitment
                platform at the address{' '}
                <span className={styles.subtitleBlock}>www.xtramile.io</span> (hereinafter the
                &quot;Platform&quot;) allowing:
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>
                        employers (hereinafter the &quot;Customer(s)&quot;), to automate recruitment
                        processes via the following solutions, detailed below: X-Parsing, X-Ranking,
                        X-Programmatics, X-Metrics, Publication of job offers.
                      </span>
                    </p>
                  </li>
                </ul>
                <p>
                  The present general terms and conditions of use apply without restriction or
                  reservation to any order for services (hereinafter the &quot;Services&quot;) by
                  the Customer from XTRAMILE, and are intended to define their terms and conditions
                  of implementation.
                </p>
                <p>
                  These present general terms and conditions of use and their appendix constitute,
                  with the Commercial Proposal, an inseparable contractual whole (hereinafter the
                  &quot;Contract&quot;). In case of contradiction, the provisions of the Commercial
                  Proposal(s) prevail over these general terms and conditions of use. In case of
                  contradiction between different Commercial Proposals, the most recent document
                  prevails over the previous ones.
                </p>
                <p>
                  The Contract prevails over all other general or specific conditions not expressly
                  approved by XTRAMILE.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>2. Platform and Services reserved for professionals</strong>
                </p>
                <p>
                  The Platform and the Services are designed and intended for professional use and
                  are therefore intended exclusively for professionals in the context of their
                  activity.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>
                    3. Subscription to the Services and acceptance of the general terms and
                    conditions of use
                  </strong>
                </p>
                <p>
                  <strong>3.1 Subscription to the Services</strong>
                </p>
                <p>
                  The Services are provided in the form of a subscription (hereinafter «
                  <strong> the Subscription</strong> »), according to one of the following terms:
                </p>
                <p className={styles.subtitleBlock}>3.1.1 Website formula</p>
                <p>The Customer takes out a Subscription on the website www.xtramile.io.</p>
                <p className={styles.subtitleBlock}>3.1.2 API Formula</p>
                <p>
                  XTRAMILE can provide the Customer with an API (hereinafter &quot;the API&quot;)
                  that the Customer must integrate into his computer system. For this purpose,
                  XTRAMILE communicates to the Customer, by any useful written means, in particular
                  by email, a link to access the API.
                </p>
                <p>
                  API integration documentation is provided by XTRAMILE. XTRAMILE can also provide
                  the Customer with technical support, under the conditions provided for in the
                  article &quot;Technical support&quot;.
                </p>
                <p>
                  In case of subscription to the API formula, the Customer acknowledges and accepts
                  that the use of the Platform requires in-depth technical knowledge and skills in
                  software development and integration in order to make the API work with the
                  Customer&lsquo;s systems.
                </p>
                <p>
                  <strong>3.2 Commercial Proposal</strong>
                </p>
                <p>3.2.1</p>
                <p>
                  Prior to any performance of the Services, it is the Customer&lsquo;s
                  responsibility to provide XTRAMILE with useful information and documents to enable
                  it to identify its needs and expectations. XTRAMILE will establish on this basis
                  one or more commercial proposals (hereinafter a «
                  <strong>Commercial Proposal</strong> »). The Commercial Proposal will indicate in
                  particular the content of the Services, their price, as well as their period of
                  validity.
                </p>
                <p>3.2.2</p>
                <p>
                  The Customer wishing to order the Services must validate the corresponding
                  Commercial Proposal within the time limit provided, by any useful written means
                  and in particular by email. Otherwise, the Commercial Proposal will lapse.
                </p>
                <p>3.2.3</p>
                <p>
                  Any validation of a Commercial Proposal, express or implied, implies full and
                  complete acceptance of these general terms conditions of use, in their version in
                  force on the date of the said Commercial Proposal. Any acceptance subject to
                  reservation is considered null and void. The Customer who does not agree to be
                  bound by these general terms and conditions of use must not order Services from
                  XTRAMILE.
                </p>
                <p>3.2.4</p>
                <p>
                  In case of modification of the initial Services or of a new order, a new
                  Commercial Proposal is established. The provisions of this new Commercial Proposal
                  prevail over those of the previous one. In the absence of any indication in the
                  new Commercial Proposal, the commitment periods remain identical to those of the
                  first Commercial Proposal.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>4. Conditions of access and use of the Platform </strong>
                </p>
                <p>
                  <strong>4.1 Legal capacity</strong>
                </p>
                <p>The Platform and the Services are accessible:</p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>
                        to any natural person with full legal capacity to commit themselves under
                        these general terms and conditions. A natural person who does not have full
                        legal capacity can only access the Platform and the Services with the
                        consent of their legal representative;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        to any legal person acting through a natural person having the legal
                        capacity to contract in the name and on behalf of the legal person.
                      </span>
                    </p>
                  </li>
                </ul>
                <p>
                  <strong>4.2 Means of access to the Platform</strong>
                </p>
                <p>
                  Access to the Platform requires the possession of suitable, efficient and secure
                  computer hardware, software and Internet connection.
                </p>
                <p>
                  Access to the Platform and its optimal use require the following minimum technical
                  configuration:
                </p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>
                        the Platform is optimised for a screen resolution of 1024 x 768 pixels,
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        for Internet Explorer 9 and higher, Chrome 32 and higher, Firefox 26 and
                        higher and Safari 5 and higher,
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        a means of connection to the Internet (allowing access to the Platform).
                      </span>
                    </p>
                  </li>
                </ul>
                <p>
                  Consequently, Customers are personally responsible for their choice of equipment
                  and Internet connection, and XTRAMILE can in no way be held responsible for the
                  consequences resulting from the installation and use of the Customer&lsquo;s
                  computer hardware or Internet browser.
                </p>
                <p>
                  <strong>4.3 Account creation</strong>
                </p>
                <p>
                  When subscribing to a Subscription, the Customer opens an account on the Platform
                  (hereinafter the &quot;Account&quot;) by completing the form provided for this
                  purpose on the Platform. For this purpose, the Customer must provide all the
                  information marked as mandatory. Any incomplete registration will not result in
                  the opening of the Account.
                </p>
                <p>
                  The Customer will receive confirmation of his registration by XTRAMILE. Any
                  incomplete registration will not be validated. Once the registration has been
                  validated by XTRAMILE, the Customer will have access to his Account. He will thus
                  be able to access his Account at any time after having identified himself using
                  his login ID and password.
                </p>
                <p>
                  Opening the Account allows the Customer to manage his use of the Services, in a
                  form and according to the technical means that XTRAMILE deems most appropriate.
                </p>
                <p>
                  The Customer guarantees that all the information he provides in the form is
                  accurate, up to date and sincere and is not vitiated by any misleading aspect. He
                  undertakes to update this information in the event of changes, so that it always
                  meets the above criteria.
                </p>
                <p>
                  The Customer agrees to create only one Account for a profile and to personally use
                  the Services, and not to allow any third party to use them in his place or on his
                  behalf.
                </p>
                <p>
                  The Customer is informed and accepts that the information entered for the purpose
                  of creating or updating his Account is valid as proof of his identity. The
                  information entered by the Customer is binding upon validation.
                </p>
                <p>
                  The Account is managed by one or more administrator(s) (hereinafter the «
                  <strong>Adiministrator(s)</strong> »). The Administrator can create one or more
                  Account accesses for other users, who will benefit from limited functionality. The
                  number of accesses that can be created by the Customer are specified in the
                  Commercial Proposal.
                </p>
                <p>
                  The Administrator is solely responsible for creating these accesses and the people
                  to whom he assigns them. It is forbidden to use the option of creating an access
                  in order to give access to the Platform, directly or indirectly, to a third party
                  competing with XTRAMILE.
                </p>
                <p>
                  The Customer must not, without this list being exhaustive, communicate,
                  disseminate, share, make accessible in any way whatsoever his password and/or his
                  username to third parties. He is responsible for maintaining the confidentiality
                  and security of his username and password, any access to the Platform using the
                  latter being deemed to have been made by the Customer. The latter must immediately
                  contact XTRAMILE through the contact details mentioned in the article
                  &quot;Contact details&quot; hereof if he notices that his Customer Account has
                  been used without his knowledge. He recognises XTRAMILE&lsquo;s right to take all
                  appropriate measures in such a case.
                </p>
                <p>
                  At any time, the Customer can request the deletion of his Account. In this case,
                  the Customer will no longer be able to use the Services.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>5. License on the Platform</strong>
                </p>
                <p>
                  The Customer has access to the Services through a license to use the Platform
                  (hereinafter the &quot;License&quot;). The characteristics of the License are
                  defined below.
                </p>
                <p>
                  <strong>5.1 Scope of the License</strong>
                </p>

                <p>
                  XTRAMILE grants the Customer, upon payment of the price defined in the article
                  &quot;Financial conditions&quot;, a non-exclusive, personal and non-transferable
                  License to use the Platform, in its version existing on the date of the present
                  general terms and conditions of use, and in any potential future versions, for the
                  sole purpose of providing the Services.
                </p>
                <p>
                  The Customer can give access to the Platform to the number of users indicated in
                  the Commercial Proposal (hereinafter the <strong>&quot;Users&quot;</strong>).
                </p>
                <p>
                  The License is granted to the Customer for his own needs, understood as the needs
                  relating to his activity and for the Users only. The Customer is prohibited from
                  assigning or transferring the benefit thereof to any third party.
                </p>
                <p>The Customer is formally prohibited from:</p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>
                        reproducing, arranging, adapting, permanently or temporarily, one of the
                        elements of the Platform in whole or in part, by any means and in any form;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        carrying out any form of commercial exploitation of the Platform with third
                        parties;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        selling, assigning, supplying, lending, renting, transferring or
                        distributing the Platform, granting sub-licenses or other rights of use, or
                        more generally, communicating to a third party or to an affiliate of all or
                        part of the Platform;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        modifying the Platform and/or merging, integrating all or part of the
                        Platform into others computer programs;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        compiling the Platform, decompiling it, disassembling, translating,
                        analysing, reverse engineering or attempting to do so, except within the
                        limits authorised by law and in particular by Article L. 122-6-1 of the
                        French Intellectual Property Code;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>using the Platform to develop a competing product;</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        and more generally carrying out any act of use or exploitation of the
                        Platform not included in the License.
                      </span>
                    </p>
                  </li>
                </ul>
                <p>
                  The License is granted for the period defined in the article &quot;Duration of the
                  Contract, termination&quot; and subject to full effective payment of the price
                  agreed in article &quot;Financial conditions&quot;.
                </p>
                <p>
                  <strong>5.2 Assistance à la mise en place de la Licence </strong>
                </p>
                <p>
                  XTRAMILE assists the Customer in setting up the Platform as specified in the
                  Commercial Proposal (hereinafter the« <strong>Setup</strong>
                   »).
                </p>
                <p>
                  Any additional Customer request relating to Setup and/or the addition of new
                  Services and or new features will be the subject of a specific order. In case of
                  agreement upon conditions, in particular financial, for adapting the License,
                  these will be the subject of an additional Commercial Proposal.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>6. Description of Services</strong>
                </p>
                <p>
                  Depending on his Subscription, the Customer has access to a certain number of
                  defined Services on the Platform, in a form and according to the functionalities
                  and technical means that XTRAMILE deems the most appropriate, and in particular:
                </p>
                <p>
                  <strong>6.1 Database</strong>
                </p>
                <p>
                  XTRAMILE provides Customers with access to a database of Candidates, including
                  Candidate profiles.
                </p>
                <p>
                  <strong>6.2 Publication of job offers</strong>
                </p>
                <p>
                  XTRAMILE publishes Customer job offers on Facebook, Instagram, on the Platform
                  and/or on any other platforms and social networks desired by Customers.
                </p>
                <p>
                  <strong>6.3 X-Metrics Tool</strong>
                </p>
                <p>
                  X-Metrics is a dashboard that helps human resources measure the performance and
                  therefore the budgets of the various digital sourcing levers set up within
                  companies.
                </p>
                <p>
                  The Customer can thus have a more detailed reading of the ROI of the distribution
                  channels used thanks to cross-analyses such as the cost of recruitment by profile,
                  by region and by level of experience.
                </p>
                <p>
                  <strong>6.4 X-Programmatics Tool</strong>
                </p>
                <p>
                  X-Programmatics allows you to automatically control the distribution of ads and
                  predict the cost of acquisition by profile, region and level of experience.
                </p>
                <p>
                  <strong>6.5 X-Ranking Tool</strong>
                </p>
                <p>
                  X-Ranking makes it possible to automatically sort applications and job offers
                  thanks to a technology based on semantics.
                </p>
                <p>
                  <strong>6.6 X-Parsing Tool</strong>
                </p>
                <p>
                  X-parsing analyses job postings and CVs to extract key information and organise
                  them into named entities that are easily identifiable.
                </p>
                <p>
                  <strong>6.7 Other services</strong>
                </p>
                <p>
                  XTRAMILE reserves the right to offer any other Service that it deems useful, in a
                  form and according to the functionalities and technical means that it deems most
                  appropriate to provide said Services.
                </p>
                <p className={styles.titleBlock}>
                  <strong>7. Specific developments</strong>
                </p>
                <p>
                  XTRAMILE may also be required to offer the Customer specific developments
                  (hereinafter the &quot;Specific Developments&quot;). The Specific Developments
                  carried out, as well as the indicative schedule and the acceptance process are,
                  where applicable, described in a Commercial Proposal.
                </p>
                <p>
                  <strong>7.1 Specifications</strong>
                </p>
                <p>
                  The parties undertake to draw up together and by mutual agreement a specification
                  defining the operational and technical elements of the Specific Developments
                  (hereinafter the <strong>&quot;Specifications&quot;</strong>).
                </p>
                <p>
                  The Specifications will determine in particular the Customer&lsquo;s needs, the
                  expected specifications, the various development phases, the expected deliverables
                  and the completion deadlines, these being mentioned for information only (as such,
                  the responsibility of XTRAMILE can by no means be incurred in the event of late
                  delivery with regards to the stated deadline).
                </p>
                <p>
                  Specific Developments can only begin once the parties have definitively validated
                  the Specifications. Its final version will be drawn up in 2 (two) copies, dated
                  and signed by the parties. Each party will keep 1 (one) copy.
                </p>
                <p>
                  <strong>7.2 Intellectual property relating to Specific Developments</strong>
                </p>
                <p>
                  The Customer only has a User License for Specific Developments, under identical
                  terms to those defined above for the Solution.
                </p>
                <p>
                  The License does not grant the Customer any intellectual property rights over the
                  Specific Developments, which remain the full and exclusive property of XTRAMILE.
                </p>
                <p>
                  The right of use is granted to the Customer subject to payment of the price of the
                  Specific Developments as defined in the terms agreed in the article
                  &quot;Financial conditions&quot;.
                </p>
                <p>
                  In addition, the Customer expressly undertakes not to use, copy or reproduce,
                  directly or indirectly, all or part of the intellectual property rights of which
                  XTRAMILE is the holder, in particular under trademark law.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>8. Maintenance and technical support of the Platform </strong>
                </p>
                <p>
                  <strong>8.1 Corrective maintenance</strong>
                </p>
                <p>
                  XTRAMILE undertakes to correct, during the term of the Contract, any anomaly
                  identified on the Platform. The Customer must report this anomaly to XTRAMILE at
                  the following address support@myxtramile.com, providing as much information as
                  possible. If the anomaly does not seem to come from the Platform, XTRAMILE will
                  immediately inform the Customer. Otherwise, it will take all the necessary means
                  to correct the anomaly within a period that will be estimated based on the
                  corrections to be made.
                </p>
                <p>
                  XTRAMILE cannot be held responsible for any maintenance in the following cases:
                </p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>
                        Customer&lsquo;s refusal to collaborate with XTRAMILE in resolving anomalies
                        and in particular to respond to questions and requests for information;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        use of the Platform in a manner inconsistent with its intended purpose or
                        with its documentation;{' '}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        unauthorised modification of the Platform by the Customer or by a third
                        party;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        failure of the Customer to fulfil its obligations under the Contract;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        implementation of all software packages, software or operating system not
                        compatible with the Platform;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>failure of electronic communication networks;</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>voluntary act of degradation, malicious intent, sabotage;</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        deterioration due to a case of force majeure as defined hereinafter or to
                        improper use of the Platform.
                      </span>
                    </p>
                  </li>
                </ul>
                <p>
                  Any intervention in the cases listed above will be the subject of an additional
                  Commercial Proposal.
                </p>
                <p>
                  <strong>8.2 Evolutive maintenance</strong>
                </p>
                <p>
                  Changes or updates to the Platform and the Services are not included in the price
                  defined in the article &quot;Financial conditions&quot;. If the Customer wishes to
                  benefit from it, this will have to be the subject of a new Commercial Proposal.
                </p>
                <p>
                  Nevertheless, XTRAMILE reserves the right, at its sole discretion, to make certain
                  new features or updates available to the Customer free of charge.
                </p>
                <p>
                  It reserves the right to limit or suspend the operation of the Platform during
                  maintenance operations. It will inform the Customer in advance by any useful means
                  of the performance of these operations.
                </p>
                <p>
                  <strong>8.3 Technical support</strong>
                </p>
                <p>
                  To the exception of malfunctions and for any question related to the integration
                  of the API, training in the use of the Services and more generally in the use of
                  the Services, XTRAMILE offers the Customer technical support.
                </p>
                <p>
                  XTRAMILE will respond as soon as possible depending on the nature of the request,
                  it being understood that technical support is open Monday to Friday from 9a.m. to
                  12p.m. and from 1p.m. to 6p.m., Paris time, France, excluding bank holidays in
                  France (hereinafter <strong>&quot;Working Hours&quot;</strong>).
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>9. Hosting</strong>
                </p>
                <p>
                  XTRAMILE undertakes to ensure, under the terms of an obligation of means, the
                  hosting of the Platform, and of the data entered by the Customers as part of their
                  use of the Services, and exchanged with them via the Platform, in accordance with
                  the uses of the profession and the state of the art, on its servers located on a
                  territory of the European Union.
                </p>
                <p>
                  As part of this hosting service, XTRAMILE undertakes to provide the Customer with
                  sufficient storage and processing capacities for the operation of the Platform.
                </p>
                <p>
                  XTRAMILE undertakes to implement all technical means, in accordance with the state
                  of the art, necessary to ensure the security of access to the Platform and the
                  Services, relating to the protection and monitoring of infrastructures, the
                  control of physical and/or intangible access to said infrastructures, as well as
                  the implementation of detection, prevention and recovery measures to protect
                  servers from malicious acts.
                </p>
                <p>
                  XTRAMILE also undertakes to take all useful precautions, with regard to the nature
                  of the data and the risks presented by the automated processing of data carried
                  out for the needs of the Services, to preserve the security of the data, and in
                  particular to prevent them from being distorted, damaged or accessed by
                  unauthorised third parties.
                </p>
              </div>
              <div></div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>10. Service level guarantee</strong>
                </p>
                <p>
                  XTRAMILE undertakes to ensure the permanence, continuity and quality of access to
                  the Platform and the Services, as well as the operation of the Platform.
                </p>
                <p>
                  As such, XTRAMILE will apply its best efforts to maintain access to its tools 24
                  hours a day, 7 days a week and guarantee 99% availability of the Platform, except
                  in cases of force majeure, as defined hereinafter.
                </p>
                <p>
                  Given the complexity of the Internet, the unequal capacities of the different
                  subnets, the influx of Users at certain times, the various bottlenecks over which
                  XTRAMILE has no control, XTRAMILE&lsquo;s liability will be limited to the
                  functioning of its servers, the outer limits of which are formed by the connection
                  points.
                </p>
                <p>
                  XTRAMILE cannot be held responsible for (i) access speeds to its servers, (ii)
                  slowdowns external to its servers, and (iii) poor transmissions due to a failure
                  or malfunction of its networks.
                </p>
                <p>
                  If necessary, XTRAMILE reserves the right to limit or suspend access to the
                  Platform and the Services in order to carry out any scheduled maintenance
                  operation on the Platform. In this case, XTRAMILE undertakes to inform the
                  Customer at least 24 (twenty-four) hours in advance, by all useful means and in
                  particular by email sent to the Administrators, of these maintenance operations.
                  XTRAMILE will apply its best efforts to ensure that said maintenance operations do
                  not exceed 8 (eight) hours and are carried out outside of Working Hours.
                </p>
                <p>
                  In the event of a security breach requiring immediate interruption of the
                  Platform, XTRAMILE reserves the right to immediately suspend access to the
                  Platform. It will inform the Customer as soon as possible.
                </p>
                <p>
                  The Customer acknowledges and accepts that this service level guarantee does not
                  cover any failure or interruption of the Platform caused by telecom operators or
                  Internet access providers.
                </p>
                <p>
                  The Customer expressly acknowledges and accepts that the above availability rate
                  will not apply in the cases listed in the article &quot;Corrective
                  maintenance&quot;.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>11. Duration of the Contract, termination</strong>
                </p>
                <p>
                  Unless otherwise provided for in the Commercial Proposal, the Services are taken
                  out in the form of a subscription (hereinafter the{' '}
                  <strong>&quot;Subscription&quot;</strong>), which begins on the date of signature
                  of the Contract for an initial term indicated in the Commercial Proposal
                  (hereinafter the <strong>&quot;Initial Period&quot;</strong>).
                </p>
                <p>
                  The Subscription is then tacitly renewed for periods of identical duration, which
                  are indicated in the Commercial Proposal (hereinafter the{' '}
                  <strong>&quot;Subsequent Period(s)&quot;</strong>), unless terminated by XTRAMILE
                  or by the Customer at the latest :
                </p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>2 (two) weeks before the end of the Initial Period;</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>2 (two) months before the end of a Subsequent Period.</span>
                    </p>
                  </li>
                </ul>
                <p>
                  The Initial Period and the Subsequent Periods are hereinafter together referred to
                  as the &quot;Period&quot;.
                </p>
                <p>
                  The termination of the Subscription by one of the parties is made by sending a
                  request to this effect to the other party by email (to the address
                  support@myxtramile.com in the event of termination by the Customer, and to the
                  email address used when creating the Account in the event of termination by
                  XTRAMILE), indicating the desire to terminate the Subscription.
                </p>
                <p>
                  Termination of the Subscription takes effect at the end of the Period during which
                  the request is sent. It results in the closure of access to the Platform. The
                  Customer will receive an Account deletion confirmation by email.
                </p>
                <p>
                  The Customer acknowledges and accepts that in the event of termination, all of the
                  settings made by the Customer on the Platform will be lost
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>12. Financial conditions</strong>
                </p>
                <p>
                  <strong>12.1 Price</strong>
                </p>
                <p>
                  The price is indicated in the Commercial Proposal and is, unless otherwise stated,
                  expressed in Euros and excluding taxes.
                </p>
                <p>
                  Any Period started is due in its entirety and any deposit paid is non-refundable.
                </p>
                <p>
                  <strong>12.2 Price-revision</strong>
                </p>
                <p>
                  Prices are subject to revision by XTRAMILE at any time, at its sole discretion.
                  The Customer will be informed of these changes by XTRAMILE by any useful written
                  means (and in particular by email) at least 3 (three) months before the entry into
                  force of the new price.
                </p>
                <p>Once effective, the new prices will apply from the Subscription renewal date.</p>
                <p>
                  If the Customer does not accept the new prices, he may terminate his Subscription
                  in accordance with the terms set out in the article &quot;Duration of the
                  Contract, termination&quot;.
                </p>
                <p>
                  <strong>12.3 Billing and payment terms</strong>
                </p>
                <p>The subscription billing methods are indicated in the Commercial Proposal.</p>
                <p>
                  Unless otherwise stated in the Commercial Proposal, each invoice is payable by
                  bank transfer within 30 (thirty) calendar days from its issue.
                </p>
                <p>
                  <strong>12.4 Payment delays and incidents</strong>
                </p>
                <p>
                  The Customer is informed and expressly accepts that any late payment of all or
                  part of an amount due on its due date will automatically result, and without
                  prejudice to the provisions of the articles &quot;Resolution for breach&quot; and
                  &quot;Sanctions for breaches&quot; and from the day following the payment date
                  shown on the invoice:
                </p>
                <p>
                  (i) the forfeiture of the term of all sums due by the Customer and their immediate
                  payability;
                </p>
                <p>
                  (ii) immediate suspension of current Services until full payment of all of the
                  sums owed by the Customer;
                </p>
                <p>
                  (iii) invoicing in favour of XTRAMILE of late interest at the rate of 3 (three)
                  times the rate of the legal interest, based on the amount of all sums due by the
                  Customer and a lump sum indemnity of 40 (forty) euros for recovery costs, without
                  prejudice to an additional compensation if the recovery costs actually incurred
                  exceed this amount.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>13. Convention of proof</strong>
                </p>
                <p>The Customer expressly recognises and accepts:</p>
                <p>
                  (i) that the data collected on the Platform and the XTRAMILE IT equipment are
                  proof of the reality of the operations carried out within the framework of the
                  present general terms and conditions of use;
                </p>
                <p>
                  (ii) that this data constitutes the main mode of proof recognised by the parties.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>14. Obligations and responsibility of the Customer</strong>
                </p>
                <p>
                  Without prejudice to the other obligations provided for herein, the Customer
                  undertakes to comply with the following obligations.
                </p>
                <p>
                  <strong>14.1</strong>
                </p>
                <p>
                  The Customer undertakes, in his use of the Platform and the Services, to comply
                  with the laws and regulations in force and not to infringe the rights of third
                  parties, or public order.
                </p>
                <p>
                  <strong>14.2</strong>
                </p>
                <p>
                  The Customer declares to have received from XTRAMILE, prior to the signature of
                  the present general terms and conditions, all advice, instructions and details
                  necessary for him to subscribe to the latter in full knowledge of the facts, that
                  he thus has sufficient knowledge of the characteristics and functionalities of the
                  Platform and the Services, and that he has, prior to the present act, sufficiently
                  communicated with XTRAMILE to ensure that the Platform and the Services correspond
                  to his expectations, needs and constraints.
                </p>
                <p>
                  The Customer is solely responsible for his use of the Platform and of the
                  Services.
                </p>
                <p>
                  <strong>14.3</strong>
                </p>
                <p>
                  The Customer agrees to keep a strictly personal use of the Services. He therefore
                  refrains from assigning, granting or transferring all or part of his rights or
                  obligations hereunder to a third party, in any way whatsoever, and in particular
                  the Customer refrains from marketing the Services provided by XTRAMILE.
                </p>
                <p>
                  More generally, the Customer undertakes to cooperate actively with XTRAMILE for
                  the proper execution of the present general terms and conditions of use.
                </p>
                <p>
                  The Customer acknowledges and accepts that any document transmitted by XTRAMILE
                  constitutes part of the Services (and in particular technical documents to help
                  integrate the tools selected by the Customer).
                </p>
                <p>
                  <strong>14.4</strong>
                </p>
                <p>
                  The Customer undertakes that the content, CVs, job offers, profiles, photographs,
                  job offers or any other information added/indicated on the Platform (hereinafter
                  the &quot;Content&quot;) are lawful and do not amount to a breach of public order,
                  good morals or the rights of third parties, do not infringe any legislative or
                  regulatory provision and more generally, are in no way likely to involve the civil
                  or criminal liability of XTRAMILE.
                </p>
                <p>
                  The Customer is solely responsible for the accuracy and completeness of the
                  Content, and XTRAMILE cannot under any circumstances be held responsible for any
                  errors, typos, omissions or indications likely to mislead.
                </p>
                <p>
                  The Customer is therefore prohibited from disseminating, in particular and without
                  this list being exhaustive:
                </p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>
                        Pornographic, obscene, indecent, shocking or unsuitable for a family
                        audience, defamatory, abusive, violent, racist, xenophobic or revisionist
                        Content;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>Infringing Content;</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>Content injurious to the image of a third party;</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Content that is false, misleading or proposing or promoting illegal
                        activities, fraudulent or deceptive;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Content having the effect of reducing, disrupting or preventing the normal
                        use of Services or to interrupt or slow down the normal flow of
                        communications between Candidates and Customers, such as content harmful to
                        the computer systems of third parties (such as viruses, worms, Trojan
                        horses, etc.)
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        and more generally Content that may infringe the rights of third parties or
                        be detrimental to third parties, in any way and any form whatsoever.
                      </span>
                    </p>
                  </li>
                </ul>
                <p>If necessary, XTRAMILE reserves the right to delete this Content.</p>
                <p>
                  <strong>14.5</strong>
                </p>
                <p>
                  The Customer is informed and accepts that the use of the Platform and Services
                  require that he be connected to the Internet, and that the quality of the Services
                  depends directly on this connection, for which he is solely responsible.
                </p>
                <p>
                  <strong>14.6</strong>
                </p>
                <p>
                  The Customer shall refrain from monetising, assigning, conceding or transferring
                  all or part of his rights or obligations hereunder to any third party, including
                  the case where this third party has a direct or indirect link with the Customer,
                  in any way whatsoever.
                </p>
                <p>
                  <strong>14.7</strong>
                </p>
                <p>
                  The Customer is solely responsible with regard to XTRAMILE and, where applicable,
                  any third party, for all damages, direct or indirect, of any nature whatsoever,
                  caused by Content published, transmitted or broadcasted during the use of the
                  Services.
                </p>
                <p>
                  More generally, the Customer thus guarantees XTRAMILE against all complaints,
                  claims, actions and/or any grievance that it may suffer as a result of the
                  violation by the Customer of any of its obligations hereunder. He agrees to pay
                  XTRAMILE all costs, charges and/or penalties that it may have to bear as a result.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>15. Prohibited behaviour</strong>
                </p>
                <p>It is strictly forbidden to use the Services for the following purposes:</p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>
                        the exercise of illegal, fraudulent or infringing activities with regards to
                        the rights or safety of third parties;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        breaching of public order or violation of the laws and regulations in force;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        intrusion into the computer system of a third party or any activity likely
                        to harm, control, interfere with, or intercept all or part of a third
                        party&lsquo;s computer system, violate its integrity or security ;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        the sending of unsolicited emails and/or prospecting or commercial
                        solicitation;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        manipulations intended to improve the referencing of a third-party site;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        assistance or encouragement, in any form and in any way whatsoever, to one
                        or more of the acts and activities described above;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        and more generally any practice diverting the Services for purposes other
                        than those for which they were designed.
                      </span>
                    </p>
                  </li>
                </ul>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>16. XTRAMILE&lsquo;s obligations and liability</strong>
                </p>
                <p>
                  Without prejudice to the other obligations provided for herein, XTRAMILE
                  undertakes to comply with the following obligations.
                </p>
                <p>
                  <strong>16.1</strong>
                </p>
                <p>
                  XTRAMILE undertakes to provide the Platform and the Services with diligence and
                  according to the rules of the art, it being specified that its responsibility
                  rests on an obligation of means, which the Customer expressly acknowledges and
                  accepts.
                </p>
                <p>
                  <strong>16.2</strong>
                </p>
                <p>
                  XTRAMILE undertakes to use its best efforts to ensure the security of the
                  Platform.
                </p>
                <p>
                  XTRAMILE cannot be held responsible for any lack of vigilance on the part of
                  Customers in maintaining the confidentiality of their username and password to
                  access the Platform.
                </p>
                <p>
                  <strong>16.3</strong>
                </p>
                <p>
                  XTRAMILE undertakes to only use the elements that may be transmitted to it within
                  the framework hereof and for the sole purposes of performing the Services, and not
                  to distribute or share them with any third party whatsoever, unless expressly
                  requested or agreed upon by the Customer.
                </p>
                <p>
                  XTRAMILE s’interdit d’utiliser ces éléments à des fins de prospection ou de
                  sollicitation commerciale. Plus généralement, il s’interdit par conséquent d’en
                  réutiliser tout ou partie sous quelque forme que ce soit et dus détourner de leur
                  finalité à savoir l’exécution des Services.
                </p>
                <p>
                  <strong>16.4</strong>
                </p>
                <p>
                  XTRAMILE has no knowledge of the Content published online by Customers as part of
                  the Services for which it does not moderate, select, verify or control in any way
                  and with respect to which it solely intervenes as a hosting provider.
                </p>
                <p>
                  XTRAMILE acts as a broker in that it provides Employees with the tools and
                  technical means allowing them to enter into a relationship for recruitment
                  purposes. Its responsibility is limited to the provision of these means, as
                  described herein, and to the establishment of a contact between Candidates and
                  Customers.
                </p>
                <p>Therefore :</p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>
                        XTRAMILE cannot be held responsible for the Content, the authors of which
                        are third parties.
                      </span>
                    </p>
                    <p>
                      <span>
                        Therefore, the Customer is solely responsible for the accuracy, completeness
                        and updating of the Content.
                      </span>
                    </p>
                    <p>
                      <span>
                        Content harmful to a third party may be notified to XTRAMILE in accordance
                        with the terms provided for in article 6 I 5 of law no. 2004-575 of June
                        21st, 2004 regarding trust in the digital economy (loi pour la confiance
                        dans l’économie numérique), XTRAMILE reserving the right to take the
                        measures described in the article &quot;Penalties for breach&quot;.
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        XTRAMILE acts in its own name and does not execute any legal act in the name
                        and on behalf of Customers, who contract directly with Candidates.
                      </span>
                    </p>
                    <p>
                      <span>
                        XTRAMILE is therefore not a party to the contracts concluded between the
                        Candidates and the Customers, it can in no way be held liable for any
                        difficulties that may arise during the conclusion or execution of these
                        contracts, nor be a party to some possible disputes whatsoever. The
                        Customers thus undertake not to involve XTRAMILE in any dispute or
                        litigation whatsoever between said persons and to make their resolution
                        their personal matter.
                      </span>
                    </p>
                  </li>
                </ul>
                <p>
                  However, constantly aiming at improving the quality of the Services, XTRAMILE
                  invites Customers to send it any comments and information they may wish to bring
                  to its attention concerning the quality of transactions carried out through the
                  Services.
                </p>
                <p>
                  <strong>16.5</strong>
                </p>
                <p>
                  XTRAMILE does not guarantee the Customer that the latter will find the desired
                  Candidate for a position.
                </p>

                <p>
                  <strong>16.6</strong>
                </p>
                <p>
                  XTRAMILE undertakes to carry out regular checks to verify the functioning and
                  accessibility of the Platform.
                </p>
                <p>
                  However, XTRAMILE does not guarantee the Customer that the Platform and the
                  Services, subject to constant research to improve performance and progress in
                  particular, will be completely free from errors, defects or defaults. As such,
                  XTRAMILE reserves the right to temporarily interrupt access to the Platform for
                  maintenance reasons. Likewise, XTRAMILE cannot be held responsible for
                  difficulties or momentary impracticalities of access to the Platform which
                  originate from circumstances external to it, such as force majeure, or which are
                  due to disruption of the telecommunication networks.
                </p>

                <p>
                  <strong>16.7</strong>
                </p>
                <p>
                  XTRAMILE reserves the right, at any time and without notice, to modify the
                  information contained on the Platform as part of its updating or the correction of
                  errors or inaccuracies.
                </p>

                <p>
                  <strong>16.8</strong>
                </p>
                <p>
                  The Services provided by the X-Metrics, X-Programmatics, X-Ranking and X-Parsing
                  tools can only be rendered for:
                </p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>computer-edited Content (i.e. not handwritten);</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        data written in the Latin alphabet and/or containing decimal numbering;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        any other precision specified by XTRAMILE in the Commercial Proposal.
                      </span>
                    </p>
                  </li>
                </ul>
                <p>
                  XTRAMILE cannot guarantee the exact extraction of data and information from the
                  Content transmitted under systems and/or formats other than those mentioned above.
                </p>
                <p>
                  <strong>16.9</strong>
                </p>
                <p>
                  XTRAMILE does not guarantee the accuracy of the marking or entry of data and
                  information contained in the Content. The Customer acknowledges and accepts that
                  Content containing unreadable data may not be processed correctly.
                </p>

                <p>
                  <strong>16.10</strong>
                </p>
                <p>
                  XTRAMILE does not guarantee that the notes or other handwritten information
                  appearing on the Content will be correctly processed by XTRAMILE.
                </p>

                <p>
                  <strong>16.11</strong>
                </p>
                <p>
                  XTRAMILE can only be held responsible with regards to any Customer for direct
                  damage suffered by said Customer. In any event, XTRAMILE&lsquo;s liability will be
                  limited to the total amount paid by Customer in the last 6 (six) months preceding
                  the event giving rise to liability.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>17. Intellectual property</strong>
                </p>
                <p>
                  <strong>17.1 Intellectual property of XTRAMILE</strong>
                </p>
                <p>
                  The Customer acknowledges by express agreement that the Contract does not confer
                  on it any intellectual property rights of any kind whatsoever on the Platform, the
                  API and more generally, on the systems, software, structures, infrastructures,
                  databases and content of any kind (technical documentation given to the Customer,
                  templates, texts, images, visuals, logos, brands, database, etc.) used by XTRAMILE
                  as part of the provision of the Services.
                </p>
                <p>
                  The Customer only has a license to use the Platform and the Content under the
                  conditions defined herein.
                </p>
                <p>
                  Consequently, all disassembling, decompilation, decryption, extractions, reuse,
                  copies and more generally all acts of reproduction, representation, distribution
                  and use of any of the elements composing the Platform, in whole or in part,
                  without the authorisation of XTRAMILE, are strictly prohibited and may be the
                  subject of legal proceedings.
                </p>
                <p>
                  <strong>17.2 Intellectual property of the Customer</strong>
                </p>
                <p>
                  For the purposes of implementing the Services, the Customer grants XTRAMILE a
                  worldwide, free, non-exclusive license to use the Content. Thus, the Customer:
                </p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>
                        consents to its Content being distributed free of charge by XTRAMILE by any
                        means and on any medium on the Platform and on all other French or foreign
                        websites, published by all companies with which XTRAMILE has agreements;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        accepts that its editorial Content be translated into all languages;{' '}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        recognises and accepts that the Content may be subject to modifications, in
                        particular with regards to their framing, their format and their colours, as
                        well as alterations or degradations in their quality, depending on the
                        technical constraints of the Platform;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        waives the right to ask XTRAMILE for any remuneration, royalty, indemnity or
                        financial compensation for this.
                      </span>
                    </p>
                  </li>
                </ul>
                <p>
                  In addition, the Customer understands and recognises that it may be difficult to
                  remove Content from social media channels and XTRAMILE sharing websites (such as
                  Facebook, Twitter, Instagram, Pinterest, Tumblr, YouTube, etc.), and agrees that
                  these elements may remain on social media channels and sharing websites as they
                  were originally posted, provided that at the end of the Agreement, XTRAMILE does
                  not repost or re allow other people to repost these items.
                </p>
              </div>
              <div>
                <p>
                  <strong>18. Hypertext links</strong>
                </p>
                <ul>
                  <li>
                    <p>To a third-party platform</p>
                  </li>
                </ul>
                <p>
                  The Platform may contain hypertext links to other websites on the Internet, and in
                  particular the Customer&lsquo;s website on which the Services can be integrated.
                </p>
                <p>
                  XTRAMILE can in no way be held responsible for the technical availability of
                  websites or mobile applications operated by third parties (including any
                  partners).
                </p>
                <p>
                  XTRAMILE undertakes no responsibility for the content, advertising, products
                  and/or services available on such third-party websites and mobile applications,
                  being reminded that they are governed by their own terms of use.
                </p>
                <p>
                  XTRAMILE is also not responsible for transactions between the Customer and any
                  advertiser, professional or merchant (including any partners) to which the
                  Customer is referred through the Platform and can in no case be a party to any
                  potential disputes whatsoever with these third parties concerning, in particular,
                  the delivery of products and/or services, guarantees, declarations or any other
                  obligations to which these third parties are bound.
                </p>
                <ul>
                  <li>
                    <p>To the Platform</p>
                  </li>
                </ul>
                <p>
                  The Customer is free to include a link to the Platform on his website. The
                  Platform must, if necessary, be displayed in a new browser window. XTRAMILE
                  nevertheless reserves the right to request the removal of a link if it considers
                  it incompatible with its Services or its image.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>19. Protection of personal data</strong>
                </p>
                <p>
                  <strong>19.1 XTRAMILE as a data controller</strong>
                </p>
                <p>
                  The detailed characteristics of this processing are explained in
                  <a href="/privacy-statement">
                    the charter relating to the protection of personal data
                  </a>
                  accessible on the Platform.
                </p>
                <p>
                  <strong>19.2 API formula: XTRAMILE as a subcontractor</strong>
                </p>
                <p>
                  As part of the use of the Platform by the Customer through an API, XTRAMILE is
                  required to process personal data as a subcontractor, the terms of which are
                  indicated in the Appendix.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>20. Commercial references</strong>
                </p>
                <p>
                  Unless expressly stated in the Commercial Proposal, the parties expressly and
                  mutually authorise each other to make free use of their respective names, brands
                  and logos as well as the references of their websites and social networks and more
                  generally for any external communication, as commercial references, in any medium
                  and in any form whatsoever, during the term of the Contract and beyond, for a
                  period of 5 (five) years.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>21. Confidentiality</strong>
                </p>
                <p>
                  Each party undertakes to keep strictly confidential the documents, items, data and
                  information of the other party that it would receive. With regard to XTRAMILE, the
                  parties already expressly agree that this obligation of confidentiality covers the
                  personal data that XTRAMILE will be required to process for the Customer as part
                  of the Services. All of this information is hereinafter referred to as
                  <strong>&quot;Confidential Information&quot;</strong>.
                </p>
                <p>
                  The party that receives Confidential Information undertakes not to disclose it
                  without the prior consent of the other party, for a period of 5 (five) years from
                  the end of the performance of the Services concerned. It may not transmit them to
                  employees, collaborators, interns or advisers unless they are bound by the same
                  obligation of confidentiality as that provided for herein. This obligation does
                  not extend to documents, elements, data and information:
                </p>
                <p>(i) of which the receiving party was already aware;</p>
                <p>
                  (ii) that are already public at the time of their communication or which would
                  become so without breach of the Contract;
                </p>
                <p>(iii) which would have been lawfully received from a third party;</p>
                <p>
                  (iv) the disclosure of which would be required by the judicial authorities, in
                  application of the laws and regulations or to establish a party&lsquo;s rights
                  under the Agreement.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>Assignment of the Contract</strong>
                </p>
                <p>
                  It is already understood that each party may delegate or assign all or part of the
                  rights and obligations of the Contract:
                </p>
                <ul>
                  <li>
                    <strong>to any affiliate company:</strong>
                  </li>
                </ul>
                <p>
                  By affiliate is meant any person, present or future, who, directly or indirectly,
                  (i) controls or is controlled by, or is under the common control, of one of the
                  parties, or (ii) over whom one of the parties holds a shareholding; the concepts
                  of control and shareholding being those understood within the meaning of Articles
                  L. 233-1 et seq. of the French Commercial Code, namely:
                </p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>
                        for the shareholding, a holding between 10% and 50% of the capital;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        for control, a holding of more than 50% of the shares or voting rights, or
                        the right to elect the majority of the members of the board of directors or
                        any other similar governing body.
                      </span>
                    </p>
                  </li>
                </ul>
                <ul>
                  <li>
                    <strong>
                      in the event of a change in control, merger, absorption, disposal, transfer of
                      one of the parties, of its business or its activities to another legal person,
                      provided that this is not from a direct competitor of the other party.
                    </strong>
                  </li>
                </ul>
                <p>
                  If it is a direct competitor, the party concerned may refuse the assignment and
                  must terminate the Contract in accordance with the terms provided herein.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>22. Subcontracting</strong>
                </p>
                <p>
                  XTRAMILE reserves the right to use subcontractors in order to provide the
                  Services. In this case, XTRAMILE will notify the Customer and undertakes to ensure
                  that its subcontractors comply with the same contractual obligations as those to
                  which it is submitted under the Contract. XTRAMILE will in any event remain solely
                  responsible for the proper performance of the Services with regard to the
                  Customer.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>23. Social regulations</strong>
                </p>
                <p>
                  XTRAMILE declares to comply with the tax and social legislation in force, to be up
                  to date with the payment of social contributions and to be able to provide proof
                  of compliance with the various applicable obligations in this area, at the
                  Customer&lsquo;s request. XTRAMILE must provide the Customer, at the
                  latter&lsquo;s request and as often as necessary, until the end of the execution
                  of the Contract, the following documents:
                </p>
                <p>
                  (i) an identification card justifying registration in the trades register or an
                  extract from the registration in the Trade and Companies Register dated less than
                  3 (three) months (K extract or KBIS), or equivalent for a foreign company;
                </p>
                <p>
                  (ii) a certificate of provision of social declarations from the social protection
                  body responsible for collecting contributions and social contributions incumbent
                  on XTRAMILE.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>24. Force majeure</strong>
                </p>
                <p>
                  Neither party can be held responsible for any difficulties or momentary
                  impossibilities in the execution of the present general terms and conditions of
                  use which are caused by force majeure. The parties agree to understand by force
                  majeure events usually recognised as such in article 1218 of the French Civil Code
                  and French courts, including in particular strikes, terrorist activities, riots,
                  insurgencies, wars, government actions, epidemics, natural disasters or fault
                  attributable to a third party telecommunication provider.
                </p>
                <p>
                  The occurrence of a case of force majeure suspends the execution of the general
                  terms and conditions upon receipt by one of the parties of the information sent by
                  registered letter with a request for acknowledgment of receipt by the other party.
                  The parties will therefore provide their best efforts to notify the other party of
                  the occurrence of a force majeure event.
                </p>
                <p>
                  In the event of a case of force majeure hindering the performance of its
                  obligations by a party and continuing beyond a period of one month, the present
                  general terms and conditions may be terminated by either party, by registered
                  letter with acknowledgment of receipt, without either party having to pay the
                  other any compensation.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>24. Resolution for breach</strong>
                </p>
                <p>
                  In the event of failure by one of the parties to comply with its obligations under
                  the Contract, it will be automatically terminated 30 (thirty) days following the
                  receipt by the defaulting party of a formal notice, which has remained
                  ineffective, by registered letter with acknowledgment of receipt, stating the
                  intention to apply this clause, without prejudice to any damages that may be
                  claimed from the defaulting party.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>25. Penalties for breach</strong>
                </p>
                <p>
                  In the event of a breach of any of the provisions of the Contract, or more
                  generally, of a breach of laws and regulations by the Customer, XTRAMILE reserves
                  the right to:
                </p>
                <p>
                  (i) suspend, delete or prevent access to the Platform to the Customer, author of
                  the breach or violation, or who participated to it;
                </p>
                <p>
                  (ii) remove any Service related to the breach or offense in question, in whole or
                  in part;
                </p>
                <p>(iii) take all appropriate measures and initiate any legal action;</p>
                <p>
                  (iv) notify the competent authorities where appropriate, cooperate with them and
                  provide them with all information useful for the investigation and suppression of
                  illegal or illicit activities.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>26. Effect of termination of the Contract and reversibility</strong>
                </p>
                <p>
                  The end of the Contract, whatever the cause, except when due to a case of force
                  majeure under the conditions above defined in the article &quot;Force
                  majeure&quot;, will result in the end of the Services, and the Customer must cease
                  without delay any use of the Platform and provide XTRAMILE with all programs and
                  documents relating to it. It will have no effect on the provisions hereof which
                  are intended to last beyond the termination of the Contract, and in particular the
                  articles &quot;Intellectual property&quot;, &quot;Effects of termination of the
                  Contract and reversibility&quot; and &quot;Confidentiality&quot;.
                </p>
                <p>
                  Upon request, XTRAMILE may proceed to the reversibility of this data in order to
                  allow the Customer, or any service provider of its choice, to resume the Services
                  subject to the present general terms and conditions under normal operating
                  conditions ensuring the continuity of said Services, on the system that the
                  Customer will have selected.
                </p>
                <p>All reversibility-related services will be charged by XTRAMILE.</p>
                <p>
                  XTRAMILE also undertakes to destroy the Customer&lsquo;s data and information and
                  not to keep a copy. The Customer agrees to actively collaborate with XTRAMILE in
                  order to facilitate the recovery of data and information.
                </p>
                <p>
                  It is understood by express agreement between the parties that XTRAMILE will be
                  released from its obligation to perform the reversibility Services referred to
                  above as long as the Customer has not paid all the invoices issued by XTRAMILE for
                  the execution of the present general terms and conditions.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>27. Amendments</strong>
                </p>
                <p>
                  XTRAMILE reserves the right to modify these general terms and conditions at any
                  time.
                </p>
                <p>
                  The Customer will be informed of these modifications by any useful written means,
                  including by email at least 2 (two) months before their entry into force.
                </p>
                <p>
                  Once in force, the amended terms and conditions will apply from the date of
                  renewal of the Subscription.
                </p>
                <p>
                  If the Customer does not accept the new general conditions, he must terminate the
                  Contract in accordance with the terms set out in the article &quot;Duration of the
                  Contract, termination&quot;.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>28. Electronic signature</strong>
                </p>
                <p>
                  It is understood between the parties that the Contract may be signed by any
                  electronic means, the parties recognising the reliability of the process thus
                  giving it the same legal value as a handwritten signature within the meaning of
                  the law.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>23. Applicable law and jurisdiction</strong>
                </p>
                <p>
                  The Contract is subject to French law and will be governed and interpreted
                  according to this law.
                </p>
                <p>
                  Any dispute that may arise concerning its validity, its interpretation or its
                  execution will be subject to the exclusive jurisdiction of the Commercial Court of
                  Paris (France), unless an imperative rule of procedure imposes the contrary.
                </p>
              </div>
              <div style={{ marginTop: '80px' }}>
                <h5 className={styles.subtitle}>Appendix</h5>
                <h5 className={styles.subtitle}>DPA</h5>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>1. Object</strong>
                </p>
                <p>
                  The purpose of the present clauses is to define the conditions under which
                  XTRAMILE undertakes to carry out on behalf of the data controller the personal
                  data processing operations defined below. As part of their contractual relations,
                  the parties undertake to comply with the regulations in force applicable to the
                  processing of personal data and, in particular, Regulation (EU) 2016/679 of the
                  European Parliament and of the Council of April 27th, 2016. applicable from 25 May
                  2018 (hereinafter the “European data protection regulation”).
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>2. Description of the processing subject of the subcontracting</strong>
                </p>
                <p>
                  XTRAMILE (hereinafter the« <strong>Subcontractor</strong> ») is authorised to
                  process on behalf of the Customer (hereinafter the«
                  <strong>Data Controller</strong>
                   ») the personal data necessary to provide its Services.
                </p>
                <p>
                  The nature of the operations carried out on the data is: the collection and
                  storage of personal data.
                </p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>
                        Collection: the Data Controller is the only one to decide on the nature,
                        purpose and use of the personal information entered, whether it is general
                        information (name, first name, date of birth), contact information (address,
                        telephone ), or more specific (personalised customer fields);
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Storage: the data entered is stored by the Subcontractor on secure servers
                        located in Europe (see below).
                      </span>
                    </p>
                  </li>
                </ul>
                <p>The purpose(s) of the processing are:</p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>
                        management of the recruitment service for Candidates who wish to apply for
                        job advertisements posted by the Data Controller;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        the management of HR events relating to the employees of the Data
                        Controller.
                      </span>
                    </p>
                  </li>
                </ul>
                <p>
                  The personal data processed is: surname, first name, email, telephone,
                  professional experience, training, skills, IP address.
                </p>
                <p>
                  The categories of data subjects are Candidates who wish to apply for a job offer
                  published by the Data Controller and the employees of the Data Controller.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>3. Duration of processing</strong>
                </p>
                <p>
                  The processing is concluded for a period of time necessary for the performance of
                  the Services by the Subcontractor. Processing will also end when the Data
                  Controller decides to close his Account. A request to close an Account (including
                  deletion of information) can be sent by email at any time to
                  <span style={{ textDecoration: 'underline' }}>rgpd@myxtramile.com</span>.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>4. Obligations of the Subcontractor vis-à-vis the Data Controller</strong>
                </p>
                <p>The Subcontractor undertakes to: </p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>
                        Process data only for the purposes that are the subject of the
                        subcontracting;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Process the data in accordance with the documented instructions of the Data
                        Controller. It is specified on this point that any configuration, by the
                        Data Controller, of the Platform, will be deemed to constitute an
                        instruction from the Data Controller, the data collected on the Platform and
                        the IT equipment of the Subcontractor constituting proof between the parties
                        in this regard.
                      </span>
                    </p>
                    <p>
                      <span>
                        If the Subcontractor considers that an instruction constitutes a violation
                        of the European data protection regulation or of any other provision of
                        Union law or of the law of the Member States relating to data protection, he
                        shall immediately inform the Data Controller. In addition, if the
                        Subcontractor is required to transfer data to a third country or to an
                        international organisation, under Union law or the law of the Member State
                        to which it is subject, it must inform the Data Controller of this legal
                        obligation before processing, unless the law concerned prohibits such
                        information for important reasons of public interest;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Guarantee the confidentiality of personal data processed under the Contract;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Ensure that the persons authorised to process personal data under the
                        Contract:
                        <ul className={styles.listEL}>
                          <li>
                            <p>
                              undertake to respect confidentiality or are subject to an appropriate
                              legal obligation of confidentiality;
                            </p>
                          </li>
                          <li>
                            <p>receive the necessary training in protection of personal data;</p>
                          </li>
                        </ul>
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Take into account, with regard to its tools, products, applications or
                        services, the principles of data protection from design and data protection
                        by default.
                      </span>
                    </p>
                  </li>
                </ul>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>5. Subcontracting</strong>
                </p>
                <p>
                  The Subcontractor may call on another subcontractor (hereinafter the «
                  <strong>Subsequent Subcontractor</strong> ») to carry out specific processing
                  activities such as in particular access management.
                </p>
                <p>
                  In this case, he informs the Data Controller in advance and in writing of any
                  planned change concerning the addition or replacement of other subcontractors.
                </p>
                <p>
                  This information must clearly indicate the subcontracted processing activities,
                  the identity and contact details of the subcontractor and the dates of the
                  subcontracting.
                </p>
                <p>
                  The Data Controller has a minimum period of 1 (one) month from the date of receipt
                  of this information to present his objections. This subcontracting can only be
                  carried out if the Data Controller has not objected within the agreed period. The
                  Subsequent Subcontractor is required to comply with the obligations hereof on
                  behalf of and according to the instructions of the Data Controller. It is the
                  responsibility of the initial Subcontractor to ensure that the Subsequent
                  Subcontractor presents the same sufficient guarantees as to the implementation of
                  appropriate technical and organisational measures so that the processing meets the
                  requirements of the European data protection regulation. If the Subsequent
                  Subcontractor does not fulfil its data protection obligations, the initial
                  Subcontractor remains fully responsible to the Data Controller for the performance
                  of its obligations by the Subsequent Subcontractor.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>6. Right of information of the persons concerned</strong>
                </p>
                <p>
                  It is the responsibility of the Data Controller to provide the information to the
                  persons concerned by the processing operations at the time of collection.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>7. Exercise of personal rights</strong>
                </p>
                <p>
                  As much as possible, the Subcontractor must help the Data Controller to fulfil his
                  obligation to respond to requests for the exercise of the rights of data subjects:
                  right of access, rectification, erasure and opposition, right to restriction of
                  processing, right to data portability, right not to be the subject of an
                  individual automated decision (including profiling). When the data subjects make
                  requests to the Subcontractor to exercise their rights, the Subcontractor must
                  send these requests by e-mail to the Data Controller.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>8. Notification of personal data breaches</strong>
                </p>
                <p>
                  The Subcontractor notifies the Data Controller of any personal data breach within
                  a maximum of 48 (forty-eight) hours after becoming aware of it, by email.
                </p>
                <p>
                  This notification is accompanied by any useful documentation to allow the Data
                  Controller, if necessary, to notify this violation to the competent supervisory
                  authority. After agreement of the Data Controller, the Subcontractor notifies the
                  competent supervisory authority (the CNIL), in the name and on behalf of the Data
                  Controller, of the personal data breaches as soon as possible and, if possible ,
                  72 (seventy-two) hours at the latest after becoming aware of it, unless the
                  violation in question is not likely to create a risk for the rights and freedoms
                  of individuals.
                </p>
                <p>The notification contains at least:</p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>
                        the description of the nature of the personal data breach including, where
                        possible, the categories and approximate number of persons affected by the
                        breach and the categories and approximate number of personal data records
                        affected;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        the name and contact details of the data protection officer or other point
                        of contact from which further information can be obtained;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        the description of the likely consequences of the personal data breach;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        the description of the measures taken or that the Data Controller proposes
                        to take to remedy the personal data breach, including, where applicable, the
                        measures to mitigate any negative consequences.
                      </span>
                    </p>
                  </li>
                </ul>
                <p>
                  If, and in the case in which, it is not possible to provide all of this
                  information at the same time, the information may be communicated in stages
                  without undue delay.
                </p>
                <p>
                  After agreement of the Data Controller, the Subcontractor communicates, in the
                  name and on behalf of the Data Controller, the personal data breach to the data
                  subject as soon as possible, when this breach is likely to give rise to a high
                  risk for the rights and freedoms of a natural person.
                </p>
                <p>
                  The communication to the data subject describes, in clear and simple terms, the
                  nature of the personal data breach and contains at least:
                </p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>
                        the description of the nature of the personal data breach including, where
                        possible, the categories and approximate number of persons affected by the
                        breach and the categories and approximate number of personal data records
                        affected;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        the name and contact details of the data protection officer or other point
                        of contact from which further information can be obtained;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        the description of the likely consequences of the personal data breach;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        the description of the measures taken or that the Data Controller proposes
                        to take to remedy the personal data breach, including, where applicable, the
                        measures to mitigate any negative consequences.
                      </span>
                    </p>
                  </li>
                </ul>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>
                    9. Subcontractor assistance in the context of compliance with its obligations by
                    the Data Controller
                  </strong>
                </p>
                <p>
                  The Subcontractor assists the Data Controller in carrying out data protection
                  impact analyses.
                </p>
                <p>
                  The Subcontractor assists the Data Controller in carrying out the prior
                  consultation of the supervisory authority.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>10. Security measures</strong>
                </p>
                <p>The Subcontractor undertakes to implement the following security measures: </p>
                <ul>
                  <li>
                    <strong>Secure access and confidentiality </strong>
                  </li>
                </ul>
                <p>
                  Browsing on the Subcontractor&lsquo;s platform is private and protected using SSL
                  technologies (https). No other person to the exception of the Data Controller (or
                  any other person designated by the Data Controller) will have access to the
                  information entered on the Platform.
                </p>
                <p>
                  Password: only the Data Controller has access to their own data. Access management
                  is delegated to a trusted partner. The use of a personal, confidential and secure
                  password is mandatory (at least eight characters, letters and numbers).
                </p>
                <p>
                  The Subcontractor does not know the password and therefore is not able to
                  communicate it. If the password is forgotten, the Data Controller can choose to
                  receive an email allowing them to choose a new password.
                </p>
                <p>
                  Sharing login information with third parties is prohibited. The Subcontractor
                  provides secure access for authorised third parties (i.e. accountant, technical
                  team and support).
                </p>
                <p>
                  Regarding the means to ensure the constant security, confidentiality, integrity,
                  availability and resilience of the processing systems and services, the
                  Subcontractor relies on the services managed by Microsoft Azure.
                </p>
                <ul>
                  <li>
                    <strong>Data retention</strong>
                  </li>
                </ul>
                <p>
                  All data entered is stored in highly secure servers and backup copies are made on
                  servers in order to minimise the risk of data loss. See
                  <a href="https://privacy.microsoft.com/fr-fr/privacystatement">
                    Microsoft&lsquo;s privacy policy
                  </a>
                  .
                </p>
                <ul>
                  <li>
                    <strong>Software protection</strong>
                  </li>
                </ul>
                <p>
                  In addition, the Subcontractor carries out security tests, patches and regular
                  updates in order to guarantee the proper functioning of the service. In the event
                  of a system failure, the Subcontractor undertakes to restore the system within 48
                  hours.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>11. Data fate</strong>
                </p>
                <p>
                  At the end of the Contract, the Subcontractor undertakes to destroy all personal
                  data.
                </p>
                <p>
                  The return must be accompanied by the destruction of all existing copies in the
                  information systems of the Subcontractor. Once the copies have been destroyed, the
                  Subcontractor must justify the destruction in writing.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>12. Data Protection Officer</strong>
                </p>
                <p>
                  The Subcontractor communicates to the Data Controller the name and contact details
                  of his data protection officer, if he has appointed one in accordance with Article
                  37 of the European data protection regulation.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>13. Record of processing activity categories</strong>
                </p>
                <p>
                  The Subcontractor declares to keep in writing a register of all categories of
                  processing activities carried out on behalf of the Data Controller.
                </p>
                <p>
                  The Data Controller may request the register of processing activities for
                  consultation at
                  <span style={{ textDecoration: 'underline' }}>rgpd@myxtramile.com</span>.
                </p>
                <p>This register must contain:</p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>
                        the name and contact details of the Data Controller on whose behalf he acts,
                        of any subcontractors and, where applicable, of the data protection officer;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        the categories of processing carried out on behalf of the Data Controller;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        where applicable, transfers of personal data to a third country or to an
                        international organisation, including the identification of that third
                        country or that international organisation and, in the case of transfers
                        referred to in Article 49, paragraph 1, second subparagraph of the European
                        data protection regulation, the documents attesting to the existence of
                        appropriate guarantees;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        where possible, a general description of technical and organisational
                        security measures, including but not limited to, as appropriate: encryption
                        of personal data, means to ensure the confidentiality, integrity,
                        availability and constant resilience of processing systems and services;
                        means to restore the availability of personal data and access to them within
                        an appropriate timeframe in the event of a physical or technical incident; a
                        procedure aimed at regularly testing, analysing and evaluating the
                        effectiveness of technical and organisational measures to ensure the
                        security of the processing.
                      </span>
                    </p>
                  </li>
                </ul>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>14. Documentation</strong>
                </p>
                <p>
                  The Subcontractor provides the Data Controller with the necessary documentation to
                  demonstrate compliance with all its obligations and to allow audits to be carried
                  out, including inspections, by the Data Controller or another auditor appointed by
                  him to contribute to these audits.
                </p>
                <p>
                  In the context of such audits, the Data Controller or the auditor appointed by him
                  will not be authorised to access the business secrets of the Subcontractor, the
                  strategic information of the latter or the information regarding which the
                  Subcontractor is committed to keeping confidential. The Subcontractor may oppose
                  any control measure by the Data Controller or the auditor appointed by him which
                  may give them access to such data or information, without the Data Controller
                  being able to formulate any claim in this regard. The Data Controller will also
                  ensure in any event that the auditor and, more generally, the staff performing the
                  audit are subject to appropriate confidentiality obligations.
                </p>
              </div>
              <div>
                <p className={styles.titleBlock}>
                  <strong>
                    15. Obligations of the Data Controller vis-à-vis the Subcontractor
                  </strong>
                </p>
                <p>The Data Controller undertakes to: </p>
                <ul className={styles.listEL}>
                  <li>
                    <p>
                      <span>
                        Provide the Subcontractor with the data referred to in II of these clauses;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Ensure, beforehand and throughout the duration of the processing, that the
                        obligations laid down by the European data protection regulation on the part
                        of the Subcontractor;
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Oversee the processing, including by performing audits and inspections of
                        the Subcontractor.
                      </span>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

TermsAndConditionsPage.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default TermsAndConditionsPage;
