import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './dialogParse.module.scss';
import Uploader from 'components/uploader/uploader';

const DialogParse = ({ closeDialog, type, demo, t }) => {
  const [showTitle, setShowTitle] = useState(true);

  const hideDialogTitle = (state) => {
    setShowTitle(state);
  };

  const goToContactForm = () => {
    closeDialog();
    document.getElementById('contact').scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <div className={styles.wrapperDialogParse}>
      <div className={styles.dialogParse}>
        <span className={styles.dialogClose} onClick={closeDialog}>
          &times;
        </span>
        {showTitle &&
          (type === 'job' ? (
            <>
              <h1 className={styles.dialogTitle}>{t('titles.Parsing of the job offer')}</h1>
              <p className={styles.dialogSubtitle}>{t('text.Download or Copy')}</p>
            </>
          ) : (
            <>
              <h1 className={styles.dialogTitle}>{t('titles.Parsing of the cv')}</h1>
            </>
          ))}
        <Uploader
          t={t}
          typeDocument={type}
          demo={demo}
          hideDialogTitle={(state) => hideDialogTitle(state)}
          goToContactForm={() => goToContactForm()}
        />
      </div>
    </div>
  );
};

DialogParse.propTypes = {
  t: PropTypes.func.isRequired,
  type: PropTypes.string,
  demo: PropTypes.bool,
  closeDialog: PropTypes.func.isRequired,
};

export default DialogParse;
