import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-elastic-carousel';
import { useMediaQuery } from 'react-responsive';

import styles from './slider2.module.scss';

const Slider2 = ({ t }) => {
  const data = [
    {
      id: '0',
      title: t('titles.impact-rh_An HR analytics tool'),
      text: t(
        'text.impact-rh_Via a dashboard and numerous KPIs'
      ),
    },
    {
      id: '1',
      title: t('titles.impact-rh_A granular analysis of major Sourcing KPIs'),
      text: t(
        'text.impact-rh_Using data such as the cost of acquiring'
      ),
    },
    {
      id: '2',
      title: t('titles.impact-rh_KPIs on demand'),
      text: t(
        'text.impact-rh_Thanks to the extensive tools'
      ),
    },
    {
      id: '3',
      title: t('titles.impact-rh_Criss-crossed comparisons'),
      text: t(
        'text.impact-rh_The data set being categorized'
      ),
    },
  ];

  const carousel = useRef(null);
  const delay = 3000;

  const shouldGoHorizontal = useMediaQuery({
    query: '(max-width: 1180px)',
  });

  const setCarouselToStart = (currentItem) => {
    if (currentItem.index === 3) {
      setTimeout(() => {
        if (!carousel) return;

        carousel?.current?.goTo(0);
      }, delay);
    }
  };

  return (
    <section className={`slider2 ${styles.section}`}>
      <div className={styles.slidersHeader}>
        {t(
          'text.impact-rh_Thanks to our BI HR Impact analysis'
        )}
      </div>

      <div className={styles.slider}>
        <Carousel
          verticalMode={!shouldGoHorizontal}
          showArrows={false}
          itemsToShow={1}
          enableSwipe={false}
          enableMouseSwipe={false}
          preventDefaultTouchmoveEvent={true}
          ref={carousel}
          enableAutoPlay
          autoPlaySpeed={delay}
          transitionMs={delay}
          onChange={(currentItem) => {
            setCarouselToStart(currentItem);
          }}
        >
          {data &&
            data.map((item, index) => (
              <div key={item.id} className={`${styles.post} ${styles[`post${index}`]}`}>
                <div className={styles.postsText}>
                  <span className={styles.title}>{item.title}</span>
                  <p className={styles.text}>{item.text}</p>
                </div>
              </div>
            ))}
        </Carousel>
      </div>
    </section>
  );
};

Slider2.propTypes = {
  t: PropTypes.func.isRequired,
};

export default Slider2;
