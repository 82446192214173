import React, { Component } from 'react';
import styles from './uploader.module.scss';
import axios from 'axios';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';

import PDF from 'assets/icons/PDF.png';
import JPG from 'assets/icons/JPG.png';
import PNG from 'assets/icons/PNG.png';

import generateId from 'utils/generateId';
import InitialUploader from './initialUploader/initialUploader';
import DataCvCollapse from 'components/uploader/dataCvCollapse/dataCvCollapse';
import DataJobCollapse from 'components/uploader/dataJobCollapse/dataJobCollapse';

const PARSER_API_URL = 'https://business-inquiries.xtramile.io';

class Uploader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      resultCv: [
        {
          results: {
            identities: { data: [] },
            educations: { data: [] },
            experiences: { data: [] },
            skills: { data: [] },
          },
        },
      ],
      resultJob: [],
      temporaryCvResults: [],
      temporaryJobResults: [],
      isUploading: false,
      hasMaxCountErr: false,
      hasDataAgreement: false,
      readyToParse: false,
      startParsingJob: false,
      isTryingToSubmit: false,
      hasUnsupportedFormatFiles: false,
      hasMaxFilesSizeErr: false,
      showParsingResults: false,
      isApplied: false,
      isSomeFailedParsingResult: false,
      isFailedParsingResult: false,
      isSuccessParsingResult: false,
      isUploadingCanceled: false,
    };

    this.extensions = {
      pdf: PDF,
      png: PNG,
      jpg: JPG,
    };

    this.maxFilesAmound = 1;
    this.maxFIleSize = 30000000;
  }

  componentDidMount() {
    this.props.history.listen((location, action) => this.listenerForHistoryBack(location, action));
  }

  listenerForHistoryBack = (location, action) => {
    if (action === 'POP') this.backToInitial();
  };

  getExtension = (file) => {
    const separatedNameArr = file.name.split('.');
    return separatedNameArr[separatedNameArr.length - 1];
  };

  validateUnsupportedFormat = (file) => {
    const separatedNameArr = file?.name.split('.');
    const format = separatedNameArr[separatedNameArr.length - 1];
    return !Object.prototype.hasOwnProperty.call(this.extensions, format);
  };

  validateMaxFileSize = (file) => file.size > this.maxFIleSize;

  onDrop = (newFiles) => {
    const { typeDocument } = this.props;
    // add new keys in file-obj for supporting of validation and other logic
    let newFilesArr = newFiles.map((item) => ({
      file: item,
      id: generateId(),
      hasFormatUnsupportedErr: this.validateUnsupportedFormat(item),
      hasMaxSizeErr: this.validateMaxFileSize(item),
      extension: this.getExtension(item),
    }));

    let newFileNames = '';
    newFiles.forEach((item) => (newFileNames += item.name));

    this.setState({
      files: newFilesArr,
      hasMaxCountErr: newFilesArr.length > this.maxFilesAmound,
      hasUnsupportedFormatFiles: newFilesArr.some((item) => item.hasFormatUnsupportedErr === true),
      hasMaxFilesSizeErr: newFilesArr.some((item) => item.hasMaxSizeErr === true),
    });

    const pathname = window.location.pathname;
    this.props.history.push(pathname);

    if (typeDocument === 'cv') {
      this.onParseCV();
    }

    if (typeDocument === 'job') {
      this.setState({
        readyToParse: true,
        isTryingToSubmit: true,
      });
    }
  };

  deleteFile = (chosenIndex, e) => {
    // undo native behavior of file selection
    e.stopPropagation();

    const { files, isUploading, hasDataAgreement } = this.state;

    if (isUploading) return;

    const newFilesArr = files.filter((item, index) => chosenIndex !== index);

    this.setState({
      files: newFilesArr,
      hasMaxCountErr: newFilesArr.length > this.maxFilesAmound,
      hasUnsupportedFormatFiles: newFilesArr.some((item) => item.hasFormatUnsupportedErr === true),
      hasMaxFilesSizeErr: newFilesArr.some((item) => item.hasMaxSizeErr === true),
      temporaryCvResults: [],
      temporaryJobResults: [],
      isUploading: false,
      hasDataAgreement,
      readyToParse: false,
      isTryingToSubmit: false,
      showParsingResults: false,
      isApplied: false,
      isSomeFailedParsingResult: false,
      isFailedParsingResult: false,
      isSuccessParsingResult: false,
      isUploadingCanceled: false,
    });
  };

  onParseJob = () => {
    const { files, hasMaxCountErr, hasUnsupportedFormatFiles, hasMaxFilesSizeErr } = this.state;

    if (files.length === 0) return;

    if (hasMaxCountErr || hasUnsupportedFormatFiles || hasMaxFilesSizeErr) {
      return;
    }

    this.setState({
      isUploading: true,
      isUploadingCanceled: false,
    });

    this.uploadOneFile(0);
  };

  onParseCV = () => {
    const {
      files,
      hasDataAgreement,
      hasMaxCountErr,
      hasUnsupportedFormatFiles,
      hasMaxFilesSizeErr,
    } = this.state;

    if (files.length === 0) return;

    this.setState({
      isTryingToSubmit: true,
    });

    if (
      (!hasDataAgreement && this.props.demo === false) ||
      hasMaxCountErr ||
      hasUnsupportedFormatFiles ||
      hasMaxFilesSizeErr
    ) {
      return;
    }

    this.setState({
      isUploading: true,
      isUploadingCanceled: false,
    });

    this.uploadOneFile(0);
  };

  uploadOneFile = async (index, isForOnlyOneFile = false) => {
    const { files, temporaryCvResults, temporaryJobResults } = this.state;
    const { typeDocument, hideDialogTitle } = this.props;
    let newFiles = files.map((item, i) => {
      return i === index
        ? {
            ...item,
            uploading: true,
          }
        : item;
    });

    this.setState({
      files: newFiles,
      isUploading: true,
    });

    const formData = new FormData();
    const propName = typeDocument === 'cv' ? 'resume' : 'offer';

    formData.append(propName, files[index].file, files[index].file.name);

    const axiosClient = axios.create({
      timeout: 0,
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: '*/*',
      },
    });

    let result = {};

    try {
      result = await axiosClient.post(`${PARSER_API_URL}/${propName}`, formData);
    } catch (e) {
      if (this.state.isUploadingCanceled) return;

      newFiles = files.map((item, i) => {
        return i === index
          ? {
              ...item,
              status: 'error',
              status_message: e?.message || 'error',
              uploading: false,
            }
          : item;
      });
    }

    if (this.state.isUploadingCanceled) return;

    if (result.data) {
      newFiles = files.map((item, i) => {
        let status = result.data[0].status;

        if (typeDocument === 'job' && result.status === 200) {
          status = 'processed';
        }

        return i === index
          ? {
              ...item,
              status,
              status_message: result.data[0].status_message,
              uploading: false,
            }
          : item;
      });
    }

    if (files.length > index + 1 && !isForOnlyOneFile) {
      if (result.data) {
        typeDocument === 'cv'
          ? temporaryCvResults.push(result.data[0])
          : temporaryJobResults.push(result.data[0]);
      }

      this.setState({
        files: newFiles,
        temporaryCvResults,
        temporaryJobResults,
        resultCv: temporaryCvResults,
        resultJob: temporaryJobResults,
      });
      this.uploadOneFile(index + 1);
    } else {
      if (result.data) {
        typeDocument === 'cv'
          ? temporaryCvResults.push(result.data[0])
          : temporaryJobResults.push(result.data[0]);
      }

      this.setState({
        isUploading: false,
        isSomeFailedParsingResult: newFiles.some((item) => item.status === 'error'),
        isFailedParsingResult: newFiles.every((item) => item.status === 'error'),
        isSuccessParsingResult: newFiles.every((item) => item.status === 'processed'),
        files: newFiles,
        temporaryCvResults,
        temporaryJobResults,
        resultCv: temporaryCvResults,
        resultJob: temporaryJobResults,
        showParsingResults: !!result.data,
      });
    }

    if (temporaryJobResults && typeDocument === 'job') {
      hideDialogTitle(false);
    }
  };

  apply = async () => {
    const { typeDocument } = this.props;
    const axiosClient = axios.create({
      timeout: 0,
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: '*/*',
      },
    });

    let result = {};

    try {
      if (typeDocument === 'cv') {
        result = await axiosClient.post(`${PARSER_API_URL}/apply`);
      }

      if (typeDocument === 'job') {
        //mocked result
        new Promise((resolve) => {
          setTimeout(
            () =>
              resolve({
                status: 201,
                statusText: '',
                request: {
                  response: 'Application received!',
                  responseText: 'Application received!',
                  responseType: '',
                  responseURL: 'https://business-inquiries.xtramile.io/apply',
                  responseXML: null,
                },
              }),
            1000
          );
        }).then((res) => {
          result = res;

          this.setState({
            isApplied: result.status === 201 || result.status === 200,
          });
        });
      }
    } catch (e) {
      console.error(e);
    }

    this.setState({
      isApplied: result.status === 201 || result.status === 200,
    });
  };

  toggleDataAgreement = () => {
    this.setState(
      (prevState) => ({
        hasDataAgreement: !prevState.hasDataAgreement,
      }),
      () => this.onParseCV()
    );
  };

  cancelUploading = () => {
    const { t } = this.props;

    let isAgreeToCancel = window.confirm(t('text.Are you sure?'));

    if (isAgreeToCancel) {
      this.backToInitial();
    }
  };

  backToInitial = () => {
    const { hasDataAgreement } = this.state;
    const { typeDocument, hideDialogTitle } = this.props;
    this.setState({
      files: [],
      temporaryCvResults: [],
      temporaryJobResults: [],
      isUploading: false,
      hasMaxCountErr: false,
      hasDataAgreement,
      readyToParse: false,
      isTryingToSubmit: false,
      hasUnsupportedFormatFiles: false,
      hasMaxFilesSizeErr: false,
      showParsingResults: false,
      isApplied: false,
      isSomeFailedParsingResult: false,
      isFailedParsingResult: false,
      isSuccessParsingResult: false,
      isUploadingCanceled: false,
    });

    if (typeDocument === 'job') {
      hideDialogTitle(true);
    }
  };

  render() {
    const {
      isUploading,
      files,
      hasMaxCountErr,
      hasDataAgreement,
      readyToParse,
      isTryingToSubmit,
      hasUnsupportedFormatFiles,
      hasMaxFilesSizeErr,
      showParsingResults,
      isFailedParsingResult,
      resultCv,
      resultJob,
      isApplied,
    } = this.state;

    const { t, typeDocument, demo } = this.props;

    return (
      <section className={styles.uploaderPage}>
        <div
          className={cx(
            !showParsingResults && !isApplied && styles.uploaderSmallBackground,
            showParsingResults && !isApplied && styles.uploaderBigBackground,
            isApplied && styles.uploaderSmallBackground
          )}
        />
        {!showParsingResults && !isApplied && (
          <InitialUploader
            t={t}
            files={files}
            extensions={this.extensions}
            hasDataAgreement={hasDataAgreement}
            readyToParse={readyToParse}
            hasMaxCountErr={hasMaxCountErr}
            hasUnsupportedFormatFiles={hasUnsupportedFormatFiles}
            hasMaxFilesSizeErr={hasMaxFilesSizeErr}
            isTryingToSubmit={isTryingToSubmit}
            isUploading={isUploading}
            isFailedParsingResult={isFailedParsingResult}
            deleteFile={this.deleteFile}
            onParseCV={this.onParseCV}
            toggleDataAgreement={this.toggleDataAgreement}
            onDrop={this.onDrop}
            backToInitial={this.backToInitial}
            typeDocument={typeDocument}
            onParseJob={this.onParseJob}
            demo={demo}
          />
        )}

        {showParsingResults && !isApplied && typeDocument === 'cv' && (
          <DataCvCollapse
            currentCv={resultCv[0]}
            demo={this.props.demo}
            t={t}
            backToInitial={this.backToInitial}
            apply={this.apply}
            goToContactForm={this.props.goToContactForm}
          />
        )}

        {showParsingResults && !isApplied && typeDocument === 'job' && (
          <DataJobCollapse
            currentJob={resultJob[0]}
            demo={this.props.demo}
            t={t}
            backToInitial={this.backToInitial}
            goToContactForm={this.props.goToContactForm}
          />
        )}

        {isApplied && (
          <div className={styles.isAppliedContainer}>
            <div className={styles.isAppliedTitle}>{t('text.Thank you !')}</div>
            <div className={styles.isAppliedMessage}>{t("text.We'll contact you soon !")}</div>
          </div>
        )}
        <div className="dragndrop"></div>
      </section>
    );
  }
}

Uploader.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.any,
  typeDocument: PropTypes.string.isRequired,
  hideDialogTitle: PropTypes.func,
  changeClassModal: PropTypes.func,
  goToContactForm: PropTypes.func,
  demo: PropTypes.bool,
};

export default withRouter(Uploader);
