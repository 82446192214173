import React from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';

import styles from './langs.module.scss';

const { languages: langs } = i18n;

class Langs extends React.Component {
  constructor(props) {
    super(props);

    this.changeLanguage = this.changeLanguage.bind(this);
    this.state = {
      isOpen: false,
      activeLang: i18n.language,
      isOpenUsersDropDown: false,
    };
  }

  changeLanguage(lng) {
    i18n.changeLanguage(lng);

    this.setState({
      activeLang: lng,
    });
  }

  render() {
    const { activeLang } = this.state;
    const { mobileVersion } = this.props;

    return mobileVersion ? (
      <span>
        {langs.length > 1 &&
          langs.map((lng) => {
            const changeLang = () => this.changeLanguage(lng);
            const active = lng === activeLang;

            return (
              !active && (
                <span key={lng}>
                  <span onClick={changeLang} className={styles.activeLang}>
                    {lng}
                  </span>
                </span>
              )
            );
          })}
      </span>
    ) : (
      <span className={styles.langsNavBar}>
        <span>
          {langs.length > 1 &&
            langs.map((lng) => {
              const changeLang = () => this.changeLanguage(lng);
              const activeClass = lng === activeLang ? 'active' : '';

              return (
                <span key={lng}>
                  <span
                    onClick={changeLang}
                    className={activeClass ? styles.activeLang : styles.lang}
                  >
                    {lng}
                  </span>
                </span>
              );
            })}
        </span>
      </span>
    );
  }
}

Langs.propTypes = {
  mobileVersion: PropTypes.bool.isRequired,
};

export default Langs;
