import React from 'react';
import PropTypes from 'prop-types';

import styles from './notFound.module.scss';

const NotFound = ({ t }) => {
  return (
    <div className={styles.continer}>
      <h1>{t('errors.Page not found')}</h1>
    </div>
  );
};

NotFound.propTypes = {
  t: PropTypes.func.isRequired,
};

export default NotFound;
