import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';

import PropTypes from 'prop-types';

import AuthenticatedApp from 'components/authenticatedApp/authenticatedApp';
import './app.scss';
import changeTitle from 'utils/changeTitle';

class App extends PureComponent {
  render() {
    const { t, history } = this.props;

    TagManager.initialize({
      gtmId: 'GTM-WH4GD9D',
    });

    changeTitle(history);

    return (
      <div className="app-wrapper">
        <AuthenticatedApp t={t} history={history} />
      </div>
    );
  }
}

App.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.any.isRequired,
};

export default withRouter(withTranslation()(App));
