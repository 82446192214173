import fiboisAva from 'assets/images/ava/fiboisAva.png';
import fibois from 'assets/icons/logo/fibois_logo.png';
import stedy from 'assets/icons/logo/stedyLogo.png';
import stedyAva from 'assets/images/ava/stedyAva.png';
import afpa from 'assets/icons/logo/afpaLogo.png';
import afpaAva from 'assets/images/ava/afpaAva.jpeg';
import armeeAir from 'assets/icons/logo/armeeAirLogo.png';
import armeeTerre from 'assets/icons/logo/armeeTerreLogo.png';
import bouygues from 'assets/icons/logo/bouyguesLogo.png';
import engie from 'assets/icons/logo/engieLogo.png';
import admr from 'assets/icons/logo/admrLogo.png';
import mozaic from 'assets/icons/logo/mozaicLogo.png';
import spie from 'assets/icons/logo/spieLogo.png';
import jobplus from 'assets/icons/logo/jobplusLogo.png';

export default [
  {
    id: '0',
    name: 'fibois',
    ava: fiboisAva,
    author: 'Gwendoline Legros',
    position: 'Fibois Grand Est Deputy General Delegate',
    logo: fibois,
    quote: 'fiboise',
  },
  {
    id: '1',
    name: 'stedy',
    ava: stedyAva,
    author: 'Karl Rigal',
    position: 'Chief Marketing Officer StedY',
    logo: stedy,
    quote: 'stedy',
  },
  {
    id: '2',
    name: 'afpa',
    ava: afpaAva,
    author: 'Catherine Gervaise',
    position: 'Afpa executive',
    logo: afpa,
    quote: 'afpa',
  },
  {
    id: '3',
    name: 'armeeAir',
    ava: null,
    author: 'Sophie Leplanquois',
    position: 'Direction des ressources humaines DRHAA',
    logo: armeeAir,
    quote: 'armeeAir',
  },
  {
    id: '4',
    name: 'armeeTerre',
    ava: null,
    author: null,
    position: null,
    logo: armeeTerre,
    quote: 'armeeTerre',
  },
  {
    id: '5',
    name: 'bouygues',
    ava: null,
    author: null,
    position: null,
    logo: bouygues,
    quote: 'bouygues',
  },
  {
    id: '6',
    name: 'engie',
    ava: null,
    author: null,
    position: null,
    logo: engie,
    quote: 'engie',
  },
  {
    id: '7',
    name: 'admr',
    ava: null,
    author: null,
    position: null,
    logo: admr,
    quote: 'admr',
  },
  {
    id: '8',
    name: 'mozaic',
    ava: null,
    author: null,
    position: null,
    logo: mozaic,
    quote: 'mozaic',
  },
  {
    id: '9',
    name: 'spie',
    ava: null,
    author: null,
    position: null,
    logo: spie,
    quote: 'spie',
  },
  {
    id: '10',
    name: 'jobplus',
    ava: null,
    author: null,
    position: null,
    logo: jobplus,
    quote: 'jobplus',
  },
];
