import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import cx from 'classnames';

import styles from './headerForXServices.module.scss';
import picto from 'assets/icons/picto_sourcingRH.svg';
import Icon from 'components/ui/icon';
import productNames from 'data/productNames';

const { XServices, XServices1, XServices2 } = productNames;

const HeaderForXServices = ({ t, state, states, routeName, activeRoute, callback }) => {
  const lang = t('lang');

  return (
    <div className={cx(styles[state], styles[activeRoute], styles.main)} onClick={() => callback(routeName)}>
      <div className={styles.titleIcon}>
        <img src={picto} alt="X-Services" />
      </div>

      <div className={styles.titleForActive}>
        {t('titles.With our programmatic offer')}
        &nbsp;
        <span className={styles.nameForActive}>{XServices}</span>
        ,
        <br />
        {t('titles.Opt for the cost of predictive recruitment!')}
      </div>

      {state === states[1] && (
        <div
          className={
            lang === 'en'
              ? `titleForNotActiveEn ${styles.titleForNotActive}`
              : styles.titleForNotActive
          }
        >
          {t('titles.Optimise your recruitment campaigns')}
        </div>
      )}

      {state !== states[0] && (
        <>
          <div className={styles.name}>
            <div>{XServices1} &nbsp;</div>
            <div>{XServices2}</div>
            <div style={{ visibility: 'hidden' }}>x</div>
          </div>

          <div className={styles.arrow}>
            <Icon name="arrow-right" width="37" height="28" color="#fff" viewbox="0 0 38 30" />
          </div>
        </>
      )}
    </div>
  );
};

HeaderForXServices.propTypes = {
  t: PropTypes.func.isRequired,
  state: PropTypes.oneOf(['active', 'notActive', 'hidden']).isRequired,
  states: PropTypes.array.isRequired,
  routeName: PropTypes.string.isRequired,
  activeRoute: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
};

export default withTranslation()(HeaderForXServices);
