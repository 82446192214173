import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TagManager from 'react-gtm-module';
import styles from './privacyStatementPage.module.scss';

const PrivacyStatementPage = ({ t, title }) => {
  useEffect(() => {
    document.title = title || '';
  }, [title]);

  TagManager.dataLayer({
    dataLayer: {
      event: 'pageview',
      pagePath: '/privacy-statement',
      pageTitle: 'Privacy Statement',
    },
  });

  const lang = t('lang');

  return (
    <div className={styles.PrivacyStatementPage}>
      <div className="container">
        <div className={styles.wrapper}>
          <h4 className={styles.title}>{t('pageTitles.Privacy statement')}</h4>

          {lang === 'fr' ? (
            <div className={styles.text}>
              <p>&nbsp;</p>
              <ul>
                <li>
                  <p>
                    <span>
                      <strong>A quoi sert la charte ?</strong>
                    </span>
                  </p>
                </li>
              </ul>
              <p>
                <span>
                  Chez Xtramile, la protection de vos données personnelles est une priorité&nbsp;!
                </span>
              </p>
              <p>
                <span>
                  Le but de la présente charte est de vous informer sur les modalités selon
                  lesquelles nous traitons vos données à caractère personnel.
                </span>
              </p>
              <p>
                <span>
                  Rassurez-vous, nous nous conformons, dans la collecte et la gestion de vos données
                  à la loi de 1978 dite « Informatique et Libertés », et désormais au règlement
                  européen du 27 avril 2016 (ci-après : le «&nbsp;<strong>RGPD </strong>
                  »).
                </span>
              </p>
              <p>&nbsp;</p>
              <ul>
                <li>
                  <p>
                    <span>
                      <strong>Qu’est-ce qu’une donnée personnelle&nbsp;?</strong>
                    </span>
                  </p>
                </li>
              </ul>
              <p>
                <span>
                  Lors de votre utilisation des services proposés par Xtramile (ci-après : les
                  «&nbsp;<strong>Services</strong> »), nous pouvons être amenés à vous demander de
                  nous communiquer des données personnelles vous concernant.
                </span>
              </p>
              <p>
                <span>
                  Le terme « <strong>donnée personnelle</strong> » désigne toutes les données qui
                  permettent de vous identifier, en tant qu’individu.
                </span>
              </p>
              <p>&nbsp;</p>
              <ul>
                <li>
                  <p>
                    <span>
                      <strong>
                        Quelles sont les données personnelles collectées par Xtramile ?
                      </strong>
                    </span>
                  </p>
                </li>
              </ul>
              <p>
                <span>
                  Dans le cadre de notre activité, nous sommes amenés à recueillir un certain nombre
                  d’informations vous concernant, et notamment vos{' '}
                  <strong>
                    nom, prénom, numéro de téléphone, adresse email, poste actuel, formation,
                    expérience professionnelle, compétences, adresse IP.
                  </strong>
                </span>
              </p>
              <p>
                <span>Ces données peuvent être collectées quand vous utilisez nos Services.</span>
              </p>
              <p>&nbsp;</p>
              <ul>
                <li>
                  <p>
                    <span>
                      <span>
                        <span>
                          <strong>Qui est le responsable du traitement&nbsp;?</strong>
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
              </ul>
              <p>
                <span>XTRAMILE</span>
              </p>
              <p>
                <span>SAS - RCS&nbsp;Metz&nbsp;: 808&nbsp;932&nbsp;037</span>
              </p>
              <p>
                <span>11 Rem Saint-Thiebault – 57000 METZ</span>
              </p>
              <p>
                <span>rgpd@myxtramile.com</span>
              </p>
              <p>&nbsp;</p>
              <ol>
                <li>
                  <p>
                    <span>
                      <strong>Utilisation de vos données à caractère personnel</strong>
                    </span>
                  </p>
                </li>
              </ol>
              <p>
                <span>
                  Sur quel(s) fondement(s) vos données sont-elles collectées&nbsp;et utilisées ?
                </span>
              </p>
              <ul>
                <li>
                  <p>
                    <span>
                      Contrat&nbsp;: Cette collecte est nécessaire afin d’exécuter le contrat conclu
                      lorsque vous utilisez nos Services
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Intérêt Légitime&nbsp;: Lorsque vous nous fournissez volontairement des
                      données personnelles, nous les collectons afin de mieux répondre à vos
                      demandes d’information sur nos Services
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Obligation légale&nbsp;: Lorsque le traitement de vos données est nécessaire
                      au respect d’une obligation légale à laquelle nous sommes soumis
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Consentement&nbsp;: S’agissant de la collecte et du stockage de vos données
                      personnelles par le biais des cookies (Google Analytics, Google Tag Manager,
                      Matomo Analytics, Matomo Tag Manager) ou de notre formulaire de contact
                      utilisés sur notre site internet
                    </span>
                  </p>
                </li>
              </ul>
              <p>&nbsp;</p>
              <p>
                <span>
                  Pour quelle(s) finalité(s) vos données sont-elles collectées&nbsp;et utilisées ?
                </span>
              </p>
              <p>&nbsp;</p>
              <ul>
                <li>
                  <p>
                    <span>
                      Constituer un fichier utilisateurs, inscrits et prospects. Gérer votre accès à
                      certains Services et leur utilisation.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Élaborer des statistiques commerciales. Personnaliser les réponses à vos
                      demandes d’information. Gérer des avis des personnes sur les Services. Envoi
                      d’informations ou de prospections
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>Respect de nos obligations légales et réglementaires</span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Mesurer le nombre de visites sur notre site internet, le nombre de pages vues
                      et l’activité des utilisateurs, et répondre à vos demandes de renseignements
                      sur nos Services
                    </span>
                  </p>
                </li>
              </ul>
              <p>
                <span>
                  NB/ Vous pouvez toujours refuser de nous transmettre vos données, nous vous
                  informerons alors des conséquences éventuelles de ce refus lorsque ces données
                  sont obligatoires.
                </span>
              </p>
              <p>&nbsp;</p>
              <ol start="2">
                <li>
                  <p>
                    <span>
                      <strong>Destinataires des données collectées</strong>
                    </span>
                  </p>
                </li>
              </ol>
              <p>
                <span>Auront accès à vos données à caractère personnel :</span>
              </p>
              <ol type="i">
                <li>
                  <p>
                    <span>Notre équipe,</span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Les services chargés du contrôle (commissaire aux comptes notamment),
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>Nos sous-traitants,</span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Les partenaires avec lesquels nous sommes susceptibles de travailler pour
                      exécuter nos missions, avec votre accord.
                    </span>
                  </p>
                </li>
              </ol>
              <p>
                <span>
                  Sans oublier (moins fun) les organismes publics, les auxiliaires de justice
                  (huissiers, notaires, etc), les officiers ministériels et les organismes chargés
                  d’effectuer le recouvrement de créances.
                </span>
              </p>
              <p>
                <span>
                  <strong>
                    Sachez que vos données à caractère personnel ne feront jamais l’objet de
                    cessions, locations ou échanges au bénéfice de tiers.
                  </strong>
                </span>
              </p>
              <p>&nbsp;</p>
              <ol start="3">
                <li>
                  <p>
                    <span>
                      <strong>Durée de conservation des données à caractère personnel</strong>
                    </span>
                  </p>
                </li>
              </ol>
              <p>
                <span>
                  Les données sont conservées pendant toute la durée de votre inscription sur le
                  site, et pour une durée de&nbsp;:
                </span>
              </p>
              <ul>
                <li>
                  <p>
                    <span>
                      Trois (3) ans à compter de votre désinscription pour ce qui concerne
                      l’utilisation des données à des fins de prospection. Au terme de ce délai de
                      trois (3) ans, nous pourrons reprendre contact avec vous pour savoir si vous
                      souhaitez continuer à recevoir des sollicitations commerciales.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Trois (3) ans minimum à compter de l’exercice du droit d’opposition concernant
                      les informations permettant de prendre en compte votre droit.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Cinq (5) ans à compter de votre désinscription pour ce qui concerne le respect
                      des obligations légales et réglementaires, ainsi que pour permettre d’établir
                      la preuve d’un droit ou d’un contrat.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>Treize (13) mois concernant les cookies visés à l’article 6.</span>
                  </p>
                </li>
              </ul>
              <p lang="fr-CA">&nbsp;</p>
              <ol start="4">
                <li>
                  <p>
                    <span>
                      <strong>Sécurité</strong>
                    </span>
                  </p>
                </li>
              </ol>
              <p>
                <span>
                  Pas d’inquiétude&nbsp;! Nous (et nos sous-traitants) avons pris toutes les
                  précautions nécessaires, ainsi que les mesures organisationnelles et techniques
                  appropriées pour préserver la sécurité, l’intégrité et la confidentialité de vos
                  données à caractère personnel.
                </span>
              </p>
              <p>&nbsp;</p>
              <ol start="5">
                <li>
                  <p>
                    <span>
                      <strong>Hébergement</strong>
                    </span>
                  </p>
                </li>
              </ol>
              <p>
                <span>
                  Elles sont également susceptibles d’être hébergées sur les serveurs de la société
                  [Microsoft Azure], situées dans l’Union Européenne et aux États-Unis.
                </span>
              </p>
              <p>
                <span>
                  Celles-ci ont adhéré au «&nbsp;Privacy Shield&nbsp;», qui a été jugé comme offrant
                  un niveau de protection adéquat par une décision de la Commission Européenne du 12
                  août 2016 (Voir la{' '}
                  <a href="https://www.privacyshield.gov/participant?id=a2zt0000000KzNaAAK&amp;status=Active">
                    décision d’adéquation
                  </a>
                  ). Pour en savoir plus sur la protection offerte par le «&nbsp;Privacy
                  Shield&nbsp;», vous pouvez consulter son{' '}
                  <a href="https://www.privacyshield.gov/">site internet</a>.
                </span>
              </p>
              <p lang="fr-CA">&nbsp;</p>
              <ol start="6">
                <li>
                  <p>
                    <span>
                      <strong>Cookies</strong>
                    </span>
                  </p>
                </li>
              </ol>
              <p>
                <span>
                  Les cookies sont des fichiers textes, souvent cryptés, stockés dans votre
                  navigateur. Ils sont créés lorsque le navigateur d’un utilisateur charge un site
                  internet donné&nbsp;: le site envoie des informations au navigateur, qui créé
                  alors un fichier texte. Chaque fois que l’utilisateur revient sur le même site, le
                  navigateur récupère ce fichier et l’envoie au serveur du site internet.
                </span>
              </p>
              <p>
                <span>
                  On peut distinguer différents types de cookies, qui n’ont pas les mêmes
                  finalités&nbsp;:
                </span>
              </p>
              <ul>
                <li>
                  <p>
                    <span>
                      Les cookies techniques sont utilisés tout au long de votre navigation, afin de
                      la faciliter et d’exécuter certaines fonctions. Un cookie technique peut par
                      exemple être utilisé pour mémoriser les réponses renseignées dans un
                      formulaire ou encore les préférences de l’utilisateur s’agissant de la langue
                      ou de la présentation d’un site internet, lorsque de telles options sont
                      disponibles.
                    </span>
                  </p>
                </li>
              </ul>
              <p>
                <span>Nous utilisons des cookies techniques.</span>
              </p>
              <ul>
                <li>
                  <p>
                    <span>
                      Les cookies des réseaux sociaux peuvent être créés par les plateformes
                      sociales pour permettre aux concepteurs de sites internet de partager le
                      contenu de leur site sur lesdites plateformes. Ces cookies peuvent notamment
                      être utilisés par les plateformes sociales pour tracer la navigation des
                      internautes sur le site internet concerné, qu’ils utilisent ou non ces
                      cookies.
                    </span>
                  </p>
                </li>
              </ul>
              <p>
                <span>
                  Nous utilisons des cookies des réseaux sociaux. Ces cookies ne sont déposés que si
                  vous donnez votre accord. Vous pouvez vous informer sur leur nature, les accepter
                  ou les refuser.
                </span>
              </p>
              <p>
                <span>
                  Nous vous invitons par ailleurs à consulter les politiques de protection de la vie
                  privée des plateformes sociales à l’origine de ces cookies, pour prendre
                  connaissance des finalités d’utilisation des informations de navigation qu’elles
                  peuvent recueillir grâce à ces cookies et des modalités d’exercice de vos droits
                  auprès de ces plateformes.
                </span>
              </p>
              <ul>
                <li>
                  <p>
                    <span>
                      Les cookies publicitaires peuvent être créés non seulement par le site
                      internet sur lequel l’utilisateur navigue, mais également par d’autres sites
                      internet diffusant des publicités, annonces, widgets ou autres éléments sur la
                      page affichée. Ces cookies peuvent notamment être utilisés pour effectuer de
                      la publicité ciblée, c’est-à-dire de la publicité déterminée en fonction de la
                      navigation de l’utilisateur.
                    </span>
                  </p>
                </li>
              </ul>
              <p>
                <span>
                  Nous utilisons également des cookies publicitaires Ces cookies ne sont déposés que
                  si vous donnez votre accord. Vous pouvez vous informer sur leur nature, les
                  accepter ou les refuser.
                </span>
              </p>
              <ul>
                <li>
                  <p>
                    <span>
                      Nous utilisons Google Analytics, Google Tag Manager, Matomo Analytics et
                      Matomo Tag Manager qui sont des outils statistiques d’analyse d’audience
                      qui génèrent un cookie permettant de mesurer le nombre de visites sur
                      le Site, le nombre de pages vues et l’activité des visiteurs. Votre adresse IP
                      est également collectée pour déterminer la ville depuis laquelle vous vous
                      connectez. Ce cookie n’est déposé que si vous donnez votre accord. Vous pouvez
                      l’accepter ou le refuser.
                    </span>
                  </p>
                </li>
              </ul>
              <p>
                <span>
                  Nous vous rappelons à toutes fins utiles qu’il vous est possible de vous opposer
                  au dépôt de cookies en configurant votre navigateur. Un tel refus pourrait
                  toutefois empêcher le bon fonctionnement du Site.
                </span>
              </p>
              <p>&nbsp;</p>
              <ol start="7">
                <li>
                  <p>
                    <span>
                      <strong>Vos droits</strong>
                    </span>
                  </p>
                </li>
              </ol>
              <p>
                <span>Quels sont vos droits sur vos données&nbsp;?</span>
              </p>
              <ul>
                <li>
                  <p>
                    <span>
                      <strong>Droit à l’information</strong>&nbsp;: C’est justement la raison pour
                      laquelle nous avons rédigé la présente charte
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <strong>Droit d’accès&nbsp;:</strong> Vous avez le droit d’accéder à tout
                      moment à l’ensemble de vos données à caractère personnel
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <strong>Droit de rectification&nbsp;: </strong>Vous avez le droit de rectifier
                      à tout moment vos données à caractère personnel inexactes, incomplètes ou
                      obsolètes
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <strong>Droit à la limitation&nbsp;: </strong>Vous avez le droit d’obtenir la
                      limitation du traitement de vos données à caractère personnel dans certains
                      cas définis à l’art.18 du RGPD
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <strong>Droit à la portabilité&nbsp;: </strong>Vous avez le droit de recevoir
                      vos données à caractère personnel dans un format lisible et d’exiger leur
                      transfert au destinataire de votre choix
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <strong>Droit à l’oubli&nbsp;:</strong> Vous avez le droit d’exiger que vos
                      données à caractère personnel soient effacées, et d’en interdire toute
                      collecte future
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <strong>Droit d’introduire une réclamation </strong>auprès d’une autorité de
                      contrôle compétente (en France, la CNIL), si vous considérez que le traitement
                      de vos données à caractère personnel constitue une violation des textes
                      applicables<strong></strong>
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <strong>Droit d’opposition </strong>Vous avez le droit de vous opposer au
                      traitement de vos données à caractère personnel. Notez toutefois que nous
                      pourrons maintenir leur traitement malgré cette opposition, pour des motifs
                      légitimes ou la défense de droits en justice.
                    </span>
                  </p>
                </li>
              </ul>
              <p>
                <span>
                  S’agissant de la prospection, vous pouvez vous y opposer à tout moment via le lien
                  de désinscription situé au bas de chacun de nos emails de prospection.
                </span>
              </p>
              <ul>
                <li>
                  <p>
                    <span>
                      <strong>{`Droit de définir des directives après votre mort&nbsp;`}</strong>
                      {`relatives à la conservation, à l'effacement et à la communication de vos données à caractère personnel, et désignant une personne chargée de leur exécution le cas échéant.`}
                    </span>
                  </p>
                </li>
              </ul>
              <p>
                <span>
                  Vous pourrez les transmettre à un tiers de confiance numérique certifié par la
                  CNIL lorsqu’il s’agit de directives générales, ou au aux coordonnées ci-dessous
                  pour vos directives spécifiques.
                </span>
              </p>
              <p>
                <span>Vous pouvez modifier ou révoquer vos directives à tout moment.</span>
              </p>
              <p>
                <span>
                  Vous pouvez exercer les droits ci-dessus en nous écrivant à rgpd@myxtramile.com
                </span>
              </p>
              <p>
                <span>
                  Ou à l’adresse suivante, pour les adeptes de la plume&nbsp;: XTRAMILE, 11 Rem
                  Saint-Thiebault - 57000 Metz
                </span>
              </p>
              <p>
                <span>
                  Merci de nous fournir, à cette occasion, une copie d’un titre d’identité signé.
                </span>
              </p>
              <p>&nbsp;</p>
              <ol start="8">
                <li>
                  <p>
                    <span>
                      <strong>Entrée en vigueur</strong>
                    </span>
                  </p>
                </li>
              </ol>
              <p>&nbsp;</p>
              <p>
                <span>La présente charte est entrée en vigueur le 26 Juillet 2019.</span>
              </p>
              <p>&nbsp;</p>
              <ol start="9">
                <li>
                  <p>
                    <span>
                      <strong>Modifications</strong>
                    </span>
                  </p>
                </li>
              </ol>
              <p>
                <span>
                  Nous nous réservons le droit, à notre seule discrétion, de modifier à tout moment
                  la présente charte, en totalité ou en partie. Ces modifications entreront en
                  vigueur à compter de la publication de la nouvelle charte. Votre utilisation du
                  Site suite à l’entrée en vigueur de ces modifications vaudra reconnaissance et
                  acceptation de la nouvelle charte. A défaut et si cette nouvelle charte ne vous
                  convient pas, vous ne devrez plus accéder au Site.
                </span>
              </p>
            </div>
          ) : (
            <div className={styles.text}>
              <p>&nbsp;</p>
              <ul>
                <li>
                  <p>
                    <span>
                      <strong>What is the purpose of this statement?</strong>
                    </span>
                  </p>
                </li>
              </ul>
              <p>
                <span>At Xtramile, the protection of your personal data is a priority!</span>
              </p>
              <p>
                <span>
                  The purpose of this statement is to inform you of how we process your personal
                  data.
                </span>
              </p>
              <p>
                <span>
                  {`Do not worry, we are acting in accordance with the Act of 1978 on Information Technology, Data Files and Civil Liberties and now with the EU Regulation of 27 April 2016 (hereinafter: the `}
                  <strong>{`"GDPR"`}</strong>
                  {`) when collecting and managing your data.`}
                </span>
              </p>
              <p>&nbsp;</p>
              <ul>
                <li>
                  <p>
                    <span>
                      <strong>What is a personal data?</strong>
                    </span>
                  </p>
                </li>
              </ul>
              <p>
                <span>
                  {`We may ask you to provide us with personal data concerning you throughout the use the services offered by Xtramile (hereinafter: the `}
                  <strong>{`"Services"`}</strong>
                  {`).`}
                </span>
              </p>
              <p>
                <strong>{`"Personal data"`}</strong>
                {` refers to all data identifying you as an individual.`}
              </p>
              <p>&nbsp;</p>
              <ul>
                <li>
                  <p>
                    <span>
                      <strong>What personal data does Xtramile collect?</strong>
                    </span>
                  </p>
                </li>
              </ul>
              <p>
                <span>
                  As part of our activity, we collect some information concerning you, including
                  your
                  <strong>
                    {' '}
                    last name, first name, phone number, email address, current position,
                    background, professional experience, expertise, IP address.
                  </strong>
                </span>
              </p>
              <p>
                <span>Those data may be collected as you use our Services.</span>
              </p>
              <p>&nbsp;</p>
              <ul>
                <li>
                  <p>
                    <span>
                      <span>
                        <span>
                          <strong>Qui est le responsable du traitement&nbsp;?</strong>
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
              </ul>
              <p>
                <span>XTRAMILE</span>
              </p>
              <p>
                <span>SAS - RCS&nbsp;Metz&nbsp;: 808&nbsp;932&nbsp;037</span>
              </p>
              <p>
                <span>11 Rem Saint-Thiebault – 57000 METZ</span>
              </p>
              <p>
                <span>rgpd@myxtramile.com</span>
              </p>
              <p>&nbsp;</p>
              <ol>
                <li>
                  <p>
                    <span>
                      <strong>Use of your personal data</strong>
                    </span>
                  </p>
                </li>
              </ol>
              <p>
                <span>On what ground(s) are your data collected&nbsp;and used for?</span>
              </p>
              <ul>
                <li>
                  <p>
                    <span>
                      Contract: The collect is necessary to the execution of the contract you
                      entered into when using our Services
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Legitimate interest: When you intentionally provide us with personal data, we
                      collect them in order to better answer your information requests regarding our
                      Services.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Legal requirement: When the processing of your data is necessary to comply
                      with a legal requirement we are subjected to
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Consent: Regarding collect and storage of your personal data through cookies
                      (Google Analytics, Google Tag Manager, Matomo Analytics, Matomo Tag Manager)
                      or our contact form used on our website
                    </span>
                  </p>
                </li>
              </ul>
              <p>&nbsp;</p>
              <p>
                <span>Why are your data collected&nbsp;and what are they used for?</span>
              </p>
              <p>&nbsp;</p>
              <ul>
                <li>
                  <p>
                    <span>
                      To create a file gathering users, subscribers and sales leads. To manage your
                      access to some of the Services and their use.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>{`To develop business statistics. To customize the answers given to your information requests. To manage people's opinion about the Services. To send information or market researches`}</span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>To comply with our legal and regulatory requirements</span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      To assess the number of visits, number of page viewed and activity of the
                      users when visiting our website, and to answer your information requests about
                      our Services
                    </span>
                  </p>
                </li>
              </ul>
              <p>
                <span>
                  NB: You may always refuse to give us your data, then we will tell you about the
                  possible consequences of such a refusal when those data are mandatory.
                </span>
              </p>
              <p>&nbsp;</p>
              <ol start="2">
                <li>
                  <p>
                    <span>
                      <strong>Recipients of collected data</strong>
                    </span>
                  </p>
                </li>
              </ol>
              <p>
                <span>The following parties will have access to your personal data:</span>
              </p>
              <ol type="i">
                <li>
                  <p>
                    <span>Our team,</span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>Services in charge of the management (including the auditor),</span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>Our sub-contractors,</span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Partners we may work with for the performances of our mission, with your
                      agreement.
                    </span>
                  </p>
                </li>
              </ol>
              <p>
                <span>
                  Not to mention (less funny) public bodies, court officers (bailiff, notary public,
                  etc.), officers of the court and bodies in charge of debt recovery.
                </span>
              </p>
              <p>
                <span>
                  <strong>
                    Note that your personal data will never be sold, rented or exchanged to the
                    benefit of third parties.
                  </strong>
                </span>
              </p>
              <p>&nbsp;</p>
              <ol start="3">
                <li>
                  <p>
                    <span>
                      <strong>Retention period for personal data</strong>
                    </span>
                  </p>
                </li>
              </ol>
              <p>
                <span>
                  Data are retained throughout the duration of your registration, on the website and
                  for a duration of:
                </span>
              </p>
              <ul>
                <li>
                  <p>
                    <span>
                      Three (3) years from the moment you closed your registration with regard to
                      the use of data for targeting purposes. At the end of this three (3) years
                      period, we may contact you to know if you wish to keep receiving commercial
                      offers.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      A minimum of three (3) years from the exercise of the right to object with
                      regard to information allowing to take your right into account.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Five (5) years from the moment you closed your registration with regard to the
                      compliance with legal and regulatory requirements, and to allow for the proof
                      of a right or a contract.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Thirteen (13) months with regard to cookies referred to in Article 6.
                    </span>
                  </p>
                </li>
              </ul>
              <p>&nbsp;</p>
              <ol start="4">
                <li>
                  <p>
                    <span>
                      <strong>Security</strong>
                    </span>
                  </p>
                </li>
              </ol>
              <p>
                <span>
                  No worries! We (as well as our sub-contractors) took all necessary precautions and
                  appropriate organizational and technical measures to protect the security,
                  integrity and confidentiality of your personal data.
                </span>
              </p>
              <p>&nbsp;</p>
              <ol start="5">
                <li>
                  <p>
                    <span>
                      <strong>Hosting</strong>
                    </span>
                  </p>
                </li>
              </ol>
              <p>
                <span>
                  They may also be hosted on the servers of the company [Microsoft Azure], located
                  in the European Union and to the United-States.
                </span>
              </p>
              <p>
                <span>
                  {`They joined the "Privacy Shield", deemed to be offering an appropriate level of protection by a decision of the European Commission of 12 August 2016 (See the `}
                  <a href="https://www.privacyshield.gov/participant?id=a2zt0000000KzNaAAK&amp;status=Active">{`adequacy decision`}</a>
                  {`). To learn more about the protection offered by the "Privacy Shield", visit its `}
                  <a href="https://www.privacyshield.gov/">{`website`}</a>.
                </span>
              </p>
              <p>&nbsp;</p>
              <ol start="6">
                <li>
                  <p>
                    <span>
                      <strong>Cookies</strong>
                    </span>
                  </p>
                </li>
              </ol>
              <p>
                <span>
                  Cookies are text files, often encrypted, stored in your browser. They are created
                  when the browser of a user is downloading a specific website: the website sends
                  information to the browser, which then creates a text file. Whenever the user
                  comes back on the same website, the browser retrieves the file and sends it to the
                  server of the website.
                </span>
              </p>
              <p>
                <span>There are different types of cookies, serving different purposes:</span>
              </p>
              <ul>
                <li>
                  <p>
                    <span>We use technical cookies.</span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      Social network cookies may be created by social platforms to allow website
                      developers to share the content of their website on those platforms. Cookies
                      may especially be used by social platforms to track the browsing of Internet
                      users on the relevant website, whether they use those cookies or not.
                    </span>
                  </p>
                </li>
              </ul>
              <p>
                <span>
                  We use social network cookies. Those cookies are placed only if you give your
                  consent. You can find information regarding their nature, allow or block them.
                </span>
              </p>
              <p>
                <span>
                  In addition, we encourage you to read privacy statements of the social platforms
                  creating those cookies, for more details regarding the purposes for which browsing
                  information they collected through those cookies are to be used and procedures for
                  exercising your rights with those platforms.
                </span>
              </p>
              <ul>
                <li>
                  <p>
                    <span>{`Advertising cookies may not only be created by the website the user is visiting, but also by other websites that are serving advertising, announcements, widgets or other elements of the page. Those cookies may especially be used to serve targeted advertising, that is to say advertising selected according to the user's browser.`}</span>
                  </p>
                </li>
              </ul>
              <p>
                <span>
                  We also use advertising cookies. They are placed only if you give your consent.
                  You can find information regarding their nature, allow or block them.
                </span>
              </p>
              <ul>
                <li>
                  <p>
                    <span>
                      We use Google Analytics, Google Tag Manager, Matomo Analytics and
                      Matomo Tag Manager, statistical tools for web analysis that create
                      a cookie allowing the assessment of the number of visits on the Website, number of
                      pages seen and the activity of the visitors. Your IP address is also collected
                      to identify the city from which you are connecting. That cookie is placed only
                      if you give your consent. You can allow or block it.
                    </span>
                  </p>
                </li>
              </ul>
              <p>
                <span>
                  We remind you that you may set your browser to object to the placement of cookies.
                  Such refusal may prevent proper functioning of the website.
                </span>
              </p>
              <p>&nbsp;</p>
              <ol start="7">
                <li>
                  <p>
                    <span>
                      <strong>Your rights</strong>
                    </span>
                  </p>
                </li>
              </ol>
              <p>
                <span>What are your rights regarding your data?</span>
              </p>
              <ul>
                <li>
                  <p>
                    <span>
                      <strong>Right to be informed</strong>&nbsp;: That is the reason why we wrote
                      this Policy.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <strong>Right of access:</strong> You have the right to access all of your
                      personal data at any time
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <strong>Right to rectification: </strong>You have the right to rectify
                      inaccurate, incomplete or outdated personal data at any time
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <strong>Right to restriction: </strong>You have the right to obtain
                      restriction of processing of your personal data under some circumstances as
                      defined in Article 18 of CDPR
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <strong>Right to portability: </strong>You have the right to receive your
                      personal data in a readable format and to obtain their transfer to the
                      recipient of your choice
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <strong>Right to be forgotten:</strong> You have the right to obtain the
                      erasure of your personal data and to prohibit any future collection
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <strong>Right to lodge a complaint </strong>with a competent supervisory
                      authority (the CNIL in France) where you consider that the processing of your
                      data infringes applicable texts
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <strong>Right to object </strong>You have the right to object to the
                      processing of your personal data. However, we may maintain their processing
                      despite this objection, based on legitimate grounds or defense of legal
                      claims.
                      <br />
                      In terms of direct marketing purposes, you may object at any time through the
                      unsubscribe link available at the bottom of each of our marketing emails.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <strong>Right to transmit instructions after your death </strong>regarding the
                      retention, erasure and communication of your personal data and appointing a
                      person responsible for their execution when necessary.
                      <br />
                      You will be able to transfer them to a trusted third party on Internet
                      certified by the CNIL when it comes to general instructions, or at the contact
                      details below when it comes to your specific instructions.
                    </span>
                  </p>
                </li>
              </ul>
              <p>
                <span>You can modify or withdraw your instructions at any time.</span>
              </p>
              <p>
                <span>
                  Or at the following address, for writing enthusiasts: XTRAMILE, 11 Rempart
                  Saint-Thiebault - 57000 Metz
                </span>
              </p>
              <p>
                <span>
                  On this occasion, please provide us with a signed copy of any identity document.
                </span>
              </p>
              <p>&nbsp;</p>
              <ol start="8">
                <li>
                  <p>
                    <span>
                      <strong>Application</strong>
                    </span>
                  </p>
                </li>
              </ol>
              <p>&nbsp;</p>
              <p>
                <span>This policy became effective on the 26 July 2019.</span>
              </p>
              <p>&nbsp;</p>
              <ol start="9">
                <li>
                  <p>
                    <span>
                      <strong>Amendments</strong>
                    </span>
                  </p>
                </li>
              </ol>
              <p>
                <span>
                  We reserve the right to, at our sole discretion, amend this policy at any moment,
                  in whole or in part. Those amendments will be effective from the publication of
                  the new policy. If you continue to use the Website after the publication of the
                  amendments, you will be deemed to have acknowledged and accepted the new policy.
                  On the contrary, and if the new policy is unacceptable to you, you shall not
                  access the website.
                </span>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

PrivacyStatementPage.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default PrivacyStatementPage;
