import React from 'react';
import PropTypes from 'prop-types';

import styles from './dataJobCollapse.module.scss';
import CustomButton from 'components/customButton/customButton';
const getXtramileLevel = (type, value) => {
  const levelValue = type.filter((item) => item.source === value);
  return levelValue[0].value;
};

const DataJobCollapse = ({ currentJob, t, backToInitial, goToContactForm }) => {
  if (!currentJob)
    return <div className={styles.noDataMessage}>{t('valuesOfJOB.Here no data yet')}</div>;

  const {
    global_occupation: { type },
  } = currentJob;
  const xtramileLevel1 = getXtramileLevel(type, 'xtramile1');
  const xtramileLevel3 = getXtramileLevel(type, 'xtramile3');

  return (
    <div className={styles.dataCollapseContainer}>
      <p className={styles.dataCollapseText}>
        {t('text.It looks')}
        <span className={styles.dataCollapseLevelValue}>{xtramileLevel3}</span>
        {t('text.jobs in the')}
        <span className={styles.dataCollapseLevelValue}>{xtramileLevel1}</span>
        {t('text.We have the information')}
      </p>
      <div className={styles.buttonsContainer}>
        <CustomButton text={t('buttons.Try another file')} onClick={backToInitial} />
        <CustomButton text={t('text.Contact us')} onClick={goToContactForm} />
      </div>
    </div>
  );
};

DataJobCollapse.propTypes = {
  currentJob: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  backToInitial: PropTypes.func.isRequired,
  goToContactForm: PropTypes.func.isRequired,
};

export default DataJobCollapse;
