import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import styles from './introductionForTechnology.module.scss';

const IntroductionForTechnology = ({ t }) => {
  return (
    <section className={styles.sectionIntroductionForTechnology}>
      <div className="container">
        <div className={styles.wrapper}>
          <div className={styles.leftCol}>
            <div className={styles.grayBloc}>
              <span className={styles.textBlock}>
                {t(
                  'text.In order to accelerate its development and be in constant contact with a dynamic technological environment, the startup joined the 2nd class of Microsoft AI Factory in 2019 and joined the Microsoft HQ incubator at Station F'
                )}
              </span>
            </div>
            <p className={styles.underText}>
              Station F<span className={styles.yellowText}>I</span>
              {i18n.language === 'fr' ? 'Programme' : 'Program'} AI Factory Microsoft
              <span className={styles.yellowText}>I</span>
              2019
            </p>
          </div>
          <div className={styles.videoWrapper}>
            <iframe
              src="https://www.youtube.com/embed/Q86FvSr-sZI"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

IntroductionForTechnology.propTypes = {
  t: PropTypes.func.isRequired,
};

export default IntroductionForTechnology;
