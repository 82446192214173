import React from 'react';
import PropTypes from 'prop-types';

import styles from './productCards.module.scss';

import multidiffIcon from 'assets/icons/picto_Trading_bleu_tech.svg';
import parsingIcon from 'assets/icons/picto_Parsing_white_tech.svg';
import servicesIcon from 'assets/icons/picto_sourcingRH.svg';
import Yarrow from 'assets/icons/YellowArrow.svg';
import Warrow from 'assets/icons/WhiteArrow.svg';
import productNames from 'data/productNames';
import { Link } from 'react-router-dom';

const { XSoftJob, XPaUrl, XSoftCV, XRaUrl, XServices, XServicesUrl, ImpactRH, ImpactRHUrl } = productNames;

const ProductCards = ({ t }) => {
  return (
    <section className="container">
      <div className={styles.productsTwoByTwo}>
        <div className={styles.cardsContainer}>
          <div className={`${styles.card} ${styles.yellow}`}>
            <img className={styles.icon} src={multidiffIcon} alt={XSoftJob} />
            <p className={styles.baseline}>{t('titles.Automate your job offers using')}</p>
            <div className={`${styles.product}`}>
              <p className={`${styles.big} ${styles.white}`}>{XSoftJob}</p>
            </div>
            <Link
              to={XPaUrl}
              onClick={() => {
                document
                  .querySelector('.app-wrapper')
                  .scrollIntoView({ block: 'start', behavior: 'smooth' });
              }}
            >
              <img src={Warrow} alt="arrow" className={styles.arrow} />
            </Link>
          </div>
          <div className={`${styles.card} ${styles.white}`}>
            <img className={styles.icon} src={parsingIcon} alt={XSoftCV} />
            <p className={`${styles.baseline} ${styles.white}`}>
              {t('titles.Automate your CV readings with')}
            </p>
            <div className={`${styles.product}`}>
              <p className={`${styles.big} ${styles.yellow}`}>{XSoftCV}</p>
            </div>
            <Link
              to={XRaUrl}
              onClick={() => {
                document
                  .querySelector('.app-wrapper')
                  .scrollIntoView({ block: 'start', behavior: 'smooth' });
              }}
            >
              <img src={Yarrow} alt="arrow" className={styles.arrow} />
            </Link>
          </div>
        </div>
        <div className={styles.cardsContainer}>
          <div className={`${styles.card} ${styles.yellow}`}>
            <img className={styles.icon} src={servicesIcon} alt={XServices} />
            <p className={styles.baseline}>{t('titles.Optimise your recruitment campaigns')}</p>
            <div className={`${styles.product}`} style={{ padding: '25px 0' }}>
              <p className={`${styles.big} ${styles.white}`}>{XServices}</p>
            </div>
            <Link
              to={XServicesUrl}
              onClick={() => {
                document
                  .querySelector('.app-wrapper')
                  .scrollIntoView({ block: 'start', behavior: 'smooth' });
              }}
            >
              <img src={Warrow} alt="arrow" className={styles.arrow} />
            </Link>
          </div>
          <div className={`${styles.card} ${styles.white}`}>
            <img className={styles.icon} src={parsingIcon} alt={ImpactRH} />
            <p className={`${styles.baseline} ${styles.white}`}>
              {t('titles.Pioneer in HR Data')}
            </p>
            <div className={`${styles.product}`} style={{ padding: '35px 0' }}>
              <p className={`${styles.big} ${styles.yellow}`}>{ImpactRH}</p>
            </div>
            <Link
              to={ImpactRHUrl}
              onClick={() => {
                document
                  .querySelector('.app-wrapper')
                  .scrollIntoView({ block: 'start', behavior: 'smooth' });
              }}
            >
              <img src={Yarrow} alt="arrow" className={styles.arrow} />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

ProductCards.propTypes = {
  t: PropTypes.func.isRequired,
};

export default ProductCards;
