import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './dataCvCollapse.module.scss';
import Icon from 'components/icon';

const PersonalInformation = ({ t, data }) => {
  const {
    firstname,
    lastname,
    address,
    postal_code,
    city,
    country,
    email,
    phone_number,
    birthday,
    nationality,
    driver_licence,
  } = data;
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => setIsOpen(!isOpen);

  const shouldShow =
    firstname ||
    lastname ||
    address ||
    postal_code ||
    city?.plaintext ||
    email ||
    phone_number?.length > 0 ||
    birthday?.plaintext ||
    nationality?.plaintext ||
    country?.plaintext ||
    driver_licence?.plaintext;

  return (
    <>
      {shouldShow && (
        <div className={styles.dataSection}>
          <div className={styles.dataBlock}>
            <div className={styles.dataTitleContainer} onClick={toggle}>
              <span className={styles.itemTitle}>{t('valuesOfCV.personal information')}</span>
              {/*<span className={styles.itemValue}></span>*/}
              <span className={isOpen ? styles.activeArrow : styles.notActiveArrow}>
                <Icon name="arrow" width="10" height="5" />
              </span>
            </div>

            <div className={isOpen ? styles.activeBlock : styles.notActiveBlock}>
              <div className={styles.partOfData}>
                {firstname && (
                  <div className={styles.dataItem}>
                    <span className={styles.itemKey}>{t('valuesOfCV.firstname')}</span>
                    <span className={styles.itemValue}>{firstname}</span>
                  </div>
                )}

                {lastname && (
                  <div className={styles.dataItem}>
                    <span className={styles.itemKey}>{t('valuesOfCV.lastname')}</span>
                    <span className={styles.itemValue}>{lastname}</span>
                  </div>
                )}

                {address && (
                  <div className={styles.dataItem}>
                    <span className={styles.itemKey}>{t('valuesOfCV.address')}</span>
                    <span className={styles.itemValue}>{address}</span>
                  </div>
                )}

                {postal_code && (
                  <div className={styles.dataItem}>
                    <span className={styles.itemKey}>{t('valuesOfCV.postal code')}</span>
                    <span className={styles.itemValue}>{postal_code}</span>
                  </div>
                )}

                {city.plaintext && (
                  <div className={styles.dataItem}>
                    <span className={styles.itemKey}>{t('valuesOfCV.city')}</span>
                    <span className={styles.itemValue}>{city.plaintext}</span>
                  </div>
                )}

                {country.plaintext && (
                  <div className={styles.dataItem}>
                    <span className={styles.itemKey}>{t('valuesOfCV.country')}</span>
                    <span className={styles.itemValue}>{country.plaintext}</span>
                  </div>
                )}

                {email && (
                  <div className={styles.dataItem}>
                    <span className={styles.itemKey}>{t('valuesOfCV.email')}</span>
                    <span className={styles.itemValue}>{email}</span>
                  </div>
                )}

                {phone_number.length > 0 && (
                  <div className={styles.dataItem}>
                    <span className={styles.itemKey}>{t('valuesOfCV.phone_number')}</span>
                    <span className={styles.itemValue}>
                      {phone_number.map((item, numberIndex) => (
                        <span className={styles.phoneNumber} key={numberIndex}>
                          {item}
                        </span>
                      ))}
                    </span>
                  </div>
                )}

                {birthday.plaintext && (
                  <div className={styles.dataItem}>
                    <span className={styles.itemKey}>{t('valuesOfCV.birthday')}</span>
                    <span className={styles.itemValue}>{birthday.plaintext}</span>
                  </div>
                )}

                {nationality.plaintext && (
                  <div className={styles.dataItem}>
                    <span className={styles.itemKey}>{t('valuesOfCV.citizenship')}</span>
                    <span className={styles.itemValue}>{nationality.plaintext}</span>
                  </div>
                )}

                {driver_licence.plaintext && (
                  <div className={styles.dataItem}>
                    <span className={styles.itemKey}>{t('valuesOfCV.driver_licence')}</span>
                    <span className={styles.itemValue}>{driver_licence.plaintext}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

PersonalInformation.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default PersonalInformation;
