const productNames = {
  XPar: 'X-Jobs HR Trading & Programmatics',
  XPa: 'X-Jobs HR Trading &',
  XRa: 'X-CV Ranking',
  XPaUrl: 'x–job-programmatics',
  XRaUrl: 'x-cv-ranking',
  XPa1: 'X-Jobs',
  XPa2: 'HR Trading &',
  XPa3: 'Programmatics',
  XRa1: 'X-CV',
  XRa2: 'Ranking',

  XSoftJob: 'Xtramile Software (Job!)',
  XSoftJob1: 'Xtramile',
  XSoftJob2: 'Software',
  XSoftJob3: '(Job!)',

  XSoftCV: 'Xtramile Software (CV!)',
  XSoftCV1: 'Xtramile',
  XSoftCV2: 'Software',
  XSoftCV3: '(CV!)',

  XServices: 'Xtramile Services',
  XServices1: 'Xtramile',
  XServices2: 'Services',
  XServicesUrl: 'x-services',

  ImpactRH: 'Impact RH BI',
  ImpactRH1: 'Impact RH',
  ImpactRH2: 'BI',
  ImpactRHUrl: 'impact-rh',

};
export default productNames;
