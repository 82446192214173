import React, {
  useRef,
  // useState
} from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-elastic-carousel';

import picto from '../../../assets/icons/x-pr/pictoPendule.svg';
import styles from './slider1.module.scss';
// import Button from 'components/button/button';
// import DialogParse from 'components/dialogParse/dialogParse';
// import Icon from 'components/ui/icon';

const Slider1 = ({ t }) => {
  const data = [
    {
      id: '0',
      textPart1: t('text.Up to '),
      textNumber: '70%',
      textPart2: t('text. saving time and productivity'),
    },
    {
      id: '1',
      textPart1: '',
      textNumber: '50%',
      textPart2: t('text.increase in applications'),
    },
    {
      id: '2',
      textPart1: '',
      textNumber: '100%',
      textPart2: t('text.synergies with programmatic'),
    },
  ];

  const carousel = useRef(null);
  const delay = 2000;

  const setCarouselToStart = (currentItem) => {
    if (currentItem.index === 2) {
      setTimeout(() => {
        if (!carousel) return;

        carousel?.current?.goTo(0);
      }, delay);
    }
  };

  // const [showDialog, setShowDialog] = useState(false);
  // const targetDialog = (show) => {
  //   setShowDialog(show);
  // };

  return (
    <section className={`slider1 ${styles.section}`}>
      <div className={styles.sectionContent}>
        <div className={styles.imgWrapper}>
          <img src={picto} alt="picto" />
        </div>

        <div className={styles.text}>
          <span>
            {t('text.Recruiters today use')}
          </span>
          <span>
            {t(
              'text.This is what disappears'
            )}
          </span>
        </div>

        <div className={styles.slider}>
          <Carousel
            itemsToShow={1}
            enableSwipe={false}
            enableMouseSwipe={false}
            preventDefaultTouchmoveEvent={true}
            ref={carousel}
            showArrows={false}
            enableAutoPlay
            autoPlaySpeed={delay}
            transitionMs={delay}
            onChange={(currentItem) => {
              setCarouselToStart(currentItem);
            }}
          >
            <div key={data[0].id} className={styles.slide}>
              <div className={styles.slidersHeader}>
                <span>{data[0].textPart1}</span>
                <span className={styles.textNumber}>{data[0].textNumber}</span>
                <span>{data[0].textPart2}</span>
              </div>
            </div>

            <div key={data[1].id} className={styles.slide}>
              <div className={styles.slidersHeader}>
                <span className={styles.textNumber}>{data[1].textNumber}</span>
                <div>{data[1].textPart2}</div>
              </div>
            </div>

            <div key={data[2].id} className={styles.slide}>
              <div className={styles.slidersHeader}>
                <span className={styles.textNumber}>{data[2].textNumber}</span>
                <div>{data[2].textPart2}</div>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
      {/* <div className={styles.sectionWrapperButtonParse}>
        <Button
          btnView={`${'button-warn-sm'} ${'small'} button-try-parse`}
          text={t('buttons.TRY OUR PARSING')}
          icon={<Icon name="goal" width="25" height="25" color="#fff" viewbox="0 0 122 119" />}
          onClick={() => targetDialog(true)}
        />
      </div> */}
      {/* {
        showDialog &&
        <DialogParse
          type="job"
          demo={true}
          closeDialog={() => targetDialog(false)}
          t={t}
        />
      } */}
    </section>
  );
};

Slider1.propTypes = {
  t: PropTypes.func.isRequired,
};

export default Slider1;
