import React from 'react';
import PropTypes from 'prop-types';

import styles from './select.module.scss';

const Select = ({ nameSelect, options, value, onChange, t }) => {
  return (
    <select
      name={nameSelect}
      id={nameSelect}
      className={styles.select}
      value={value || '- Choose a value -'}
      onChange={onChange}
    >
      {options &&
        options.map((option, index) => (
          <option
            key={index}
            value={option.name}
            disabled={option.disabled}
            className={styles.option}
          >
            {option.name === '- Choose a value -' ? t('text.- Choose a value -') : option.name}
          </option>
        ))}
    </select>
  );
};

Select.propTypes = {
  nameSelect: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default Select;
