import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './customerReviews.module.scss';
import CustomersTestifySlider from 'components/caseStudies/feedbackSlider/customersTestifySlider';
import cx from 'classnames';
import productNames from 'data/productNames';
const { XSoftJob, XSoftCV, XServices } = productNames;

const CustomersReviewsSection = ({ t, shouldGoCollapse }) => {
  const [activeSlide, setActiveSlide] = useState(0);

  return (
    <section>
      <div className={styles.customerReviewsSection}>
        <div className={`${styles.col} ${styles.left}`}>
          <div className={styles.content}>
            <h1 className={styles.title}>{t('titles.Our clients testify')}</h1>
            <p className={styles.text}>{t('text.More than 70 companies trust us')}</p>
          </div>
        </div>
        <div className={styles.clientPart}>
          <div className={`${styles.col} ${styles.right}`}>
            <h3 className={styles.productName}>{XSoftJob}</h3>
            <span className={styles.clientName} onClick={() => setActiveSlide(3)}>
              L&lsquo;ARMÉE DE L&lsquo;AIR ET DE L&lsquo;ESPACE
            </span>
            <span className={styles.clientName} onClick={() => setActiveSlide(4)}>
              L&lsquo;ARMÉE DE TERRE
            </span>
          </div>
          <div className={`${styles.col} ${styles.right}`}>
            <h3 className={styles.productName}>{XSoftCV}</h3>
            <span className={styles.clientName} onClick={() => setActiveSlide(0)}>
              FIBOIS
            </span>
            <span className={styles.clientName} onClick={() => setActiveSlide(9)}>
              SPIE
            </span>
            <span className={styles.clientName} onClick={() => setActiveSlide(10)}>
              JOB +
            </span>
          </div>
          <div className={`${styles.col} ${styles.right}`}>
            <h3 className={styles.productName}>{XServices}</h3>
            <span className={styles.clientName} onClick={() => setActiveSlide(1)}>
              STEDY
            </span>
            <span className={styles.clientName} onClick={() => setActiveSlide(2)}>
              AFPA
            </span>
            <span className={styles.clientName} onClick={() => setActiveSlide(5)}>
              BOUYGUES
            </span>
            <span className={styles.clientName} onClick={() => setActiveSlide(6)}>
              ENGIE
            </span>
            <span className={styles.clientName} onClick={() => setActiveSlide(7)}>
              ADMR
            </span>
            <span className={styles.clientName} onClick={() => setActiveSlide(8)}>
              MOZAIK RH
            </span>
          </div>
        </div>
      </div>
      <div className={styles.customerReviewsCard}>
        <div
          className={cx('howItWorks', 'container')}
          style={{ boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.19)', marginBottom: '90px' }}
        >
          <CustomersTestifySlider
            t={t}
            activeSlide={activeSlide}
            shouldGoCollapse={shouldGoCollapse}
          />
        </div>
      </div>
    </section>
  );
};

CustomersReviewsSection.propTypes = {
  t: PropTypes.func.isRequired,
  shouldGoCollapse: PropTypes.bool.isRequired,
};

export default CustomersReviewsSection;
