import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import styles from './header.module.scss';
import Langs from './langs';
// import logo from 'assets/images/Xtramile_logo.png';
import logo from 'assets/images/Animation-logo-jaune.gif';
import customer_access from 'assets/icons/customer_access.svg';
import get_a_demo from 'assets/icons/get_a_demo.svg';

import burger from 'assets/icons/burger.svg';
import closeIcon from 'assets/icons/cross_close.svg';

import HeaderDropdown from 'components/headerDropdown/headerDropdown';
import SmallHeaderDropdown from 'components/smallHeaderDropdown/smallHeaderDropdown';
import productNames from 'data/productNames';

const { XPaUrl, XRaUrl } = productNames;

const Header = ({ t }) => {
  const [stateMenu, setStateMenu] = useState(false); //state of dropdown menu in HEADER
  const [location, setLocation] = useState(''); //the state of the location
  const [elem, setElem] = useState(''); //the element to scroll to
  const history = useHistory(); // current location

  //to open or to close dropdown menu in HEADER
  const toogleMenu = () => {
    setStateMenu((prev) => !prev);
  };

  const isWide = useMediaQuery({
    query: '(min-width: 1180px)',
  });

  const isWideEnough = useMediaQuery({
    query: '(min-width: 825px)',
  });

  const isSmall = useMediaQuery({
    query: '(max-width: 750px)',
  });

  //to close dropdown per click on body or click option of menu
  const closeDropdown = (e) => {
    //functioning per click on burger-menu

    if (typeof e.target.className !== 'string') return setStateMenu(false);

    if (
      e.target.className === 'burger-menu' ||
      e.target.className.includes('header_menu') ||
      e.target.className.includes('header_menuActive') ||
      e.target.className.includes('header_menuContainer') ||
      e.target.className.includes('header_burgerMenu')
    )
      return () => toogleMenu();

    setStateMenu(false);
  };

  useEffect(() => {
    document.addEventListener('click', (e) => closeDropdown(e));
    return document.removeEventListener('click', (e) => closeDropdown(e));
  }, []);

  useEffect(() => {
    if (elem) {
      //If there was a redirect from a page where there is no form
      if (history.location.pathname !== location) {
        //state to initialState
        setLocation('');
        setElem('');
        return () => document.getElementById(elem).scrollIntoView();
      }
      //scrolling to the form from the current page where the form is
      document.getElementById(elem).scrollIntoView({
        behavior: 'smooth',
      });

      //state to initialState
      setLocation('');
      setElem('');
    }
  });

  //form-guiding method
  const goToForm = (e, elem) => {
    e.preventDefault();

    const loc = history.location.pathname;
    //close dropdown menu
    setStateMenu((prev) => !prev);

    if (['/home', `/${XPaUrl}`, `/${XRaUrl}`].includes(loc)) {
      setLocation(loc);
      setElem(elem);
      return;
    }

    setLocation(loc);
    setElem(elem);
    history.push('/');
  };

  return (
    <section className={styles.container} id="header">
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <div>
            <Link to="/">
              <span className={styles.logo}>
                <img src={logo} alt="logo" />
              </span>
            </Link>

            {isWide && (
              <span className={styles.text}>
                {t('titles.Innovation at the service of recruitment & human capital')}
              </span>
            )}
          </div>

          <div>
            {!isSmall && (
              <a href="https://app.xtramile.io" className={styles.texLink} rel="noreferrer" target="_blank">
                <img
                  src={customer_access}
                  alt="customer_access"
                  style={{ width: '29px', height: '21px' }}
                />
                {isWideEnough && t('titles.customer access')}
              </a>
            )}
            <a href="#" className={styles.texLink} onClick={(e) => goToForm(e, 'contact')}>
              <img
                src={get_a_demo}
                alt="customer_access"
                style={{ width: '21px', height: '25px' }}
              />
              {isWideEnough && t('titles.get a demo')}
            </a>

            {!isSmall && <Langs mobileVersion={false} />}

            <div className={styles.menuContainer} onClick={toogleMenu}>
              {isWideEnough && (
                <span className={stateMenu ? styles.menuActive : styles.menu}>MENU</span>
              )}
              <span className={styles.burgerMenu}>
                <img
                  src={stateMenu ? closeIcon : burger}
                  alt="burger of menu"
                  className="burger-menu"
                />
              </span>
            </div>
          </div>
        </header>
        {stateMenu &&
          (isSmall ? (
            <SmallHeaderDropdown
              t={t}
              goToForm={(e, elem) => goToForm(e, elem)}
              closeDropdown={(e) => closeDropdown(e)}
            />
          ) : (
            <HeaderDropdown
              t={t}
              goToForm={(e, elem) => goToForm(e, elem)}
              closeDropdown={(e) => closeDropdown(e)}
            />
          ))}
      </div>
    </section>
  );
};

Header.propTypes = {
  t: PropTypes.func.isRequired,
};

export default Header;
