import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import styles from './disclaimerPage.module.scss';
import TagManager from 'react-gtm-module';

const DisclaimerPage = ({ t, title }) => {
  useEffect(() => {
    document.title = title || '';
  }, [title]);

  TagManager.dataLayer({
    dataLayer: {
      event: 'pageview',
      pagePath: '/disclaimer',
      pageTitle: 'Disclaimer',
    },
  });

  const lang = t('lang');

  return (
    <div className={styles.DisclaimerPage}>
      <div className="container">
        <div className={styles.wrapper}>
          <h4 className={styles.title}>{t('pageTitles.Disclaimer')}</h4>
          {lang === 'fr' ? (
            <div>
              <p>
                Ce site web contient des idées, des écrits et des documents originaux qui
                appartiennent à la société Xtramile (sauf précision contraire). La société Xtramile
                SAS se réserve les droits de propriété intellectuelle sur ce Site Web et son
                contenu.
              </p>
              <p>{`Ce Site Web a été développé comme un service offert par Xtramile SAS à ses utilisateurs. Comme tout autre service, en dépit de nos efforts, les informations fournies sur ce Site Web peuvent devenir obsolètes au fil du temps. Xtramile SAS décline toute responsabilité quant à l'exactitude ou l'exhaustivité ou l'utilisation de, ni accepte encourir une quelconque responsabilité ayant trait à la mise à jour des informations contenues sur ce Site Web. Xtramile SAS se réserve le droit de changer ou de supprimer ou de modifier le contenu de ce Site Web à tout moment sans obligation d'en informer quiconque. Ces documents et informations sont fournis « TELS QUELS » SANS GARANTIE D'AUCUNE SORTE, EXPLICITE OU IMPLICITE, Y COMPRIS, MAIS SANS S'Y LIMITER, LES GARANTIES IMPLICITES DE QUALITÉ MARCHANDE, D'ADÉQUATION À UN USAGE PARTICULIER OU D'ABSENCE DE CONTREFAÇON. En raison des progrès et des développements commerciaux en cours, les informations de ce Site Web peuvent ne pas toujours être complètement actualisées et, pour cette raison, ces informations sont fournies sur la base de « TELLES QUELLES ».`}</p>
              <p>{`NOUS NE FOURNISSONS AUCUNE GARANTIE, NE FAISONS AUCUNE DÉCLARATION ET NE PRENONS AUCUN ENGAGEMENT EXPLICITE OU IMPLICITE À PROPOS DE TOUT CONTENU DE CE SITE WEB (Y COMPRIS, SANS LIMITATION, LA PERTINENCE, LA CRÉDIBILITÉ, L'EXACTITUDE, L'EXHAUSTIVITÉ OU L'ADÉQUATION POUR UN USAGE PARTICULIER DE CE CONTENU OU LE FAIT QUE LES RÉSULTATS QUI POURRONT DÉCOULER DE L'UTILISATION DU SITE WEB SERONT SANS ERREUR OU FIABLES). Vous acceptez que l'utilisation de renseignements obtenus ou téléchargés à partir ou à travers ce Site Web sont à votre entière discrétion et risque.`}</p>
              <p>{`Rien sur ce Site Web ne doit être interprété comme l'expression d'un conseil, d’un avis, d’un opinion ou la formulation d'une recommandation ni ne devrait être invoqué comme fondement d'une décision ou action. `}</p>
              <p>{`Dans la mesure permise par la loi, Xtramile SAS décline expressément toute responsabilité pour toute perte ou dommage direct, indirect ou consécutif subi par l'Utilisateur de notre Site Web ou en relation avec l'Utilisation, l'incapacité d'utiliser, ou les résultats de l'utilisation de notre Site Web, tous les sites qui lui sont liés et tout matériel affiché sur celui-ci, y compris, sans limitation, toute responsabilité pour (i) la perte de revenus ou de profitss, (ii) la perte de chance, (iii) la perte de contrats, (iv) la perte d'économies anticipées, (v) la perte de données, (vi) la perte de clientèle, (vii) la perte de temps de gérance ou de travail ; et (viii) pour toute autre perte ou dommage de toute nature, quelle que soit la manière qu'il advienne et qu'il soit causé par un délit civil (y compris la négligence), violation de contrat ou autrement, même si prévisible.`}</p>
              <p>
                Nous ne garantissons pas que les fonctions contenues dans ce Site Web ne seront pas
                interrompues ou sans erreurs, que les défauts seront corrigés ou que ce Site Web ou
                le serveur qui le met à disposition des Utilisateurs seront totalement exempts de
                virus ou de bogues.
              </p>
              <p>{`Si ce Site Web contient des hyperliens ou des références à d'autres Sites Web (de tiers), ceux-ci devront être considérés comme un service à l’Utilisateur. Xtramile SAS ne peut pas contrôler d'autres site web et Xtramile SAS n'est donc pas responsable et n'accepte aucune responsabilité quant aux conséquences d'informations inexactes ou incomplètes figurant sur ces autres sites web. Nous vous conseillons de toujours vérifier les informations aussi bien sur leurs aspects juridiques que les politiques de confidentialité de tous les sites webs que vous visitez.`}</p>
              <p>
                {`Conformément à la loi française n°78-17 du 6 janvier 1978, aucune information personnelle n'est recueillie, ni communiquée sans votre connaissance ou le consentement du Site Web. Selon la loi « Informatique et Libertés » du 6 janvier1978, vous pouvez accéder à vos renseignements personnels, exiger des corrections et votre retrait de la base de données en envoyant un e-mail explicite dans ce sens à contact@myxtramile.com.`}
                <br />
                {`Si vous ne souhaitez pas recevoir d'informations commerciales susceptibles de vous intéresser, veuillez le préciser en envoyant un courrier électronique explicite dans ce sens à contact@myxtramile.com.`}
              </p>
              <p>PROPRIÉTÉ INTELLECTUELLE</p>
              <p>
                {`L'intégralité du Site Web est soumise à la législation française sur les droits d'auteur et le droit de propriété intellectuelle. Tous les droits de reproduction sont réservés, y compris les documents téléchargeables, les illustrations et les photographies. La reproduction de tout ou une partie de ce Site Web sur un support électronique est formellement interdite sauf autorisation expresse et préalable de Xtramile SAS.&nbsp;`}
                <br />
                {`Xtramile SAS vous autorise par la présente la copie des documents publiés sur ce Site Web pour une utilisation purement non commerciale, conformément aux Conditions Générales des Utilisateurs, pourvu que toute copie de ces matériaux que vous effectuez conserve tous les droits d'auteur et autres avis de propriété ainsi que les avertissements figurant sur la présente et sur le Site Web, et en respectant les trois conditions suivantes : la distribution gratuite, le respect de l'intégrité du contenu reproduit et la mention bien lisible de la source du document.`}
              </p>
              <p>{`Nonobstant ce consentement limité, toute autre utilisation (en partie) sans le consentement écrit préalable de Xtramile SAS est interdite. Une demande de consentement pour l'utilisation (une partie de) de ce site web peut être envoyée à contact@myxtramile.com .`}</p>
            </div>
          ) : (
            <div>
              <p>
                This Website contains original ideas, text and figures. Xtramile SAS reserves the
                intellectual property rights on this Website and its content.
              </p>
              <p>{`This Website has been developed as a service of Xtramile SAS. Like any other service, in spite of our best efforts the information in this Website may become out of date over time. Xtramile SAS accepts no liability for the accuracy or completeness or use of, nor any liability to update, the information contained on this website. Xtramile SAS reserves the right to change or amend, remove or alter the content of this website at any time without the obligation to inform anyone. These materials are provided 'AS IS' WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. As a result of ongoing business advances and developments, the information on this Website may not always be completely up to date and, for this reason, such information is provided on an 'AS IS' and 'AS AVAILABLE' basis.`}</p>
              <p>
                WE MAKE NO WARRANTIES, REPRESENTATIONS OR GIVE ANY UNDERTAKINGS EITHER EXPRESS OR
                IMPLIED ABOUT ANY OF THE CONTENT OF THIS WEBSITE (INCLUDING, WITHOUT LIMITATION, THE
                TIMELINESS, CURRENCY, ACCURACY, COMPLETENESS OR FITNESS FOR ANY PARTICULAR PURPOSE
                OF SUCH CONTENT OR THAT THE RESULTS WHICH MAY BE OBTAINED FROM THE USE OF THE
                WEBSITE WILL BE ERROR FREE OR RELIABLE). You agree that the use of information
                obtained or downloaded from or through this Website is at your sole discretion and
                risk. Some jurisdictions do not allow the exclusion of implied warranties, so the
                above exclusion may not apply to you.
              </p>
              <p>
                Nothing on this Website should be construed as the giving of advice or the making of
                a recommendation and it should not be relied on as the basis for any decision or
                action.&nbsp;
              </p>
              <p>
                To the extent permitted by law, Xtramile SAS and third parties connected to us
                hereby expressly exclude any liability for any direct, indirect or consequential
                loss or damage incurred by any user in connection with our website or in connection
                with the use, inability to use, or results of the use of our website, any websites
                linked to it and any materials posted on it, including, without limitation any
                liability for (i) loss of income or revenue, (ii) loss of business, (iii) loss of
                profits or contracts, (iv) loss of anticipated savings, (v) loss of data, (vi) loss
                of goodwill, (vii) wasted management or office time; and (viii) for any other loss
                or damage of any kind, however arising and whether caused by tort (including
                negligence), breach of contract or otherwise, even if foreseeable.
              </p>
              <p>
                We do not warrant that functions contained in this website will be uninterrupted or
                error free, that defects will be corrected, or that this website or the server that
                makes it available are free of viruses or bugs.
              </p>
              <p>
                If this Website contains hyperlinks or referrals to other websites (of third
                parties), than this is to be considered as a service to the visitor. Xtramile SAS
                can’t control the other website. Xtramile SAS is not liable for and does not accept
                any liability for the consequences of incorrect or incomplete information on these
                other websites. We do advise you to always check the information on legal aspects as
                well on privacy policies of all sites you visit.
              </p>
              <p>
                In accordance with French law n° 78-17 of January 6, 1978, no personal information
                is collected nor communicated without your knowledge or consent on the Website. In
                appliance with the law of Information and Liberty of January 6th, 1978, you can
                access your personal information, request corrections and opt out of inclusion in
                the database by sending an email to contact@myxtramile.com.
                <br />
                If you do not wish to receive commercial information that may interest you, please
                specify so by sending an email to contact@myxtramile.com.
              </p>
              <p>INTELLECTUAL PROPERTY</p>
              <p>
                The entirety of the Website is subject to French legislation on copyright and
                intellectual property law. Any reproduction rights are reserved including
                downloadable documents, illustrations and photographs. Reproduction of all or part
                of this Website on any electronic medium is strictly forbidden unless specifically
                authorized by Xtramile.
                <br />
                Xtramile SAS hereby authorizes you to copy materials published on this Website for
                non-commercial use only, in accordance with the Users general Conditions, provided
                any copy of these materials which you make shall retain all copyright and other
                proprietary notices and any disclaimer contained thereon and on this Website, and
                subject to three following conditions: free distribution, respect for the integrity
                of reproduced content and clear legible citation of the document source.
              </p>
              <p>
                Notwithstanding this limited consent all other (partly) use without prior written
                consent of Xtramile SAS is prohibited. A request for consent to use (part of) this
                website can be send to contact@myxtramile.com
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

DisclaimerPage.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default DisclaimerPage;
